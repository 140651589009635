import { Codebooks } from './codebooks.types';

const items = {
  light: 'light',
  dark: 'dark',
} as const;

export type UserUITheme = keyof typeof items;

export const CbUserUITheme = {
  code: Codebooks.USER_UI_THEME,
  items,
};

export const DEFAULT_THEME: UserUITheme = items.light;
