import { type TableProps, Table as AntdTable } from 'antd';
import { type AlignType } from 'rc-table/lib/interface';
import styled, { css } from 'styled-components';

import { useBreakpoint } from '../../../utils/style';
import {
  ExtraSmallTextStyles,
  SmallTextStyles,
  XXSmallTextStyles,
} from '../../atoms/Typography';

import { paginationCSS } from '../../atoms/Pagination/Pagination.styles';
import { radioStyles } from '../../atoms/Radio/Radio.styles';

const HEADER_CELL_MIN_HEIGHT = 32;
const HEADER_CELL_MIN_HEIGHT_MOBILE = 24;
const HEADER_CELL_MIN_HEIGHT_SECONDARY = 34;
const BODY_CELL_MIN_HEIGHT = 56;
const BODY_CELL_MIN_HEIGHT_MOBILE = 48;
const BODY_CELL_MIN_HEIGHT_SECONDARY = 32;
const EMPTY_TABLE_HEIGHT = 220;

// [Antd Typings] Ant'd internal types are not exported, need to re-type.
type AntdTypeTable = <RecordType extends object = any>(
  props: TableProps<RecordType>
) => React.ReactElement;

export const Table = styled<AntdTypeTable>(AntdTable)<{
  $hidePagination: boolean;
  $variant?: 'primary' | 'secondary' | 'compact';
  $noHeader?: boolean;
  $headerVerticalAlign?: 'top' | 'middle' | 'bottom';
  $outlined: boolean;
  $splitRows: boolean;
}>`
  &.ant-table-wrapper {
    overflow: hidden;
    ${({ theme, $outlined }) =>
      $outlined &&
      css`
        border: 1px solid ${theme.color.general.borders.primary};
        border-radius: ${({ theme }) => theme.borderRadius.lg}px;
      `};

    .ant-table {
      line-height: normal;
      background: ${({ theme }) => theme.color.general.layout.one};

      ${({ $variant, theme, $noHeader }) =>
        $variant === 'secondary' &&
        css`
          background: ${theme.color.info.backgrounds.secondaryDefault};
          padding: ${$noHeader ? theme.spacing.xs : theme.spacing.sm}px
            ${theme.spacing.md}px;
          border-radius: ${theme.borderRadius.md}px;

          & .ant-table-expanded-row > td > div {
            background: ${theme.color.info.backgrounds.secondaryDefault};
          }
        `};

      /* HEADER ROW */

      .ant-table-thead tr {
        /* HEADER CELL */

        th {
          ${SmallTextStyles};
          height: ${HEADER_CELL_MIN_HEIGHT}px;
          font-size: ${({ theme }) => theme.typography.size.xs}px;
          line-height: ${({ theme }) => theme.typography.height.xs}px;
          background: ${({ theme }) => theme.color.general.layout.two};
          padding: ${({ theme, $variant }) =>
            `${
              $variant === 'secondary' ? theme.spacing.xs : theme.spacing.sm
            }px`};
          color: ${({ theme }) => theme.color.general.typography.secondary};
          border-bottom: none;
          vertical-align: ${({ $headerVerticalAlign }) =>
            $headerVerticalAlign || 'bottom'};
          text-transform: capitalize;

          &.no-text-transform {
            text-transform: none;
          }

          ${({ $variant, theme }) =>
            $variant === 'secondary' &&
            css`
              color: ${({ theme }) => theme.color.general.typography.tertiary};
              background: ${theme.color.info.backgrounds.secondaryDefault};
              height: ${HEADER_CELL_MIN_HEIGHT_SECONDARY}px;
            `};

          ${({ theme }) => useBreakpoint(theme).mobile`
              height: ${HEADER_CELL_MIN_HEIGHT_MOBILE}px;
              padding: ${theme.spacing.xs}px;
          `}
          // FIRST COLUMN HEADER
          &:first-of-type {
            ${({ $variant, theme }) => css`
              padding-left: ${$variant === 'secondary'
                ? 0
                : theme.spacing.md}px;
            `}
            ${({ theme }) => useBreakpoint(theme).mobile`
              padding-left: ${theme.spacing.sm}px;
            `}
          }

          // LAST COLUMN HEADER
          &:last-of-type {
            padding-right: ${({ theme }) => `${theme.spacing.md}px`};
            ${({ theme }) => useBreakpoint(theme).mobile`
              padding-right: ${theme.spacing.sm}px;
            `}
          }

          &::before {
            display: none; // remove default
          }
        }
      }

      .ant-table-tbody {
        /* BODY ROW */

        tr.ant-table-row {
          &.ant-table-row-selected {
            &,
            td,
            &.ant-table-row-cell-hover {
              background: ${({ theme }) =>
                theme.color.info.backgrounds.secondaryDefault};
            }
          }

          /* HIGHLIGHTED BODY ROW */

          &.highlighted-row {
            background: ${({ theme }) =>
              theme.color.info.backgrounds.secondaryDefault};
          }

          /* IF ROW EXPANDABLE */

          .ant-table-row-expand-icon-cell ~ .ant-table-cell {
            cursor: pointer;
          }

          /* IF ROW SELECTABLE */

          .ant-table-cell.ant-table-selection-column ~ .ant-table-cell {
            cursor: pointer;
          }

          /* FIRST ROW */

          &:first-of-type td {
            ${({ $noHeader }) =>
              $noHeader &&
              css`
                border-top: 0;
              `};
          }

          /* ROWS */

          td {
            ${SmallTextStyles};
            height: ${BODY_CELL_MIN_HEIGHT}px;
            padding: ${({ theme }) => `${theme.spacing.sm}px`};
            border-top: ${({ theme }) =>
              `1px solid ${theme.color.general.borders.primary}`};
            border-bottom: none;

            ${({ $variant, theme }) =>
              $variant === 'secondary' &&
              css`
                height: ${BODY_CELL_MIN_HEIGHT_SECONDARY}px;
                border-top-color: ${theme.color.info.backgrounds
                  .secondaryHover};
                padding: ${theme.spacing.xs}px;
              `}
            ${({ theme }) => useBreakpoint(theme).mobile`
              height: ${BODY_CELL_MIN_HEIGHT_MOBILE}px;
              padding: ${theme.spacing.xs}px;
            `}
              // FIRST COLUMN
            &:first-of-type {
              ${({ $variant, theme }) => css`
                padding-left: ${$variant === 'secondary'
                  ? 0
                  : theme.spacing.md}px;
              `}
              ${({ theme }) => useBreakpoint(theme).mobile`
                padding-left: ${theme.spacing.sm}px;
              `}
            }

            // LAST COLUMN
            &:last-of-type {
              ${({ $variant, theme }) => css`
                padding-right: ${$variant === 'secondary'
                  ? 0
                  : theme.spacing.md}px;
              `}
              ${({ theme }) => useBreakpoint(theme).mobile`
                padding-right: ${theme.spacing.sm}px;
              `}
            }

            /* EXPAND ICON */

            &.ant-table-row-expand-icon-cell {
              cursor: pointer;

              ${({ theme }) => useBreakpoint(theme).mobile`
                padding-right: ${theme.spacing.sm}px;
              `}
              .ant-table-row-expand-icon {
                width: 21px;
                height: 21px;
                border-radius: 0;
                color: ${({ theme }) => theme.color.info.typography.primary};
                border-color: ${({ theme }) =>
                  theme.color.info.typography.primary};
                background-color: ${({ theme }) =>
                  theme.color.general.layout.one};

                /* PLUS SIGN */

                &:before {
                  right: 5px;
                  left: 5px;
                  top: 9px;
                  border-radius: 1px;
                }

                &:after {
                  top: 5px;
                  bottom: 5px;
                  left: 9px;
                  border-radius: 1px;
                }

                &.ant-table-row-expand-icon-expanded {
                  background-color: ${({ theme }) =>
                    theme.color.info.typography.primary};
                  color: ${({ theme }) => theme.color.general.layout.one};
                }
              }
            }
          }

          &:hover > td,
          & > td.ant-table-cell-row-hover {
            background: unset; // unset default
          }
        }

        /* EXPANDED ROW */

        tr.ant-table-expanded-row > td {
          padding: 0;
          border-bottom: none;
          background: inherit;
        }
      }

      /* EMPTY STATE */

      &.ant-table-empty {
        .ant-table-tbody {
          height: ${EMPTY_TABLE_HEIGHT}px;

          .ant-table-placeholder > td {
            border-bottom: none;
            background-color: ${({ theme }) => theme.color.general.layout.one};
          }
        }
      }
    }

    /* LOADING STATE */

    .ant-spin-container {
      &:after {
        background: none;
      }

      &.ant-spin-blur:after {
        background: ${({ theme }) => theme.color.general.layout.one};
        opacity: 0.8;
      }
    }

    /* PAGINATION */

    .ant-pagination {
      background: ${({ theme }) => theme.color.general.layout.one};
      padding: ${({ theme }) =>
        `${theme.spacing.xl}px ${theme.spacing.md}px ${theme.spacing.md}px`};
      margin: 0;
      ${({ theme }) => useBreakpoint(theme).mobile`
         padding: ${theme.spacing.lg}px ${theme.spacing.sm}px ${theme.spacing.sm}px;
      `}
      ${paginationCSS};
      ${({ $hidePagination }) =>
        $hidePagination &&
        css`
          .ant-pagination-prev,
          .ant-pagination-item,
          .ant-pagination-next {
            display: none;
          }
        `}
    }

    .ant-table-cell-scrollbar:not([rowspan]) {
      box-shadow: none;
    }
  }

  ${radioStyles};

  ${({ $variant }) => $variant === 'compact' && compactTableCSS};

  ${({ $splitRows }) => $splitRows && splitRowsTableCSS};
`;

export const AlwaysExpandedArea = styled.div<{ $extraPadding?: boolean }>`
  ${({ theme, $extraPadding }) => css`
    background: ${theme.color.general.layout.one};
    padding-top: ${$extraPadding && theme.spacing.md + 'px'};
  `};
`;
export const ExpandIconWrapper = styled.button`
  height: 100%;
  width: 100%;
`;

export const InfoContainer = styled.span`
  display: inline-block;
  height: ${({ theme }) => theme.icon.size.xs}px;
  color: ${({ theme }) => theme.color.info.typography.primary};
  cursor: help;
  vertical-align: middle;
`;

export const TitleContainer = styled.div<{ $align?: AlignType }>`
  display: flex;
  align-items: center;

  ${({ $align }) => css`
    justify-content: ${$align === 'right'
      ? 'flex-end'
      : $align === 'center'
      ? 'center'
      : 'flex-start'};
  `}
`;

const COMPACT_HEADER_MIN_HEIGHT = 24;
const COMPACT_BODY_CELL_MIN_HEIGHT = 24;

const compactTableCSS = css`
  ${({ theme }) => css`
    &.ant-table-wrapper {
      .ant-table {
        /* HEADER ROW */

        .ant-table-thead tr {
          /* HEADER CELL */

          th {
            ${XXSmallTextStyles};
            height: ${COMPACT_HEADER_MIN_HEIGHT}px;
            color: ${theme.color.general.typography.secondary};
            padding: 5px ${theme.spacing.xs}px;
            // FIRST COLUMN HEADER
            &:first-of-type {
              padding-left: ${theme.spacing.sm}px;
            }

            // LAST COLUMN HEADER
            &:last-of-type {
              padding-right: ${theme.spacing.sm}px;
            }
          }
        }

        .ant-table-tbody {
          /* BODY ROW */

          tr.ant-table-row {
            /* ROWS */

            td {
              ${ExtraSmallTextStyles};
              height: ${COMPACT_BODY_CELL_MIN_HEIGHT}px;
              padding: ${theme.spacing.xs}px;

              ${() =>
                useBreakpoint(theme).mobile`${css`
                  ${SmallTextStyles};
                `}`};

              // FIRST COLUMN
              &:first-of-type {
                padding-left: ${theme.spacing.sm}px;
              }

              // LAST COLUMN
              &:last-of-type {
                padding-right: ${theme.spacing.sm}px;
              }
            }
          }
        }
      }

      ${TitleContainer} {
        height: ${theme.typography.height.xxs}px;
      }
    }
  `}
`;

const splitRowsTableCSS = css`
  ${({ theme }) => css`
    &.ant-table-wrapper.table-split-rows {
      & > div > div > .ant-table {
        background: none;

        & > div > div > table {
          border-spacing: 0 ${theme.spacing.md}px;

          /* HEADER ROW */
          .ant-table-thead tr {
            background: ${theme.color.general.layout.two};

            /* HEADER CELL */
            th {
              background: none;

              // FIRST COLUMN HEADER
              &:first-of-type {
                border-bottom-left-radius: ${theme.borderRadius.md}px;
                border-top-left-radius: ${theme.borderRadius.md}px;
              }

              // LAST COLUMN HEADER
              &:last-of-type {
                border-bottom-right-radius: ${theme.borderRadius.md}px;
                border-top-right-radius: ${theme.borderRadius.md}px;
              }
            }
          }

          & > .ant-table-tbody {
            /* BODY ROW */

            & > .ant-table-expanded-row > td {
              & > div:first-of-type {
                // remove border-spacing between row and expanded content row
                margin-top: -${theme.spacing.md}px;

                border: 1px solid ${theme.color.general.borders.primary};
                border-top-width: 0;
              }

              & > .ant-table-wrapper {
                border: 1px solid ${theme.color.general.borders.primary};
                border-top-width: 0;
                border-bottom-left-radius: ${theme.borderRadius.md}px;
                border-bottom-right-radius: ${theme.borderRadius.md}px;

                background: ${theme.color.general.layout.one};

                &:before,
                &:after {
                  display: none;
                }
              }
            }

            & > tr.ant-table-row {
              /* ROWS */
              background: ${theme.color.general.layout.one};

              & > td {
                border-bottom: 1px solid ${theme.color.general.borders.primary};
                border-top: 1px solid ${theme.color.general.borders.primary};

                // FIRST COLUMN CELL
                &:first-of-type {
                  border-left: 1px solid ${theme.color.general.borders.primary};
                  border-top-left-radius: ${theme.borderRadius.md}px;
                  border-bottom-left-radius: ${theme.borderRadius.md}px;
                }

                // LAST COLUMN CELL
                &:last-of-type {
                  border-right: 1px solid ${theme.color.general.borders.primary};
                  border-top-right-radius: ${theme.borderRadius.md}px;
                  border-bottom-right-radius: ${theme.borderRadius.md}px;
                }
              }

              &.highlighted-row {
                td {
                  // FIRST COLUMN CELL
                  &:first-of-type {
                    border-bottom-left-radius: 0;
                  }

                  // LAST COLUMN CELL
                  &:last-of-type {
                    border-bottom-right-radius: 0;
                  }
                }
              }
            }
          }
        }
      }

      & .ant-pagination {
        padding: ${theme.spacing.md}px;
        border: 1px solid ${theme.color.general.borders.primary};
        border-radius: ${theme.borderRadius.md}px;
      }
    }
  `}
`;

export const ScrollAnchor = styled.div`
  && {
    width: 0;
    height: 0;
  }
`;
