import React from 'react';

import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import { DarkModeSwitch } from '@npm/core/ui/components/molecules/DarkModeSwitch';
import { CypressDataIds } from '@npm/core/ui/constants';
import { useOktaUserClaims } from '@npm/okta';

import { useUserContextStore } from '../../context';

import * as S from './UserPanel.styles';

export const UserPanel = () => {
  const user = useUserContextStore(store => store.user);
  const getFullName = useUserContextStore(store => store.getFullName);
  const oktaUserClaims = useOktaUserClaims();

  return (
    <S.UserPanel>
      {!user && !oktaUserClaims ? (
        <S.StyledSkeleton title={false} paragraph={{ rows: 2 }} />
      ) : (
        <S.NameWrapper
          onClick={e => e.stopPropagation()}
          data-cy={CypressDataIds.UserDropdown.UserName}
        >
          <Heading variant="h3" data-dd-privacy="mask">
            {getFullName() || oktaUserClaims?.name}
          </Heading>
          <Text size="xs">{user?.person?.email || oktaUserClaims?.email}</Text>
        </S.NameWrapper>
      )}
      <DarkModeSwitch />
    </S.UserPanel>
  );
};
