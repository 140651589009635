import React from 'react';
import { useTheme } from 'styled-components';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { Text } from '@npm/core/ui/components/atoms/Typography';

import { type SidebarSection as SidebarSectionProps } from '../../PostOnboarding.types';
import { SidebarItem } from '../SidebarItem';

import * as S from './SidebarSection.styles';

type Props = SidebarSectionProps &
  Pick<
    React.ComponentProps<typeof SidebarItem>,
    'activeStepKey' | 'onboardingStatusData' | 'onClick'
  >;

export const SidebarSection = ({
  title,
  subTitle,
  titleTooltip,
  subTitleTooltip,
  items,
  ...sidebarItemProps
}: Props) => {
  const theme = useTheme();

  return (
    <S.SectionWrapper outlined={true} noContentPadding>
      {subTitle && (
        <S.SectionSubTitleWrapper>{subTitle}</S.SectionSubTitleWrapper>
      )}
      <Flex justify="space-between">
        <S.SectionTitleWrapper>
          <Text size="sm" colorVariant="primary">
            {title}
          </Text>
          {titleTooltip && (
            <Tooltip size="lg" title={titleTooltip}>
              <Flex>
                <Icon
                  size="xs"
                  name="info-circle"
                  color={theme.color.info.typography.primary}
                />
              </Flex>
            </Tooltip>
          )}
        </S.SectionTitleWrapper>
      </Flex>
      <S.ItemsWrapper>
        {items.map(item => {
          if (item.hideItem) return null;

          return (
            <SidebarItem
              key={item.key}
              step={item}
              testId={`stepper-item-${item.key}`}
              {...sidebarItemProps}
            />
          );
        })}
      </S.ItemsWrapper>
    </S.SectionWrapper>
  );
};
