import React, { useCallback, useEffect, useState } from 'react';
import { type FormInstance } from 'antd/lib/form';

import { Divider } from '@npm/core/ui/components/atoms/Divider';
import { PhoneNumberInput } from '@npm/core/ui/components/atoms/FormattableInput';
import {
  FormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { Input } from '@npm/core/ui/components/atoms/Input';
import { Select } from '@npm/core/ui/components/atoms/Select';
import {
  type AddressAutocompleteFields,
  AddressAutocomplete,
} from '@npm/core/ui/components/molecules/AddressAutocomplete';
import { CypressDataIds } from '@npm/core/ui/constants';
import {
  Codebooks,
  mapCbItemsForSelectOptions,
  useCodebook,
} from '@npm/data-access';

import { CountrySelect } from '../../../filters';

import * as S from './ContactInformationForm.styles';

type ExtendedAddressAutocompleteFields = AddressAutocompleteFields & {
  address_line_2?: string;
  phoneNumber?: string;
};

type Props = {
  form: FormInstance;
  initialCountryCode?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  isFilled?: boolean;
  inputLayout?: 'horizontal' | 'vertical';
  showDivider?: boolean;
};

const LoadingDataSkeleton = () => (
  <>
    {[...Array(8)].map((_, index) => (
      <S.Skeleton key={index} paragraph={{ rows: 1 }} title={false} />
    ))}
  </>
);

export const ContactInformationForm = ({
  form,
  isLoading,
  initialCountryCode,
  isDisabled,
  isFilled,
  inputLayout = 'horizontal',
  showDivider = false,
}: Props) => {
  const { data: countryData } = useCodebook({
    type: Codebooks.COUNTRY,
  });

  const { data: subregionData } = useCodebook({
    type: Codebooks.SUBREGION,
  });

  const [showAddressFields, setShowAddressFields] = useState(false);
  const [isStateDisabled, setIsStateDisabled] = useState(true);
  const [country, setCountry] = useState('');

  const fullFormVisible = showAddressFields || isFilled;

  const countries = countryData?.codebooks ?? [];
  const subregions = subregionData?.codebooks ?? [];

  const filteredStateOptions = useCallback(
    (country: string) => {
      const filteredSubregions = subregions.filter(subregion =>
        subregion.code.startsWith(country || initialCountryCode)
      );

      return mapCbItemsForSelectOptions(filteredSubregions);
    },
    [initialCountryCode, subregions]
  );

  const handleAutocompleteSelect = (
    address: ExtendedAddressAutocompleteFields
  ) => {
    const countryCbItem = countries.find(
      country => country.code === address.country
    );

    const subregion = subregions.find(subr => {
      if (subr.code === `${address.country}.${address.state}`) {
        return subr;
      }
      if (
        subr.code.startsWith(address.country) &&
        subr.name === address.state
      ) {
        return subr;
      }
      return null;
    });

    form.setFieldsValue({
      ...address,
      country: address.country && countryCbItem?.code ? address.country : '',
      state: subregion?.code,
      address_line_2: '',
    });

    setShowAddressFields(true);

    const countryFieldValue = form.getFieldValue('country');

    if (countryFieldValue) {
      setIsStateDisabled(false);
      setCountry(countryFieldValue);
    }
  };

  useEffect(() => {
    setIsStateDisabled(!initialCountryCode);
  }, [initialCountryCode]);

  // if user tries to submit form without expanding the hidden address fields
  // they should show up, so that they can fill them
  const handleShowAddressFields = (errors: string[]) => {
    if (errors.length && !showAddressFields) {
      setShowAddressFields(true);
      return true;
    }

    return fullFormVisible;
  };

  if (isLoading) {
    return (
      <S.LoadingWrapper>
        <LoadingDataSkeleton />
      </S.LoadingWrapper>
    );
  }

  return (
    <div data-cy="contact-information-form">
      <FormItem noStyle shouldUpdate>
        {form => (
          <>
            <S.Row>
              <S.FormItem
                name="address_autocomplete"
                $direction={inputLayout}
                labelPosition="top"
                label="Search For Your Address"
                data-cy="search_address"
              >
                <AddressAutocomplete
                  onSelect={(_, address) => handleAutocompleteSelect(address)}
                  disabled={isDisabled}
                />
              </S.FormItem>
            </S.Row>

            {!fullFormVisible && (
              <S.Button
                variant="outline"
                data-cy={CypressDataIds.Onboarding.ManualAddressButton}
                onClick={() => setShowAddressFields(true)}
                blockOnMobile
              >
                Enter address manually
              </S.Button>
            )}
            <S.AddressFormWrap
              $isOpen={handleShowAddressFields(
                form.getFieldError('address_line_1')
              )}
            >
              <S.Row $direction={inputLayout}>
                <S.FormItem
                  $direction={inputLayout}
                  labelPosition="top"
                  name="address_line_1"
                  label="Address Line 1"
                  extra="Physical address required. Do not provide a P.O. Box, “in care of” address or mailbox drop location."
                  rules={[VALIDATION_RULES.required('Address Line 1')]}
                  validateTrigger="onBlur"
                >
                  <Input placeholder="Address Line 1" />
                </S.FormItem>
                <S.FormItem
                  $direction={inputLayout}
                  name="address_line_2"
                  label="Address Line 2"
                  requiredMark="optional"
                >
                  <Input placeholder="Address Line 2" />
                </S.FormItem>
              </S.Row>
              <S.Row $direction={inputLayout}>
                <S.FormItem
                  $direction={inputLayout}
                  labelPosition="top"
                  name="country"
                  label="Country"
                  rules={[VALIDATION_RULES.required('Country')]}
                  validateTrigger="onBlur"
                >
                  <CountrySelect
                    onChange={value => {
                      if (value) {
                        setIsStateDisabled(false);
                        setCountry(value);
                        form.setFieldsValue({ state: '' });
                      }
                    }}
                  />
                </S.FormItem>
                <S.FormItem
                  $direction={inputLayout}
                  labelPosition="top"
                  name="state"
                  label="State"
                  rules={[VALIDATION_RULES.required('State')]}
                >
                  <Select
                    options={filteredStateOptions(country)}
                    disabled={isStateDisabled}
                    showSearch
                  />
                </S.FormItem>
              </S.Row>
              <S.Row $direction={inputLayout}>
                <S.FormItem
                  $direction={inputLayout}
                  labelPosition="top"
                  name="city"
                  label="City"
                  rules={[VALIDATION_RULES.required('City')]}
                >
                  <Input placeholder="City" />
                </S.FormItem>
                <S.FormItem
                  $direction={inputLayout}
                  labelPosition="top"
                  name="zip"
                  label="Zip Code"
                  rules={[VALIDATION_RULES.required('Zip')]}
                >
                  <Input placeholder="Zip Code" />
                </S.FormItem>
              </S.Row>
            </S.AddressFormWrap>
            {showDivider && <Divider />}
            <S.Row>
              <S.FormItem
                $direction={inputLayout}
                labelPosition="top"
                extra="For numbers outside the US, include '+' followed by the country code."
                name="phone"
                label="Phone"
                rules={[
                  VALIDATION_RULES.required('Phone'),
                  VALIDATION_RULES.phoneNumber(),
                ]}
                validateTrigger="onSubmit"
              >
                <PhoneNumberInput placeholder="Phone" />
              </S.FormItem>
            </S.Row>
          </>
        )}
      </FormItem>
    </div>
  );
};
