import type React from 'react';

export type StepDefinition<T> = {
  title?: React.ReactNode;
  key: string;
  component?: (props: StepProps<T>) => React.ReactNode;
  // TODO: not necessary - remove?
  rule?: (context: T | undefined) => boolean;
  layout?: boolean;
  hideItem?: boolean;
  showProgressBar?: boolean;
  substeps?: Substep<T>[];
};

type Substep<T> = StepDefinition<T>;

export type OnNextPayload<TContext> = {
  // data to be saved in context
  context?: Partial<TContext>;
  // any other data that needs to be passed where necessary
  attributes?: Record<string, unknown>;
};

export type StepProps<T> = {
  step: StepDefinition<T>;
  progress: number;
  context: Partial<T> | undefined;
  updateContext: React.Dispatch<ContextAction<T>>;
  onNext: (payload?: OnNextPayload<T>) => void;
  onBack?: () => void;
  isNavigating: boolean;
  isCurrentStepLast: boolean;
  onCompleteStep?: (
    attributes?: Record<string, unknown>
  ) => Promise<void> | void;
  toggleSidebarOpen?: () => void;
  nextStep?: StepDefinition<T>;
  nextSubstep?: StepDefinition<T>;
};

type ContextActionConfig<T = {}> = {
  onComplete?: () => void;
  isStepIncomplete?: (step: StepDefinition<T>, context: T) => boolean;
};

export interface WizardContext<T> {
  data?: Partial<T>;
  stepIndex: number;
  substepIndex?: number;
  progress: number;
  steps: StepDefinition<T>[];
}

export enum ContextActionType {
  UPDATE_DATA = 'UPDATE_DATA',
  MOVE_NEXT = 'MOVE_NEXT',
  MOVE_BACK = 'MOVE_BACK',
  UPDATE_STEP_INDEX = 'UPDATE_STEP_INDEX',
  UPDATE_SUBSTEP_INDEX = 'UPDATE_SUBSTEP_INDEX',
  UPDATE_STEPS = 'UPDATE_STEPS',
  RESET_CONTEXT = 'RESET_CONTEXT',
}

export type ContextAction<T> =
  | {
      type: ContextActionType.UPDATE_DATA;
      payload: Partial<T>;
      config?: ContextActionConfig;
    }
  | {
      type: ContextActionType.MOVE_NEXT;
      config?: ContextActionConfig;
    }
  | {
      type: ContextActionType.MOVE_BACK;
      config?: ContextActionConfig;
    }
  | {
      type: ContextActionType.UPDATE_STEP_INDEX;
      payload:
        | number
        | string
        | { step: number | string; substep?: number | string };
      config?: ContextActionConfig;
    }
  | {
      type: ContextActionType.UPDATE_STEPS;
      payload: StepDefinition<T>[];
      config?: ContextActionConfig;
    }
  | {
      type: ContextActionType.RESET_CONTEXT;
      payload?: Partial<WizardContext<T>>;
    };

export enum PageState {
  NONE,
  INITIAL,
  EDIT,
  FILLED,
  SUCCESS,
}
