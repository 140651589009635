import React, { useMemo } from 'react';
import { uniqBy } from 'lodash';

import {
  Select,
  SELECT_PAGE_SIZE,
  useSelectAsync,
} from '@npm/core/ui/components/atoms/Select';
import { onPopupScroll } from '@npm/core/ui/components/atoms/Select/Select.utils';
import { CompanySearchLogo } from '@npm/core/ui/components/molecules/CompanySearchLogo';
import { CypressDataIds } from '@npm/core/ui/constants';
import {
  type IssuerEntityAggregate,
  type IssuerEntityApiIssuerEntityFiltersIndexRequest,
  type IssuerEntityApiIssuerEntityIndexRequest,
  useIssuerEntityShow,
} from '@npm/data-access';

import { useRequestCompanyCoverage } from '../../../company/RequestCompanyCoverage';

import { useIssuerEntities } from './CompanySearch.hooks';

type Props = {
  onSelect?: (val?: string) => void;
  onClear?: (val?: string) => void;
  onChange?: (val: string) => void;
  value?: string | number;
  defaultEntity?: IssuerEntityAggregate;
  placeholder?: string;
  suffixIcon?: React.ReactNode;
  disabled?: boolean;
  variables?: IssuerEntityApiIssuerEntityIndexRequest;
  resourceType?: IssuerEntityApiIssuerEntityFiltersIndexRequest['resourceType'];
  // Flag if search should be clear after item is selected
  autoClearSearchValue?: boolean;
  requestCoverageVariant?: 'area' | 'row';
};

const LOADING_PLACEHOLDER = 'Search';

export const CompanySearch = ({
  defaultEntity,
  value,
  placeholder,
  variables = {},
  resourceType,
  autoClearSearchValue = true,
  requestCoverageVariant,
  ...restProps
}: Props) => {
  const [{ searchTerm }, selectAsyncProps] = useSelectAsync();
  const {
    requestCoverageButtonComponent,
    requestCoverageModalComponent,
    requestCoverageAreaComponent,
  } = useRequestCompanyCoverage({ searchTerm });

  const {
    data,
    isLoading,
    error,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useIssuerEntities({
    variables: {
      page: 1,
      size: SELECT_PAGE_SIZE,
      ...variables,
    },
    searchTerm,
    resourceType,
  });

  const { data: issuerEntity } = useIssuerEntityShow(
    {
      id: value?.toString(),
    },
    {
      queryConfig: {
        enabled: !!value,
      },
    }
  );

  const options = useMemo(() => {
    if (!data && !defaultEntity) return null;

    const mergedIssuerEntity = data?.pages?.reduce((mergedArray, page) => {
      return mergedArray.concat(page.issuer_entities);
    }, []);

    const companies =
      defaultEntity && !searchTerm
        ? [defaultEntity, ...(mergedIssuerEntity || [])]
        : mergedIssuerEntity || [];

    return uniqBy(
      [
        ...[
          ...companies,
          ...(issuerEntity && !searchTerm ? [issuerEntity] : []),
        ].map(item => ({
          label: (
            <CompanySearchLogo
              url={item.logo_url}
              size="sm"
              name={item.name}
              title={item.name}
              isDisabled={restProps.disabled}
            />
          ),
          value: item.id,
        })),
      ],
      'value'
    );
  }, [data, defaultEntity, searchTerm, issuerEntity]);

  // hack to not show for a second company id in the select, if it is not loaded yet
  // the id is shown because of debounce of the searchTerm
  const existOptionForValue = useMemo(() => {
    return options?.some(option => option.value === value);
  }, [options, value]);

  const resetSearchAndCallMethod = (
    val: string,
    fn?: (val: string) => void
  ) => {
    if (autoClearSearchValue === true) {
      selectAsyncProps?.onSearch('');
    }
    fn?.(val);
  };

  const isSearchedCompanyExisting = options?.some(
    company =>
      company?.label?.props?.name?.toLowerCase() === searchTerm.toLowerCase()
  );

  const isSearchedCompanyNotFound =
    !isLoading && !!searchTerm.length && !isSearchedCompanyExisting;

  return (
    <>
      <Select
        variant="search"
        onPopupScroll={e =>
          onPopupScroll(e, hasNextPage && !isFetchingNextPage && fetchNextPage)
        }
        data-cy={CypressDataIds.Filters.CompanySearch}
        placeholder={
          placeholder ||
          (data
            ? `All (${data?.pages[0]?.pagination?.total_records ?? 0})`
            : LOADING_PLACEHOLDER)
        }
        loading={isLoading}
        infiniteLoading={isFetchingNextPage}
        // If value is not in options or issuer entity is loading or options length, set value to null
        value={existOptionForValue ? value : null}
        error={error}
        {...restProps}
        onChange={(val: string) => {
          resetSearchAndCallMethod(val, restProps.onChange);
        }}
        onSelect={(val: string) => {
          resetSearchAndCallMethod(val, restProps.onSelect);
        }}
        {...selectAsyncProps}
      >
        {options?.map(option => (
          <Select.Option
            key={option.value}
            value={option.value}
            label={option.label}
          >
            {option.label}
          </Select.Option>
        ))}
        {isSearchedCompanyNotFound && requestCoverageVariant === 'row' && (
          <Select.Option disabled>
            {requestCoverageButtonComponent}
          </Select.Option>
        )}
        {isSearchedCompanyNotFound && requestCoverageVariant === 'area' && (
          <Select.Option disabled>{requestCoverageAreaComponent}</Select.Option>
        )}
      </Select>
      {requestCoverageVariant === 'row' && requestCoverageModalComponent}
    </>
  );
};
