/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { MatchActivityIndex } from '../model';
// @ts-ignore
import { MatchActivityRofrWaiveCreateRequestContract } from '../model';
// @ts-ignore
import { MatchActivityWithMatchesAggregate } from '../model';
/**
 * MatchActivityApi - axios parameter creator
 * @export
 */
export const MatchActivityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves match activities associated with the issuer entity specified in the path
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {number} [eventId] 
         * @param {number} [issuerEntityId] 
         * @param {number} [issuanceId] 
         * @param {string} [assetType] 
         * @param {Array<string>} [matchState] 
         * @param {string} [transactionDate] 
         * @param {number} [accountId] 
         * @param {Array<number>} [orderItemId] 
         * @param {boolean} [issuerActionItemsFirst] 
         * @param {boolean} [findRequiresAttention] 
         * @param {boolean} [assignedAccounts] 
         * @param {Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matchActivitiesIndex: async (page?: number, size?: number, search?: string, eventId?: number, issuerEntityId?: number, issuanceId?: number, assetType?: string, matchState?: Array<string>, transactionDate?: string, accountId?: number, orderItemId?: Array<number>, issuerActionItemsFirst?: boolean, findRequiresAttention?: boolean, assignedAccounts?: boolean, includeField?: Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/match-activities`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (eventId !== undefined) {
                localVarQueryParameter['event_id'] = eventId;
            }

            if (issuerEntityId !== undefined) {
                localVarQueryParameter['issuer_entity_id'] = issuerEntityId;
            }

            if (issuanceId !== undefined) {
                localVarQueryParameter['issuance_id'] = issuanceId;
            }

            if (assetType !== undefined) {
                localVarQueryParameter['asset_type'] = assetType;
            }

            if (matchState) {
                localVarQueryParameter['match_state'] = matchState.join(COLLECTION_FORMATS.csv);
            }

            if (transactionDate !== undefined) {
                localVarQueryParameter['transaction_date'] = transactionDate;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['account_id'] = accountId;
            }

            if (orderItemId) {
                localVarQueryParameter['order_item_id'] = orderItemId.join(COLLECTION_FORMATS.csv);
            }

            if (issuerActionItemsFirst !== undefined) {
                localVarQueryParameter['issuer_action_items_first'] = issuerActionItemsFirst;
            }

            if (findRequiresAttention !== undefined) {
                localVarQueryParameter['find_requires_attention'] = findRequiresAttention;
            }

            if (assignedAccounts !== undefined) {
                localVarQueryParameter['assigned_accounts'] = assignedAccounts;
            }

            if (includeField) {
                localVarQueryParameter['include_field'] = includeField.join(COLLECTION_FORMATS.csv);
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary waives ROFR for all matches within the match activity
         * @param {MatchActivityRofrWaiveCreateRequestContract} matchActivityRofrWaiveCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matchActivityRofrWaivesCreate: async (matchActivityRofrWaiveCreateRequestContract: MatchActivityRofrWaiveCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'matchActivityRofrWaiveCreateRequestContract' is not null or undefined
            assertParamExists('matchActivityRofrWaivesCreate', 'matchActivityRofrWaiveCreateRequestContract', matchActivityRofrWaiveCreateRequestContract)
            const localVarPath = changeUrl(`/api/match-activity-rofr-waives`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(matchActivityRofrWaiveCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MatchActivityApi - functional programming interface
 * @export
 */
export const MatchActivityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MatchActivityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves match activities associated with the issuer entity specified in the path
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {number} [eventId] 
         * @param {number} [issuerEntityId] 
         * @param {number} [issuanceId] 
         * @param {string} [assetType] 
         * @param {Array<string>} [matchState] 
         * @param {string} [transactionDate] 
         * @param {number} [accountId] 
         * @param {Array<number>} [orderItemId] 
         * @param {boolean} [issuerActionItemsFirst] 
         * @param {boolean} [findRequiresAttention] 
         * @param {boolean} [assignedAccounts] 
         * @param {Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async matchActivitiesIndex(page?: number, size?: number, search?: string, eventId?: number, issuerEntityId?: number, issuanceId?: number, assetType?: string, matchState?: Array<string>, transactionDate?: string, accountId?: number, orderItemId?: Array<number>, issuerActionItemsFirst?: boolean, findRequiresAttention?: boolean, assignedAccounts?: boolean, includeField?: Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchActivityIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.matchActivitiesIndex(page, size, search, eventId, issuerEntityId, issuanceId, assetType, matchState, transactionDate, accountId, orderItemId, issuerActionItemsFirst, findRequiresAttention, assignedAccounts, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary waives ROFR for all matches within the match activity
         * @param {MatchActivityRofrWaiveCreateRequestContract} matchActivityRofrWaiveCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async matchActivityRofrWaivesCreate(matchActivityRofrWaiveCreateRequestContract: MatchActivityRofrWaiveCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchActivityWithMatchesAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.matchActivityRofrWaivesCreate(matchActivityRofrWaiveCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MatchActivityApi - factory interface
 * @export
 */
export const MatchActivityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MatchActivityApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves match activities associated with the issuer entity specified in the path
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {number} [eventId] 
         * @param {number} [issuerEntityId] 
         * @param {number} [issuanceId] 
         * @param {string} [assetType] 
         * @param {Array<string>} [matchState] 
         * @param {string} [transactionDate] 
         * @param {number} [accountId] 
         * @param {Array<number>} [orderItemId] 
         * @param {boolean} [issuerActionItemsFirst] 
         * @param {boolean} [findRequiresAttention] 
         * @param {boolean} [assignedAccounts] 
         * @param {Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matchActivitiesIndex(page?: number, size?: number, search?: string, eventId?: number, issuerEntityId?: number, issuanceId?: number, assetType?: string, matchState?: Array<string>, transactionDate?: string, accountId?: number, orderItemId?: Array<number>, issuerActionItemsFirst?: boolean, findRequiresAttention?: boolean, assignedAccounts?: boolean, includeField?: Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<MatchActivityIndex> {
            return localVarFp.matchActivitiesIndex(page, size, search, eventId, issuerEntityId, issuanceId, assetType, matchState, transactionDate, accountId, orderItemId, issuerActionItemsFirst, findRequiresAttention, assignedAccounts, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary waives ROFR for all matches within the match activity
         * @param {MatchActivityRofrWaiveCreateRequestContract} matchActivityRofrWaiveCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matchActivityRofrWaivesCreate(matchActivityRofrWaiveCreateRequestContract: MatchActivityRofrWaiveCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<MatchActivityWithMatchesAggregate> {
            return localVarFp.matchActivityRofrWaivesCreate(matchActivityRofrWaiveCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for matchActivitiesIndex operation in MatchActivityApi.
 * @export
 * @interface MatchActivityApiMatchActivitiesIndexRequest
 */
export interface MatchActivityApiMatchActivitiesIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof MatchActivityApiMatchActivitiesIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof MatchActivityApiMatchActivitiesIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof MatchActivityApiMatchActivitiesIndex
     */
    readonly search?: string

    /**
     * 
     * @type {number}
     * @memberof MatchActivityApiMatchActivitiesIndex
     */
    readonly eventId?: number

    /**
     * 
     * @type {number}
     * @memberof MatchActivityApiMatchActivitiesIndex
     */
    readonly issuerEntityId?: number

    /**
     * 
     * @type {number}
     * @memberof MatchActivityApiMatchActivitiesIndex
     */
    readonly issuanceId?: number

    /**
     * 
     * @type {string}
     * @memberof MatchActivityApiMatchActivitiesIndex
     */
    readonly assetType?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof MatchActivityApiMatchActivitiesIndex
     */
    readonly matchState?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof MatchActivityApiMatchActivitiesIndex
     */
    readonly transactionDate?: string

    /**
     * 
     * @type {number}
     * @memberof MatchActivityApiMatchActivitiesIndex
     */
    readonly accountId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof MatchActivityApiMatchActivitiesIndex
     */
    readonly orderItemId?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof MatchActivityApiMatchActivitiesIndex
     */
    readonly issuerActionItemsFirst?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MatchActivityApiMatchActivitiesIndex
     */
    readonly findRequiresAttention?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MatchActivityApiMatchActivitiesIndex
     */
    readonly assignedAccounts?: boolean

    /**
     * 
     * @type {Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>}
     * @memberof MatchActivityApiMatchActivitiesIndex
     */
    readonly includeField?: Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>

    /**
     * 
     * @type {string}
     * @memberof MatchActivityApiMatchActivitiesIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof MatchActivityApiMatchActivitiesIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof MatchActivityApiMatchActivitiesIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof MatchActivityApiMatchActivitiesIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof MatchActivityApiMatchActivitiesIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof MatchActivityApiMatchActivitiesIndex
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for matchActivityRofrWaivesCreate operation in MatchActivityApi.
 * @export
 * @interface MatchActivityApiMatchActivityRofrWaivesCreateRequest
 */
export interface MatchActivityApiMatchActivityRofrWaivesCreateRequest {
    /**
     * 
     * @type {MatchActivityRofrWaiveCreateRequestContract}
     * @memberof MatchActivityApiMatchActivityRofrWaivesCreate
     */
    readonly matchActivityRofrWaiveCreateRequestContract: MatchActivityRofrWaiveCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof MatchActivityApiMatchActivityRofrWaivesCreate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof MatchActivityApiMatchActivityRofrWaivesCreate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof MatchActivityApiMatchActivityRofrWaivesCreate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof MatchActivityApiMatchActivityRofrWaivesCreate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof MatchActivityApiMatchActivityRofrWaivesCreate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof MatchActivityApiMatchActivityRofrWaivesCreate
     */
    readonly xUserRoleId?: string
}

/**
 * MatchActivityApi - object-oriented interface
 * @export
 * @class MatchActivityApi
 * @extends {BaseAPI}
 */
export class MatchActivityApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves match activities associated with the issuer entity specified in the path
     * @param {MatchActivityApiMatchActivitiesIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchActivityApi
     */
    public matchActivitiesIndex(requestParameters: MatchActivityApiMatchActivitiesIndexRequest = {}, options?: AxiosRequestConfig) {
        return MatchActivityApiFp(this.configuration).matchActivitiesIndex(requestParameters.page, requestParameters.size, requestParameters.search, requestParameters.eventId, requestParameters.issuerEntityId, requestParameters.issuanceId, requestParameters.assetType, requestParameters.matchState, requestParameters.transactionDate, requestParameters.accountId, requestParameters.orderItemId, requestParameters.issuerActionItemsFirst, requestParameters.findRequiresAttention, requestParameters.assignedAccounts, requestParameters.includeField, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary waives ROFR for all matches within the match activity
     * @param {MatchActivityApiMatchActivityRofrWaivesCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchActivityApi
     */
    public matchActivityRofrWaivesCreate(requestParameters: MatchActivityApiMatchActivityRofrWaivesCreateRequest, options?: AxiosRequestConfig) {
        return MatchActivityApiFp(this.configuration).matchActivityRofrWaivesCreate(requestParameters.matchActivityRofrWaiveCreateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type MatchActivitiesIndexErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const MatchActivitiesIndexErrorCodes = [
];

export type MatchActivityRofrWaivesCreateErrorTypes =

    | {
        status: 403;
    }

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const MatchActivityRofrWaivesCreateErrorCodes = [
    403,
    422,
];


