/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { StakeholdersStatisticShow } from '../model';
/**
 * StakeholderOwnershipStatsApi - axios parameter creator
 * @export
 */
export const StakeholderOwnershipStatsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary list all stakeholders and their ownership
         * @param {string} issuerId 
         * @param {'PUBLIC' | 'NPMS_PORTFOLIO'} [capTableType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stakeholderOwnershipStats: async (issuerId: string, capTableType?: 'PUBLIC' | 'NPMS_PORTFOLIO', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('stakeholderOwnershipStats', 'issuerId', issuerId)
            const localVarPath = changeUrl(`/api/admin/issuers/{issuer_id}/stakeholders/ownership/statistics`)
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (capTableType !== undefined) {
                localVarQueryParameter['cap_table_type'] = capTableType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StakeholderOwnershipStatsApi - functional programming interface
 * @export
 */
export const StakeholderOwnershipStatsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StakeholderOwnershipStatsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary list all stakeholders and their ownership
         * @param {string} issuerId 
         * @param {'PUBLIC' | 'NPMS_PORTFOLIO'} [capTableType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stakeholderOwnershipStats(issuerId: string, capTableType?: 'PUBLIC' | 'NPMS_PORTFOLIO', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StakeholdersStatisticShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stakeholderOwnershipStats(issuerId, capTableType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StakeholderOwnershipStatsApi - factory interface
 * @export
 */
export const StakeholderOwnershipStatsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StakeholderOwnershipStatsApiFp(configuration)
    return {
        /**
         * 
         * @summary list all stakeholders and their ownership
         * @param {string} issuerId 
         * @param {'PUBLIC' | 'NPMS_PORTFOLIO'} [capTableType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stakeholderOwnershipStats(issuerId: string, capTableType?: 'PUBLIC' | 'NPMS_PORTFOLIO', options?: any): AxiosPromise<StakeholdersStatisticShow> {
            return localVarFp.stakeholderOwnershipStats(issuerId, capTableType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for stakeholderOwnershipStats operation in StakeholderOwnershipStatsApi.
 * @export
 * @interface StakeholderOwnershipStatsApiStakeholderOwnershipStatsRequest
 */
export interface StakeholderOwnershipStatsApiStakeholderOwnershipStatsRequest {
    /**
     * 
     * @type {string}
     * @memberof StakeholderOwnershipStatsApiStakeholderOwnershipStats
     */
    readonly issuerId: string

    /**
     * 
     * @type {'PUBLIC' | 'NPMS_PORTFOLIO'}
     * @memberof StakeholderOwnershipStatsApiStakeholderOwnershipStats
     */
    readonly capTableType?: 'PUBLIC' | 'NPMS_PORTFOLIO'
}

/**
 * StakeholderOwnershipStatsApi - object-oriented interface
 * @export
 * @class StakeholderOwnershipStatsApi
 * @extends {BaseAPI}
 */
export class StakeholderOwnershipStatsApi extends BaseAPI {
    /**
     * 
     * @summary list all stakeholders and their ownership
     * @param {StakeholderOwnershipStatsApiStakeholderOwnershipStatsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StakeholderOwnershipStatsApi
     */
    public stakeholderOwnershipStats(requestParameters: StakeholderOwnershipStatsApiStakeholderOwnershipStatsRequest, options?: AxiosRequestConfig) {
        return StakeholderOwnershipStatsApiFp(this.configuration).stakeholderOwnershipStats(requestParameters.issuerId, requestParameters.capTableType, options).then((request) => request(this.axios, this.basePath));
    }
}

export type StakeholderOwnershipStatsErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const StakeholderOwnershipStatsErrorCodes = [
];


