import React from 'react';
import { useTheme } from 'styled-components';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import {
  DATE_FORMATS,
  formatDate,
  formatQuantity,
} from '@npm/core/ui/utils/formatters';

import { type CompanyOverviewChartSeries } from '../../../../CompanyOverview.types';
import { type TapeDValue } from '../../../TapeD.types';

import * as S from './TapeDChartTooltip.styles';

type Props = {
  value: TapeDValue | undefined;
  series: CompanyOverviewChartSeries;
};

export const TapeDChartTooltip = ({ value, series }: Props) => {
  const theme = useTheme();

  if (!value) {
    return null;
  }

  return (
    <S.Wrapper>
      <Flex direction="column" gap="sm" align="stretch">
        <Flex direction="row" gap={'xs'} justify="space-between" align="center">
          <Text color={'info'}>TapeD Price</Text>
          <Text size="xs">
            {formatDate(value.date, { dateFormat: DATE_FORMATS.DATE })}
          </Text>
        </Flex>
        <S.ContentWrapper>
          <Text.Price
            style={{
              fontSize: theme.typography.size.xl + 'px',
              lineHeight: theme.typography.height.xxl + 'px',
            }}
            value={
              series === 'PPS'
                ? value.mid_estimate
                : value.mid_implied_valuation
            }
            color={'info'}
            compact
            currencyAsSymbol
            formatOptions={{
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }}
          />
          <Flex align={'center'}>
            <Text size={'xxs'} colorVariant={'tertiary'}>
              {series === 'PPS' ? 'Implied Val.' : 'Price Per Share'}
            </Text>
            &nbsp;
            <Text.Price
              size={'xxs'}
              colorVariant={'primary'}
              value={
                series === 'PPS'
                  ? value.mid_implied_valuation
                  : value.mid_estimate
              }
              compact
              currencyAsSymbol
              formatOptions={{
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }}
            />
          </Flex>
        </S.ContentWrapper>
        <S.Footer>
          <Text size={'sm'} colorVariant={'primary'}>
            Prem/Disc
          </Text>
          <Flex align={'center'} gap={'xs'}>
            <Text
              size={'xs'}
              color={value.mid_discount_or_premium > 0 ? 'success' : 'error'}
            >
              {formatQuantity(value.mid_discount_or_premium, {
                maximumFractionDigits: 0,
              })}
              %
            </Text>
            <Text size={'xs'} colorVariant={'primary'}>
              vs LR
            </Text>
            <Text size={'xs'} colorVariant={'tertiary'}>
              (
              {formatDate(value.last_round_date, {
                dateFormat: DATE_FORMATS.DATE,
              })}
              )
            </Text>
          </Flex>
        </S.Footer>
      </Flex>
    </S.Wrapper>
  );
};
