/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * PersonaWebhookApi - axios parameter creator
 * @export
 */
export const PersonaWebhookApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Updates the background state of an Account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicPersonaCreate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/public/persona`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PersonaWebhookApi - functional programming interface
 * @export
 */
export const PersonaWebhookApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PersonaWebhookApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Updates the background state of an Account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicPersonaCreate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicPersonaCreate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PersonaWebhookApi - factory interface
 * @export
 */
export const PersonaWebhookApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PersonaWebhookApiFp(configuration)
    return {
        /**
         * 
         * @summary Updates the background state of an Account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicPersonaCreate(options?: any): AxiosPromise<void> {
            return localVarFp.publicPersonaCreate(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PersonaWebhookApi - object-oriented interface
 * @export
 * @class PersonaWebhookApi
 * @extends {BaseAPI}
 */
export class PersonaWebhookApi extends BaseAPI {
    /**
     * 
     * @summary Updates the background state of an Account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonaWebhookApi
     */
    public publicPersonaCreate(options?: AxiosRequestConfig) {
        return PersonaWebhookApiFp(this.configuration).publicPersonaCreate(options).then((request) => request(this.axios, this.basePath));
    }
}

export type PublicPersonaCreateErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const PublicPersonaCreateErrorCodes = [
];


