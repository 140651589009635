import { type FormInstance } from 'antd';

import { Margin } from '@npm/core/ui/components/atoms/common';
import { Divider } from '@npm/core/ui/components/atoms/Divider';
import { Form } from '@npm/core/ui/components/atoms/Form';
import {
  FormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { CharLimitHelper, TextArea } from '@npm/core/ui/components/atoms/Input';
import { Select } from '@npm/core/ui/components/atoms/Select';
import {
  CollapsibleFormSection,
  useCollapsibleFormSection,
} from '@npm/core/ui/components/molecules/CollapsibleFormSection';
import { DrawerSection } from '@npm/core/ui/components/molecules/DrawerSection';
import { WatchlistDataIds } from '@npm/core/ui/constants';
import {
  type UserRoleAggregate,
  type WatchlistApiWatchlistIndexRequest,
  CbAssetType,
  useUserRoleIndex,
} from '@npm/data-access';

import {
  NPMS_NAME,
  USER_ROLE_INDEX_BASE_PARAMS,
} from '../../../../auth/user/context';
import { useObo } from '../../../../auth/user/role';
import { UserRoleSearch } from '../../../../filters';
import {
  getAssetTypePreview,
  getNotePreview,
  NOTE_MAX_LENGTH,
  NOTE_PREVIEW_MAX_LENGTH,
  OrderSizeInput,
} from '../../../../order';

import { getAccountFormItemPreview } from './UpsertWatchlistForm.utils';

const getLatestNpmsAccount = (roles: UserRoleAggregate[]) => {
  const filteredRoles = roles?.filter(
    role => role.subject?.brokerage_firm_name === NPMS_NAME
  );
  return filteredRoles?.[filteredRoles.length - 1];
};

type Props = {
  form: FormInstance<WatchlistApiWatchlistIndexRequest>;
  assetTypes: { label: string; value: string }[];
  showSectionTitles?: boolean;
  showAccountFormItem?: boolean;
  filterAccounts?: (role: UserRoleAggregate) => boolean;
};

export const WatchlistEntrySection = ({
  form,
  assetTypes,
  showSectionTitles = true,
  showAccountFormItem = false,
  filterAccounts,
}: Props) => {
  const { isObo, oboAccount } = useObo();
  const { data: userRoleIndexData } = useUserRoleIndex({
    ...USER_ROLE_INDEX_BASE_PARAMS,
  });
  const roles = filterAccounts
    ? userRoleIndexData?.user_roles?.filter(filterAccounts)
    : userRoleIndexData?.user_roles;

  const { activeKeys, handleItemChange, handleSectionChange } =
    useCollapsibleFormSection({});

  const assetType = Form.useWatch('asset_type', form);
  const accountId = Form.useWatch('account_id', form);
  const note = Form.useWatch('note', form);

  const orderSizeFormItem = (
    <FormItem name="minimum_size">
      <OrderSizeInput sizeType={'USD'} label="Min. Size" required={false} />
    </FormItem>
  );

  const assetTypeFormItem = (
    <CollapsibleFormSection.Item
      key="asset_type"
      name="asset_type"
      label="Asset Type"
      preview={getAssetTypePreview(assetTypes, assetType)}
    >
      <Margin bottom="sm">
        <FormItem
          name="asset_type"
          initialValue={CbAssetType.items.AnyShareClass}
          data-cy={WatchlistDataIds.Drawer.Form.AssetTypeSelect}
        >
          <Select
            options={assetTypes}
            onSelect={() => handleItemChange('asset_type')}
          />
        </FormItem>
      </Margin>
    </CollapsibleFormSection.Item>
  );

  const accountFormItem = (
    <CollapsibleFormSection.Item
      key="account_id"
      name="account_id"
      label="Account"
      preview={
        isObo ? oboAccount?.name : getAccountFormItemPreview(roles, accountId)
      }
      {...(isObo && { collapsible: 'disabled' })}
    >
      <Margin bottom="sm">
        <FormItem
          name="account_id"
          initialValue={
            isObo
              ? oboAccount?.id
              : getLatestNpmsAccount(roles)?.subject?.id ??
                roles?.[0]?.subject?.id
          }
        >
          <UserRoleSearch filterOptions={filterAccounts} />
        </FormItem>
      </Margin>
    </CollapsibleFormSection.Item>
  );

  const noteFormItem = (
    <CollapsibleFormSection.Item
      label="Note"
      key="note"
      name="note"
      placeholder=""
      preview={getNotePreview(note, NOTE_PREVIEW_MAX_LENGTH)}
    >
      <FormItem
        name="note"
        rules={[VALIDATION_RULES.max(NOTE_MAX_LENGTH)]}
        extra={<CharLimitHelper fieldName="note" limit={NOTE_MAX_LENGTH} />}
      >
        {/* overwrite scrollIntoView on text area focus, not desired here */}
        <TextArea placeholder="Note" onFocus={null} />
      </FormItem>
    </CollapsibleFormSection.Item>
  );

  if (!showSectionTitles) {
    return (
      <div>
        <Margin bottom="md">{orderSizeFormItem}</Margin>
        <CollapsibleFormSection
          activeKey={activeKeys}
          onChange={handleSectionChange}
        >
          {assetTypeFormItem}
          <Divider marginBottom={0} />
          {showAccountFormItem && (
            <>
              {accountFormItem}
              <Divider marginBottom={0} />
            </>
          )}
          {noteFormItem}
        </CollapsibleFormSection>
      </div>
    );
  }

  return (
    <>
      <DrawerSection
        iconName="clipboard-text"
        title="Size"
        content={
          <Margin top="md" bottom="md">
            {orderSizeFormItem}
          </Margin>
        }
      />
      <DrawerSection
        iconName="adjust"
        title="Order Preferences"
        content={
          <Margin top={'sm'}>
            <CollapsibleFormSection
              activeKey={activeKeys}
              onChange={handleSectionChange}
            >
              {assetTypeFormItem}
              <Divider marginBottom={0} />
              {showAccountFormItem && (
                <>
                  {accountFormItem}
                  <Divider marginBottom={0} />
                </>
              )}
              {noteFormItem}
            </CollapsibleFormSection>
          </Margin>
        }
      />
    </>
  );
};
