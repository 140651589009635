import { type TableVerticalRowType } from '@npm/core/ui/components/molecules/TableVertical';
import { type DocumentSimple, type Holding } from '@npm/data-access';

import { getHoldingItem } from '../../../holdings';

export const getHoldingRows = ({
  onDocumentClick,
}: {
  onDocumentClick?: (doc: DocumentSimple) => void;
}): TableVerticalRowType<Holding>[] => {
  return [
    {
      ...getHoldingItem('asset')({
        styledAssetType: true,
      }),
    },
    {
      ...getHoldingItem('class')(),
    },
    {
      ...getHoldingItem('state')(),
      title: 'Status',
    },
    {
      ...getHoldingItem('registered_name')(),
    },
    {
      ...getHoldingItem('proof_of_ownership_document')({
        onClick: onDocumentClick,
      }),
    },
    {
      ...getHoldingItem('vested_qty')(),
    },
    {
      ...getHoldingItem('acquisition_date')(),
    },
  ];
};
