import { Input } from 'antd';
import styled, { css } from 'styled-components';

import { useBreakpoint } from '../../../utils/style';
import { InputTextStyles } from '../Typography';

import {
  disabledCSS,
  INPUT_HEIGHT,
  INPUT_MOBILE_HEIGHT,
  readOnlyCSS,
} from '../Input/styles';

const ICON_WIDTH = 20;
const ICON_HEIGHT = 20;
const LEFT_PADDING_WITH_ICON = 40;

export const StyledSearch = styled(Input.Search)<{
  $readOnly?: boolean;
  $isSearchIconVisible?: boolean;
}>`
  /* Hide up and down arrows for search number input: https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp */

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */

  input[type='number'] {
    -moz-appearance: textfield;
  }

  // Fix for autofill background color
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  &.ant-input-group-wrapper {
    background-color: ${({ theme }) => theme.color.general.layout.two};
    color: ${({ theme }) => theme.color.general.typography.primary};
    border-width: 1px;
    border-style: solid;
    border-radius: ${({ theme }) => theme.borderRadius.sm}px;
    border-color: ${({ theme }) => theme.color.general.borders.secondary};

    /* On focus */
    &:focus-within:not(.ant-input-group-wrapper-disabled) {
      border-color: ${({ theme }) => theme.color.info.borders.primary};
      background-color: ${({ theme }) => theme.color.general.layout.one};
      box-shadow: ${({ theme }) => theme.shadow.level1},
        0 0 0 1px ${({ theme }) => theme.color.info.borders.primary};
    }

    /* On hover */

    &:hover:not(.ant-input-group-wrapper-disabled) {
      border-color: ${({ theme }) => theme.color.info.borders.primary};
    }

    /* On error */

    &.ant-input-group-wrapper-status-error {
      border-color: ${({ theme }) => theme.color.error.typography.primary};
      background-color: ${({ theme }) =>
        theme.color.general.layout
          .two}; /* This overrides ANT style when losing hover */

      &:not(:hover) {
        border-color: ${({ theme }) => theme.color.error.typography.primary};
      }

      .ant-input-status-error {
        background-color: ${({ theme }) => theme.color.general.layout.two};
      }

      &:hover:not(.ant-input-group-wrapper-disabled) {
        border-color: ${({ theme }) => theme.color.error.typography.primary};
      }

      &:focus-within {
        box-shadow: ${({ theme }) => theme.shadow.level1},
          0 0 0 1px ${({ theme }) => theme.color.error.typography.primary};
      }
    }
  }

  /* Input field overrides (it's a separate element inside a styled box) */

  .ant-input {
    ${InputTextStyles};
    padding: ${({ theme }) =>
      `${theme.spacing.sm}px ${theme.spacing.lg / 2}px `};
    border: none;
    background: none;

    padding-left: ${({ theme, $isSearchIconVisible }) =>
      `${
        $isSearchIconVisible ? theme.spacing.lg / 2 : LEFT_PADDING_WITH_ICON
      }px `};

    &::placeholder {
      color: ${({ theme }) => theme.color.general.typography.secondary};
    }

    &:focus,
    &.ant-input-status-error:not(.ant-input-disabled):not(
        .ant-input-borderless
      ).ant-input:focus {
      border: none;
      box-shadow: none;
    }

    &:hover,
    &.ant-input-status-error:not(.ant-input-disabled):not(
        .ant-input-borderless
      ).ant-input:hover {
      border: none;
      background: none;
    }

    // -2px because the border is on the wrapper
    height: ${INPUT_HEIGHT - 2}px;
    ${({ theme }) => useBreakpoint(theme).mobile`
        height: ${INPUT_MOBILE_HEIGHT - 2}px;
      `}
  }

  /* Disabled state */

  &.ant-input-group-wrapper.ant-input-group-wrapper-disabled {
    ${disabledCSS};
  }

  /* Read Only state */
  ${({ $readOnly }) =>
    $readOnly &&
    css`
      &.ant-input-group-wrapper {
        ${readOnlyCSS};
      }
    `};

  .ant-input-group-addon {
    background: none;
    position: absolute;
    left: ${({ theme }) => `${theme.spacing.sm}px`};
    ${({ $isSearchIconVisible }) =>
      $isSearchIconVisible &&
      css`
        display: none;
      `};
  }

  /** Icon */

  .ant-btn {
    height: 40px;
    width: 40px;
    background: none;
    border: none;

    .anticon-search svg {
      width: ${ICON_WIDTH}px;
      height: ${ICON_HEIGHT}px;
      color: ${({ theme }) => theme.color.general.typography.secondary};
    }
  }

  /* Icon for error state */

  &.ant-input-group-wrapper-status-error .ant-btn .anticon-search svg {
    color: ${({ theme }) => theme.color.error.typography.primary};
  }

  &.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    left: 0;
  }

  input:focus::placeholder {
    opacity: 0;
  }
`;
