import { useEffect } from 'react';
import { Form } from 'antd';
import { isNil } from 'lodash';

import { TooltipGlobalStyles } from '@npm/core/ui/components/atoms/Tooltip';
import type {
  Holding,
  IssuerEntityAggregate,
  VisibilityCode,
} from '@npm/data-access';

import { type OrderSizeType } from '../../../../../../order';
import { OrderSize } from '../../../../components/OrderSize';
import type { Action, OrderPlacementFormValues } from '../..';

type Props = {
  activeAction: Action;
  holding: Holding | undefined;
  remainingHoldingQuantity?: number;
  orderSizeType: OrderSizeType;
  company: IssuerEntityAggregate;
  visibility: VisibilityCode;
  toggleSizeType?: () => void;
  showSizeExtraRow?: boolean;
  showMinimumQuantity?: boolean;
};

export const OrderEntrySection = ({
  activeAction,
  holding,
  remainingHoldingQuantity,
  orderSizeType,
  toggleSizeType,
  company,
  visibility,
  showMinimumQuantity,
  showSizeExtraRow,
}: Props) => {
  const form = Form.useFormInstance<OrderPlacementFormValues>();
  const quantity = Form.useWatch('quantity', form);
  const pricePerShare = Form.useWatch('pricePerShare', form);

  useEffect(() => {
    const estimatedGrossPrice =
      (isNil(quantity) ? 0 : quantity) *
      (isNil(pricePerShare) ? 0 : pricePerShare);
    form.setFieldsValue({ estimatedGrossPrice });
    form.validateFields(['estimatedGrossPrice']);
  }, [form, quantity, pricePerShare]);

  return (
    <>
      <TooltipGlobalStyles />
      <OrderSize
        activeAction={activeAction}
        remainingQuantity={
          remainingHoldingQuantity ?? holding?.remaining_quantity
        }
        sizeType={orderSizeType}
        onSizeTypeChange={toggleSizeType}
        company={company}
        visibility={visibility}
        showSizeExtraRow={showSizeExtraRow}
        showMinimumQuantity={showMinimumQuantity}
      />
    </>
  );
};
