import React, { useMemo, useState } from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { VenusApi } from '@npm/data-access';

import { type SectionProps } from '../CompanyOverview.types';
import { NotCoveredBanner } from '../components/NotCoveredBanner';

import { CompanyStats } from './CompanyStats';
import { useExpandInvestmentTableRowByStatClick } from './FinancingHistory.hooks';
import { useFilteredFinancialActivityData } from './FinancingHistory.utils';
import { FinancingHistoryChart } from './FinancingHistoryChart';
import { InvestmentDataTable } from './InvestmentDataTable';

export const FinancingHistory = ({
  issuerEntity,
  companyProfile,
  isLoading,
  showStats = true,
}: SectionProps & {
  companyProfile: VenusApi.CompanyProfile | undefined;
  showStats?: boolean;
}) => {
  const { isMobile } = useBreakpoints();

  const { data, isFetching: isLoadingFinancialActivity } =
    VenusApi.useCompanyFinancialActivity(
      {
        id: issuerEntity?.venus_id?.toString(),
      },
      {
        queryConfig: { enabled: issuerEntity?.venus_id != null },
        onError: err => {
          if (err?.originalStatus === 404) return; // ignore
          throw err;
        },
      }
    );

  const [includeUnclassified, setIncludeUnclassified] = useState(false);

  const visibleRows = useMemo(
    () =>
      data?.financial_activity?.filter(
        financialActivity =>
          !financialActivity.unclassified || includeUnclassified
      ) ?? [],
    [data, includeUnclassified]
  );

  const { pagination, setPagination, defaultExpandedRowKeys, onStatItemClick } =
    useExpandInvestmentTableRowByStatClick(visibleRows);

  const filteredData = useFilteredFinancialActivityData(data);

  if (isLoadingFinancialActivity || !companyProfile) {
    return null;
  }

  if (!companyProfile.covered || !data.financial_activity.length) {
    return <NotCoveredBanner issuerEntity={issuerEntity} />;
  }

  return (
    <Flex direction="column" gap={isMobile ? 'sm' : 'md'}>
      {showStats && (
        <CompanyStats
          data={data}
          isLoading={isLoadingFinancialActivity}
          onItemClick={onStatItemClick}
          denomination={issuerEntity?.denomination}
        />
      )}
      {!!filteredData?.length && <FinancingHistoryChart data={filteredData} />}
      <InvestmentDataTable
        venusCompanyId={issuerEntity?.venus_id}
        issuerEntityId={issuerEntity?.id}
        data={visibleRows}
        isLoading={isLoading || isLoadingFinancialActivity}
        pagination={pagination}
        onTableChange={setPagination}
        defaultExpandedRowKeys={defaultExpandedRowKeys}
        includeUnclassified={includeUnclassified}
        setIncludeUnclassified={setIncludeUnclassified}
      />
    </Flex>
  );
};
