import React, { type ComponentProps } from 'react';
import { type CollapseProps, Collapse } from 'antd';

import { CypressDataIds } from '../../../constants';
import { TooltipInfoIcon } from '../../atoms/Tooltip';
import { Text } from '../../atoms/Typography';

import * as S from './CollapsibleFormSection.styles';

type Props = {
  label: string | React.ReactNode;
  name: string;
  optional?: boolean;
  preview?: string | React.ReactNode;
  tooltipText?: string | React.ReactNode;
  placeholder?: string;
} & Omit<ComponentProps<typeof Collapse.Panel>, 'header'>;

const CollapsibleFormSectionItem = ({
  label,
  name,
  optional,
  preview,
  tooltipText,
  placeholder = '--',
  ...props
}: Props) => {
  return (
    <Collapse.Panel
      header={
        <S.HeaderWrapper
          data-cy={`${CypressDataIds.Holdings.Form.CollapsibleForm.ItemHeader}-${name}`}
        >
          <Text size="sm" color="info" colorVariant="primary">
            {label}
            {tooltipText && <TooltipInfoIcon title={tooltipText} />}
            {optional && (
              <Text size="sm" colorVariant="secondary" as="span">
                &nbsp;(Optional)
              </Text>
            )}
          </Text>
          <S.ValuePreview
            size="sm"
            color="general"
            colorVariant={preview ? 'primary' : 'secondary'}
          >
            {preview || placeholder}
          </S.ValuePreview>
        </S.HeaderWrapper>
      }
      forceRender
      {...props}
    />
  );
};

export const CollapsibleFormSection = (props: CollapseProps) => {
  return (
    <S.CollapseWrapper
      data-cy={CypressDataIds.Holdings.Form.CollapsibleForm.Section}
      {...props}
    />
  );
};

CollapsibleFormSection.Item = CollapsibleFormSectionItem;
