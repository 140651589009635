import { useWindowSize } from 'usehooks-ts';

import { breakpoints } from '../themes';

export type BreakpointsReturnType = {
  isMobile: boolean; // to 575px
  isSmTablet: boolean; // to 767px
  isTablet: boolean; // to 991px
  isSmDesktop: boolean; // to 1199px
  isDesktop: boolean; // to 1599px
  isXlDesktop: boolean; // from 1600px
};

const DEFAULT_VALUE: BreakpointsReturnType = {
  isMobile: false,
  isSmTablet: false,
  isTablet: false,
  isSmDesktop: false,
  isDesktop: false,
  isXlDesktop: false,
};

export const useBreakpoints = () => {
  const { width } = useWindowSize();

  const { mobile, smTablet, tablet, smDesktop, desktop } = breakpoints;

  if (width <= mobile) {
    return { ...DEFAULT_VALUE, isMobile: true };
  } else if (width > mobile && width <= smTablet) {
    // note that if isSmTablet: true then isTablet: true
    return { ...DEFAULT_VALUE, isSmTablet: true, isTablet: true };
  } else if (width > smTablet && width <= tablet) {
    return { ...DEFAULT_VALUE, isTablet: true };
  } else if (width > tablet && width <= smDesktop) {
    // note that if isSmDesktop: true then isDesktop: true
    return { ...DEFAULT_VALUE, isSmDesktop: true, isDesktop: true };
  } else if (width > smTablet && width <= desktop) {
    return { ...DEFAULT_VALUE, isDesktop: true };
  }

  // note that if isXlDesktop: true then isDesktop: true
  return { ...DEFAULT_VALUE, isDesktop: true, isXlDesktop: true };
};
