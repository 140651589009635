export type WhitelistedAnswerCodes =
  | 'org_type'
  | 'signature'
  | 'entity_rep_title'
  | 'entity_total_assets'
  | 'qualified_purchaser'
  | 'qualified_institutional_buyer'
  | 'qualified_institutional_buyer_assets'
  | 'qualified_client'
  | 'qp_signature'
  | 'qp_entity_rep_title'
  | 'qib_signature'
  | 'qib_entity_rep_title'
  | 'qp_signature'
  | 'qp_entity_rep_title'
  | 'qc_signature'
  | 'qc_entity_rep_title';

export const whitelistedAnswerCodes = new Set<WhitelistedAnswerCodes>([
  'org_type',
  'signature',
  'entity_rep_title',
  'entity_total_assets',
  'qualified_purchaser',
  'qualified_institutional_buyer',
  'qualified_institutional_buyer_assets',
  'qualified_client',
  'qp_signature',
  'qp_entity_rep_title',
  'qib_signature',
  'qib_entity_rep_title',
  'qc_signature',
  'qc_entity_rep_title',
]);
