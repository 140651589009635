import { createContext, useContext, useEffect, useMemo } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useLocation } from 'react-router';
import { isEqual } from 'lodash';

import { FullScreenLoading } from '@npm/core/ui/components/molecules/FullScreenLoading';
import { useCurrentRoute } from '@npm/core/ui/hooks/useCurrentRoute';
import {
  type FeatureFlag,
  type FeatureFlagNameEnum,
  getApiErrorCode,
  useFeatureFlagsIndex,
} from '@npm/data-access';

import { useUserContextStore } from '../../auth/user/context';
import { InitialLoadingPart, useInitialLoadingStore } from '../initialLoading';

import { FEATURE_FLAGS_MENU_ENABLED } from './featureFlags.env';

type FlagType = typeof FeatureFlagNameEnum[keyof typeof FeatureFlagNameEnum];

type FeatureFlagsContextType = {
  featureFlags: FeatureFlag[];
  isEnabled: (
    // in here we are not using FeatureFlagDefinition because we want to be sure that API matches our copy
    featureFlag:
      | {
          type: 'allow-if-disabled';
          flag: FlagType;
        }
      | {
          type: 'allow-if-enabled';
          flag: FlagType;
        }
      | undefined
  ) => boolean;
  isMenuEnabled: boolean;
};

export const FeatureFlagsContext =
  createContext<FeatureFlagsContextType>(undefined);

type Props = {
  children?: React.ReactElement;
};

// DO NOT CHANGE, IT'S BECAUSE OTERWISE BUILD WILL REMOVE THE WHOLE DATADOG
const IS_ENABLED_BY_ENV = isEqual(FEATURE_FLAGS_MENU_ENABLED, 'true');

const isErrorPath = (location: string) => {
  // 4xx and 5xx http errors
  const regex = /^\/([4-5]\d{2})/g;
  return regex.test(location);
};

export const FeatureFlagsProvider = ({ children }: Props) => {
  const finishLoadingPartOfApp = useInitialLoadingStore(
    store => store.finishLoadingPart
  );

  const user = useUserContextStore(store => store.user);
  const currentRoute = useCurrentRoute();
  const { pathname } = useLocation();
  const handleError = useErrorHandler();

  const featureFlagLoadingEnabled =
    !!user &&
    currentRoute?.route?.needLogin !== false &&
    currentRoute?.route?.needUser !== false &&
    !isErrorPath(pathname);

  const { data, isLoading } = useFeatureFlagsIndex(
    {},
    {
      roles: {
        // Prevents refetching of feature flags when the current role changes.
        // The feature flags are not role-specific.
        oboUserId: undefined,
        roleId: undefined,
        oboAccountId: undefined,
        workstation: undefined,
        acrossAccount: undefined,
      },
      queryConfig: {
        enabled: featureFlagLoadingEnabled,
      },
      onError: e => {
        // ignoring 441 because it's handled in initTOSHandling
        if (getApiErrorCode(e) !== 441) {
          handleError(e);
        }
        finishLoadingPartOfApp(InitialLoadingPart.FeatureFlags);
      },
    }
  );

  useEffect(() => {
    if ((data && !isLoading) || !featureFlagLoadingEnabled) {
      finishLoadingPartOfApp(InitialLoadingPart.FeatureFlags);
    }
  }, [data, isLoading, featureFlagLoadingEnabled]);

  const contextValue = useMemo(() => {
    const hasFeatureFlag = (flag: FlagType) => {
      return (
        !!data?.feature_flags?.find(f => f.name === flag)?.enabled === true
      );
    };

    return {
      featureFlags: data?.feature_flags || [],
      isEnabled: featureFlag => {
        if (!featureFlag) return true;

        if (featureFlag.type === 'allow-if-enabled') {
          return hasFeatureFlag(featureFlag.flag);
        }

        if (featureFlag.type === 'allow-if-disabled') {
          return !hasFeatureFlag(featureFlag.flag);
        }

        return true;
      },
      isMenuEnabled:
        /^[^@]+@((nasdaq|npm|oakslab)\.com)$/gi.test(user?.email || '') &&
        data?.feature_flags?.length &&
        IS_ENABLED_BY_ENV,
    } satisfies FeatureFlagsContextType;
  }, [data, user]);

  if (isLoading && featureFlagLoadingEnabled) {
    return <FullScreenLoading loadingTitle={'Loading user data'} />;
  }

  return (
    <FeatureFlagsContext.Provider value={contextValue}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const withFeatureFlagsContext = <T,>(Component: React.ComponentType) => {
  return (props: T) => (
    <FeatureFlagsProvider>
      <Component {...props} />
    </FeatureFlagsProvider>
  );
};

export const useFeatureFlags = () => {
  const context = useContext(FeatureFlagsContext);
  if (context === undefined) {
    throw new Error(
      'useFeatureFlags must be used within a FeatureFlagProvider'
    );
  }
  return context;
};
