import { CodebooksSelect } from '..';

export const DEFAULT_PINNED_COUNTRIES = ['US'];

export const CountrySelect = ({
  pinOptions = DEFAULT_PINNED_COUNTRIES,
  showSearch = true,
  placeholder = 'Select',
  ...props
}: Omit<React.ComponentProps<typeof CodebooksSelect>, 'codebook'>) => {
  return (
    <CodebooksSelect
      codebook="COUNTRY"
      pinOptions={pinOptions}
      showSearch={showSearch}
      placeholder={placeholder}
      {...props}
    />
  );
};
