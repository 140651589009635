import { createGlobalStyle } from 'styled-components';

import {
  WIZARD_CONTENT_WRAPPER_ID,
  WIZARD_SIDEBAR_ID,
  WIZARD_WRAPPER_ID,
} from '@npm/core/ui/components/organisms/Wizard/Layout';

export const ResetWizardStyles = createGlobalStyle`
  #${WIZARD_CONTENT_WRAPPER_ID} {
    background: transparent;
    min-height: 100%;
  }
  #${WIZARD_WRAPPER_ID} {
    padding: 0;
    background: transparent;
  }
  #${WIZARD_SIDEBAR_ID} {
    height: 100%;
    padding-bottom: 0;
  }
`;
