import { type Wizard } from '@npm/core/ui/components/organisms/Wizard';
import { type OnboardingStatus, CbOnboardingStatus } from '@npm/data-access';

import { type PostOnboardingContext } from '../PostOnboarding.types';

export const getParentItemState = (
  onboardingStatus: OnboardingStatus,
  step: Wizard.StepDefinition<PostOnboardingContext>
) => {
  let total = 0;
  let totalCompleted = 0;
  let state: keyof typeof CbOnboardingStatus['items'] =
    onboardingStatus[step.key]?.code || CbOnboardingStatus.items.incomplete;

  if (step.substeps) {
    const substepCodes = step.substeps.map(s => onboardingStatus[s.key]?.code);

    total = substepCodes.length;
    totalCompleted = substepCodes.filter(
      code => code === CbOnboardingStatus.items.completed
    ).length;

    if (substepCodes.some(code => code === CbOnboardingStatus.items.warning)) {
      state = CbOnboardingStatus.items.warning;
    }

    if (
      substepCodes.every(code => code === CbOnboardingStatus.items.completed)
    ) {
      state = CbOnboardingStatus.items.completed;
    }
  }

  return { state, total, totalCompleted };
};
