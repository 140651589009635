import { Form } from '@npm/core/ui/components/atoms/Form';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import {
  CbTaxDocument,
  useAccountShow,
  usePersonShow,
  usePersonUpdate,
} from '@npm/data-access';

import { FormFields } from '../Form/Form.types';

export const useIndividualTaxIdForm = (
  personId: number | undefined,
  { onUpdate }: { onUpdate?: () => void }
) => {
  const { withShowApiErrorMiddleware } = useAlerts();

  const [form] = Form.useForm();

  const { data, isLoading } = usePersonShow(
    {
      id: personId,
    },
    {
      queryConfig: {
        enabled: !!personId,
      },
    }
  );

  const { isFetching: isAccountLoading, data: accountData } = useAccountShow(
    {
      id: data?.account_id?.toString(),
      // to fetch representative's account info
      xAcrossAccounts: 'true',
    },
    { queryConfig: { enabled: !!data?.account_id } }
  );

  const { execute, isLoading: isUpdating } = usePersonUpdate();

  const handleSubmit = async () => {
    let values;

    try {
      values = await form.validateFields();
    } catch (e) {
      return console.error('Failed:', e);
    }

    try {
      await withShowApiErrorMiddleware(execute)({
        id: personId!,
        personUpdateRequestContract: {
          tax_id: values[FormFields.INPUT_KEY],
          tax_id_exempt: values[FormFields.CHECKBOX_KEY] === true,
        },
      });

      onUpdate?.();
    } catch (e) {
      console.error(e);
    }
  };

  return {
    form,
    data,
    handleSubmit,
    isLoading: isLoading || isAccountLoading,
    isUpdating,
    isExemptHidden:
      accountData?.tax_document?.code === CbTaxDocument.items.DOCUMENT_W9,
  };
};
