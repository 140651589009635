import { useMemo, useState } from 'react';
import { differenceInMonths } from 'date-fns';
import { useTheme } from 'styled-components';

import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { NoDataArea } from '@npm/core/ui/components/atoms/NoDataArea';
import { Card } from '@npm/core/ui/components/molecules/Card';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { type IssuerEntityAggregate, VenusApi } from '@npm/data-access';

import { UnlockPremiumContextProvider } from '../../auth/user/components';
import { usePremiumPlan } from '../../auth/user/role';
import { type Document } from '../../documents/DocumentViewer/DocumentViewer.types';

import { CapTableBasic } from './CapTableBasic';
import type { CompanyOverviewChartRange } from './CompanyOverview.types';
import { CompanyOverviewTabs } from './CompanyOverviewTabs.config';
import { PremiumTrialBanner } from './PremiumTrialBanner';
import { SourceDocumentsBasic } from './SourceDocumentsBasic';
import { TapeDPricingBasic } from './TapeDPricingBasic';
import {
  CapTable,
  FinancingHistory,
  General,
  SourceDocuments,
  TapeDPricing,
  Terms,
} from '.';

export const useCompanyOverviewTabs = ({
  companyId,
  company,
  isLoadingCompany,
}: {
  companyId: number | undefined;
  company: IssuerEntityAggregate | undefined;
  isLoadingCompany: boolean;
}) => {
  const { isBasic, isTrial, isPremium } = usePremiumPlan();

  const theme = useTheme();

  const venusCompanyId = company?.venus_id;

  const [isCompanyDataAvailable, setIsCompanyDataAvailable] = useState(true);

  // Reset isCompanyDataAvailable when companyId changes:
  const [prevCompanyId, setPrevCompanyId] = useState(companyId);
  if (companyId !== prevCompanyId) {
    setPrevCompanyId(companyId);
    setIsCompanyDataAvailable(true);
  }

  const { data: companyProfile, isFetching: isLoadingCompanyProfile } =
    VenusApi.useCompanyProfile(
      {
        id: venusCompanyId?.toString(),
      },
      {
        queryConfig: { enabled: venusCompanyId != null },
        onError: error => {
          if (error.originalStatus === 404) {
            return setIsCompanyDataAvailable(false);
          }
          throw error;
        },
      }
    );

  const getLabel = (text: string) => {
    return (
      <Flex align="center" gap="xs">
        <span>{text}</span>
        {!isPremium && (
          <Icon
            name="diamond"
            size="xs"
            color={theme.color.warning.typography.primary}
          />
        )}
      </Flex>
    );
  };

  const premiumTrialBanner = isTrial ? (
    <Margin bottom="lg">
      <UnlockPremiumContextProvider companyName={company?.name}>
        <PremiumTrialBanner />
      </UnlockPremiumContextProvider>
    </Margin>
  ) : null;

  if (!companyId || !company) return [];

  if (
    !isCompanyDataAvailable ||
    (!isLoadingCompany && venusCompanyId == null)
  ) {
    return [
      {
        key: CompanyOverviewTabs.Profile,
        label: 'Profile',
        children: (
          <Card>
            <NoDataArea heading="We don't have this data" iconColor="info" />
          </Card>
        ),
      },
    ];
  }

  return [
    {
      key: CompanyOverviewTabs.TapedPricing,
      label: getLabel('Pricing'),
      children: isBasic ? (
        <TapeDPricingBasic companyName={company.name} />
      ) : (
        <>
          {premiumTrialBanner}
          <TapeDPricing
            issuerEntity={company}
            isLoadingIssuerEntity={isLoadingCompany}
            companyProfile={companyProfile}
          />
          <Terms />
        </>
      ),
    },
    {
      key: CompanyOverviewTabs.FinancingHistory,
      label: 'Financing History',
      children: (
        <>
          <FinancingHistory
            issuerEntity={company}
            companyProfile={companyProfile}
            isLoading={isLoadingCompany || isLoadingCompanyProfile}
            showStats
          />
          <Terms />
        </>
      ),
    },
    {
      key: CompanyOverviewTabs.CapTableWaterfalls,
      label: getLabel('Cap Table & Waterfalls'),
      children: isBasic ? (
        <CapTableBasic companyName={company.name} />
      ) : (
        <>
          {premiumTrialBanner}
          <CapTable
            issuerEntity={company}
            companyProfile={companyProfile}
            showBanner
          />
          <Terms />
        </>
      ),
    },
    {
      key: CompanyOverviewTabs.SourceDocuments,
      label: getLabel('Source Documents'),
      children: isBasic ? (
        <SourceDocumentsBasic companyName={company.name} />
      ) : (
        <>
          {premiumTrialBanner}
          <SourceDocuments
            issuerEntity={company}
            companyProfile={companyProfile}
          />
        </>
      ),
    },
    {
      key: CompanyOverviewTabs.Profile,
      label: 'Profile',
      children: (
        <General
          issuerEntity={company}
          companyProfile={companyProfile}
          isLoading={isLoadingCompany || isLoadingCompanyProfile}
        />
      ),
    },
  ];
};

const getPageCount = (minDate: Date, maxDate: Date, range: number) => {
  return Math.ceil(differenceInMonths(maxDate, minDate) / range) - 1;
};

export const getRangeAsNumber = (range: string) => {
  return parseInt(range.replace('M', ''));
};

export const useCompanyOverviewChartFilter = (
  minDateFromData: Date,
  maxDateFromData: Date
) => {
  const { isMobile, isTablet, isSmTablet } = useBreakpoints();

  const [range, setRange] = useState<CompanyOverviewChartRange>(
    isMobile || isTablet ? '6M' : isSmTablet ? '12M' : '24M'
  );

  const rangeAsNumber = getRangeAsNumber(range);

  const pageCount = getPageCount(
    minDateFromData,
    maxDateFromData,
    rangeAsNumber
  );

  const [currentPage, setCurrentPage] = useState(0);

  if ((isMobile || isTablet) && rangeAsNumber > 6) {
    setRange('6M');
    setCurrentPage(0);
  }

  if (isSmTablet && rangeAsNumber > 12) {
    setRange('12M');
    setCurrentPage(0);
  }

  const currentMaxDate = useMemo(() => {
    const value = new Date(maxDateFromData.getTime());
    value.setMonth(
      value.getMonth() - (pageCount - (pageCount - currentPage)) * rangeAsNumber
    );
    return value;
  }, [range, maxDateFromData, pageCount, currentPage, rangeAsNumber]);

  const currentMinDate = useMemo(() => {
    const value = new Date(currentMaxDate.getTime());
    value.setMonth(value.getMonth() - rangeAsNumber);
    return value;
  }, [range, currentMaxDate, rangeAsNumber]);

  return {
    currentMaxDate,
    currentMinDate,
    range,
    currentPage,
    setCurrentPage,
    setRange: val => {
      setRange(val);
      setCurrentPage(0);
    },
    pageCount,
  };
};

export const useHandleFilingClick = (
  venusCompanyId: number,
  issuerEntityId: number,
  showDocumentPreview: (document: Document | null | undefined) => void
) => {
  const [filingIdLoading, setFilingIdLoading] = useState(null);
  const [fetchFiling] = VenusApi.useInternalFilingsShowLazy();

  const handleFilingClick = async (filingId: number) => {
    setFilingIdLoading(filingId);

    try {
      const filing = await fetchFiling({ variables: { id: filingId } });

      if (filing.url && filing.url.trim().startsWith('http')) {
        window.open(filing.url.trim());
      } else {
        showDocumentPreview({
          type: 'with-path',
          path: `/api/venus/${venusCompanyId}/filings/${filingId}?issuer_entity_id=${issuerEntityId}`,
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setFilingIdLoading(null);
    }
  };

  return { handleFilingClick, filingIdLoading };
};
