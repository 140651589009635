import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { type NegotiationShowAggregate } from '@npm/data-access';

import { CertificateDetails } from '../CertificateDetails/CertificateDetails';

import * as S from './HoldingDetails.styles';

type Props = {
  negotiation?: NegotiationShowAggregate;
};

export const HoldingDetails = ({ negotiation }: Props) => {
  return (
    <Flex align="center" justify="space-between" gap="sm">
      <S.HoldingDetailsWrapper>
        <S.StyledIcon size="xs" name="file-text" />
        {!!negotiation?.holding?.certificate_number && (
          <>
            <CertificateDetails holding={negotiation?.holding} />
            <S.Seperator>·</S.Seperator>
          </>
        )}
        {!!negotiation && (
          <Text colorVariant="secondary" size="sm">
            {negotiation?.asset_type?.name}
          </Text>
        )}
      </S.HoldingDetailsWrapper>
    </Flex>
  );
};
