import React from 'react';

import type { ExtendedColumnType } from '@npm/core/ui/components/molecules/Table';
import { DATE_FORMATS, formatDate } from '@npm/core/ui/utils/formatters';
import type { CompanyPricingApi } from '@npm/data-access';

import { CompanyOverviewPriceDisplay } from '../../../components/CompanyOverviewPriceDisplay';

type DataType =
  CompanyPricingApi.HistoricalPriceIndex['historical_prices']['aggregated_bids'][number];

export const getColumns = ({
  showTotalSize,
}: {
  showTotalSize: boolean;
}): ExtendedColumnType<DataType>[] => [
  {
    title: 'Date',
    key: 'date',
    render: (_, { date }) =>
      formatDate(date, { dateFormat: DATE_FORMATS.MONTH_AND_YEAR }),
  },
  {
    title: 'Total Size $',
    titleInfo:
      'The total volume of closed trades observed over the stated month.',
    key: 'total_size',
    render: (_, { total_value }) => total_value,
    hidden: !showTotalSize,
  },
  {
    title: 'Avg. PPS',
    key: 'pps',
    align: 'right',
    render: (
      _,
      { price_per_share, implied_valuation, discount_or_premium }
    ) => (
      <CompanyOverviewPriceDisplay
        price={price_per_share}
        impliedValuation={implied_valuation}
        change={discount_or_premium}
        multiplyChange={true}
        align="flex-end"
      />
    ),
  },
];
