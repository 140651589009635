import React from 'react';
import { capitalize } from 'lodash';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Flex, Padding } from '@npm/core/ui/components/atoms/common';
import {
  ATSPoolLabel,
  CbLabel,
  Label,
} from '@npm/core/ui/components/atoms/Label';
import { TooltipInfoIcon } from '@npm/core/ui/components/atoms/Tooltip';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { Card } from '@npm/core/ui/components/molecules/Card';
import { CompanyLogo } from '@npm/core/ui/components/molecules/CompanyLogo';
import { DrawerSection } from '@npm/core/ui/components/molecules/DrawerSection';
import { TableVertical } from '@npm/core/ui/components/molecules/TableVertical';
import { getTimezoneShort } from '@npm/core/ui/utils/formatters';
import {
  CbOrderBuySell,
  Codebooks,
  useCodebook,
  useIssuerEntityShow,
} from '@npm/data-access';
import {
  type BrokerageFirmAggregate,
  type OrderSourceCode,
  CbATSPool,
  CbOrderEntryType,
  CbOrderSource,
  CbVisibility,
} from '@npm/data-access';

import { useIsInvestor } from '../../../../../../auth/user/role/hooks/useIsInvestor';
import { type OrderSizeType, normalizeSize } from '../../../../../../order';
import { getStructurePreview } from '../../../../../../order/OrderData';
import { calculateEstimatedNetPPS } from '../../../../../opportunities/utils/calculateEstimatedNetPPS';
import {
  calculateEstimatedPrice,
  EXECUTION_FEE,
} from '../../../../../utils/investor/calculateEstimatedPrice';
import {
  getExpirationPreview,
  getPlacedAtPreview,
  getPublishedAtPreview,
} from '../../../../components/inputs';
import { getReceivedAtPreview } from '../../../../components/inputs';
import { OrderAlerts } from '../../../../components/OrderAlerts';
import { OrderTotal } from '../../../../components/OrderTotal';
import { type OrderPlacementFormValues } from '../../..';

type Props = {
  values: OrderPlacementFormValues;
  errorMessage?: string;
  activeAction: 'buy' | 'sell';
  orderSizeType: OrderSizeType;
  showATSPool?: boolean;
  currency?: string;
  brokerageFirm: BrokerageFirmAggregate | undefined;
  source: OrderSourceCode | undefined;
};

export const OrderDetailsSection = ({
  values,
  errorMessage,
  activeAction,
  orderSizeType,
  showATSPool = false,
  currency = 'USD',
  brokerageFirm,
  source,
}: Props) => {
  const isInvestor = useIsInvestor();

  const { data: assetTypeData } = useCodebook({
    type: Codebooks.ASSET_TYPE,
  });
  const assetTypes = assetTypeData?.codebooks ?? [];

  const { data: orderEntryTypeData } = useCodebook({
    type: CbOrderEntryType.code,
  });
  const orderEntryTypes = orderEntryTypeData?.codebooks || [];

  const { data: visibilityData } = useCodebook({ type: CbVisibility.code });
  const visibilities = visibilityData?.codebooks || [];

  const { data: atsPoolData } = useCodebook({ type: CbATSPool.code });
  const atsPools = atsPoolData?.codebooks || [];

  const { data: structureData } = useCodebook({
    type: Codebooks.SUBMISSION_STRUCTURE,
  });
  const structures = structureData?.codebooks || [];

  const { data: rofrData } = useCodebook({ type: Codebooks.ORDER_ITEM_ROFR });
  const rofrOptions = rofrData?.codebooks || [];

  const { data: stateData } = useCodebook({ type: Codebooks.ORDER_ITEM_STATE });
  const stateOptions = stateData?.codebooks || [];

  const { data: issuerEntity } = useIssuerEntityShow(
    {
      id: values?.issuerEntityId?.toString(),
    },
    {
      queryConfig: {
        enabled: !!values?.issuerEntityId,
      },
    }
  );

  const normalizedSize = normalizeSize({
    ...values,
    sizeType: orderSizeType,
  });

  const estimatedPrice = calculateEstimatedPrice(
    normalizedSize.USD,
    activeAction
  );

  const investorEstimatedNetPriceToolTips = {
    sell: 'A commission we charge for your offer when you successfully sell the shares',
    buy: 'A commission we charge for your bid when you successfully buy the shares',
  };

  const displayExecutionFee = EXECUTION_FEE * 100;

  const netPPS = calculateEstimatedNetPPS(
    values.pricePerShare,
    displayExecutionFee,
    values.buySell === CbOrderBuySell.items.buy
  );

  return (
    <DrawerSection
      iconName="clipboard-text"
      title="Order Terms"
      content={
        <Flex gap="lg" direction="column">
          <Card variant="compact" background="one" noContentPadding>
            <Padding left="sm" right="sm">
              <TableVertical
                rows={[
                  {
                    title: 'Side',
                    key: 'orderSide',
                    render: () => (
                      <Label
                        variant={activeAction === 'buy' ? 'success' : 'error'}
                        round
                      >
                        {capitalize(activeAction)}
                      </Label>
                    ),
                  },
                  {
                    title: 'Company',
                    key: 'company',
                    render: () => (
                      <CompanyLogo
                        url={issuerEntity?.logo_url}
                        name={issuerEntity?.name}
                        size="sm"
                      />
                    ),
                  },
                  {
                    title: 'PPS',
                    key: 'PPS',
                    render: (_, item) => (
                      <Text.Price
                        value={item.pricePerShare}
                        currency={currency}
                        formatOptions={{ long: true }}
                      />
                    ),
                  },
                  {
                    title: 'Estimated Net PPS',
                    key: 'netPPS',
                    render: _ => (
                      <Flex gap="xs">
                        <Text.Price value={netPPS} currency="USD" />
                      </Flex>
                    ),
                  },
                  {
                    title: 'Size (Shares)',
                    key: 'sizeShares',
                    render: () => (
                      <Text.Quantity
                        value={normalizedSize.Shares}
                        formatOptions={{ maximumFractionDigits: 0 }}
                      />
                    ),
                  },
                  {
                    title: 'Asset Type',
                    key: 'assetType',
                    render: (_, { assetType }) =>
                      assetTypes?.find(({ code }) => code === assetType)
                        ?.name ?? assetType,
                    hidden: activeAction === 'sell',
                  },
                  {
                    title: 'Order Type',
                    key: 'orderType',
                    render: (_, { orderType }) =>
                      CbOrderEntryType.getLabel(
                        CbLabel,
                        orderEntryTypes.find(({ code }) => code === orderType)
                      ),
                  },
                  {
                    title: 'Visibility',
                    key: 'visibility',
                    render: (_, { visibility, atsPool }) => (
                      <>
                        <Text size="sm" colorVariant="primary">
                          {visibilities.find(({ code }) => code === visibility)
                            ?.name ?? visibility}
                        </Text>
                        {showATSPool && (
                          <ATSPoolLabel
                            brokerageFirm={brokerageFirm}
                            codebookItem={atsPools.find(
                              ({ code }) => code === atsPool
                            )}
                            textProps={{
                              size: 'xs',
                              colorVariant: 'secondary',
                            }}
                          />
                        )}
                      </>
                    ),
                    hidden: !values.visibility || isInvestor,
                  },
                  {
                    title: 'Structure',
                    key: 'structure',
                    render: (_, values) =>
                      getStructurePreview({
                        ...values,
                        structureCodebooks: structures,
                      }),
                  },
                  {
                    title: (
                      <>
                        Execution Fee
                        <TooltipInfoIcon
                          title={
                            investorEstimatedNetPriceToolTips[activeAction]
                          }
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                          }}
                        />
                      </>
                    ),
                    key: 'investorExecutionFee',
                    render: () => (
                      <Text.Quantity unit="%" value={displayExecutionFee} />
                    ),
                    hidden: !isInvestor,
                  },
                  {
                    title:
                      activeAction === 'buy'
                        ? 'Estimated Gross Purchase Price'
                        : 'Estimated Gross Proceeds',
                    key: 'investorEstimatedNetPrice',
                    render: () => <Text.Price value={normalizedSize.USD} />,
                    hidden: !isInvestor,
                  },
                  {
                    title: `Received At (${getTimezoneShort()})`,
                    key: 'orderReceivedAt',
                    render: (_, { orderReceivedDate }) =>
                      getReceivedAtPreview(orderReceivedDate, source),
                    hidden: isInvestor,
                  },
                  {
                    title: `Published At (${getTimezoneShort()})`,
                    key: 'orderPublishedDate',
                    render: (_, { orderPublishedDate }) =>
                      getPublishedAtPreview(orderPublishedDate),
                    hidden: (_, { visibility }) =>
                      source !== CbOrderSource.items.historical ||
                      visibility === CbVisibility.items.internal,
                  },
                  {
                    title: `Created At (${getTimezoneShort()})`,
                    key: 'placedAtDate',
                    render: (_, { placedAtDate }) =>
                      getPlacedAtPreview(placedAtDate),
                    hidden: source !== CbOrderSource.items.historical,
                  },
                  {
                    title: `Expires At (${getTimezoneShort()})`,
                    key: 'expiration',
                    render: (
                      _,
                      { expiration, goodTillCancelled, visibility }
                    ) =>
                      getExpirationPreview(
                        goodTillCancelled,
                        expiration,
                        source,
                        visibility
                      ),
                    hidden: isInvestor,
                  },
                  {
                    title: 'Status',
                    key: 'state',
                    render: (_, { state }) =>
                      stateOptions.find(({ code }) => code === state)?.name ??
                      state,
                    hidden: source !== CbOrderSource.items.historical,
                  },
                  {
                    title: 'Data Room',
                    key: 'dataRoom',
                    render: (_, values) =>
                      'dataRoom' in values && values.dataRoom ? 'Yes' : 'No',
                    hidden: (_, values) =>
                      activeAction !== 'sell' ||
                      !('dataRoom' in values) ||
                      values.dataRoom == null,
                  },
                  {
                    title: 'ROFR',
                    key: 'rofr',
                    render: (_, values) =>
                      'rofr' in values &&
                      (rofrOptions.find(({ code }) => code === values.rofr)
                        ?.name ??
                        values.rofr),
                    hidden: (_, values) =>
                      activeAction !== 'sell' ||
                      !('rofr' in values) ||
                      values.rofr == null,
                  },
                ]}
                data={values}
              />
            </Padding>
            {isInvestor ? (
              <OrderTotal
                total={estimatedPrice}
                label={
                  activeAction === 'buy'
                    ? 'Estimated Net Purchase Price'
                    : 'Estimated Net Proceeds'
                }
              />
            ) : (
              <OrderTotal
                total={normalizedSize.USD}
                label={
                  activeAction === 'buy'
                    ? 'Estimated Gross Purchase Price'
                    : 'Estimated Gross Proceeds'
                }
              />
            )}
          </Card>
          {brokerageFirm && (
            <OrderAlerts
              formValues={values}
              brokerageFirm={brokerageFirm}
              source={source}
            />
          )}
          {errorMessage && <Alert type="error" message={errorMessage} />}
        </Flex>
      }
    />
  );
};
