import React, { useCallback, useMemo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import {
  type StepperItemVariant,
  StepperItem,
} from '@npm/core/ui/components/atoms/StepperItem';
import { type Wizard } from '@npm/core/ui/components/organisms/Wizard';
import { expandCollapse } from '@npm/core/ui/utils/animations';
import { type OnboardingStatus, CbOnboardingStatus } from '@npm/data-access';

import { type PostOnboardingContext } from '../../PostOnboarding.types';
import { isStepIncomplete } from '../../PostOnboarding.utils';
import { getParentItemState } from '../Sidebar.utils';

import * as S from '../SidebarSection/SidebarSection.styles';

const stateToStepperItemVariantMap: Record<
  keyof typeof CbOnboardingStatus['items'],
  StepperItemVariant
> = {
  [CbOnboardingStatus.items.incomplete]: 'default',
  [CbOnboardingStatus.items.warning]: 'error',
  [CbOnboardingStatus.items.completed]: 'done',
  [CbOnboardingStatus.items.not_applicable]: 'default',
};

type Props = {
  step: Wizard.StepDefinition<PostOnboardingContext>;
  activeStepKey: string;
  onboardingStatusData: OnboardingStatus;
  onClick: (key: string, isParentItemClicked: boolean) => void;
  testId?: string;
};

export const SidebarItem = ({
  step,
  activeStepKey,
  onboardingStatusData,
  onClick,
  testId,
}: Props) => {
  const { state, total, totalCompleted } = getParentItemState(
    onboardingStatusData,
    step
  );
  const variant = stateToStepperItemVariantMap[state];
  const isExpandable = !!step.substeps;
  const isExpanded = useMemo(
    () => !!step.substeps?.find(s => s.key === activeStepKey),
    [step.substeps, activeStepKey]
  );

  const handleClick = useCallback(() => {
    const key = isExpandable
      ? step.substeps.find(s =>
          isStepIncomplete(onboardingStatusData[s.key]?.code)
        )?.key || step.substeps[0].key
      : step.key;

    onClick(key, isExpandable);
  }, [isExpandable, step.key, step.substeps, onboardingStatusData, onClick]);

  return (
    <S.ItemWrapper $areChildrenExpanded={isExpandable && isExpanded}>
      <StepperItem
        variant={variant}
        size="sm"
        isExpanded={isExpanded}
        isActive={activeStepKey === step.key}
        onClick={handleClick}
        title={step.title}
        extra={isExpandable ? `${totalCompleted} / ${total}` : null}
        testId={testId}
      />
      <div style={{ overflow: 'hidden' }}>
        <AnimatePresence initial={false}>
          {isExpandable && isExpanded && (
            <motion.div {...expandCollapse}>
              {step.substeps.map(s => {
                const isSubstepActive = s.key === activeStepKey;
                const substepState = onboardingStatusData[s.key]?.code;
                const substepVariant =
                  stateToStepperItemVariantMap[substepState];

                return (
                  <StepperItem
                    key={s.key}
                    size="sm"
                    title={s.title}
                    onClick={() => onClick(s.key, false)}
                    variant={substepVariant}
                    isActive={isSubstepActive}
                    testId={testId}
                  />
                );
              })}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </S.ItemWrapper>
  );
};
