import { type CodebookApiCodebookRequest } from '../generated';

type NpmCodebooks = CodebookApiCodebookRequest['type'];

type NpmType = {
  [key in NpmCodebooks]: key;
};

export const Codebooks = {
  ACCOUNT_SPONSORSHIP_TYPE: 'ACCOUNT_SPONSORSHIP_TYPE',
  ACCOUNT_TYPE: 'ACCOUNT_TYPE',
  ACCREDITATION_LABEL: 'ACCREDITATION_LABEL',
  ACCREDITATION_LEVEL: 'ACCREDITATION_LEVEL',
  ACCREDITATION_QUESTION: 'ACCREDITATION_QUESTION',
  ACCREDITATION_STATE: 'ACCREDITATION_STATE',
  ACCREDITATION_FILTER: 'ACCREDITATION_FILTER',
  ACTION_COUNTER_TYPE: 'ACTION_COUNTER_TYPE',
  AFFILIATE_DISCLOSURE: 'AFFILIATE_DISCLOSURE',
  AGREEMENT_DOCUMENT_SIGNER: 'AGREEMENT_DOCUMENT_SIGNER',
  AGREEMENT_TYPE: 'AGREEMENT_TYPE',
  ASSET_TYPE: 'ASSET_TYPE',
  ATS_POOL: 'ATS_POOL',
  ATTESTATION_RULE: 'ATTESTATION_RULE',
  BACKGROUND_CHECK_STATE: 'BACKGROUND_CHECK_STATE',
  BANK_ACCOUNT_TYPE: 'BANK_ACCOUNT_TYPE',
  BENEFICIAL_OWNER_ENTITY_EXEMPTION: 'BENEFICIAL_OWNER_ENTITY_EXEMPTION',
  BENEFICIAL_OWNER_EXEMPTION: 'BENEFICIAL_OWNER_EXEMPTION',
  BENEFICIAL_OWNERSHIP_ASSESSMENT_STATUS:
    'BENEFICIAL_OWNERSHIP_ASSESSMENT_STATUS',
  BENEFICIAL_OWNER_ROLE: 'BENEFICIAL_OWNER_ROLE',
  BROKERAGE_TYPE: 'BROKERAGE_TYPE',
  BUYER_SURVEY: 'BUYER_SURVEY',
  BUYER_SURVEY_QUESTION: 'BUYER_SURVEY_QUESTION',
  COMPANY_ACTIVE: 'COMPANY_ACTIVE',
  COUNTRY: 'COUNTRY',
  DOCUMENT_TYPE: 'DOCUMENT_TYPE',
  ENTITY_ASSET: 'ENTITY_ASSET',
  ENTITY_ASSETS_REP_ANS: 'ENTITY_ASSETS_REP_ANS',
  ENTITY_ASSETS_REP_BANK_ANS: 'ENTITY_ASSETS_REP_BANK_ANS',
  ERISA_ASSET_ANS: 'ERISA_ASSET_ANS',
  EVENT_MESSAGE_TYPE: 'EVENT_MESSAGE_TYPE',
  EVENT_PHASE: 'EVENT_PHASE',
  EVENT_ROLE: 'EVENT_ROLE',
  EVENT_STATUS: 'EVENT_STATUS',
  EVENT_SUBMISSION_STEP: 'EVENT_SUBMISSION_STEP',
  GRANT_TYPE: 'GRANT_TYPE',
  HOLDING_STATE: 'HOLDING_STATE',
  INCOME_ANS: 'INCOME_ANS',
  INTERMEDIARY_ROUTING_TYPE: 'INTERMEDIARY_ROUTING_TYPE',
  ISSUER_ACCOUNT_ROLE: 'ISSUER_ACCOUNT_ROLE',
  INSTITUTIONAL_CLIENT: 'INSTITUTIONAL_CLIENT',
  LAST_FUNDED: 'LAST_FUNDED',
  LAST_LOGIN: 'LAST_LOGIN',
  LAST_TRANSACTED_ACTION: 'LAST_TRANSACTED_ACTION',
  LATEST_DEAL_SIZE: 'LATEST_DEAL_SIZE',
  LATEST_VALUATION: 'LATEST_VALUATION',
  LIABILITY_ANS: 'LIABILITY_ANS',
  LOGIN_TYPE: 'LOGIN_TYPE',
  MATCH_STATE: 'MATCH_STATE',
  MATCH_SOURCE: 'MATCH_SOURCE',
  MATCH_TIMELINE_STATUS: 'MATCH_TIMELINE_STATUS',
  MNA_STEP: 'MNA_STEP',
  NDA_STATUS: 'NDA_STATUS',
  NEGOTIATION_ACTION: 'NEGOTIATION_ACTION',
  NEGOTIATION_STATE: 'NEGOTIATION_STATE',
  NEGOTIATION_TRANSACTION_PHASE: 'NEGOTIATION_TRANSACTION_PHASE',
  NET_WORTH: 'NET_WORTH',
  NET_WORTH_FILING: 'NET_WORTH_FILING',
  NOTIFICATION_CATEGORY: 'NOTIFICATION_CATEGORY',
  NOTIFICATION_STATE: 'NOTIFICATION_STATE',
  NOTE_SUBJECT_TYPE: 'NOTE_SUBJECT_TYPE',
  ONBOARDING_STATUS: 'ONBOARDING_STATUS',
  ORDER_BUY_SELL: 'ORDER_BUY_SELL',
  ORDER_ENTRY_CATEGORY: 'ORDER_ENTRY_CATEGORY',
  ORDER_ENTRY_TYPE: 'ORDER_ENTRY_TYPE',
  ORDER_ITEM_STATE: 'ORDER_ITEM_STATE',
  ORDER_ITEM_UNIT: 'ORDER_ITEM_UNIT',
  ORDER_ITEM_ROFR: 'ORDER_ITEM_ROFR',
  ORDER_SOURCE: 'ORDER_SOURCE',
  ORGANIZATION_TYPE: 'ORGANIZATION_TYPE',
  ORGANIZATION_TYPE_V2: 'ORGANIZATION_TYPE_V2',
  QUALIFIED_PURCHASER_TYPE: 'QUALIFIED_PURCHASER_TYPE',
  QUALIFIED_INSTITUTIONAL_BUYER_TYPE: 'QUALIFIED_INSTITUTIONAL_BUYER_TYPE',
  OWNER_TYPE: 'OWNER_TYPE',
  PAYMENT_INFORMATION_TYPE: 'PAYMENT_INFORMATION_TYPE',
  PLACED_AT: 'PLACED_AT',
  RECEIVED_AT: 'RECEIVED_AT',
  PUBLISHED_AT: 'PUBLISHED_AT',
  ROLE_TYPE: 'ROLE_TYPE',
  ROFR_ACTION_TYPE: 'ROFR_ACTION_TYPE',
  SECONDMARKET_ORDER_SORT: 'SECONDMARKET_ORDER_SORT',
  SUBJECT_TYPE: 'SUBJECT_TYPE',
  SUBMISSION_STATE: 'SUBMISSION_STATE',
  SUBMISSION_STRUCTURE: 'SUBMISSION_STRUCTURE',
  SUBREGION: 'SUBREGION',
  SUBSCRIPTION_PLAN: 'SUBSCRIPTION_PLAN',
  TAX_DOCUMENT: 'TAX_DOCUMENT',
  TOTAL_FUNDING: 'TOTAL_FUNDING',
  TRANSACTION_DATE: 'TRANSACTION_DATE',
  USER_ROLE_STATUS: 'USER_ROLE_STATUS',
  USER_ROLE_TYPE: 'USER_ROLE_TYPE',
  USER_STATUS: 'USER_STATUS',
  USER_UI_THEME: 'USER_UI_THEME',
  VISIBILITY: 'VISIBILITY',
  WORKSTATION_TYPE: 'WORKSTATION_TYPE',
  YES_OR_NO: 'YES_OR_NO',
  RESOURCE_TYPE: 'RESOURCE_TYPE',
  HOLDING_SORT: 'HOLDING_SORT',
  QUALIFIED_CLIENT_TYPE: 'QUALIFIED_CLIENT_TYPE',
  ACCOUNT_STATUS: 'ACCOUNT_STATUS',

  // TODO: uncomment when new swagger types generated
  // NEGOTIATION_STATUS = 'NEGOTIATION_STATUS',
  // NEGOTIATION_ACTION_STATUS = 'NEGOTIATION_ACTION_STATUS',
  // NEGOTIATION_ORDER_STATUS = 'NEGOTIATION_ORDER_STATUS',
} satisfies Partial<NpmType>;

export interface CodebookAttribute {
  name?: string;
  value?: string;
}

export interface CodebookItem {
  book?: NpmCodebooks | string | null;
  code?: string;
  name?: string;
  attributes?: Array<CodebookAttribute>;
}

export type CodebookItems = NpmCodebooks;
