import React from 'react';

import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';

type Props = { title: string; variant?: 'h3' | 'h4' };

export const AccreditationDataItemHeading = ({
  title,
  variant = 'h3',
}: Props) => {
  return variant === 'h3' ? (
    <Heading variant="h3">{title}</Heading>
  ) : (
    <Text size="sm" as="h4" colorVariant="primary">
      {title}
    </Text>
  );
};
