import { type DefaultOptionType } from 'antd/lib/select';

export const sortCbSelectOptions = (
  allOptions: DefaultOptionType[],
  pinnedOptionKeys: (string | number)[]
) => {
  if (!pinnedOptionKeys) return allOptions;

  return [...allOptions].sort(
    (a, b) =>
      Number(pinnedOptionKeys.includes(b.value)) -
      Number(pinnedOptionKeys.includes(a.value))
  );
};
