import styled from 'styled-components';

import { TooltipStyles } from '@npm/core/ui/components/atoms/Charts/styles/Tooltip.styles';

export const ChartContainer = styled.div`
  ${TooltipStyles};

  position: relative;
  height: 400px;
  width: 100%;
`;
