import React from 'react';

import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useHistory } from '@npm/core/ui/components/molecules/Link';
import { CypressDataIds } from '@npm/core/ui/constants';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';

import * as S from './Header.styles';

type Props = {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  returnPath: string;
  afterButtonText?: string;
};

export const Header = ({
  sidebarOpen,
  setSidebarOpen,
  returnPath,
  afterButtonText,
}: Props) => {
  const history = useHistory();
  const { isMobile, isTablet } = useBreakpoints();
  const isDesktop = !(isMobile || isTablet);

  const handleClick = () => {
    if (sidebarOpen || isDesktop) {
      history.push(returnPath);
    } else {
      setSidebarOpen(true);
    }
  };

  return (
    <S.HeaderButtonWrap>
      <S.BackButtonWrap>
        <Icon name="chevron-left" size="xs" />
        <Text
          data-cy={CypressDataIds.Onboarding.LeaveButton}
          onClick={handleClick}
          size="sm"
        >
          {sidebarOpen || isDesktop
            ? 'Leave Account Onboarding'
            : 'Back to Checklist'}
        </Text>
      </S.BackButtonWrap>
      {afterButtonText && (
        <Text size="sm" colorVariant="primary">
          {afterButtonText}
        </Text>
      )}
    </S.HeaderButtonWrap>
  );
};
