import { CompanyTag } from '@npm/core/ui/components/molecules/CompanyTag';
import {
  type IssuerEntityAggregate,
  useIssuerEntityShow,
} from '@npm/data-access';

type Props = {
  companyId: string;
  company?: IssuerEntityAggregate;
  disableRemove?: boolean;
  onClose: () => void;
};

export const WatchlistCompanyTag = ({
  companyId,
  company,
  disableRemove = false,
  onClose,
}: Props) => {
  const { data } = useIssuerEntityShow(
    {
      id: companyId,
    },
    { queryConfig: { enabled: !company } }
  );

  const usedCompany = data || company;

  return (
    <CompanyTag
      company={
        usedCompany
          ? {
              name: usedCompany.name,
              logo_url: usedCompany.logo_url,
            }
          : undefined
      }
      onClose={disableRemove ? undefined : onClose}
    />
  );
};
