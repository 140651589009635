/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ActionStatusResult } from '../model';
// @ts-ignore
import { Security } from '../model';
// @ts-ignore
import { SecurityUpdateRequest } from '../model';
/**
 * S2SInvestorHoldingsApi - axios parameter creator
 * @export
 */
export const S2SInvestorHoldingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary creates a new security from sm holding
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        s2SCreateSecurityFromSMHolding: async (xNPMAuthorization?: string, xNPMUser?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/s2s/create_security_from_sm_holding`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xNPMUser !== undefined && xNPMUser !== null) {
                localVarHeaderParameter['X-NPM-User'] = String(xNPMUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary destroy the security under an user
         * @param {string} npmGuid 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        s2SDestroySecurity: async (npmGuid: string, xNPMAuthorization?: string, xNPMUser?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'npmGuid' is not null or undefined
            assertParamExists('s2SDestroySecurity', 'npmGuid', npmGuid)
            const localVarPath = changeUrl(`/api/s2s/securities/{npm_guid}`)
                .replace(`{${"npm_guid"}}`, encodeURIComponent(String(npmGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xNPMUser !== undefined && xNPMUser !== null) {
                localVarHeaderParameter['X-NPM-User'] = String(xNPMUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary retrieves a security
         * @param {string} npmGuid 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        s2SGetSecurity: async (npmGuid: string, xNPMAuthorization?: string, xNPMUser?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'npmGuid' is not null or undefined
            assertParamExists('s2SGetSecurity', 'npmGuid', npmGuid)
            const localVarPath = changeUrl(`/api/s2s/securities/{npm_guid}`)
                .replace(`{${"npm_guid"}}`, encodeURIComponent(String(npmGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xNPMUser !== undefined && xNPMUser !== null) {
                localVarHeaderParameter['X-NPM-User'] = String(xNPMUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list all holdings in accounts a user has access to
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        s2SInvestorHoldingsIndex: async (xNPMAuthorization?: string, xNPMUser?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/s2s/investor_holdings_overview`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xNPMUser !== undefined && xNPMUser !== null) {
                localVarHeaderParameter['X-NPM-User'] = String(xNPMUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates a holding from an caller that isn\'t SecondMarket
         * @param {string} npmGuid 
         * @param {SecurityUpdateRequest} securityUpdateRequest 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        s2SUpdateSecurityFromHolding: async (npmGuid: string, securityUpdateRequest: SecurityUpdateRequest, xNPMAuthorization?: string, xNPMUser?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'npmGuid' is not null or undefined
            assertParamExists('s2SUpdateSecurityFromHolding', 'npmGuid', npmGuid)
            // verify required parameter 'securityUpdateRequest' is not null or undefined
            assertParamExists('s2SUpdateSecurityFromHolding', 'securityUpdateRequest', securityUpdateRequest)
            const localVarPath = changeUrl(`/api/s2s/securities/{npm_guid}`)
                .replace(`{${"npm_guid"}}`, encodeURIComponent(String(npmGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xNPMUser !== undefined && xNPMUser !== null) {
                localVarHeaderParameter['X-NPM-User'] = String(xNPMUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(securityUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates a holding
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        s2SUpdateSecurityFromSmHolding: async (xNPMAuthorization?: string, xNPMUser?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/s2s/update_security_from_sm_holding`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xNPMUser !== undefined && xNPMUser !== null) {
                localVarHeaderParameter['X-NPM-User'] = String(xNPMUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * S2SInvestorHoldingsApi - functional programming interface
 * @export
 */
export const S2SInvestorHoldingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = S2SInvestorHoldingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary creates a new security from sm holding
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async s2SCreateSecurityFromSMHolding(xNPMAuthorization?: string, xNPMUser?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Security>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.s2SCreateSecurityFromSMHolding(xNPMAuthorization, xNPMUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary destroy the security under an user
         * @param {string} npmGuid 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async s2SDestroySecurity(npmGuid: string, xNPMAuthorization?: string, xNPMUser?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionStatusResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.s2SDestroySecurity(npmGuid, xNPMAuthorization, xNPMUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary retrieves a security
         * @param {string} npmGuid 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async s2SGetSecurity(npmGuid: string, xNPMAuthorization?: string, xNPMUser?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Security>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.s2SGetSecurity(npmGuid, xNPMAuthorization, xNPMUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list all holdings in accounts a user has access to
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async s2SInvestorHoldingsIndex(xNPMAuthorization?: string, xNPMUser?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.s2SInvestorHoldingsIndex(xNPMAuthorization, xNPMUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updates a holding from an caller that isn\'t SecondMarket
         * @param {string} npmGuid 
         * @param {SecurityUpdateRequest} securityUpdateRequest 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async s2SUpdateSecurityFromHolding(npmGuid: string, securityUpdateRequest: SecurityUpdateRequest, xNPMAuthorization?: string, xNPMUser?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Security>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.s2SUpdateSecurityFromHolding(npmGuid, securityUpdateRequest, xNPMAuthorization, xNPMUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updates a holding
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async s2SUpdateSecurityFromSmHolding(xNPMAuthorization?: string, xNPMUser?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Security>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.s2SUpdateSecurityFromSmHolding(xNPMAuthorization, xNPMUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * S2SInvestorHoldingsApi - factory interface
 * @export
 */
export const S2SInvestorHoldingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = S2SInvestorHoldingsApiFp(configuration)
    return {
        /**
         * 
         * @summary creates a new security from sm holding
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        s2SCreateSecurityFromSMHolding(xNPMAuthorization?: string, xNPMUser?: string, options?: any): AxiosPromise<Security> {
            return localVarFp.s2SCreateSecurityFromSMHolding(xNPMAuthorization, xNPMUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary destroy the security under an user
         * @param {string} npmGuid 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        s2SDestroySecurity(npmGuid: string, xNPMAuthorization?: string, xNPMUser?: string, options?: any): AxiosPromise<ActionStatusResult> {
            return localVarFp.s2SDestroySecurity(npmGuid, xNPMAuthorization, xNPMUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary retrieves a security
         * @param {string} npmGuid 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        s2SGetSecurity(npmGuid: string, xNPMAuthorization?: string, xNPMUser?: string, options?: any): AxiosPromise<Security> {
            return localVarFp.s2SGetSecurity(npmGuid, xNPMAuthorization, xNPMUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list all holdings in accounts a user has access to
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        s2SInvestorHoldingsIndex(xNPMAuthorization?: string, xNPMUser?: string, options?: any): AxiosPromise<void> {
            return localVarFp.s2SInvestorHoldingsIndex(xNPMAuthorization, xNPMUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates a holding from an caller that isn\'t SecondMarket
         * @param {string} npmGuid 
         * @param {SecurityUpdateRequest} securityUpdateRequest 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        s2SUpdateSecurityFromHolding(npmGuid: string, securityUpdateRequest: SecurityUpdateRequest, xNPMAuthorization?: string, xNPMUser?: string, options?: any): AxiosPromise<Security> {
            return localVarFp.s2SUpdateSecurityFromHolding(npmGuid, securityUpdateRequest, xNPMAuthorization, xNPMUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates a holding
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        s2SUpdateSecurityFromSmHolding(xNPMAuthorization?: string, xNPMUser?: string, options?: any): AxiosPromise<Security> {
            return localVarFp.s2SUpdateSecurityFromSmHolding(xNPMAuthorization, xNPMUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for s2SCreateSecurityFromSMHolding operation in S2SInvestorHoldingsApi.
 * @export
 * @interface S2SInvestorHoldingsApiS2SCreateSecurityFromSMHoldingRequest
 */
export interface S2SInvestorHoldingsApiS2SCreateSecurityFromSMHoldingRequest {
    /**
     * 
     * @type {string}
     * @memberof S2SInvestorHoldingsApiS2SCreateSecurityFromSMHolding
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof S2SInvestorHoldingsApiS2SCreateSecurityFromSMHolding
     */
    readonly xNPMUser?: string
}

/**
 * Request parameters for s2SDestroySecurity operation in S2SInvestorHoldingsApi.
 * @export
 * @interface S2SInvestorHoldingsApiS2SDestroySecurityRequest
 */
export interface S2SInvestorHoldingsApiS2SDestroySecurityRequest {
    /**
     * 
     * @type {string}
     * @memberof S2SInvestorHoldingsApiS2SDestroySecurity
     */
    readonly npmGuid: string

    /**
     * 
     * @type {string}
     * @memberof S2SInvestorHoldingsApiS2SDestroySecurity
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof S2SInvestorHoldingsApiS2SDestroySecurity
     */
    readonly xNPMUser?: string
}

/**
 * Request parameters for s2SGetSecurity operation in S2SInvestorHoldingsApi.
 * @export
 * @interface S2SInvestorHoldingsApiS2SGetSecurityRequest
 */
export interface S2SInvestorHoldingsApiS2SGetSecurityRequest {
    /**
     * 
     * @type {string}
     * @memberof S2SInvestorHoldingsApiS2SGetSecurity
     */
    readonly npmGuid: string

    /**
     * 
     * @type {string}
     * @memberof S2SInvestorHoldingsApiS2SGetSecurity
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof S2SInvestorHoldingsApiS2SGetSecurity
     */
    readonly xNPMUser?: string
}

/**
 * Request parameters for s2SInvestorHoldingsIndex operation in S2SInvestorHoldingsApi.
 * @export
 * @interface S2SInvestorHoldingsApiS2SInvestorHoldingsIndexRequest
 */
export interface S2SInvestorHoldingsApiS2SInvestorHoldingsIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof S2SInvestorHoldingsApiS2SInvestorHoldingsIndex
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof S2SInvestorHoldingsApiS2SInvestorHoldingsIndex
     */
    readonly xNPMUser?: string
}

/**
 * Request parameters for s2SUpdateSecurityFromHolding operation in S2SInvestorHoldingsApi.
 * @export
 * @interface S2SInvestorHoldingsApiS2SUpdateSecurityFromHoldingRequest
 */
export interface S2SInvestorHoldingsApiS2SUpdateSecurityFromHoldingRequest {
    /**
     * 
     * @type {string}
     * @memberof S2SInvestorHoldingsApiS2SUpdateSecurityFromHolding
     */
    readonly npmGuid: string

    /**
     * 
     * @type {SecurityUpdateRequest}
     * @memberof S2SInvestorHoldingsApiS2SUpdateSecurityFromHolding
     */
    readonly securityUpdateRequest: SecurityUpdateRequest

    /**
     * 
     * @type {string}
     * @memberof S2SInvestorHoldingsApiS2SUpdateSecurityFromHolding
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof S2SInvestorHoldingsApiS2SUpdateSecurityFromHolding
     */
    readonly xNPMUser?: string
}

/**
 * Request parameters for s2SUpdateSecurityFromSmHolding operation in S2SInvestorHoldingsApi.
 * @export
 * @interface S2SInvestorHoldingsApiS2SUpdateSecurityFromSmHoldingRequest
 */
export interface S2SInvestorHoldingsApiS2SUpdateSecurityFromSmHoldingRequest {
    /**
     * 
     * @type {string}
     * @memberof S2SInvestorHoldingsApiS2SUpdateSecurityFromSmHolding
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof S2SInvestorHoldingsApiS2SUpdateSecurityFromSmHolding
     */
    readonly xNPMUser?: string
}

/**
 * S2SInvestorHoldingsApi - object-oriented interface
 * @export
 * @class S2SInvestorHoldingsApi
 * @extends {BaseAPI}
 */
export class S2SInvestorHoldingsApi extends BaseAPI {
    /**
     * 
     * @summary creates a new security from sm holding
     * @param {S2SInvestorHoldingsApiS2SCreateSecurityFromSMHoldingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof S2SInvestorHoldingsApi
     */
    public s2SCreateSecurityFromSMHolding(requestParameters: S2SInvestorHoldingsApiS2SCreateSecurityFromSMHoldingRequest = {}, options?: AxiosRequestConfig) {
        return S2SInvestorHoldingsApiFp(this.configuration).s2SCreateSecurityFromSMHolding(requestParameters.xNPMAuthorization, requestParameters.xNPMUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary destroy the security under an user
     * @param {S2SInvestorHoldingsApiS2SDestroySecurityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof S2SInvestorHoldingsApi
     */
    public s2SDestroySecurity(requestParameters: S2SInvestorHoldingsApiS2SDestroySecurityRequest, options?: AxiosRequestConfig) {
        return S2SInvestorHoldingsApiFp(this.configuration).s2SDestroySecurity(requestParameters.npmGuid, requestParameters.xNPMAuthorization, requestParameters.xNPMUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary retrieves a security
     * @param {S2SInvestorHoldingsApiS2SGetSecurityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof S2SInvestorHoldingsApi
     */
    public s2SGetSecurity(requestParameters: S2SInvestorHoldingsApiS2SGetSecurityRequest, options?: AxiosRequestConfig) {
        return S2SInvestorHoldingsApiFp(this.configuration).s2SGetSecurity(requestParameters.npmGuid, requestParameters.xNPMAuthorization, requestParameters.xNPMUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list all holdings in accounts a user has access to
     * @param {S2SInvestorHoldingsApiS2SInvestorHoldingsIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof S2SInvestorHoldingsApi
     */
    public s2SInvestorHoldingsIndex(requestParameters: S2SInvestorHoldingsApiS2SInvestorHoldingsIndexRequest = {}, options?: AxiosRequestConfig) {
        return S2SInvestorHoldingsApiFp(this.configuration).s2SInvestorHoldingsIndex(requestParameters.xNPMAuthorization, requestParameters.xNPMUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates a holding from an caller that isn\'t SecondMarket
     * @param {S2SInvestorHoldingsApiS2SUpdateSecurityFromHoldingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof S2SInvestorHoldingsApi
     */
    public s2SUpdateSecurityFromHolding(requestParameters: S2SInvestorHoldingsApiS2SUpdateSecurityFromHoldingRequest, options?: AxiosRequestConfig) {
        return S2SInvestorHoldingsApiFp(this.configuration).s2SUpdateSecurityFromHolding(requestParameters.npmGuid, requestParameters.securityUpdateRequest, requestParameters.xNPMAuthorization, requestParameters.xNPMUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates a holding
     * @param {S2SInvestorHoldingsApiS2SUpdateSecurityFromSmHoldingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof S2SInvestorHoldingsApi
     */
    public s2SUpdateSecurityFromSmHolding(requestParameters: S2SInvestorHoldingsApiS2SUpdateSecurityFromSmHoldingRequest = {}, options?: AxiosRequestConfig) {
        return S2SInvestorHoldingsApiFp(this.configuration).s2SUpdateSecurityFromSmHolding(requestParameters.xNPMAuthorization, requestParameters.xNPMUser, options).then((request) => request(this.axios, this.basePath));
    }
}

export type S2SCreateSecurityFromSMHoldingErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const S2SCreateSecurityFromSMHoldingErrorCodes = [
];

export type S2SDestroySecurityErrorTypes =

    | {
        status: 404;
    }

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const S2SDestroySecurityErrorCodes = [
    404,
    422,
];

export type S2SGetSecurityErrorTypes =

    | {
        status: 404;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const S2SGetSecurityErrorCodes = [
    404,
];

export type S2SInvestorHoldingsIndexErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const S2SInvestorHoldingsIndexErrorCodes = [
];

export type S2SUpdateSecurityFromHoldingErrorTypes =

    | {
        status: 404;
    }

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const S2SUpdateSecurityFromHoldingErrorCodes = [
    404,
    422,
];

export type S2SUpdateSecurityFromSmHoldingErrorTypes =

    | {
        status: 404;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const S2SUpdateSecurityFromSmHoldingErrorCodes = [
    404,
];


