import { Codebooks } from './codebooks.types';

const items = {
  reset: 'reset',
} as const;

export const CbMNAStep = {
  code: Codebooks.MNA_STEP,
  items: items,
};
