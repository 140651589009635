import React, { type ComponentProps, useCallback, useState } from 'react';
import { Form } from 'antd';

import { useConfirmOnLeave } from '@npm/core/ui/components/molecules/Link/useConfirmOnLeave';
import { DiscardModal } from '@npm/core/ui/components/organisms/DiscardModal';
import { Drawer } from '@npm/core/ui/components/organisms/Drawer';
import {
  type AccountSimple,
  type Holding,
  type IssuerEntityAggregate,
  CbHoldingState,
} from '@npm/data-access';

import { usePermissions } from '../../auth/permissions/usePermissions';
import { useObo } from '../../auth/user/role';
import { useCurrentRole } from '../../auth/user/role/hooks/useCurrentRole';
import { type OboDefinition } from '../../auth/user/role/userRole.types';
import { type HoldingFormHookArgs, HoldingForm, useHoldingForm } from '../Form';
import { type HoldingCreateForm } from '../Form/HoldingForm.types';

import { DrawerTitle, InstructionsAlert } from './components';

type HoldingDrawerProps = Pick<HoldingFormHookArgs, 'id'> & {
  onSuccess?: (holding?: Holding) => void;
  initialIssuerEntity?: IssuerEntityAggregate;
  initialAccount?: AccountSimple;
  onAddHoldingUploadFailed?: (id: number) => void;
  onAccountSwitch?: () => void;
  oboOverride?: OboDefinition;
  isCorrectionMode?: boolean;
  showCompanyLogoInTitle?: boolean;
} & Pick<
    ComponentProps<typeof HoldingForm>,
    | 'disabledAccountSelect'
    | 'disabledIssuerEntitySelect'
    | 'hideIssuerEntitySelect'
    | 'showAccountSectionTitle'
  > &
  Pick<ComponentProps<typeof Drawer>, 'open' | 'onClose' | 'backToRoute'>;

export const HoldingDrawer = ({
  id,
  onClose,
  open,
  backToRoute,
  onSuccess,
  initialIssuerEntity,
  initialAccount,
  onAddHoldingUploadFailed,
  hideIssuerEntitySelect = false,
  disabledAccountSelect = Boolean(id),
  disabledIssuerEntitySelect = Boolean(id),
  showAccountSectionTitle = false,
  onAccountSwitch,
  oboOverride,
  isCorrectionMode = false,
  showCompanyLogoInTitle = false,
}: HoldingDrawerProps) => {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const { canWrite, BRO_ACCOUNT_MANAGER } = usePermissions();
  const role = useCurrentRole();
  const disabled = !(canWrite || BRO_ACCOUNT_MANAGER);

  const { oboAccount } = useObo();
  const [form] = Form.useForm<HoldingCreateForm>();

  const { setHasUnsavedChanges, onCloseAttempt, discardModalProps } =
    useConfirmOnLeave();

  const handleCloseConfirm = useCallback(() => {
    onClose?.();
    form.resetFields();
    setHasUnsavedChanges(false);
    setIsSubmitDisabled(false);
  }, [form, onClose, setHasUnsavedChanges]);

  const handleUpdate = useCallback(
    (holding?: Holding) => {
      onSuccess?.(holding);
      setHasUnsavedChanges(false);
      if (holding?.id) {
        handleCloseConfirm();
      }
    },
    [onSuccess, handleCloseConfirm]
  );

  const { data, isLoading, isUpdating, submit, collapsibleSectionArgs } =
    useHoldingForm({
      form,
      id,
      onAddHoldingUploadFailed,
      onUpdate: handleUpdate,
      oboOverride,
      setIsSubmitDisabled,
    });

  return (
    <>
      <Drawer
        backToRoute={backToRoute}
        title={
          <DrawerTitle
            id={id}
            withCompanyLogo={showCompanyLogoInTitle}
            issuerEntity={initialIssuerEntity}
          />
        }
        open={open}
        onClose={() => onCloseAttempt(handleCloseConfirm)}
        isLoading={isLoading}
        onSubmit={submit}
        buttonText={id ? 'Save Holding' : 'Add New Holding'}
        buttonLoading={isUpdating}
        isDisabled={disabled || isSubmitDisabled}
      >
        <>
          {data?.state?.code === CbHoldingState.items.needs_verification && (
            <InstructionsAlert instructions={data?.correction_instructions} />
          )}
          <HoldingForm
            form={form}
            onValuesChange={() => setHasUnsavedChanges(true)}
            initialData={data}
            hideIssuerEntitySelect={hideIssuerEntitySelect}
            disabledIssuerEntitySelect={disabledIssuerEntitySelect}
            disabledAccountSelect={disabledAccountSelect}
            showAccountSectionTitle={showAccountSectionTitle}
            preselectedValues={{
              id,
              issuer_entity: data?.issuer_entity || initialIssuerEntity,
              account: data?.account || initialAccount,
              registered_name: oboAccount?.name || role?.subject?.name,
              proof_of_ownership_document: data?.proof_of_ownership_document,
            }}
            currency={data?.asset?.denomination?.name || 'USD'}
            collapsibleSectionArgs={collapsibleSectionArgs}
            onAccountSwitch={onAccountSwitch}
            isCorrectionMode={isCorrectionMode}
          />
        </>
      </Drawer>
      <DiscardModal {...discardModalProps} />
    </>
  );
};
