import React, { type ComponentProps } from 'react';

import { CardBigRow, Flex } from '@npm/core/ui/components/atoms/common';
import { Heading } from '@npm/core/ui/components/atoms/Typography';
import { BigNumberValue } from '@npm/core/ui/components/molecules/BigNumberValue';
import { Card } from '@npm/core/ui/components/molecules/Card';
import { CompanyLogo } from '@npm/core/ui/components/molecules/CompanyLogo';
import { TableVertical } from '@npm/core/ui/components/molecules/TableVertical';
import { Drawer } from '@npm/core/ui/components/organisms/Drawer';
import { type NegotiationShowHolding } from '@npm/data-access';

import { AccountInfo } from '../../../auth/user/context/AccountInfo';
import {
  DocumentViewerModal,
  useDocumentViewer,
} from '../../../documents/DocumentViewer';

import { getHoldingRows } from './HoldingDetailsDrawer.rows';

type Props = {
  holding: NegotiationShowHolding;
} & Pick<ComponentProps<typeof Drawer>, 'open' | 'onClose'>;

export const HoldingDetailsDrawer = ({ holding, open, onClose }: Props) => {
  const { documentViewerModalProps, showNpmDocument } = useDocumentViewer();

  return (
    <Drawer
      buttonText={'Close'}
      onSubmit={onClose}
      title={
        <Flex justify="space-between" align="center" style={{ width: '100%' }}>
          <Heading variant="h3">
            {holding?.certificate_number}
            {' · '}
            {holding?.asset?.type?.name}
          </Heading>

          <CompanyLogo
            url={holding?.issuer_entity?.logo_url}
            title={holding?.issuer_entity?.logo_name}
            name={holding?.issuer_entity?.name}
            size="sm"
          />
        </Flex>
      }
      onClose={onClose}
      open={open}
    >
      <Flex direction="column" gap="md">
        <AccountInfo
          accountPanelProps={{
            showTitle: false,
            showRepresentative: false,
          }}
          accountId={holding?.account?.id}
        />

        <Card variant="drawer">
          <CardBigRow>
            <BigNumberValue title={'Quantity'} value={holding?.quantity} />
            <BigNumberValue
              currency={holding?.asset?.denomination?.name}
              title={'Cost Basis'}
              value={holding?.cost_basis}
            />
          </CardBigRow>

          <TableVertical
            rows={getHoldingRows({
              onDocumentClick: showNpmDocument,
            })}
            data={holding}
          />
        </Card>
      </Flex>
      <DocumentViewerModal {...documentViewerModalProps} />
    </Drawer>
  );
};
