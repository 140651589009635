import { type Wizard } from '@npm/core/ui/components/organisms/WizardNew';
import {
  type AccountShowAggregate,
  type CbBackgroundCheckState,
  type CbOnboardingStatus,
  type OnboardingStatus,
  type Person,
} from '@npm/data-access';

import { type SidebarSection } from '../postOnboarding/PostOnboarding.types';

export type PostOnboardingContextType = {
  account: AccountShowAggregate;
  onboardingStatus: OnboardingStatus;
  activeSectionProps: {
    activeSection: SidebarSection;
    stepIndex: number;
    totalSteps: number;
    isSubstep: boolean;
    totalStepSubsteps: number;
    substepIndex: number;
  };
  sectionsProgress: Record<SectionKey, number>;
  isInvitedToLivePrograms: boolean;
  representative?: Person;
};

export type GetWizardStepsArgs = {
  onboardingStatus: OnboardingStatus | undefined;
  personId?: number;
  backgroundCheckStatus?: keyof typeof CbBackgroundCheckState['items'];
  canEditSpouse?: boolean;
  shouldUsePersona?: boolean;
};

export type GetWizardStepsFn = (
  args: GetWizardStepsArgs & {
    getSectionsConfig: (args: GetWizardStepsArgs) => OnboardingSectionConfig[];
  }
) => Wizard.StepDefinition[];

export type GetSectionsConfigFn = (
  args: GetWizardStepsArgs
) => OnboardingSectionConfig[];

export type OnboardingStep = Wizard.StepDefinition & {
  substeps?: Wizard.StepDefinition[];
  tooltip?: string;
};

export type OnboardingSectionConfig = {
  key: SectionKey;
  items?: (
    | OnboardingSectionItemWithSubsteps
    | OnboardingSectionItemWithoutSubsteps
  )[];
  title: string;
  tooltip?: string;
  subTitle?: string;
  subTitleTooltip?: string;
  showProgress?: boolean;
  actionButton?: React.ReactNode;
  wholeClickable?: boolean;
  hideContentHeader?: boolean;
};

export const sectionKeyToTitleMap = {
  aml_kyc: 'AML / KYC', // non-NPMS
  identity_verification: 'Identity Verification', // NPMS
  agreements: 'Agreements',
  other_requirements: 'Other Requirements',
};

export const parentStepKeyToTitleMap = {
  personal_information: 'Personal Information',
  entity_information: 'Entity Information',
  identity_verification: 'Identity Verification',
  representative_identity_verification: 'Representative Information',
};

export type SectionKey = keyof typeof sectionKeyToTitleMap;
export type ParentStepKey = keyof typeof parentStepKeyToTitleMap;
export type StepKey = keyof typeof CbOnboardingStatus.steps;

export type OnboardingSectionItemWithSubsteps = OnboardingStep & {
  key: ParentStepKey;
  substeps: OnboardingSectionItemWithoutSubsteps[];
};
export type OnboardingSectionItemWithoutSubsteps = OnboardingStep & {
  key: StepKey;
};
