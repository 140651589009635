import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useForm } from 'antd/es/form/Form';

import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { useConfirmOnLeave } from '@npm/core/ui/components/molecules/Link';
import { DiscardModal } from '@npm/core/ui/components/organisms/DiscardModal';
import { Drawer } from '@npm/core/ui/components/organisms/Drawer';
import { WatchlistDataIds } from '@npm/core/ui/constants';
import { handleValidationError } from '@npm/core/ui/utils/form';
import { type UserRoleAggregate, useWatchlistCreate } from '@npm/data-access';

import { NewAccountDrawerWithTabs } from '../../../account/CreateNewAccount';

import {
  type FormValues,
  UpsertWatchlistForm,
} from './Form/UpsertWatchlistForm';

type Props = {
  open: boolean;
  onClose: () => void;
  accountId?: number;
  filterAccounts?: (role: UserRoleAggregate) => boolean;
  disableAccountSelect?: boolean;
};

export const AddWatchlistDrawer = ({
  open,
  onClose,
  accountId,
  filterAccounts,
  disableAccountSelect,
}: Props) => {
  const [form] = useForm<FormValues>();
  const [openAccountDrawer, setOpenAccountDrawer] = useState(false);

  const { withShowApiErrorMiddleware } = useAlerts();
  const { setHasUnsavedChanges, onCloseAttempt, discardModalProps } =
    useConfirmOnLeave();

  const queryClient = useQueryClient();
  const { execute, isLoading } = useWatchlistCreate();

  const handleCloseConfirm = () => {
    form.resetFields();
    setHasUnsavedChanges(false);
    onClose();
  };

  const handleSubmit = async (values: FormValues) => {
    try {
      await form.validateFields();
    } catch (err) {
      handleValidationError(form, err);
      return;
    }

    try {
      await withShowApiErrorMiddleware(execute)(
        {
          watchlistCreateRequestContract: {
            account_id: accountId,
            ...values,
            issuer_entity_id: [Number(values.issuer_entity_id)],
          },
        },
        {
          onComplete: () => {
            queryClient.invalidateQueries({
              queryKey: ['IssuerEntityIndex'],
            });
          },
        }
      );
      handleCloseConfirm();
      Notification.success({
        message: 'Watchlist successfully created.',
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Drawer
        data-cy={WatchlistDataIds.Drawer.AddDrawer}
        open={open}
        title={'Add to Watchlist'}
        buttonText={'Add to Watchlist'}
        buttonLoading={isLoading}
        isDisabled={isLoading}
        onClose={() => onCloseAttempt(handleCloseConfirm)}
        onSubmit={form.submit}
        destroyOnClose
      >
        <UpsertWatchlistForm
          form={form}
          onFinish={handleSubmit}
          onValuesChange={() => setHasUnsavedChanges(true)}
          accountId={accountId}
          disableAccountSelect={disableAccountSelect}
          filterAccounts={filterAccounts}
          onCreateAccount={() => setOpenAccountDrawer(true)}
        />
      </Drawer>
      <NewAccountDrawerWithTabs
        isOpen={openAccountDrawer}
        onClose={() => setOpenAccountDrawer(false)}
      />
      <DiscardModal {...discardModalProps} />
    </>
  );
};
