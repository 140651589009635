import { Codebooks } from './codebooks.types';

const Items = {
  issuer_entity: 'issuer_entity',
  match: 'match',
} as const;

export const CbNoteSubjectType = {
  code: Codebooks.NOTE_SUBJECT_TYPE,
  items: Items,
};
