import { Form } from 'antd';

import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import {
  CbAccreditationLevel,
  CbQualifiedPurchaserType,
  useCodebook,
  useEntityAccountAccreditationUpdate,
} from '@npm/data-access';

import type { PurchaserQuestionnaireFormFields } from '../../Accreditation.types';

export const usePurchaserQuestionnaireForm = (
  accreditationId: number,
  accountId: number,
  onUpdate: () => void
) => {
  const [form] = Form.useForm<PurchaserQuestionnaireFormFields>();
  const { withShowApiErrorMiddleware } = useAlerts();
  const { execute: updateAccreditation, isLoading: isAccreditationUpdating } =
    useEntityAccountAccreditationUpdate();

  const { data: data, isLoading: isLoading } = useCodebook({
    type: CbQualifiedPurchaserType.code,
  });

  const handleSubmit = async () => {
    let values: PurchaserQuestionnaireFormFields;

    try {
      values = await form.validateFields([
        CbAccreditationLevel.items.qualified_purchaser,
        'signature',
        'entity_rep_title',
      ]);
    } catch (e) {
      return console.error('Validation failed:', e);
    }

    try {
      await withShowApiErrorMiddleware(updateAccreditation)({
        accountId,
        id: accreditationId,
        accountsAccreditationUpdateRequestContract: {
          answers: [
            {
              field: {
                code: CbAccreditationLevel.items.qualified_purchaser,
              },
              answer: {
                code: values.qualified_purchaser,
              },
            },
            {
              field: {
                code: 'entity_rep_title',
              },
              answer: {
                code: values.entity_rep_title,
              },
            },
          ],
          signature: values.signature,
        },
      });
    } catch (e) {
      console.error('Failed to update accreditation:', e);
    }

    onUpdate();
  };

  return {
    form,
    data,
    isLoading,
    handleSubmit,
    isUpdating: isAccreditationUpdating,
  };
};
