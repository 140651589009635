import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import { CbBackgroundCheckState } from '@npm/data-access';

import { ZendeskContactButton } from '../../../app/zendesk';

import * as S from './PersonaVerificationFailed.styles';

const ZENDESK_TICKET_GROUP_ID = 'onboarding_individual_identity_verification';

type Props = {
  variant:
    | typeof CbBackgroundCheckState.items.failed
    | typeof CbBackgroundCheckState.items.marked_for_review;
};

export const PersonaVerificationFailed = ({ variant }: Props) => {
  return (
    <S.Card>
      <Flex direction="column" justify="center" align="center" gap="lg">
        <S.PersonShieldIconWrapper $variant={variant}>
          <Icon
            name={
              variant === CbBackgroundCheckState.items.failed
                ? 'circle-x'
                : 'warning'
            }
            strokeWidth={2}
            size="lg"
          />
        </S.PersonShieldIconWrapper>
        <Flex direction="column" align="center" gap="sm">
          <Heading textAlign="center" variant="h1" colorVariant="secondary">
            {variant === CbBackgroundCheckState.items.failed
              ? 'We failed to verify your identity'
              : 'We are reviewing your identity verification'}
          </Heading>
          <Text align="center" colorVariant="tertiary">
            If you have questions or concerns, email{' '}
            <Text size="sm" href="mailto:support@npm.com">
              support@npm.com
            </Text>{' '}
            or{' '}
            <ZendeskContactButton
              zendeskTicketGroupId={ZENDESK_TICKET_GROUP_ID}
            >
              Click Here
            </ZendeskContactButton>
            .
          </Text>
        </Flex>
      </Flex>
    </S.Card>
  );
};
