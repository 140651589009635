import {
  type AccountShowAggregate,
  CbEventStatus,
  useEventListLazy,
} from '@npm/data-access';

import { useCurrentWorkstation } from '../../auth/user/role';

export const useIsInvitedToLivePrograms = () => {
  const workstation = useCurrentWorkstation();
  const isBrokerageWS = workstation?.type?.code === 'brokerage';
  const [fetchEventList] = useEventListLazy();

  return async (account: AccountShowAggregate) => {
    try {
      if (isBrokerageWS) return false;

      const eventListData = await fetchEventList({
        variables: {
          accountId: account.id,
          eventStatus: CbEventStatus.items.ACTIVE,
          size: 0,
        },
      });

      return eventListData.pagination?.total_records > 0;
    } catch (err) {
      console.error(err);
      return false;
    }
  };
};
