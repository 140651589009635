export type DdContextVariableKey =
  | 'role_type'
  | 'investor_type'
  | 'sponsored_level'
  | 'premium_user'
  | 'npm_user';

type DdRoleType = 'investor' | 'broker' | 'issuer';
export type DdWorkstation = 'investor' | 'brokerage' | 'issuer';

export const DD_WORKSTATION_TO_ROLE_NAME: Record<DdWorkstation, DdRoleType> = {
  investor: 'investor',
  brokerage: 'broker',
  issuer: 'issuer',
};
