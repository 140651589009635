import { useWizard } from 'react-use-wizard';

import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import { type WizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';
import { usePreservableQueryParamsStore } from '@npm/core/ui/services/paths';
import { buildQueryParamsString } from '@npm/core/ui/utils/url';

import { type PostOnboardingContextType } from '../PostOnboarding.types';
import { findNextIncompleteOnboardingStep } from '../PostOnboarding.utils';
import { ACTIVE_STEP_QUERY_PARAM_KEY } from '../PostOnboardingWizardWrapper';

export const useReturnUrl = () => {
  const { activeStep } = useWizard();
  const preservableParams = usePreservableQueryParamsStore
    .getState()
    .getPreservableQueryParams(window.location.href);

  const { steps, onboardingStatus } = useWizardStore(
    (s: WizardStore<PostOnboardingContextType>) => ({
      steps: s.steps,
      onboardingStatus: s.data.onboardingStatus,
    })
  );

  const nextIncompleteStep = findNextIncompleteOnboardingStep({
    steps,
    onboardingStatus,
    startIndex: activeStep,
  });

  return `${window.location.origin}${
    window.location.pathname
  }${buildQueryParamsString({
    ...preservableParams,
    [ACTIVE_STEP_QUERY_PARAM_KEY]: nextIncompleteStep,
  })}`;
};
