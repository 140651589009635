import React, { useEffect } from 'react';
import { type FormInstance } from 'antd';

import { Margin } from '@npm/core/ui/components/atoms/common';
import { Form } from '@npm/core/ui/components/atoms/Form';
import {
  FormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { NoDataArea } from '@npm/core/ui/components/atoms/NoDataArea';
import { CypressDataIds } from '@npm/core/ui/constants';
import {
  type AccountShowAggregate,
  type BrokerageFirmAggregate,
  type Holding,
  type IssuerEntityAggregate,
  type OrderSourceCode,
  type UserRoleApiUserRoleIndexRequest,
  type UserRoleIndex,
  type VisibilityCode,
  CbAccountType,
  CbAssetType,
  CbATSPool,
  CbOrderEntryType,
  CbOrderItemState,
  CbOrderSource,
  CbRoleType,
  CbSubmissionStructure,
  CbVisibility,
} from '@npm/data-access';

import { isSpvHolding } from '../../../../..//holdings';
import { isNPMS, useUserContextStore } from '../../../../../auth/user/context';
import { type OboDefinition } from '../../../../../auth/user/role/userRole.types';
import { CompanyCard } from '../../../../../company';
import {
  CompanySearch,
  HoldingSelect,
  UserRoleSearch,
} from '../../../../../filters';
import { type OrderSizeType } from '../../../../../order';
import { HoldingCard } from '../../../components/HoldingCard';
import { OrderAlerts } from '../../../components/OrderAlerts';
import {
  isAccountGuestRole,
  isAccountNotAccredited,
} from '../../../utils/order';
import { type OrderPlacementFormValues } from '../OrderPlacementDrawer.types';

import { AccountNotAccredited } from './components/AccountNotAccredited';
import { InsufficientPermission } from './components/InsufficientPermission';
import { NoHoldingsFound } from './components/NoHoldingsFound';
import { OrderEntrySection } from './components/OrderEntrySection';
import { OrderPreferencesSection } from './components/OrderPreferencesSection';
import { SideSelect } from './components/SideSelect';

import * as S from './OrderPlacementForm.styles';

type Props = {
  form: FormInstance<OrderPlacementFormValues>;
  onValuesChange?: (
    changedValues: Partial<OrderPlacementFormValues>,
    values: OrderPlacementFormValues
  ) => void;
  disabledFields?: ('issuerEntityId' | 'buySell' | 'holdingId' | 'accountId')[];
  company: IssuerEntityAggregate;
  source: OrderSourceCode | undefined;
  activeAction: 'buy' | 'sell';
  selectedHolding: Holding | undefined;
  setSelectedHolding: (holding: Holding) => void;
  openAddHoldingDrawer: () => void;
  allowedAssetTypeOptions: { label: string; value: string }[];
  noHoldingsFound?: boolean;
  holdingsLoading?: boolean;
  orderSizeType: OrderSizeType;
  toggleSizeType: () => void;
  updateSizeType: (val: OrderSizeType) => void;
  onAccountSwitch: () => void;
  brokerageFirm: BrokerageFirmAggregate | undefined;
  isInvestor: boolean;
  obo: OboDefinition | null;
  selectedAccount: AccountShowAggregate;
};

export const OrderPlacementForm = ({
  form,
  onValuesChange,
  disabledFields,
  company,
  source,
  activeAction,
  selectedHolding,
  setSelectedHolding,
  openAddHoldingDrawer,
  allowedAssetTypeOptions,
  noHoldingsFound,
  holdingsLoading,
  orderSizeType,
  toggleSizeType,
  updateSizeType,
  brokerageFirm,
  onAccountSwitch,
  isInvestor,
  obo,
  selectedAccount,
}: Props) => {
  const initialValues: Partial<OrderPlacementFormValues> = {
    buySell: activeAction,
    assetType: CbAssetType.items.AnyShareClass,
    visibility: CbVisibility.items.internal,
    atsPool: CbATSPool.items.internal,
    orderType: CbOrderEntryType.items.ioi,
    structure: CbSubmissionStructure.items.direct,
    goodTillCancelled: true,
    ...(source === CbOrderSource.items.historical && {
      state: CbOrderItemState.items.expired,
    }),
  };

  const formValues =
    Form.useWatch([], form) ?? ({} as OrderPlacementFormValues);
  const accountId = obo?.account?.id ?? formValues.accountId;
  const holdingId = Form.useWatch('holdingId', form);
  const isIndividualAccountSelected =
    selectedAccount?.type?.code === CbAccountType.items.PersonAccount;

  const handleHoldingSelect = (holding: Holding) => {
    setSelectedHolding(holding);
    const isSpv = isSpvHolding(holding?.asset?.type?.code);
    if (
      isSpv &&
      form.getFieldValue('structure') !== CbSubmissionStructure.items.spv
    ) {
      form.setFieldsValue({
        structure: CbSubmissionStructure.items.spv,
      });
    }
    if (
      !isSpv &&
      form.getFieldValue('structure') !== CbSubmissionStructure.items.direct
    ) {
      form.setFieldsValue({
        structure: CbSubmissionStructure.items.direct,
      });
    }
  };

  useEffect(() => {
    // portfolio (non-SM) holdings have ids in the range of 0 to -∞ before they get assigned a SM holding id -> allow 0 value
    if (!holdingId && holdingId !== 0) {
      handleHoldingSelect(null);
    }
  }, [holdingId]);

  const autoselectAccount = (
    data: UserRoleIndex,
    variables: UserRoleApiUserRoleIndexRequest
  ) => {
    if (!isInvestor || accountId || variables.search) return;
    const npmsAccounts = data?.user_roles?.filter(
      role =>
        isNPMS(role.subject) && role.role_name.code !== CbRoleType.items.SPOUSE
    );
    if (npmsAccounts?.length === 1) {
      form.setFieldValue('accountId', npmsAccounts[0]?.subject?.id);
    }
  };

  const user = useUserContextStore(store => store.user);

  const isSelectedAccountRestricted =
    (activeAction === 'buy' &&
      source !== CbOrderSource.items.historical &&
      isAccountNotAccredited(selectedAccount)) ||
    (!obo && isAccountGuestRole(selectedAccount, user?.email));

  const showFormFields =
    !isSelectedAccountRestricted &&
    accountId &&
    selectedAccount &&
    formValues.issuerEntityId;

  useEffect(() => {
    if (isIndividualAccountSelected && isInvestor) {
      form.setFieldsValue({ orderType: CbOrderEntryType.items.firm });
    }
  }, [form, isIndividualAccountSelected, isInvestor]);

  return (
    <S.OrderPlacementFormContainer>
      <Form<OrderPlacementFormValues>
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={initialValues}
        onValuesChange={onValuesChange}
      >
        <FormItem
          name="issuerEntityId"
          label="Company"
          rules={[{ required: true, message: 'This field is mandatory' }]}
          marginBottom="md"
          hidden={disabledFields?.includes('issuerEntityId')}
        >
          <CompanySearch
            variables={{ unverifiedHoldings: true }}
            placeholder="Search"
            data-cy={CypressDataIds.Holdings.Form.CompanySearch}
            defaultEntity={company}
            requestCoverageVariant="row"
          />
        </FormItem>
        {disabledFields?.includes('issuerEntityId') && company && (
          <CompanyCard company={company} />
        )}
        <FormItem
          name="accountId"
          label="Account"
          rules={[{ required: true, message: 'This field is mandatory' }]}
          marginBottom="md"
          style={obo ? { display: 'none' } : {}}
        >
          <UserRoleSearch
            disabled={!!obo}
            onFetchComplete={autoselectAccount}
            filterOptions={
              isInvestor
                ? userRole =>
                    isNPMS(userRole.subject) &&
                    userRole.role_name.code !== CbRoleType.items.SPOUSE
                : undefined
            }
          />
        </FormItem>

        <FormItem
          name="buySell"
          rules={[{ required: true, message: 'This field is mandatory' }]}
          marginBottom="md"
          hidden={disabledFields?.includes('buySell')}
        >
          <SideSelect
            onChange={(action: 'buy' | 'sell') => {
              if (formValues.quantity || formValues.minimumQuantity) {
                // Do not switch the size type automatically if there's already a value
                return;
              }
              updateSizeType(action === 'buy' ? 'USD' : 'Shares');
            }}
          />
        </FormItem>

        {!showFormFields ? (
          <div>
            {isSelectedAccountRestricted ? (
              activeAction === 'buy' &&
              isAccountNotAccredited(selectedAccount) ? (
                <AccountNotAccredited
                  selectedAccountId={accountId}
                  isInvestor={isInvestor}
                  obo={obo}
                />
              ) : (
                <InsufficientPermission
                  onClick={onAccountSwitch}
                  isObo={!!obo}
                />
              )
            ) : (
              <NoDataArea
                title={
                  !formValues.issuerEntityId
                    ? 'Select a company to enter an order'
                    : 'Select an account to enter IOI'
                }
                iconColor="info"
              />
            )}
          </div>
        ) : activeAction === 'sell' && (noHoldingsFound || holdingsLoading) ? (
          <Margin bottom="lg">
            <NoHoldingsFound
              loading={holdingsLoading}
              companyName={company?.name}
              onClick={openAddHoldingDrawer}
            />
          </Margin>
        ) : (
          <>
            {activeAction === 'sell' && (
              <Margin bottom="md" top="lg">
                <FormItem
                  name="holdingId"
                  label="Holding"
                  rules={[VALIDATION_RULES.required()]}
                  marginBottom="sm"
                >
                  <HoldingSelect
                    onItemSelected={handleHoldingSelect}
                    onAddHoldingClick={openAddHoldingDrawer}
                    accountId={accountId}
                    issuerEntityId={formValues.issuerEntityId}
                    labelBasePropName="name"
                    disabled={
                      disabledFields?.includes('holdingId') &&
                      'holdingId' in formValues &&
                      !!formValues.holdingId
                    }
                    secondmarket
                    includeSpvs
                    includePortfolioHoldings
                    oboOverride={obo}
                  />
                </FormItem>
                {selectedHolding && <HoldingCard holding={selectedHolding} />}
              </Margin>
            )}

            <Margin bottom="lg" top="lg">
              <OrderEntrySection
                activeAction={activeAction}
                holding={selectedHolding}
                orderSizeType={orderSizeType}
                toggleSizeType={toggleSizeType}
                company={company}
                visibility={formValues.visibility as VisibilityCode}
              />
            </Margin>

            <Margin bottom="lg" top="lg">
              <OrderPreferencesSection
                activeAction={activeAction}
                assetTypes={allowedAssetTypeOptions}
                source={source}
                brokerageFirm={brokerageFirm}
                isObo={!!obo}
                isIndividualAccountSelected={isIndividualAccountSelected}
              />
            </Margin>

            {!!obo && (
              <OrderAlerts
                formValues={formValues}
                brokerageFirm={brokerageFirm}
                source={source}
              />
            )}
          </>
        )}
      </Form>
    </S.OrderPlacementFormContainer>
  );
};
