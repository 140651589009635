import { compact } from 'lodash';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { CbBackgroundCheckState, CbOnboardingStatus } from '@npm/data-access';

import { PersonaVerificationButton } from './Persona/PersonaVerificationButton';
import {
  type GetSectionsConfigFn,
  type GetWizardStepsArgs,
  type OnboardingSectionConfig,
  parentStepKeyToTitleMap,
  sectionKeyToTitleMap,
} from './PostOnboarding.types';
import { filterOnboardingSectionsConfig } from './PostOnboarding.utils';
import {
  AuthorizedSignersPostOnboarding,
  BankAccountInformationPostOnboarding,
  GovernmentIDPostOnboarding,
  IndividualAccreditationPostOnboarding,
  IndividualInformationPostOnboarding,
  IndividualTaxIdPostOnboarding,
  MarketplaceAgreementPostOnboarding,
  PayingAgentAgreementPostOnboarding,
  PersonaVerification,
  PersonaVerificationFailed,
  SpousalInformationPostOnboarding,
  TaxInformationPostOnboarding,
} from './steps';

const getAMLKYCRepetitiveSteps = (personId: number, canEditSpouse: boolean) => {
  const personalInformation = {
    key: 'personal_information_state',
    title: CbOnboardingStatus.titleMap.personal_information_state,
    component: stepProps => (
      <IndividualInformationPostOnboarding
        stepProps={stepProps}
        personId={personId}
      />
    ),
    isEditable: true,
  } as const;

  const taxInformation = {
    key: 'tax_information_state',
    title: CbOnboardingStatus.titleMap.tax_information_state,
    component: stepProps => (
      <TaxInformationPostOnboarding
        stepProps={stepProps}
        zendeskTicketGroupId="post_onboarding_individual_tax_information_state"
      />
    ),
  } as const;

  const representativePhoto = {
    key: 'representative_photo_state',
    title: CbOnboardingStatus.titleMap.representative_photo_state,
    component: stepProps => (
      <GovernmentIDPostOnboarding
        zendeskTicketGroupId="post_onboarding_individual_government_id"
        stepProps={stepProps}
      />
    ),
  } as const;

  const individualTaxId = {
    key: 'individual_tax_state',
    title: CbOnboardingStatus.titleMap.individual_tax_state,
    component: stepProps => (
      <IndividualTaxIdPostOnboarding
        zendeskTicketGroupId="post_onboarding_individual_tax_id"
        stepProps={stepProps}
        personId={personId}
      />
    ),
  } as const;

  const authorizedSigner = {
    key: 'authorized_signer_state',
    title: CbOnboardingStatus.titleMap.authorized_signer_state,
    component: stepProps => (
      <AuthorizedSignersPostOnboarding stepProps={stepProps} />
    ),
    isEditable: true,
  } as const;

  const spouseInformation = {
    key: 'spouse_information_state',
    title: CbOnboardingStatus.titleMap.spouse_information_state,
    component: stepProps => (
      <SpousalInformationPostOnboarding stepProps={stepProps} />
    ),
    isEditable: canEditSpouse,
  } as const;

  return {
    personalInformation,
    taxInformation,
    representativePhoto,
    individualTaxId,
    authorizedSigner,
    spouseInformation,
  };
};

export const personaRequiredSteps: (keyof typeof CbOnboardingStatus.steps)[] = [
  'personal_information_state',
  'tax_information_state',
  'representative_photo_state',
  'spouse_information_state',
];

const getPersonaIdentityVerificationSection = ({
  onboardingStatus,
  personId,
  canEditSpouse,
  backgroundCheckStatus,
}: Omit<GetWizardStepsArgs, 'shouldUsePersona'>): OnboardingSectionConfig => {
  const {
    personalInformation,
    taxInformation,
    representativePhoto,
    individualTaxId,
    authorizedSigner,
    spouseInformation,
  } = getAMLKYCRepetitiveSteps(personId, canEditSpouse);

  const isBackgroundCheckFailed =
    backgroundCheckStatus === CbBackgroundCheckState.items.failed ||
    backgroundCheckStatus === CbBackgroundCheckState.items.marked_for_review;
  const isBackgroundCheckPassed =
    backgroundCheckStatus === CbBackgroundCheckState.items.passed;
  const isBackgroundCheckComplete =
    isBackgroundCheckPassed || isBackgroundCheckFailed;

  const areAllSectionsFilledIn = personaRequiredSteps.every(
    step => onboardingStatus[step].code === CbOnboardingStatus.items.completed
  );

  const isPersonaFlowCompleted =
    areAllSectionsFilledIn && isBackgroundCheckComplete;

  const isPersonaFlowInProgress = personaRequiredSteps.some(
    step => onboardingStatus[step].code === CbOnboardingStatus.items.completed
  );

  const actionButton = isBackgroundCheckFailed ? (
    <Alert
      type={
        backgroundCheckStatus === CbBackgroundCheckState.items.failed
          ? 'error'
          : 'warning'
      }
      icon={
        <Icon
          name={
            backgroundCheckStatus === CbBackgroundCheckState.items.failed
              ? 'circle-x'
              : 'warning'
          }
        />
      }
      message={
        backgroundCheckStatus === CbBackgroundCheckState.items.failed
          ? 'Identity Verification Failed'
          : 'Identity Verification Under Review'
      }
    />
  ) : (
    <PersonaVerificationButton
      withAlert={isPersonaFlowInProgress}
      isCompleted={isPersonaFlowCompleted || isBackgroundCheckPassed}
      buttonVariant={isPersonaFlowInProgress ? 'default' : 'outline'}
      text={
        isPersonaFlowInProgress
          ? 'Complete Your Verification'
          : 'Start Verification'
      }
    />
  );

  const allItems: OnboardingSectionConfig['items'] = [
    personalInformation,
    taxInformation,
    representativePhoto,
    individualTaxId,
    authorizedSigner,
    spouseInformation,
  ];

  const completePersonaStep: OnboardingSectionConfig['items'] = [
    {
      key: 'personal_information_state',
      title: '',
      component: stepProps => (
        <PersonaVerification
          stepProps={stepProps}
          variant={isPersonaFlowInProgress ? 'in-progress' : 'not-started'}
        />
      ),
    },
  ];

  const failedPersonaStep: OnboardingSectionConfig['items'] = [
    {
      key: 'personal_information_state',
      title: '',
      component: () => (
        <PersonaVerificationFailed
          variant={
            backgroundCheckStatus as
              | typeof CbBackgroundCheckState.items.failed
              | typeof CbBackgroundCheckState.items.marked_for_review
          }
        />
      ),
    },
  ];

  return {
    key: 'identity_verification',
    title: sectionKeyToTitleMap.identity_verification,
    tooltip:
      'You will be required to provide a US Tax ID and a valid, unexpired government issued photo ID. This information is used to populate transaction documentation and satisfy any applicable regulatory requirements. If you do not have a US Tax ID, please check the checkbox next to "I do not have a US Tax ID."',
    showProgress: true,
    actionButton,
    hideContentHeader: true,
    items: isBackgroundCheckFailed
      ? failedPersonaStep
      : isPersonaFlowCompleted || isBackgroundCheckPassed
      ? allItems
      : completePersonaStep,
  };
};

const getNonPersonaAMLKYCSection = ({
  personId,
  canEditSpouse,
}: GetWizardStepsArgs): OnboardingSectionConfig => {
  const {
    personalInformation,
    taxInformation,
    representativePhoto,
    individualTaxId,
    authorizedSigner,
    spouseInformation,
  } = getAMLKYCRepetitiveSteps(personId, canEditSpouse);

  return {
    key: 'aml_kyc',
    title: sectionKeyToTitleMap.aml_kyc,
    tooltip:
      'In order to help the government fight the funding of terrorism and money laundering activities, U.S. federal law requires all financial institutions to obtain, verify, and record information that identifies each person or entity with whom we conduct securities transactions.',
    showProgress: true,
    items: [
      {
        key: 'personal_information',
        title: parentStepKeyToTitleMap.personal_information,
        component: () => null,
        tooltip:
          'This information is collected to populate agreements and confirm your identity to keep you and NPM safe.',
        substeps: [personalInformation, taxInformation, spouseInformation],
      },
      {
        key: 'identity_verification',
        title: parentStepKeyToTitleMap.identity_verification,
        component: () => null,
        tooltip:
          'You will be required to provide a US Tax ID and a valid, unexpired government issued photo ID. This information is used to populate transaction documentation and satisfy any applicable regulatory requirements. If you do not have a US Tax ID, please check the checkbox next to "I do not have a US Tax ID."',
        substeps: [individualTaxId, representativePhoto],
      },
      authorizedSigner,
    ],
  };
};

export const getIndividualOnboardingSections: GetSectionsConfigFn = props => {
  const { onboardingStatus, personId, shouldUsePersona } = props;

  const config: OnboardingSectionConfig[] = [
    shouldUsePersona
      ? getPersonaIdentityVerificationSection(props)
      : getNonPersonaAMLKYCSection(props),
    {
      key: 'agreements',
      title: sectionKeyToTitleMap.agreements,
      subTitle: 'Section Required for Marketplace',
      tooltip: 'Legal agreements required for broker-dealer services.',
      subTitleTooltip: '',
      items: [
        {
          key: 'marketplace_agreement_state',
          title: CbOnboardingStatus.titleMap.marketplace_agreement_state,
          component: stepProps => (
            <MarketplaceAgreementPostOnboarding
              zendeskTicketGroupId="post_onboarding_individual_marketplace_agreement"
              stepProps={stepProps}
            />
          ),
        },
        {
          key: 'paying_agent_agreement_state',
          title: CbOnboardingStatus.titleMap.paying_agent_agreement_state,
          component: stepProps => (
            <PayingAgentAgreementPostOnboarding
              zendeskTicketGroupId="post_onboarding_individual_paying_agent_agreement"
              stepProps={stepProps}
            />
          ),
        },
      ],
    },
    {
      key: 'other_requirements',
      title: sectionKeyToTitleMap.other_requirements,
      subTitle: 'Section Required for Marketplace',
      tooltip:
        "Required information to complete the settlement process and to evaluate the individual's level of financial sophistication.",
      items: [
        {
          key: 'accredited_investor_state',
          title: CbOnboardingStatus.titleMap.accredited_investor_state,
          titleTooltip: (
            <>
              Investors in private company securities must be, at minimum, an
              accredited investor within the meaning of Rule 501 of Regulation
              D. Please complete this step to establish this account’s status as
              an accredited investor. <br /> <br />
              Note: This step is not required if you are participating in a
              company-sponsored tender offer.
            </>
          ),
          component: stepProps => (
            <IndividualAccreditationPostOnboarding
              zendeskTicketGroupId="post_onboarding_individual_accreditation"
              stepProps={stepProps}
              personId={personId}
            />
          ),
        },
        {
          key: 'bank_account_state',
          title: CbOnboardingStatus.titleMap.bank_account_state,
          component: stepProps => (
            <BankAccountInformationPostOnboarding stepProps={stepProps} />
          ),
          isEditable: true,
        },
      ],
    },
  ];

  return filterOnboardingSectionsConfig(config, onboardingStatus);
};
