import { type CbOnboardingStatus } from '@npm/data-access';

import { PageState } from '../components/organisms/Wizard/Wizard.types';

export const onboardingStatusToPageStateMap: Record<
  keyof typeof CbOnboardingStatus['items'],
  PageState
> = {
  incomplete: PageState.INITIAL,
  completed: PageState.FILLED,
  warning: PageState.INITIAL,
  not_applicable: PageState.NONE,
} as const;
