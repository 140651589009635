type Counter<U> = (value?: U) => number;

export type ObjectFilterCalculator<T extends object> = {
  [key in keyof T]?: Counter<T[key]> | false;
};

export type CompatibleObject = {
  [key: string]: string | boolean | string[] | undefined;
};

const countDefault = (val?: string | boolean | string[]) => {
  if (typeof val === 'undefined') {
    return 0;
  }
  if (val === null) {
    return 0;
  }
  if (typeof val === 'string') {
    return val ? 1 : 0;
  }
  if (typeof val === 'boolean') {
    return val ? 1 : 0;
  }
  if (Array.isArray(val)) {
    return 1;
  }
  if (typeof val === 'number') {
    return 1;
  }

  return 0;
};

/**
 * Hook for counting active filters
 * Automatically counts filters in object, extendable with generics.
 * @param mapper - abreaviated object passed to `usePersistedFilters` hook, accepts functions for properties in object
 * @returns { calculateActiveFilters } - function that calculates active filters
 */
export const calculateActiveFilters = <T extends CompatibleObject>(
  mapper: ObjectFilterCalculator<T>,
  values: Partial<T>
) => {
  return Object.entries(values).reduce<number>(
    (acc, [untyped_key, untyped_value]) => {
      const key = untyped_key as keyof T;
      const value = untyped_value as T[keyof T];
      const counter = mapper[key];

      // we using shorthand `property: false` to disable counting
      if (counter === false) {
        return acc;
      }

      // we are using custom counter function
      if (typeof counter === 'function') {
        const counterFn = counter as (val: T[keyof T]) => number;
        return acc + counterFn(value);
      }

      // use default counter
      return acc + countDefault(value);
    },
    0
  );
};

/**
 *
 * Example of usage
 *
 * const variables = {
 *   orderEntryType: ['ioi'],
 *   atsPool: 'ATS',
 *   only_active: true,
 * };
 *
 * const { calculateActiveFilters } = useActiveFilterCalculator({
 *   orderEntryType: val => (val === 'ioi' ? 1 : 0),
 *   atsPool: val => (val === 'ATS' ? 1 : 0),
 * });
 *
 * const activeFilters = calculateActiveFilters(variables);
 *
 */
