import React, { memo, useMemo } from 'react';
import { isMobile, isTablet } from 'react-device-detect';

import { Helmet } from '@npm/core/ui/components/atoms/Helmet';
import {
  type TopbarItem,
  Content,
  Layout,
  Navigation,
} from '@npm/core/ui/components/organisms/Layout';
import { HamburgerMenu } from '@npm/core/ui/components/organisms/Layout/HamburgerMenu';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';

import { useUserContextStore } from '../auth/user/context';
import { simpleLogoutItem } from '../auth/user/menu/menuItems';
import { UserPanel } from '../auth/user/menu/UserPanel/UserPanel';

type Props = {
  children: React.ReactNode;
};

export const BasicPageLayout = memo(function BasicPageLayout({
  children,
}: Props) {
  const { isMobile: isMobileBreakpoint, isTablet: isTableBreakpoint } =
    useBreakpoints();
  const isNewUser = useUserContextStore(store => store.isNewUser);

  const topBarConfig = useMemo(() => {
    if (isMobile || isTablet || isMobileBreakpoint || isTableBreakpoint) {
      return [
        {
          component: (
            <HamburgerMenu
              key="menu-mobile"
              mobileMenuType={isNewUser ? 'basic' : null}
            >
              <UserPanel />
              <Navigation menuItems={[simpleLogoutItem]} />
            </HamburgerMenu>
          ),
          position: 'right',
        },
      ] satisfies TopbarItem[];
    }

    return undefined;
  }, [isNewUser, isMobileBreakpoint, isTableBreakpoint]);

  return (
    <Layout topBarConfig={topBarConfig} noPadding={true}>
      <Content showPageHeader={false} noPadding={true}>
        {children}
        <Helmet pageTitle={null}></Helmet>
      </Content>
    </Layout>
  );
});
