/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Security } from '../model';
/**
 * S2SIssuerHoldingsApi - axios parameter creator
 * @export
 */
export const S2SIssuerHoldingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary retrieves a security
         * @param {string} issuerNpmGuid 
         * @param {string} securityNpmGuid 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xNPMUser] 
         * @param {string} [xIssuerNPMGUID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        s2SGetIssuerSecurity: async (issuerNpmGuid: string, securityNpmGuid: string, xNPMAuthorization?: string, xNPMUser?: string, xIssuerNPMGUID?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerNpmGuid' is not null or undefined
            assertParamExists('s2SGetIssuerSecurity', 'issuerNpmGuid', issuerNpmGuid)
            // verify required parameter 'securityNpmGuid' is not null or undefined
            assertParamExists('s2SGetIssuerSecurity', 'securityNpmGuid', securityNpmGuid)
            const localVarPath = changeUrl(`/api/s2s/issuers/{issuer_npm_guid}/securities/{security_npm_guid}`)
                .replace(`{${"issuer_npm_guid"}}`, encodeURIComponent(String(issuerNpmGuid)))
                .replace(`{${"security_npm_guid"}}`, encodeURIComponent(String(securityNpmGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xNPMUser !== undefined && xNPMUser !== null) {
                localVarHeaderParameter['X-NPM-User'] = String(xNPMUser);
            }

            if (xIssuerNPMGUID !== undefined && xIssuerNPMGUID !== null) {
                localVarHeaderParameter['X-Issuer-NPM-GUID'] = String(xIssuerNPMGUID);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * S2SIssuerHoldingsApi - functional programming interface
 * @export
 */
export const S2SIssuerHoldingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = S2SIssuerHoldingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary retrieves a security
         * @param {string} issuerNpmGuid 
         * @param {string} securityNpmGuid 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xNPMUser] 
         * @param {string} [xIssuerNPMGUID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async s2SGetIssuerSecurity(issuerNpmGuid: string, securityNpmGuid: string, xNPMAuthorization?: string, xNPMUser?: string, xIssuerNPMGUID?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Security>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.s2SGetIssuerSecurity(issuerNpmGuid, securityNpmGuid, xNPMAuthorization, xNPMUser, xIssuerNPMGUID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * S2SIssuerHoldingsApi - factory interface
 * @export
 */
export const S2SIssuerHoldingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = S2SIssuerHoldingsApiFp(configuration)
    return {
        /**
         * 
         * @summary retrieves a security
         * @param {string} issuerNpmGuid 
         * @param {string} securityNpmGuid 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xNPMUser] 
         * @param {string} [xIssuerNPMGUID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        s2SGetIssuerSecurity(issuerNpmGuid: string, securityNpmGuid: string, xNPMAuthorization?: string, xNPMUser?: string, xIssuerNPMGUID?: string, options?: any): AxiosPromise<Security> {
            return localVarFp.s2SGetIssuerSecurity(issuerNpmGuid, securityNpmGuid, xNPMAuthorization, xNPMUser, xIssuerNPMGUID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for s2SGetIssuerSecurity operation in S2SIssuerHoldingsApi.
 * @export
 * @interface S2SIssuerHoldingsApiS2SGetIssuerSecurityRequest
 */
export interface S2SIssuerHoldingsApiS2SGetIssuerSecurityRequest {
    /**
     * 
     * @type {string}
     * @memberof S2SIssuerHoldingsApiS2SGetIssuerSecurity
     */
    readonly issuerNpmGuid: string

    /**
     * 
     * @type {string}
     * @memberof S2SIssuerHoldingsApiS2SGetIssuerSecurity
     */
    readonly securityNpmGuid: string

    /**
     * 
     * @type {string}
     * @memberof S2SIssuerHoldingsApiS2SGetIssuerSecurity
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof S2SIssuerHoldingsApiS2SGetIssuerSecurity
     */
    readonly xNPMUser?: string

    /**
     * 
     * @type {string}
     * @memberof S2SIssuerHoldingsApiS2SGetIssuerSecurity
     */
    readonly xIssuerNPMGUID?: string
}

/**
 * S2SIssuerHoldingsApi - object-oriented interface
 * @export
 * @class S2SIssuerHoldingsApi
 * @extends {BaseAPI}
 */
export class S2SIssuerHoldingsApi extends BaseAPI {
    /**
     * 
     * @summary retrieves a security
     * @param {S2SIssuerHoldingsApiS2SGetIssuerSecurityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof S2SIssuerHoldingsApi
     */
    public s2SGetIssuerSecurity(requestParameters: S2SIssuerHoldingsApiS2SGetIssuerSecurityRequest, options?: AxiosRequestConfig) {
        return S2SIssuerHoldingsApiFp(this.configuration).s2SGetIssuerSecurity(requestParameters.issuerNpmGuid, requestParameters.securityNpmGuid, requestParameters.xNPMAuthorization, requestParameters.xNPMUser, requestParameters.xIssuerNPMGUID, options).then((request) => request(this.axios, this.basePath));
    }
}

export type S2SGetIssuerSecurityErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const S2SGetIssuerSecurityErrorCodes = [
    401,
    404,
];


