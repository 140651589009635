/** truncates a string and adds ellipsis
 or other separator in the middle */
export const truncate = (
  str: string,
  maxLength = 30,
  separator = '...',
  separatorPosition?: number
) => {
  if (!str || str.length <= maxLength) return str;
  const sepLength = separator.length,
    charsToShow = maxLength - sepLength,
    frontChars = Math.ceil(separatorPosition || charsToShow / 2),
    backChars = Math.floor(
      separatorPosition == null
        ? charsToShow / 2
        : charsToShow - separatorPosition
    );
  return (
    str.substring(0, frontChars) +
    separator +
    str.substring(str.length - backChars)
  );
};

/**
 * truncates a string before last space before the maxLength and adds ellipsis
 * if there is no space before the maxLength, it simply truncates the string to maxLength and adds ellipsis
 */
export const shorten = (str: string | undefined, maxLength = 32) => {
  if (!str || str.length <= maxLength) return str;

  const lastSpace = str.lastIndexOf(' ', maxLength);

  if (lastSpace === -1) return str.substring(0, maxLength) + '...';

  return str.substring(0, lastSpace) + '...';
};

/** truncates a string ending with a dot and file extension, adds ellipsis or other separator right before the extension,
 if no file extension is present in the string, it truncates the string with our regular `truncate` method */
export const truncateFilename = (
  filename: string,
  maxLength = 30,
  separator = '...'
) => {
  if (!filename || filename.length <= maxLength) return filename;
  const fileExtension = filename.split('.').pop();
  const fileExtensionIndex = filename.lastIndexOf('.' + fileExtension);
  if (fileExtensionIndex !== -1 && fileExtension != null) {
    const truncatedName = filename.substring(0, fileExtensionIndex);
    const truncatedLength = maxLength - separator.length - fileExtension.length;
    const truncatedFilename = truncatedName.substring(0, truncatedLength);
    return truncatedFilename + separator + fileExtension;
  } else {
    return truncate(filename, maxLength, separator);
  }
};
