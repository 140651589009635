import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { CbLabel } from '@npm/core/ui/components/atoms/Label';
import { type Codebook, CbAccreditationLevel } from '@npm/data-access';

import { AccreditationDataItemHeading } from '../../EntityAccreditation/ReadOnly/AccreditationDataItemHeading';

type Props = {
  level: Codebook;
  isFailed: boolean;
  headingVariant?: React.ComponentProps<
    typeof AccreditationDataItemHeading
  >['variant'];
};

export const AccreditedInvestorStatus = ({
  level,
  isFailed,
  headingVariant,
}: Props) => {
  return (
    <Flex justify="space-between" align="center">
      <AccreditationDataItemHeading
        title="Investor Status"
        variant={headingVariant}
      />
      {CbAccreditationLevel.getLabel(CbLabel, level, {
        name: isFailed ? 'warning' : 'check',
      })}
    </Flex>
  );
};
