import React, { useState } from 'react';

import { Form } from '@npm/core/ui/components/atoms/Form';
import { ModalKey, useQsModal } from '@npm/core/ui/components/molecules/Modal';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { useAccountIndex } from '@npm/data-access';
import { useUserContextStore } from '@npm/features/auth/user/context';

import { type OptInFormValues } from './OptInEntitySelection/OptInEntitySelection.types';
import { CancelOptInModal, OptInFooter } from './components';
import { OptInMode, OptInStep } from './OptIn.enums';
import { useNpmSettlementBrokerageFirmId } from './OptIn.hooks';
import { OptInEntitySelection } from './OptInEntitySelection';
import { OptInSignature } from './OptInSignature';

import * as S from './OptIn.styles';

export const OptIn = () => {
  const { isMobile, isSmTablet } = useBreakpoints();

  const { isOpen, handleClose: handleCloseConfirm } = useQsModal(
    ModalKey.OPT_IN_MODAL
  );

  const [optInStep, setOptInStep] = useState<OptInStep>(
    OptInStep.ACCOUNT_TYPE_SELECTION
  );
  const [optInMode, setOptInMode] = useState<OptInMode | undefined>(
    OptInMode.COPY_ACCOUNT
  );

  const [form] = Form.useForm<OptInFormValues>();

  const user = useUserContextStore(store => store.user);
  const npmSettlementBrokerageId = useNpmSettlementBrokerageFirmId();

  const { isLoading: isLoadingEntities, data: eligibleEntities } =
    useAccountIndex(
      {
        hasActiveEvents: false,
        brokerageFirmId: npmSettlementBrokerageId,
      },
      {
        queryConfig: {
          enabled: !!npmSettlementBrokerageId && isOpen,
        },
      }
    );

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);

  const handleCloseAttempt = () => {
    if (hasUnsavedChanges) {
      setIsDiscardModalOpen(true);
    } else {
      handleCloseConfirm();
    }
  };

  return (
    <>
      <S.StyledModal
        open={isOpen}
        closable
        destroyOnClose
        cancelText={OptInStep.SIGNATURE && 'Cancel'}
        onCancel={handleCloseAttempt}
        size={'xl'}
        title={
          optInStep === OptInStep.SIGNATURE && 'Opt-in with your existing info'
        }
        isFullScreen={isSmTablet || isMobile}
        footer={
          optInStep === OptInStep.ACCOUNT_TYPE_SELECTION ? null : (
            <OptInFooter
              optInMode={optInMode}
              setOptInStep={setOptInStep}
              form={form}
            />
          )
        }
      >
        <Form<OptInFormValues>
          form={form}
          initialValues={{
            legalName: '',
            selectedEntity: undefined,
          }}
          onValuesChange={() => setHasUnsavedChanges(true)}
        >
          <Form.Item
            name="accountType"
            noStyle
            hidden={optInStep !== OptInStep.ACCOUNT_TYPE_SELECTION}
          >
            <OptInEntitySelection
              eligibleEntities={eligibleEntities}
              setOptInStep={setOptInStep}
            />
          </Form.Item>

          {optInStep === OptInStep.SIGNATURE && (
            <OptInSignature
              eligibleEntities={eligibleEntities}
              form={form}
              isLoadingEntities={isLoadingEntities}
              optInMode={optInMode}
              setIsCancelling={setIsDiscardModalOpen}
              setOptinMode={setOptInMode}
              user={user}
            />
          )}
        </Form>
      </S.StyledModal>

      <CancelOptInModal
        open={isDiscardModalOpen}
        onConfirm={handleCloseConfirm}
        onClose={() => setIsDiscardModalOpen(false)}
        setOptInStep={setOptInStep}
      />
    </>
  );
};
