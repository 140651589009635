/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



  /**
 * 
 * @export
 * @interface AccountSimple
 */
export interface AccountSimple {
    /**
     * 
     * @type {string}
     * @memberof AccountSimple
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSimple
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSimple
     */
    'account_type'?: AccountSimpleAccountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AccountSimple
     */
    'sm_created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSimple
     */
    'sm_external_id'?: string;
}

export const AccountSimpleAccountTypeEnum = {
    PersonAccount: 'PersonAccount',
    OrganizationAccount: 'OrganizationAccount',
    AnonymousAccount: 'AnonymousAccount'
} as const;

export type AccountSimpleAccountTypeEnum = typeof AccountSimpleAccountTypeEnum[keyof typeof AccountSimpleAccountTypeEnum];


