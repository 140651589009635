import Big from 'big.js';

import { EMPTY_VALUE } from '@npm/core/ui/components/atoms/Typography';

export const calculateEstimatedNetPPS = (
  pps: string | number | undefined,
  executionFee: number | undefined,
  isBuy: boolean
): number | string => {
  if (!!pps && !!executionFee) {
    const result = new Big(pps)
      .div(100)
      .mul(new Big(100).add(isBuy ? executionFee : -1 * executionFee));

    if (result.lt(0)) {
      return '--';
    }

    return result.toNumber();
  }

  return EMPTY_VALUE;
};
