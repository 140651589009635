import { Codebooks } from './codebooks.types';

const actionToTitleMap: Record<keyof typeof Items, string> = {
  assign: 'Assign',
  block: 'Block',
  waive: 'Approve',
};

const Items = {
  assign: 'assign',
  block: 'block',
  waive: 'waive',
} as const;

export const CbRofrActionType = {
  code: Codebooks.ROFR_ACTION_TYPE,
  items: Items,
  titleMap: actionToTitleMap,
};
