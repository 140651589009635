import { useReducer, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';

import { useHistory } from '@npm/core/ui/components/molecules/Link';
import { Wizard } from '@npm/core/ui/components/organisms/Wizard';
import {
  type AccountOnboardingStatusShowErrorTypes,
  type AccountShowAggregate,
  type AccountShowErrorTypes,
  type Workstation,
  CbAccountType,
  CbWorkstationType,
  getApiErrorCode,
  useAccountOnboardingStatusShow,
  useAccountShow,
} from '@npm/data-access';

import { useCurrentWorkstation, useObo } from '../../auth/user/role';
import {
  type GetSidebarConfigFn,
  type PostOnboardingContext,
} from '../PostOnboarding.types';
import { getSteps } from '../PostOnboarding.utils';

import { useGetPostOnboardingPersonId } from './useGetPostOnboardingPersonId';
import { useInitialStepsSetup } from './useInitialStepsSetup';

const { createWizardContextReducer } = Wizard;

// include `user_id` in the OnboardingStatusShow request
// only in OBO mode or in BRO's individual account onboarding section (entity onboarding can include multiple reps)
const getUserIdForOnboardingStatus = (
  workstation: Workstation | null,
  oboUserId: number | null,
  account: AccountShowAggregate | undefined
) => {
  let userId = null;

  if (oboUserId) {
    userId = oboUserId;
  } else if (
    account?.type?.code === CbAccountType.items.PersonAccount &&
    workstation?.type?.code === CbWorkstationType.items.brokerage
  ) {
    userId = account.user?.id;
  }

  return userId;
};

export const usePostOnboardingContext = (
  accountId: number,
  getSidebarConfig: GetSidebarConfigFn
) => {
  const [isLoading, setIsLoading] = useState(true);
  const [context, updateContext] = useReducer(
    createWizardContextReducer<PostOnboardingContext>(),
    Wizard.INITIAL_CONTEXT_STATE
  );
  const history = useHistory();
  const handleError = useErrorHandler();
  const { oboUserId } = useObo();
  const workstation = useCurrentWorkstation();
  const getPersonId = useGetPostOnboardingPersonId();

  useInitialStepsSetup(context, updateContext);

  const onError = (
    err: AccountOnboardingStatusShowErrorTypes | AccountShowErrorTypes
  ) => {
    if (getApiErrorCode(err) === 404) {
      history.replace('/404');
    } else {
      handleError(err);
    }
  };

  const { data: account } = useAccountShow(
    {
      id: accountId?.toString(),
    },
    {
      onComplete: data => {
        updateContext({
          type: Wizard.ContextActionType.UPDATE_DATA,
          payload: {
            account: data,
          },
        });
      },
      onError,
      queryConfig: {
        enabled: !!accountId,
      },
    }
  );

  useAccountOnboardingStatusShow(
    {
      id: accountId,
      userId: getUserIdForOnboardingStatus(workstation, oboUserId, account),
    },
    {
      onComplete: async data => {
        updateContext({
          type: Wizard.ContextActionType.UPDATE_DATA,
          payload: {
            onboardingStatus: data,
          },
        });

        updateContext({
          type: Wizard.ContextActionType.UPDATE_STEPS,
          payload: getSteps({
            onboardingStatus: data,
            isIndividual:
              account?.type?.code === CbAccountType.items.PersonAccount,
            getSidebarConfig,
            personId: getPersonId(account),
          }),
        });

        setIsLoading(false);
      },
      onError,
      queryConfig: {
        enabled: !!account,
      },
    }
  );

  return [context, updateContext, { isLoading }] as const;
};
