import { useEffect } from 'react';
import {
  BooleanParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from 'use-query-params';

import { Wizard } from '@npm/core/ui/components/organisms/Wizard';

import { type PostOnboardingContext } from '../PostOnboarding.types';
import {
  findPostOnboardingNextIncompleteStep,
  getFirstStepInWarningState,
} from '../PostOnboarding.utils';

export const VIEW_WARNING_STATE_STEP_QUERY_PARAM = 'viewWarning';

export const useInitialStepsSetup = (
  context: Wizard.WizardContext<PostOnboardingContext>,
  updateContext: React.Dispatch<Wizard.ContextAction<PostOnboardingContext>>
) => {
  const { goToStep } = Wizard.useGoToStepInWizard({ context, updateContext });
  const [queryParams] = useQueryParams({
    step: StringParam,
    pageState: NumberParam,
    [VIEW_WARNING_STATE_STEP_QUERY_PARAM]: BooleanParam,
  });

  // find first incomplete step on initial post-onboarding load (when no pageState query param is set yet)
  useEffect(() => {
    if (
      !context.steps ||
      !context.data?.onboardingStatus ||
      !context.data?.account
    ) {
      return;
    }

    if (queryParams[VIEW_WARNING_STATE_STEP_QUERY_PARAM]) {
      goToStep(
        getFirstStepInWarningState(context.data?.account),
        undefined,
        'replaceIn'
      );
      return;
    }

    if (!queryParams.pageState) {
      const firstIncompleteStepIndex = findPostOnboardingNextIncompleteStep(
        context,
        { startingIndex: -1 }
      );

      goToStep(firstIncompleteStepIndex, undefined, 'replaceIn');
    }
  }, [context.steps, context.data?.onboardingStatus, context.data?.account]);
};
