import { FormItem } from '@npm/core/ui/components/atoms/FormItem';
import { Select } from '@npm/core/ui/components/atoms/Select';
import { CollapsibleFormSection } from '@npm/core/ui/components/molecules/CollapsibleFormSection';

import { getAssetTypePreview } from '../../../../order';

type Props = {
  value?: string;
  options: {
    label: string;
    value: string;
  }[];
  onItemChange: () => void;
  key: string;
} & Partial<React.ComponentProps<typeof CollapsibleFormSection.Item>>;

export const AssetTypeFormSection = ({
  value,
  options,
  onItemChange,
  ...props
}: Props) => {
  return (
    <CollapsibleFormSection.Item
      label="Asset Type"
      preview={getAssetTypePreview(options, value)}
      name={props.key}
      {...props}
    >
      <FormItem name="assetType" initialValue={''} marginBottom="sm">
        <Select options={options} onSelect={onItemChange} />
      </FormItem>
    </CollapsibleFormSection.Item>
  );
};
