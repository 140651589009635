import React from 'react';
import { type LinkProps } from 'react-router-dom';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import { useHistory } from '@npm/core/ui/components/molecules/Link';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import {
  CbEventStatus,
  useEventList,
  useWatchlistIndex,
} from '@npm/data-access';

import { useUserContextStore } from '../../auth/user/context';
import { useCurrentRole } from '../../auth/user/role/hooks/useCurrentRole';
import { getInvestorWorkstationLandingPage } from '../../workstations/workstationLandingPage.utils';

import { IconBox } from './IconBox';

type Props = {
  modalProps: React.ComponentProps<typeof Modal>;
  accountId?: number;
};

const getFooterButtonsProps = ({
  handleRedirect,
  isEntityNpmsInvestor,
  hasLivePrograms,
  watchlistCompanyId,
  isLoading,
  isMobile,
  landingPageUrl,
}: {
  landingPageUrl: ReturnType<typeof getInvestorWorkstationLandingPage>;
  handleRedirect: (to: LinkProps['to']) => void;
  isEntityNpmsInvestor: boolean;
  hasLivePrograms: boolean;
  watchlistCompanyId: number | undefined;
  isLoading: boolean;
  isMobile: boolean;
}): Partial<Props['modalProps']> => {
  if (isLoading) {
    return {
      footer: (
        <Skeleton.Button
          block={isMobile}
          style={{ width: isMobile ? '100%' : '160px' }}
        />
      ),
    };
  }

  const okButtonProps = {
    icon: <Icon name="external-link" size="xs" />,
  };

  const cancelButtonProps = {
    cancelText: 'Buying? Join Waitlist',
    onCancel: () => handleRedirect(landingPageUrl),
  };

  if (isEntityNpmsInvestor) {
    return {
      okText: 'Go to Dashboard',
      onOk: () => handleRedirect('/investor-workstation/entity-dashboard'),
      okButtonProps,
    };
  }

  if (hasLivePrograms) {
    return {
      okText: 'Continue to Live Programs',
      onOk: () =>
        handleRedirect('/investor-workstation/programs/live-programs'),
      okButtonProps,
      ...cancelButtonProps,
    };
  }

  return {
    okText: '+ Add Holdings',
    onOk: () =>
      handleRedirect(
        // They will often have just one company, but even if they have more than one, we still redirect to the first company
        watchlistCompanyId
          ? `/second-market/investor/sell-indication?companyId=${watchlistCompanyId}`
          : '/investor-workstation/account/holdings'
      ),
    ...cancelButtonProps,
  };
};

export const OnboardingCompletedModal = ({ accountId, modalProps }: Props) => {
  const { isMobile } = useBreakpoints();
  const history = useHistory();
  const isEntityNpmsInvestor = useUserContextStore(s => s.isEntityNpmsInvestor);

  const { data, isFetching } = useEventList(
    { accountId, eventStatus: CbEventStatus.items.ACTIVE, size: 0 },
    {
      queryConfig: {
        enabled: !!accountId && modalProps.open && !isEntityNpmsInvestor,
      },
    }
  );

  const hasLivePrograms = data?.pagination?.total_records > 0;

  const { data: watchlistData } = useWatchlistIndex({
    accountId,
    size: 1,
  });

  const watchlistCompanyId = watchlistData?.watchlists?.[0]?.issuer_entity_id;

  const handleRedirect = (to: LinkProps['to']) => {
    history.push(to);
  };

  const role = useCurrentRole();
  const context = useUserContextStore();
  const landingPageUrl = getInvestorWorkstationLandingPage(context, role);

  return (
    <Modal
      {...modalProps}
      {...getFooterButtonsProps({
        handleRedirect,
        isEntityNpmsInvestor,
        hasLivePrograms,
        isLoading: isFetching,
        isMobile,
        landingPageUrl,
        watchlistCompanyId,
      })}
    >
      <Flex>
        <IconBox />
        <div>
          <Heading variant="h2" marginBottom="sm">
            Congratulations!
          </Heading>
          <Text size="sm">
            You have successfully completed all Onboarding steps.{' '}
            {isEntityNpmsInvestor
              ? 'Click on "Go to Dashboard" to import your portfolio and/or add companies to your watchlist.'
              : hasLivePrograms
              ? 'Click on "Continue to Live Programs" to participate in an event.'
              : ''}
          </Text>
        </div>
      </Flex>
    </Modal>
  );
};
