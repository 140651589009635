import React, { type ComponentProps, type FC } from 'react';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Margin } from '@npm/core/ui/components/atoms/common';
import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { Wizard } from '@npm/core/ui/components/organisms/WizardNew';
import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import { type WizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';
import { CypressDataIds } from '@npm/core/ui/constants';

import {
  FormationDocumentForm,
  FormationDocumentReadOnly,
  useFormationDocumentForm,
} from '../../../onboardingAndSubmissionSteps/FormationDocument';
import { type PostOnboardingContextType } from '../../PostOnboarding.types';
import { StepContainer } from '../../StepContainer';
import { type WarningStateAlert } from '../../WarningStateAlert';

const { StepState } = Wizard;

type Props = {
  stepProps: Wizard.StepProps;
} & Pick<ComponentProps<typeof WarningStateAlert>, 'zendeskTicketGroupId'>;

export const FormationDocumentPostOnboarding: FC<Props> = ({
  stepProps,
  zendeskTicketGroupId,
}) => {
  const { stepState, account } = useWizardStore(
    (s: WizardStore<PostOnboardingContextType>) => ({
      stepState: s.activeStepState,
      account: s.data.account,
    })
  );

  const { form, handleSubmit, data, isLoading, isUpdating } =
    useFormationDocumentForm(account, {
      onUpdate: () => {
        stepProps.handleStepComplete();
        Notification.success({
          message: 'You have updated your Account Formation Document',
        });
      },
    });

  return (
    <StepContainer
      stepProps={stepProps}
      isLoading={isLoading}
      isUpdating={isUpdating}
      handleSubmit={handleSubmit}
      zendeskTicketGroupId={zendeskTicketGroupId}
      data-cy={CypressDataIds.PostOnboarding.FormationDocument.Step}
    >
      {stepState === StepState.EDIT && (
        <>
          <Margin bottom="lg">
            <Alert
              message={`We will use this document to verify the identity of ${account?.name}`}
            />
          </Margin>
          <FormationDocumentForm
            form={form}
            accountName={account?.name}
            headingsVariant="h2"
            handleValuesChange={() => stepProps.setFormHasUnsavedChanges(true)}
          />
        </>
      )}
      {stepState === StepState.FILLED && data?.constituent_document && (
        <FormationDocumentReadOnly data={data} accountName={account?.name} />
      )}
    </StepContainer>
  );
};
