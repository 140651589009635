import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { VenusApi } from '@npm/data-access';

import { type SectionProps } from '../CompanyOverview.types';
import { NotCoveredBanner } from '../components/NotCoveredBanner';

import { CapTableBanner, CapTableBannerErrorSkeleton } from './CapTableBanner';
import { CapTableStats } from './CapTableStats';
import { CapTable as Table } from './Table';

type Props = SectionProps & {
  companyProfile: VenusApi.CompanyProfile | undefined;
  showBanner?: boolean;
};

export const CapTable = ({
  issuerEntity,
  companyProfile,
  showBanner = false,
}: Props) => {
  const { isMobile } = useBreakpoints();

  const { data, isFetching } = VenusApi.useCompanyStockClasses(
    {
      page: 1,
      size: 1,
      companyId: issuerEntity?.venus_id?.toString(),
    },
    {
      queryConfig: { enabled: issuerEntity?.venus_id != null },
    }
  );

  if (
    companyProfile &&
    (!companyProfile.covered || (!isFetching && !data?.stock_classes?.length))
  ) {
    return <NotCoveredBanner issuerEntity={issuerEntity} />;
  }

  return (
    <Flex direction="column" gap={isMobile ? 'sm' : 'md'}>
      <CapTableStats
        venusCompanyId={issuerEntity?.venus_id}
        companyName={issuerEntity?.name}
      />
      {showBanner && issuerEntity?.venus_id != null && (
        <ErrorBoundary FallbackComponent={CapTableBannerErrorSkeleton}>
          <CapTableBanner
            venusCompanyId={issuerEntity?.venus_id}
            companyName={issuerEntity?.name}
          />
        </ErrorBoundary>
      )}
      <Table
        companyName={issuerEntity?.name}
        venusCompanyId={issuerEntity?.venus_id}
        denomination={issuerEntity?.denomination}
      />
    </Flex>
  );
};
