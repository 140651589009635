/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminPartnerPartnerAccountIndex } from '../model';
/**
 * AdminPartnerAccountApi - axios parameter creator
 * @export
 */
export const AdminPartnerAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary lists all partner accounts
         * @param {string} [userEmail] 
         * @param {string} [partnerName] 
         * @param {string} [accountNpmGuid] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPartnerAccountIndex: async (userEmail?: string, partnerName?: string, accountNpmGuid?: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/admin/partner/partner_accounts`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userEmail !== undefined) {
                localVarQueryParameter['user_email'] = userEmail;
            }

            if (partnerName !== undefined) {
                localVarQueryParameter['partner_name'] = partnerName;
            }

            if (accountNpmGuid !== undefined) {
                localVarQueryParameter['account_npm_guid'] = accountNpmGuid;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPartnerAccountApi - functional programming interface
 * @export
 */
export const AdminPartnerAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPartnerAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary lists all partner accounts
         * @param {string} [userEmail] 
         * @param {string} [partnerName] 
         * @param {string} [accountNpmGuid] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPartnerAccountIndex(userEmail?: string, partnerName?: string, accountNpmGuid?: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPartnerPartnerAccountIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPartnerAccountIndex(userEmail, partnerName, accountNpmGuid, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPartnerAccountApi - factory interface
 * @export
 */
export const AdminPartnerAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPartnerAccountApiFp(configuration)
    return {
        /**
         * 
         * @summary lists all partner accounts
         * @param {string} [userEmail] 
         * @param {string} [partnerName] 
         * @param {string} [accountNpmGuid] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPartnerAccountIndex(userEmail?: string, partnerName?: string, accountNpmGuid?: string, page?: number, size?: number, options?: any): AxiosPromise<AdminPartnerPartnerAccountIndex> {
            return localVarFp.adminPartnerAccountIndex(userEmail, partnerName, accountNpmGuid, page, size, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminPartnerAccountIndex operation in AdminPartnerAccountApi.
 * @export
 * @interface AdminPartnerAccountApiAdminPartnerAccountIndexRequest
 */
export interface AdminPartnerAccountApiAdminPartnerAccountIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminPartnerAccountApiAdminPartnerAccountIndex
     */
    readonly userEmail?: string

    /**
     * 
     * @type {string}
     * @memberof AdminPartnerAccountApiAdminPartnerAccountIndex
     */
    readonly partnerName?: string

    /**
     * 
     * @type {string}
     * @memberof AdminPartnerAccountApiAdminPartnerAccountIndex
     */
    readonly accountNpmGuid?: string

    /**
     * 
     * @type {number}
     * @memberof AdminPartnerAccountApiAdminPartnerAccountIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminPartnerAccountApiAdminPartnerAccountIndex
     */
    readonly size?: number
}

/**
 * AdminPartnerAccountApi - object-oriented interface
 * @export
 * @class AdminPartnerAccountApi
 * @extends {BaseAPI}
 */
export class AdminPartnerAccountApi extends BaseAPI {
    /**
     * 
     * @summary lists all partner accounts
     * @param {AdminPartnerAccountApiAdminPartnerAccountIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPartnerAccountApi
     */
    public adminPartnerAccountIndex(requestParameters: AdminPartnerAccountApiAdminPartnerAccountIndexRequest = {}, options?: AxiosRequestConfig) {
        return AdminPartnerAccountApiFp(this.configuration).adminPartnerAccountIndex(requestParameters.userEmail, requestParameters.partnerName, requestParameters.accountNpmGuid, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }
}

export type AdminPartnerAccountIndexErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AdminPartnerAccountIndexErrorCodes = [
];


