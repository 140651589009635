import { useEffect, useState } from 'react';

import * as S from './IndividualOnbordingAlert.styles';

const INDIVIDUAL_ONBOARDING_ALERRT_HIDDEN_STORAGE_KEY =
  'individual-onboarding-alert';

export const IndividualOnboardingAlert = () => {
  const [isHidden, setIsHidden] = useState(true);

  useEffect(() => {
    setIsHidden(
      Boolean(
        window.localStorage.getItem(
          INDIVIDUAL_ONBOARDING_ALERRT_HIDDEN_STORAGE_KEY
        )
      )
    );
  }, []);

  const onClose = () => {
    window.localStorage.setItem(
      INDIVIDUAL_ONBOARDING_ALERRT_HIDDEN_STORAGE_KEY,
      'hidden'
    );
    setIsHidden(true);
  };

  if (isHidden) return null;

  return (
    <S.StyledAlert
      onClose={onClose}
      closable
      expandable={false}
      type="info"
      message="Onboarding + Holdings are required to sell in the marketplace."
      description="Are you a buyer? Complete Onboarding and join the waitlist for access."
    />
  );
};
