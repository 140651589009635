import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';

import { usePersonaContext } from '../PersonaContext';

type Props = {
  text: string;
  buttonVariant?: 'default' | 'outline';
  isCompleted?: boolean;
  withAlert?: boolean;
};

export const PersonaVerificationButton = ({
  text,
  buttonVariant,
  isCompleted,
  withAlert,
}: Props) => {
  const { openModal, isPolling } = usePersonaContext();

  if (isCompleted) {
    return (
      <Alert
        centered
        type="success"
        icon={<Icon name="circle-check" />}
        message="We confirmed your Identity"
      />
    );
  }

  const button = (
    <Button
      variant={buttonVariant || 'default'}
      onClick={openModal}
      icon={<Icon name="shield" />}
      loading={isPolling}
    >
      {text}
    </Button>
  );

  return withAlert ? (
    <Flex direction="column" align="stretch" gap="sm">
      {button}
      <Alert
        type="warning"
        icon={<Icon name="warning" />}
        message="Few steps remaining to complete verification"
      />
    </Flex>
  ) : (
    button
  );
};
