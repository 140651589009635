/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  SecuritiesApi,
  AccountSecurityIndexErrorCodes,
  AccountSecurityIndexErrorTypes,
  NewSecuritiesByDateErrorCodes,
  NewSecuritiesByDateErrorTypes,
  NewSecuritiesBySectorErrorCodes,
  NewSecuritiesBySectorErrorTypes,
  NewSecuritiesByIndustryErrorCodes,
  NewSecuritiesByIndustryErrorTypes,
  FindDuplicateSecurityIdsErrorCodes,
  FindDuplicateSecurityIdsErrorTypes,
  AdminPortfolioStatsErrorCodes,
  AdminPortfolioStatsErrorTypes,
  SecurityAdminListErrorCodes,
  SecurityAdminListErrorTypes,
  SecurityAdminShowErrorCodes,
  SecurityAdminShowErrorTypes,
  PortfolioStatsErrorCodes,
  PortfolioStatsErrorTypes,
  SecurityIndexAllErrorCodes,
  SecurityIndexAllErrorTypes,
  SecurityInvestorShowErrorCodes,
  SecurityInvestorShowErrorTypes,
  SecurityShowErrorCodes,
  SecurityShowErrorTypes,
  SecurityDestroyErrorCodes,
  SecurityUpdateErrorCodes,
  SecurityIndexErrorCodes,
  SecurityIndexErrorTypes,
  SecurityCreateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new SecuritiesApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type AccountSecurityIndexMethod = SecuritiesApi['accountSecurityIndex'];
export type AccountSecurityIndexResponseType = MethodResult<AccountSecurityIndexMethod>;

export const useAccountSecurityIndex = (
  variables: Parameters<AccountSecurityIndexMethod>[0],
  config?: UseQueryConfig<
    AccountSecurityIndexMethod,
    AccountSecurityIndexErrorTypes
  >
) => {
  return useQuery<
    AccountSecurityIndexMethod,
    AccountSecurityIndexErrorTypes
  >(
    (...args) => api.accountSecurityIndex(...args),
    AccountSecurityIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountSecurityIndex'],
        ...apiQueryConfig.AccountSecurityIndex,
        ...config?.queryConfig
      },
    },
    'AccountSecurityIndex',
  );
};

export const useAccountSecurityIndexInfinite = (
  variables: Parameters<AccountSecurityIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    AccountSecurityIndexMethod,
    AccountSecurityIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    AccountSecurityIndexMethod,
    AccountSecurityIndexErrorTypes
  >(
    (...args) => api.accountSecurityIndex(...args),
    AccountSecurityIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountSecurityIndex', 'AccountSecurityIndexInfinite'],
        ...apiQueryConfig.AccountSecurityIndex,
        ...config?.queryConfig,
      },
    },
    'AccountSecurityIndex',
  );
};

export const useAccountSecurityIndexLazy = (baseOptions?: {
  variables?: Parameters<AccountSecurityIndexMethod>[0],
  config?: UseQueryConfig<
    AccountSecurityIndexMethod,
    AccountSecurityIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    AccountSecurityIndexMethod,
    AccountSecurityIndexErrorTypes
  >(
    (...args) => api.accountSecurityIndex(...args),
    AccountSecurityIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AccountSecurityIndex'],
        ...apiQueryConfig.AccountSecurityIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AccountSecurityIndex',
  );
};


export type NewSecuritiesByDateMethod = SecuritiesApi['newSecuritiesByDate'];
export type NewSecuritiesByDateResponseType = MethodResult<NewSecuritiesByDateMethod>;

export const useNewSecuritiesByDate = (
  variables: Parameters<NewSecuritiesByDateMethod>[0],
  config?: UseQueryConfig<
    NewSecuritiesByDateMethod,
    NewSecuritiesByDateErrorTypes
  >
) => {
  return useQuery<
    NewSecuritiesByDateMethod,
    NewSecuritiesByDateErrorTypes
  >(
    (...args) => api.newSecuritiesByDate(...args),
    NewSecuritiesByDateErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['NewSecuritiesByDate'],
        ...apiQueryConfig.NewSecuritiesByDate,
        ...config?.queryConfig
      },
    },
    'NewSecuritiesByDate',
  );
};

export const useNewSecuritiesByDateInfinite = (
  variables: Parameters<NewSecuritiesByDateMethod>[0],
  config?: UseInfiniteQueryConfig<
    NewSecuritiesByDateMethod,
    NewSecuritiesByDateErrorTypes
  >
) => {
  return useInfiniteQuery<
    NewSecuritiesByDateMethod,
    NewSecuritiesByDateErrorTypes
  >(
    (...args) => api.newSecuritiesByDate(...args),
    NewSecuritiesByDateErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['NewSecuritiesByDate', 'NewSecuritiesByDateInfinite'],
        ...apiQueryConfig.NewSecuritiesByDate,
        ...config?.queryConfig,
      },
    },
    'NewSecuritiesByDate',
  );
};

export const useNewSecuritiesByDateLazy = (baseOptions?: {
  variables?: Parameters<NewSecuritiesByDateMethod>[0],
  config?: UseQueryConfig<
    NewSecuritiesByDateMethod,
    NewSecuritiesByDateErrorTypes
  >
}) => {
  return useLazyQuery<
    NewSecuritiesByDateMethod,
    NewSecuritiesByDateErrorTypes
  >(
    (...args) => api.newSecuritiesByDate(...args),
    NewSecuritiesByDateErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['NewSecuritiesByDate'],
        ...apiQueryConfig.NewSecuritiesByDate,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'NewSecuritiesByDate',
  );
};


export type NewSecuritiesBySectorMethod = SecuritiesApi['newSecuritiesBySector'];
export type NewSecuritiesBySectorResponseType = MethodResult<NewSecuritiesBySectorMethod>;

export const useNewSecuritiesBySector = (
  variables: Parameters<NewSecuritiesBySectorMethod>[0],
  config?: UseQueryConfig<
    NewSecuritiesBySectorMethod,
    NewSecuritiesBySectorErrorTypes
  >
) => {
  return useQuery<
    NewSecuritiesBySectorMethod,
    NewSecuritiesBySectorErrorTypes
  >(
    (...args) => api.newSecuritiesBySector(...args),
    NewSecuritiesBySectorErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['NewSecuritiesBySector'],
        ...apiQueryConfig.NewSecuritiesBySector,
        ...config?.queryConfig
      },
    },
    'NewSecuritiesBySector',
  );
};

export const useNewSecuritiesBySectorInfinite = (
  variables: Parameters<NewSecuritiesBySectorMethod>[0],
  config?: UseInfiniteQueryConfig<
    NewSecuritiesBySectorMethod,
    NewSecuritiesBySectorErrorTypes
  >
) => {
  return useInfiniteQuery<
    NewSecuritiesBySectorMethod,
    NewSecuritiesBySectorErrorTypes
  >(
    (...args) => api.newSecuritiesBySector(...args),
    NewSecuritiesBySectorErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['NewSecuritiesBySector', 'NewSecuritiesBySectorInfinite'],
        ...apiQueryConfig.NewSecuritiesBySector,
        ...config?.queryConfig,
      },
    },
    'NewSecuritiesBySector',
  );
};

export const useNewSecuritiesBySectorLazy = (baseOptions?: {
  variables?: Parameters<NewSecuritiesBySectorMethod>[0],
  config?: UseQueryConfig<
    NewSecuritiesBySectorMethod,
    NewSecuritiesBySectorErrorTypes
  >
}) => {
  return useLazyQuery<
    NewSecuritiesBySectorMethod,
    NewSecuritiesBySectorErrorTypes
  >(
    (...args) => api.newSecuritiesBySector(...args),
    NewSecuritiesBySectorErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['NewSecuritiesBySector'],
        ...apiQueryConfig.NewSecuritiesBySector,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'NewSecuritiesBySector',
  );
};


export type NewSecuritiesByIndustryMethod = SecuritiesApi['newSecuritiesByIndustry'];
export type NewSecuritiesByIndustryResponseType = MethodResult<NewSecuritiesByIndustryMethod>;

export const useNewSecuritiesByIndustry = (
  variables: Parameters<NewSecuritiesByIndustryMethod>[0],
  config?: UseQueryConfig<
    NewSecuritiesByIndustryMethod,
    NewSecuritiesByIndustryErrorTypes
  >
) => {
  return useQuery<
    NewSecuritiesByIndustryMethod,
    NewSecuritiesByIndustryErrorTypes
  >(
    (...args) => api.newSecuritiesByIndustry(...args),
    NewSecuritiesByIndustryErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['NewSecuritiesByIndustry'],
        ...apiQueryConfig.NewSecuritiesByIndustry,
        ...config?.queryConfig
      },
    },
    'NewSecuritiesByIndustry',
  );
};

export const useNewSecuritiesByIndustryInfinite = (
  variables: Parameters<NewSecuritiesByIndustryMethod>[0],
  config?: UseInfiniteQueryConfig<
    NewSecuritiesByIndustryMethod,
    NewSecuritiesByIndustryErrorTypes
  >
) => {
  return useInfiniteQuery<
    NewSecuritiesByIndustryMethod,
    NewSecuritiesByIndustryErrorTypes
  >(
    (...args) => api.newSecuritiesByIndustry(...args),
    NewSecuritiesByIndustryErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['NewSecuritiesByIndustry', 'NewSecuritiesByIndustryInfinite'],
        ...apiQueryConfig.NewSecuritiesByIndustry,
        ...config?.queryConfig,
      },
    },
    'NewSecuritiesByIndustry',
  );
};

export const useNewSecuritiesByIndustryLazy = (baseOptions?: {
  variables?: Parameters<NewSecuritiesByIndustryMethod>[0],
  config?: UseQueryConfig<
    NewSecuritiesByIndustryMethod,
    NewSecuritiesByIndustryErrorTypes
  >
}) => {
  return useLazyQuery<
    NewSecuritiesByIndustryMethod,
    NewSecuritiesByIndustryErrorTypes
  >(
    (...args) => api.newSecuritiesByIndustry(...args),
    NewSecuritiesByIndustryErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['NewSecuritiesByIndustry'],
        ...apiQueryConfig.NewSecuritiesByIndustry,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'NewSecuritiesByIndustry',
  );
};


export type FindDuplicateSecurityIdsMethod = SecuritiesApi['findDuplicateSecurityIds'];
export type FindDuplicateSecurityIdsResponseType = MethodResult<FindDuplicateSecurityIdsMethod>;

export const useFindDuplicateSecurityIds = (
  variables: Parameters<FindDuplicateSecurityIdsMethod>[0],
  config?: UseQueryConfig<
    FindDuplicateSecurityIdsMethod,
    FindDuplicateSecurityIdsErrorTypes
  >
) => {
  return useQuery<
    FindDuplicateSecurityIdsMethod,
    FindDuplicateSecurityIdsErrorTypes
  >(
    (...args) => api.findDuplicateSecurityIds(...args),
    FindDuplicateSecurityIdsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['FindDuplicateSecurityIds'],
        ...apiQueryConfig.FindDuplicateSecurityIds,
        ...config?.queryConfig
      },
    },
    'FindDuplicateSecurityIds',
  );
};

export const useFindDuplicateSecurityIdsInfinite = (
  variables: Parameters<FindDuplicateSecurityIdsMethod>[0],
  config?: UseInfiniteQueryConfig<
    FindDuplicateSecurityIdsMethod,
    FindDuplicateSecurityIdsErrorTypes
  >
) => {
  return useInfiniteQuery<
    FindDuplicateSecurityIdsMethod,
    FindDuplicateSecurityIdsErrorTypes
  >(
    (...args) => api.findDuplicateSecurityIds(...args),
    FindDuplicateSecurityIdsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['FindDuplicateSecurityIds', 'FindDuplicateSecurityIdsInfinite'],
        ...apiQueryConfig.FindDuplicateSecurityIds,
        ...config?.queryConfig,
      },
    },
    'FindDuplicateSecurityIds',
  );
};

export const useFindDuplicateSecurityIdsLazy = (baseOptions?: {
  variables?: Parameters<FindDuplicateSecurityIdsMethod>[0],
  config?: UseQueryConfig<
    FindDuplicateSecurityIdsMethod,
    FindDuplicateSecurityIdsErrorTypes
  >
}) => {
  return useLazyQuery<
    FindDuplicateSecurityIdsMethod,
    FindDuplicateSecurityIdsErrorTypes
  >(
    (...args) => api.findDuplicateSecurityIds(...args),
    FindDuplicateSecurityIdsErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['FindDuplicateSecurityIds'],
        ...apiQueryConfig.FindDuplicateSecurityIds,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'FindDuplicateSecurityIds',
  );
};


export type AdminPortfolioStatsMethod = SecuritiesApi['adminPortfolioStats'];
export type AdminPortfolioStatsResponseType = MethodResult<AdminPortfolioStatsMethod>;

export const useAdminPortfolioStats = (
  variables: Parameters<AdminPortfolioStatsMethod>[0],
  config?: UseQueryConfig<
    AdminPortfolioStatsMethod,
    AdminPortfolioStatsErrorTypes
  >
) => {
  return useQuery<
    AdminPortfolioStatsMethod,
    AdminPortfolioStatsErrorTypes
  >(
    (...args) => api.adminPortfolioStats(...args),
    AdminPortfolioStatsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AdminPortfolioStats'],
        ...apiQueryConfig.AdminPortfolioStats,
        ...config?.queryConfig
      },
    },
    'AdminPortfolioStats',
  );
};

export const useAdminPortfolioStatsInfinite = (
  variables: Parameters<AdminPortfolioStatsMethod>[0],
  config?: UseInfiniteQueryConfig<
    AdminPortfolioStatsMethod,
    AdminPortfolioStatsErrorTypes
  >
) => {
  return useInfiniteQuery<
    AdminPortfolioStatsMethod,
    AdminPortfolioStatsErrorTypes
  >(
    (...args) => api.adminPortfolioStats(...args),
    AdminPortfolioStatsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AdminPortfolioStats', 'AdminPortfolioStatsInfinite'],
        ...apiQueryConfig.AdminPortfolioStats,
        ...config?.queryConfig,
      },
    },
    'AdminPortfolioStats',
  );
};

export const useAdminPortfolioStatsLazy = (baseOptions?: {
  variables?: Parameters<AdminPortfolioStatsMethod>[0],
  config?: UseQueryConfig<
    AdminPortfolioStatsMethod,
    AdminPortfolioStatsErrorTypes
  >
}) => {
  return useLazyQuery<
    AdminPortfolioStatsMethod,
    AdminPortfolioStatsErrorTypes
  >(
    (...args) => api.adminPortfolioStats(...args),
    AdminPortfolioStatsErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AdminPortfolioStats'],
        ...apiQueryConfig.AdminPortfolioStats,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AdminPortfolioStats',
  );
};


export type SecurityAdminListMethod = SecuritiesApi['securityAdminList'];
export type SecurityAdminListResponseType = MethodResult<SecurityAdminListMethod>;

export const useSecurityAdminList = (
  variables: Parameters<SecurityAdminListMethod>[0],
  config?: UseQueryConfig<
    SecurityAdminListMethod,
    SecurityAdminListErrorTypes
  >
) => {
  return useQuery<
    SecurityAdminListMethod,
    SecurityAdminListErrorTypes
  >(
    (...args) => api.securityAdminList(...args),
    SecurityAdminListErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SecurityAdminList'],
        ...apiQueryConfig.SecurityAdminList,
        ...config?.queryConfig
      },
    },
    'SecurityAdminList',
  );
};

export const useSecurityAdminListInfinite = (
  variables: Parameters<SecurityAdminListMethod>[0],
  config?: UseInfiniteQueryConfig<
    SecurityAdminListMethod,
    SecurityAdminListErrorTypes
  >
) => {
  return useInfiniteQuery<
    SecurityAdminListMethod,
    SecurityAdminListErrorTypes
  >(
    (...args) => api.securityAdminList(...args),
    SecurityAdminListErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SecurityAdminList', 'SecurityAdminListInfinite'],
        ...apiQueryConfig.SecurityAdminList,
        ...config?.queryConfig,
      },
    },
    'SecurityAdminList',
  );
};

export const useSecurityAdminListLazy = (baseOptions?: {
  variables?: Parameters<SecurityAdminListMethod>[0],
  config?: UseQueryConfig<
    SecurityAdminListMethod,
    SecurityAdminListErrorTypes
  >
}) => {
  return useLazyQuery<
    SecurityAdminListMethod,
    SecurityAdminListErrorTypes
  >(
    (...args) => api.securityAdminList(...args),
    SecurityAdminListErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['SecurityAdminList'],
        ...apiQueryConfig.SecurityAdminList,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'SecurityAdminList',
  );
};


export type SecurityAdminShowMethod = SecuritiesApi['securityAdminShow'];
export type SecurityAdminShowResponseType = MethodResult<SecurityAdminShowMethod>;

export const useSecurityAdminShow = (
  variables: Parameters<SecurityAdminShowMethod>[0],
  config?: UseQueryConfig<
    SecurityAdminShowMethod,
    SecurityAdminShowErrorTypes
  >
) => {
  return useQuery<
    SecurityAdminShowMethod,
    SecurityAdminShowErrorTypes
  >(
    (...args) => api.securityAdminShow(...args),
    SecurityAdminShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SecurityAdminShow'],
        ...apiQueryConfig.SecurityAdminShow,
        ...config?.queryConfig
      },
    },
    'SecurityAdminShow',
  );
};

export const useSecurityAdminShowInfinite = (
  variables: Parameters<SecurityAdminShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    SecurityAdminShowMethod,
    SecurityAdminShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    SecurityAdminShowMethod,
    SecurityAdminShowErrorTypes
  >(
    (...args) => api.securityAdminShow(...args),
    SecurityAdminShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SecurityAdminShow', 'SecurityAdminShowInfinite'],
        ...apiQueryConfig.SecurityAdminShow,
        ...config?.queryConfig,
      },
    },
    'SecurityAdminShow',
  );
};

export const useSecurityAdminShowLazy = (baseOptions?: {
  variables?: Parameters<SecurityAdminShowMethod>[0],
  config?: UseQueryConfig<
    SecurityAdminShowMethod,
    SecurityAdminShowErrorTypes
  >
}) => {
  return useLazyQuery<
    SecurityAdminShowMethod,
    SecurityAdminShowErrorTypes
  >(
    (...args) => api.securityAdminShow(...args),
    SecurityAdminShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['SecurityAdminShow'],
        ...apiQueryConfig.SecurityAdminShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'SecurityAdminShow',
  );
};


export type PortfolioStatsMethod = SecuritiesApi['portfolioStats'];
export type PortfolioStatsResponseType = MethodResult<PortfolioStatsMethod>;

export const usePortfolioStats = (
  variables: Parameters<PortfolioStatsMethod>[0],
  config?: UseQueryConfig<
    PortfolioStatsMethod,
    PortfolioStatsErrorTypes
  >
) => {
  return useQuery<
    PortfolioStatsMethod,
    PortfolioStatsErrorTypes
  >(
    (...args) => api.portfolioStats(...args),
    PortfolioStatsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['PortfolioStats'],
        ...apiQueryConfig.PortfolioStats,
        ...config?.queryConfig
      },
    },
    'PortfolioStats',
  );
};

export const usePortfolioStatsInfinite = (
  variables: Parameters<PortfolioStatsMethod>[0],
  config?: UseInfiniteQueryConfig<
    PortfolioStatsMethod,
    PortfolioStatsErrorTypes
  >
) => {
  return useInfiniteQuery<
    PortfolioStatsMethod,
    PortfolioStatsErrorTypes
  >(
    (...args) => api.portfolioStats(...args),
    PortfolioStatsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['PortfolioStats', 'PortfolioStatsInfinite'],
        ...apiQueryConfig.PortfolioStats,
        ...config?.queryConfig,
      },
    },
    'PortfolioStats',
  );
};

export const usePortfolioStatsLazy = (baseOptions?: {
  variables?: Parameters<PortfolioStatsMethod>[0],
  config?: UseQueryConfig<
    PortfolioStatsMethod,
    PortfolioStatsErrorTypes
  >
}) => {
  return useLazyQuery<
    PortfolioStatsMethod,
    PortfolioStatsErrorTypes
  >(
    (...args) => api.portfolioStats(...args),
    PortfolioStatsErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['PortfolioStats'],
        ...apiQueryConfig.PortfolioStats,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'PortfolioStats',
  );
};


export type SecurityIndexAllMethod = SecuritiesApi['securityIndexAll'];
export type SecurityIndexAllResponseType = MethodResult<SecurityIndexAllMethod>;

export const useSecurityIndexAll = (
  variables: Parameters<SecurityIndexAllMethod>[0],
  config?: UseQueryConfig<
    SecurityIndexAllMethod,
    SecurityIndexAllErrorTypes
  >
) => {
  return useQuery<
    SecurityIndexAllMethod,
    SecurityIndexAllErrorTypes
  >(
    (...args) => api.securityIndexAll(...args),
    SecurityIndexAllErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SecurityIndexAll'],
        ...apiQueryConfig.SecurityIndexAll,
        ...config?.queryConfig
      },
    },
    'SecurityIndexAll',
  );
};

export const useSecurityIndexAllInfinite = (
  variables: Parameters<SecurityIndexAllMethod>[0],
  config?: UseInfiniteQueryConfig<
    SecurityIndexAllMethod,
    SecurityIndexAllErrorTypes
  >
) => {
  return useInfiniteQuery<
    SecurityIndexAllMethod,
    SecurityIndexAllErrorTypes
  >(
    (...args) => api.securityIndexAll(...args),
    SecurityIndexAllErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SecurityIndexAll', 'SecurityIndexAllInfinite'],
        ...apiQueryConfig.SecurityIndexAll,
        ...config?.queryConfig,
      },
    },
    'SecurityIndexAll',
  );
};

export const useSecurityIndexAllLazy = (baseOptions?: {
  variables?: Parameters<SecurityIndexAllMethod>[0],
  config?: UseQueryConfig<
    SecurityIndexAllMethod,
    SecurityIndexAllErrorTypes
  >
}) => {
  return useLazyQuery<
    SecurityIndexAllMethod,
    SecurityIndexAllErrorTypes
  >(
    (...args) => api.securityIndexAll(...args),
    SecurityIndexAllErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['SecurityIndexAll'],
        ...apiQueryConfig.SecurityIndexAll,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'SecurityIndexAll',
  );
};


export type SecurityInvestorShowMethod = SecuritiesApi['securityInvestorShow'];
export type SecurityInvestorShowResponseType = MethodResult<SecurityInvestorShowMethod>;

export const useSecurityInvestorShow = (
  variables: Parameters<SecurityInvestorShowMethod>[0],
  config?: UseQueryConfig<
    SecurityInvestorShowMethod,
    SecurityInvestorShowErrorTypes
  >
) => {
  return useQuery<
    SecurityInvestorShowMethod,
    SecurityInvestorShowErrorTypes
  >(
    (...args) => api.securityInvestorShow(...args),
    SecurityInvestorShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SecurityInvestorShow'],
        ...apiQueryConfig.SecurityInvestorShow,
        ...config?.queryConfig
      },
    },
    'SecurityInvestorShow',
  );
};

export const useSecurityInvestorShowInfinite = (
  variables: Parameters<SecurityInvestorShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    SecurityInvestorShowMethod,
    SecurityInvestorShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    SecurityInvestorShowMethod,
    SecurityInvestorShowErrorTypes
  >(
    (...args) => api.securityInvestorShow(...args),
    SecurityInvestorShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SecurityInvestorShow', 'SecurityInvestorShowInfinite'],
        ...apiQueryConfig.SecurityInvestorShow,
        ...config?.queryConfig,
      },
    },
    'SecurityInvestorShow',
  );
};

export const useSecurityInvestorShowLazy = (baseOptions?: {
  variables?: Parameters<SecurityInvestorShowMethod>[0],
  config?: UseQueryConfig<
    SecurityInvestorShowMethod,
    SecurityInvestorShowErrorTypes
  >
}) => {
  return useLazyQuery<
    SecurityInvestorShowMethod,
    SecurityInvestorShowErrorTypes
  >(
    (...args) => api.securityInvestorShow(...args),
    SecurityInvestorShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['SecurityInvestorShow'],
        ...apiQueryConfig.SecurityInvestorShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'SecurityInvestorShow',
  );
};


export type SecurityShowMethod = SecuritiesApi['securityShow'];
export type SecurityShowResponseType = MethodResult<SecurityShowMethod>;

export const useSecurityShow = (
  variables: Parameters<SecurityShowMethod>[0],
  config?: UseQueryConfig<
    SecurityShowMethod,
    SecurityShowErrorTypes
  >
) => {
  return useQuery<
    SecurityShowMethod,
    SecurityShowErrorTypes
  >(
    (...args) => api.securityShow(...args),
    SecurityShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SecurityShow'],
        ...apiQueryConfig.SecurityShow,
        ...config?.queryConfig
      },
    },
    'SecurityShow',
  );
};

export const useSecurityShowInfinite = (
  variables: Parameters<SecurityShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    SecurityShowMethod,
    SecurityShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    SecurityShowMethod,
    SecurityShowErrorTypes
  >(
    (...args) => api.securityShow(...args),
    SecurityShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SecurityShow', 'SecurityShowInfinite'],
        ...apiQueryConfig.SecurityShow,
        ...config?.queryConfig,
      },
    },
    'SecurityShow',
  );
};

export const useSecurityShowLazy = (baseOptions?: {
  variables?: Parameters<SecurityShowMethod>[0],
  config?: UseQueryConfig<
    SecurityShowMethod,
    SecurityShowErrorTypes
  >
}) => {
  return useLazyQuery<
    SecurityShowMethod,
    SecurityShowErrorTypes
  >(
    (...args) => api.securityShow(...args),
    SecurityShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['SecurityShow'],
        ...apiQueryConfig.SecurityShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'SecurityShow',
  );
};


export type SecurityDestroyMethod = SecuritiesApi['securityDestroy'];

export const useSecurityDestroy = () => {
  return useMutation<SecurityDestroyMethod>(
    (...args) => api.securityDestroy(...args),
    SecurityDestroyErrorCodes,
    'SecurityDestroy',
    '/api/investor/accounts/{account_id}/companies/{company_id}/securities/{id}',
    'delete'
  );
};

export type SecurityUpdateMethod = SecuritiesApi['securityUpdate'];

export const useSecurityUpdate = () => {
  return useMutation<SecurityUpdateMethod>(
    (...args) => api.securityUpdate(...args),
    SecurityUpdateErrorCodes,
    'SecurityUpdate',
    '/api/investor/accounts/{account_id}/companies/{company_id}/securities/{id}',
    'patch'
  );
};

export type SecurityIndexMethod = SecuritiesApi['securityIndex'];
export type SecurityIndexResponseType = MethodResult<SecurityIndexMethod>;

export const useSecurityIndex = (
  variables: Parameters<SecurityIndexMethod>[0],
  config?: UseQueryConfig<
    SecurityIndexMethod,
    SecurityIndexErrorTypes
  >
) => {
  return useQuery<
    SecurityIndexMethod,
    SecurityIndexErrorTypes
  >(
    (...args) => api.securityIndex(...args),
    SecurityIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SecurityIndex'],
        ...apiQueryConfig.SecurityIndex,
        ...config?.queryConfig
      },
    },
    'SecurityIndex',
  );
};

export const useSecurityIndexInfinite = (
  variables: Parameters<SecurityIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    SecurityIndexMethod,
    SecurityIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    SecurityIndexMethod,
    SecurityIndexErrorTypes
  >(
    (...args) => api.securityIndex(...args),
    SecurityIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SecurityIndex', 'SecurityIndexInfinite'],
        ...apiQueryConfig.SecurityIndex,
        ...config?.queryConfig,
      },
    },
    'SecurityIndex',
  );
};

export const useSecurityIndexLazy = (baseOptions?: {
  variables?: Parameters<SecurityIndexMethod>[0],
  config?: UseQueryConfig<
    SecurityIndexMethod,
    SecurityIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    SecurityIndexMethod,
    SecurityIndexErrorTypes
  >(
    (...args) => api.securityIndex(...args),
    SecurityIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['SecurityIndex'],
        ...apiQueryConfig.SecurityIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'SecurityIndex',
  );
};


export type SecurityCreateMethod = SecuritiesApi['securityCreate'];

export const useSecurityCreate = () => {
  return useMutation<SecurityCreateMethod>(
    (...args) => api.securityCreate(...args),
    SecurityCreateErrorCodes,
    'SecurityCreate',
    '/api/investor/accounts/{account_id}/companies/{company_id}/securities',
    'post'
  );
};
