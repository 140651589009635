import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Label } from '@npm/core/ui/components/atoms/Label';
import {
  type EventAggregate,
  type Submission,
  type SubmissionIndex,
  type UserRoleAggregate,
  CbEventMessageType,
  CbEventPhase,
  CbOwnerType,
  CbSubmissionState,
} from '@npm/data-access';
import type { IconNames, LabelVariant } from '@npm/utils';

export const extractHoldings = (submission: Submission) => {
  const allHoldings = submission.items
    ?.filter(it => it.holding)
    ?.map(it => it.holding);
  const holdingIds = [...new Set(allHoldings?.map(h => h.id))];
  return holdingIds.map(id => allHoldings?.find(holding => holding.id === id));
};

export const isPostMatch = (submission: Submission) => {
  return submission.state.code !== CbEventMessageType.items.incomplete;
};

export const isUpcomingEvent = (event: EventAggregate) => {
  return event.current_phase?.code === CbEventPhase.items.upcoming;
};

export const isInPreviewPhase = (event: EventAggregate) => {
  return event.current_phase?.code === CbEventPhase.items.preview;
};

export const getHighestPricedBid = (orders?: { quantity?: number }[]) => {
  return (
    orders?.reduce((prev, curr) => Math.max(prev, curr?.quantity || 0), 0) ?? 0
  );
};

export const getEventInfo = (event: EventAggregate) => {
  const isTender = event.type?.code === CbOwnerType.items.TenderOffer;
  const isDAS = event.type?.code === CbOwnerType.items.DutchAuctionSell;
  const isDAB = event.type?.code === CbOwnerType.items.DutchAuctionBuy;
  const isInfo = event.type?.code === CbOwnerType.items.InformationalEvent;
  const isSellSchedule = isDAS && event.order_settings?.sell_schedule;
  const isSell = isTender || isDAS;
  const isBuy = isDAB;
  const isNdaSigned =
    (event.allow_nda === true && !!event.nda_signed_at) ||
    event.allow_nda === false;
  const isMA = event.type?.code === CbOwnerType.items.MergerAndAcquisition;

  return {
    isTender,
    isDAS,
    isDAB,
    isBuy,
    isSell,
    isSellSchedule,
    isInfo,
    isMA,
    isNdaSigned,
  };
};

export const getSubmissionState = (
  submissions: SubmissionIndex,
  userRole: UserRoleAggregate,
  ndaSigned: boolean | undefined,
  isTender?: boolean
) => {
  const filteredSubmissions = submissions?.submissions?.filter(
    sub => sub?.account?.id === userRole?.subject?.id
  );
  const submissionCount = filteredSubmissions?.length;

  if (!ndaSigned) {
    return (
      <Label variant="warning" icon={<Icon name={'clock'} />}>
        Pending NDA
      </Label>
    );
  }

  if (!submissionCount) {
    return <Label variant="general">No Submission</Label>;
  }

  const submissionStates = filteredSubmissions?.map(sub => sub?.state?.code);
  const { incomplete, pending, settled, submitted } = CbSubmissionState.items;

  const allMatch = (state: string) =>
    submissionStates?.every(code => code === state);
  const hasCompleteSubmissionsOnly = submissionStates?.every(
    code => code === settled || code === submitted
  );

  const getLabel = (variant: LabelVariant, icon: IconNames, text: string) => (
    <Label variant={variant} icon={icon ? <Icon name={icon} /> : undefined}>
      {text}
    </Label>
  );
  const submissionText = `${submissionCount} Submission${
    submissionCount > 1 ? 's' : ''
  }`;

  if (isTender) {
    if (allMatch(incomplete)) {
      return getLabel(
        'warning',
        'info-circle',
        `${submissionCount} Incomplete`
      );
    }
    if (allMatch(pending)) {
      return getLabel('warning', 'clock', `${submissionCount} Pending`);
    }
    if (hasCompleteSubmissionsOnly) {
      return getLabel('success', 'check', submissionText);
    }
    return getLabel('success', 'check', submissionText);
  }

  return getLabel('info', undefined, submissionText);
};
