import React from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Label } from '@npm/core/ui/components/atoms/Label';
import { Text } from '@npm/core/ui/components/atoms/Typography';

type SearchTermStatusProps = {
  searchTerm: string;
  requestedCompanies: Set<string>;
  setCompanyNameForCoverageRequest: (name: string) => void;
  setIsModalOpen: (isOpen: boolean) => void;
};

export const RequestCoverageButton: React.FC<SearchTermStatusProps> = ({
  searchTerm,
  requestedCompanies,
  setCompanyNameForCoverageRequest,
  setIsModalOpen,
}) => {
  return (
    <Flex justify={'space-between'} align={'center'}>
      <Flex>
        <Text>{`"${searchTerm}"`}</Text>
        <Text colorVariant={'tertiary'} lineClamp={1}>
          &nbsp;is not available
        </Text>
      </Flex>
      <Flex style={{ margin: '2px' }}>
        {requestedCompanies.has(searchTerm) ? (
          <Label variant="success" round icon={<Icon name={'check'} />}>
            Requested
          </Label>
        ) : (
          <Button
            size={'sm'}
            variant={'text'}
            onClick={() => {
              setCompanyNameForCoverageRequest(searchTerm);
              setIsModalOpen(true);
            }}
            icon={<Icon name={'plus'} />}
          >
            Request
          </Button>
        )}
      </Flex>
    </Flex>
  );
};
