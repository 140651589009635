import { type CbLabelComponent, type LabelVariant } from '@npm/utils';

import { type CodebookItem, Codebooks } from './codebooks.types';

const Items = {
  no: 'no',
  yes: 'yes',
  not_provided: 'not_provided',
} as const;

type Keys = keyof typeof Items;

const LabelMap: Record<Keys, LabelVariant> = {
  no: 'info',
  yes: 'info',
  not_provided: 'general',
};

export const CbInstitutionalClient = {
  code: Codebooks.INSTITUTIONAL_CLIENT,
  items: Items,
  getLabel: (
    Component: CbLabelComponent,
    codebookItem: CodebookItem | undefined
  ) =>
    Component({
      variant: LabelMap[(codebookItem?.code as Keys) ?? 'none'],
      label: codebookItem?.name,
      round: true,
    }),
};
