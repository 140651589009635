/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Negotiation } from '../model';
// @ts-ignore
import { NegotiationCreateRequestContract } from '../model';
// @ts-ignore
import { NegotiationIndex } from '../model';
// @ts-ignore
import { NegotiationShowAggregate } from '../model';
// @ts-ignore
import { NegotiationUpdateAggregate } from '../model';
// @ts-ignore
import { NegotiationUpdateRequestContract } from '../model';
/**
 * NegotiationApi - axios parameter creator
 * @export
 */
export const NegotiationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary initiate a negotiation
         * @param {NegotiationCreateRequestContract} negotiationCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        negotiationCreate: async (negotiationCreateRequestContract: NegotiationCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'negotiationCreateRequestContract' is not null or undefined
            assertParamExists('negotiationCreate', 'negotiationCreateRequestContract', negotiationCreateRequestContract)
            const localVarPath = changeUrl(`/api/negotiations`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(negotiationCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list negotiations for the current user
         * @param {number} [negotiationId] 
         * @param {number} [orderItemId] 
         * @param {number} [childOrderItemId] 
         * @param {number} [issuerEntityId] 
         * @param {string} [negotiationState] limits results to negotiations in the given status
         * @param {string} [negotiationTransactionPhase] limits results to negotiations with the given transaction phase
         * @param {boolean} [findActionable] 
         * @param {number} [accountId] 
         * @param {number} [routingBrokerId] 
         * @param {boolean} [findLive] 
         * @param {string} [negotiationOrderSide] filter for buy or sell order item types
         * @param {string} [sort] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        negotiationIndex: async (negotiationId?: number, orderItemId?: number, childOrderItemId?: number, issuerEntityId?: number, negotiationState?: string, negotiationTransactionPhase?: string, findActionable?: boolean, accountId?: number, routingBrokerId?: number, findLive?: boolean, negotiationOrderSide?: string, sort?: string, page?: number, size?: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/negotiations`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (negotiationId !== undefined) {
                localVarQueryParameter['negotiation_id'] = negotiationId;
            }

            if (orderItemId !== undefined) {
                localVarQueryParameter['order_item_id'] = orderItemId;
            }

            if (childOrderItemId !== undefined) {
                localVarQueryParameter['child_order_item_id'] = childOrderItemId;
            }

            if (issuerEntityId !== undefined) {
                localVarQueryParameter['issuer_entity_id'] = issuerEntityId;
            }

            if (negotiationState !== undefined) {
                localVarQueryParameter['negotiation_state'] = negotiationState;
            }

            if (negotiationTransactionPhase !== undefined) {
                localVarQueryParameter['negotiation_transaction_phase'] = negotiationTransactionPhase;
            }

            if (findActionable !== undefined) {
                localVarQueryParameter['find_actionable'] = findActionable;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['account_id'] = accountId;
            }

            if (routingBrokerId !== undefined) {
                localVarQueryParameter['routing_broker_id'] = routingBrokerId;
            }

            if (findLive !== undefined) {
                localVarQueryParameter['find_live'] = findLive;
            }

            if (negotiationOrderSide !== undefined) {
                localVarQueryParameter['negotiation_order_side'] = negotiationOrderSide;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary retrieves the details of a negotiation
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        negotiationShow: async (id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('negotiationShow', 'id', id)
            const localVarPath = changeUrl(`/api/negotiations/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary take action on a negotiation in progress
         * @param {number} id 
         * @param {NegotiationUpdateRequestContract} negotiationUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        negotiationUpdate: async (id: number, negotiationUpdateRequestContract: NegotiationUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('negotiationUpdate', 'id', id)
            // verify required parameter 'negotiationUpdateRequestContract' is not null or undefined
            assertParamExists('negotiationUpdate', 'negotiationUpdateRequestContract', negotiationUpdateRequestContract)
            const localVarPath = changeUrl(`/api/negotiations/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(negotiationUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NegotiationApi - functional programming interface
 * @export
 */
export const NegotiationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NegotiationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary initiate a negotiation
         * @param {NegotiationCreateRequestContract} negotiationCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async negotiationCreate(negotiationCreateRequestContract: NegotiationCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Negotiation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.negotiationCreate(negotiationCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list negotiations for the current user
         * @param {number} [negotiationId] 
         * @param {number} [orderItemId] 
         * @param {number} [childOrderItemId] 
         * @param {number} [issuerEntityId] 
         * @param {string} [negotiationState] limits results to negotiations in the given status
         * @param {string} [negotiationTransactionPhase] limits results to negotiations with the given transaction phase
         * @param {boolean} [findActionable] 
         * @param {number} [accountId] 
         * @param {number} [routingBrokerId] 
         * @param {boolean} [findLive] 
         * @param {string} [negotiationOrderSide] filter for buy or sell order item types
         * @param {string} [sort] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async negotiationIndex(negotiationId?: number, orderItemId?: number, childOrderItemId?: number, issuerEntityId?: number, negotiationState?: string, negotiationTransactionPhase?: string, findActionable?: boolean, accountId?: number, routingBrokerId?: number, findLive?: boolean, negotiationOrderSide?: string, sort?: string, page?: number, size?: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NegotiationIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.negotiationIndex(negotiationId, orderItemId, childOrderItemId, issuerEntityId, negotiationState, negotiationTransactionPhase, findActionable, accountId, routingBrokerId, findLive, negotiationOrderSide, sort, page, size, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary retrieves the details of a negotiation
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async negotiationShow(id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NegotiationShowAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.negotiationShow(id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary take action on a negotiation in progress
         * @param {number} id 
         * @param {NegotiationUpdateRequestContract} negotiationUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async negotiationUpdate(id: number, negotiationUpdateRequestContract: NegotiationUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NegotiationUpdateAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.negotiationUpdate(id, negotiationUpdateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NegotiationApi - factory interface
 * @export
 */
export const NegotiationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NegotiationApiFp(configuration)
    return {
        /**
         * 
         * @summary initiate a negotiation
         * @param {NegotiationCreateRequestContract} negotiationCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        negotiationCreate(negotiationCreateRequestContract: NegotiationCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<Negotiation> {
            return localVarFp.negotiationCreate(negotiationCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list negotiations for the current user
         * @param {number} [negotiationId] 
         * @param {number} [orderItemId] 
         * @param {number} [childOrderItemId] 
         * @param {number} [issuerEntityId] 
         * @param {string} [negotiationState] limits results to negotiations in the given status
         * @param {string} [negotiationTransactionPhase] limits results to negotiations with the given transaction phase
         * @param {boolean} [findActionable] 
         * @param {number} [accountId] 
         * @param {number} [routingBrokerId] 
         * @param {boolean} [findLive] 
         * @param {string} [negotiationOrderSide] filter for buy or sell order item types
         * @param {string} [sort] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        negotiationIndex(negotiationId?: number, orderItemId?: number, childOrderItemId?: number, issuerEntityId?: number, negotiationState?: string, negotiationTransactionPhase?: string, findActionable?: boolean, accountId?: number, routingBrokerId?: number, findLive?: boolean, negotiationOrderSide?: string, sort?: string, page?: number, size?: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<NegotiationIndex> {
            return localVarFp.negotiationIndex(negotiationId, orderItemId, childOrderItemId, issuerEntityId, negotiationState, negotiationTransactionPhase, findActionable, accountId, routingBrokerId, findLive, negotiationOrderSide, sort, page, size, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary retrieves the details of a negotiation
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        negotiationShow(id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<NegotiationShowAggregate> {
            return localVarFp.negotiationShow(id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary take action on a negotiation in progress
         * @param {number} id 
         * @param {NegotiationUpdateRequestContract} negotiationUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        negotiationUpdate(id: number, negotiationUpdateRequestContract: NegotiationUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<NegotiationUpdateAggregate> {
            return localVarFp.negotiationUpdate(id, negotiationUpdateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for negotiationCreate operation in NegotiationApi.
 * @export
 * @interface NegotiationApiNegotiationCreateRequest
 */
export interface NegotiationApiNegotiationCreateRequest {
    /**
     * 
     * @type {NegotiationCreateRequestContract}
     * @memberof NegotiationApiNegotiationCreate
     */
    readonly negotiationCreateRequestContract: NegotiationCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof NegotiationApiNegotiationCreate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof NegotiationApiNegotiationCreate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof NegotiationApiNegotiationCreate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof NegotiationApiNegotiationCreate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof NegotiationApiNegotiationCreate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof NegotiationApiNegotiationCreate
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for negotiationIndex operation in NegotiationApi.
 * @export
 * @interface NegotiationApiNegotiationIndexRequest
 */
export interface NegotiationApiNegotiationIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof NegotiationApiNegotiationIndex
     */
    readonly negotiationId?: number

    /**
     * 
     * @type {number}
     * @memberof NegotiationApiNegotiationIndex
     */
    readonly orderItemId?: number

    /**
     * 
     * @type {number}
     * @memberof NegotiationApiNegotiationIndex
     */
    readonly childOrderItemId?: number

    /**
     * 
     * @type {number}
     * @memberof NegotiationApiNegotiationIndex
     */
    readonly issuerEntityId?: number

    /**
     * limits results to negotiations in the given status
     * @type {string}
     * @memberof NegotiationApiNegotiationIndex
     */
    readonly negotiationState?: string

    /**
     * limits results to negotiations with the given transaction phase
     * @type {string}
     * @memberof NegotiationApiNegotiationIndex
     */
    readonly negotiationTransactionPhase?: string

    /**
     * 
     * @type {boolean}
     * @memberof NegotiationApiNegotiationIndex
     */
    readonly findActionable?: boolean

    /**
     * 
     * @type {number}
     * @memberof NegotiationApiNegotiationIndex
     */
    readonly accountId?: number

    /**
     * 
     * @type {number}
     * @memberof NegotiationApiNegotiationIndex
     */
    readonly routingBrokerId?: number

    /**
     * 
     * @type {boolean}
     * @memberof NegotiationApiNegotiationIndex
     */
    readonly findLive?: boolean

    /**
     * filter for buy or sell order item types
     * @type {string}
     * @memberof NegotiationApiNegotiationIndex
     */
    readonly negotiationOrderSide?: string

    /**
     * 
     * @type {string}
     * @memberof NegotiationApiNegotiationIndex
     */
    readonly sort?: string

    /**
     * 
     * @type {number}
     * @memberof NegotiationApiNegotiationIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof NegotiationApiNegotiationIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof NegotiationApiNegotiationIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof NegotiationApiNegotiationIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof NegotiationApiNegotiationIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof NegotiationApiNegotiationIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof NegotiationApiNegotiationIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof NegotiationApiNegotiationIndex
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for negotiationShow operation in NegotiationApi.
 * @export
 * @interface NegotiationApiNegotiationShowRequest
 */
export interface NegotiationApiNegotiationShowRequest {
    /**
     * 
     * @type {number}
     * @memberof NegotiationApiNegotiationShow
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof NegotiationApiNegotiationShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof NegotiationApiNegotiationShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof NegotiationApiNegotiationShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof NegotiationApiNegotiationShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof NegotiationApiNegotiationShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof NegotiationApiNegotiationShow
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for negotiationUpdate operation in NegotiationApi.
 * @export
 * @interface NegotiationApiNegotiationUpdateRequest
 */
export interface NegotiationApiNegotiationUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof NegotiationApiNegotiationUpdate
     */
    readonly id: number

    /**
     * 
     * @type {NegotiationUpdateRequestContract}
     * @memberof NegotiationApiNegotiationUpdate
     */
    readonly negotiationUpdateRequestContract: NegotiationUpdateRequestContract

    /**
     * 
     * @type {string}
     * @memberof NegotiationApiNegotiationUpdate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof NegotiationApiNegotiationUpdate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof NegotiationApiNegotiationUpdate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof NegotiationApiNegotiationUpdate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof NegotiationApiNegotiationUpdate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof NegotiationApiNegotiationUpdate
     */
    readonly xUserRoleId?: string
}

/**
 * NegotiationApi - object-oriented interface
 * @export
 * @class NegotiationApi
 * @extends {BaseAPI}
 */
export class NegotiationApi extends BaseAPI {
    /**
     * 
     * @summary initiate a negotiation
     * @param {NegotiationApiNegotiationCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NegotiationApi
     */
    public negotiationCreate(requestParameters: NegotiationApiNegotiationCreateRequest, options?: AxiosRequestConfig) {
        return NegotiationApiFp(this.configuration).negotiationCreate(requestParameters.negotiationCreateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list negotiations for the current user
     * @param {NegotiationApiNegotiationIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NegotiationApi
     */
    public negotiationIndex(requestParameters: NegotiationApiNegotiationIndexRequest = {}, options?: AxiosRequestConfig) {
        return NegotiationApiFp(this.configuration).negotiationIndex(requestParameters.negotiationId, requestParameters.orderItemId, requestParameters.childOrderItemId, requestParameters.issuerEntityId, requestParameters.negotiationState, requestParameters.negotiationTransactionPhase, requestParameters.findActionable, requestParameters.accountId, requestParameters.routingBrokerId, requestParameters.findLive, requestParameters.negotiationOrderSide, requestParameters.sort, requestParameters.page, requestParameters.size, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary retrieves the details of a negotiation
     * @param {NegotiationApiNegotiationShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NegotiationApi
     */
    public negotiationShow(requestParameters: NegotiationApiNegotiationShowRequest, options?: AxiosRequestConfig) {
        return NegotiationApiFp(this.configuration).negotiationShow(requestParameters.id, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary take action on a negotiation in progress
     * @param {NegotiationApiNegotiationUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NegotiationApi
     */
    public negotiationUpdate(requestParameters: NegotiationApiNegotiationUpdateRequest, options?: AxiosRequestConfig) {
        return NegotiationApiFp(this.configuration).negotiationUpdate(requestParameters.id, requestParameters.negotiationUpdateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type NegotiationCreateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const NegotiationCreateErrorCodes = [
    401,
];

export type NegotiationIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const NegotiationIndexErrorCodes = [
    401,
];

export type NegotiationShowErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const NegotiationShowErrorCodes = [
    401,
];

export type NegotiationUpdateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 447;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const NegotiationUpdateErrorCodes = [
    401,
    447,
];


