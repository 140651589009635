/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  PersonaWebhookApi,
  PublicPersonaCreateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new PersonaWebhookApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type PublicPersonaCreateMethod = PersonaWebhookApi['publicPersonaCreate'];

export const usePublicPersonaCreate = () => {
  return useMutation<PublicPersonaCreateMethod>(
    (...args) => api.publicPersonaCreate(...args),
    PublicPersonaCreateErrorCodes,
    'PublicPersonaCreate',
    '/api/public/persona',
    'post'
  );
};
