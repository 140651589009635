/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AgreementSigningCreateRequestContract } from '../model';
// @ts-ignore
import { DocusignSigning } from '../model';
// @ts-ignore
import { ErrorValidation } from '../model';
/**
 * AgreementSigningApi - axios parameter creator
 * @export
 */
export const AgreementSigningApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary creates a docusign envelope and returns the url
         * @param {AgreementSigningCreateRequestContract} agreementSigningCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agreementSigning: async (agreementSigningCreateRequestContract: AgreementSigningCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementSigningCreateRequestContract' is not null or undefined
            assertParamExists('agreementSigning', 'agreementSigningCreateRequestContract', agreementSigningCreateRequestContract)
            const localVarPath = changeUrl(`/api/agreement-signing`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agreementSigningCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a result from the callback
         * @param {number} agreementDocumentId 
         * @param {number} userId 
         * @param {string} envelopeId 
         * @param {string} agreementDocumentSigner 
         * @param {string} [event] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agreementSigningComplete: async (agreementDocumentId: number, userId: number, envelopeId: string, agreementDocumentSigner: string, event?: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementDocumentId' is not null or undefined
            assertParamExists('agreementSigningComplete', 'agreementDocumentId', agreementDocumentId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('agreementSigningComplete', 'userId', userId)
            // verify required parameter 'envelopeId' is not null or undefined
            assertParamExists('agreementSigningComplete', 'envelopeId', envelopeId)
            // verify required parameter 'agreementDocumentSigner' is not null or undefined
            assertParamExists('agreementSigningComplete', 'agreementDocumentSigner', agreementDocumentSigner)
            const localVarPath = changeUrl(`/api/agreement-signing/{agreement_document_id}`)
                .replace(`{${"agreement_document_id"}}`, encodeURIComponent(String(agreementDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (envelopeId !== undefined) {
                localVarQueryParameter['envelope_id'] = envelopeId;
            }

            if (agreementDocumentSigner !== undefined) {
                localVarQueryParameter['agreement_document_signer'] = agreementDocumentSigner;
            }

            if (event !== undefined) {
                localVarQueryParameter['event'] = event;
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgreementSigningApi - functional programming interface
 * @export
 */
export const AgreementSigningApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgreementSigningApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary creates a docusign envelope and returns the url
         * @param {AgreementSigningCreateRequestContract} agreementSigningCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agreementSigning(agreementSigningCreateRequestContract: AgreementSigningCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocusignSigning>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.agreementSigning(agreementSigningCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a result from the callback
         * @param {number} agreementDocumentId 
         * @param {number} userId 
         * @param {string} envelopeId 
         * @param {string} agreementDocumentSigner 
         * @param {string} [event] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agreementSigningComplete(agreementDocumentId: number, userId: number, envelopeId: string, agreementDocumentSigner: string, event?: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.agreementSigningComplete(agreementDocumentId, userId, envelopeId, agreementDocumentSigner, event, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AgreementSigningApi - factory interface
 * @export
 */
export const AgreementSigningApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgreementSigningApiFp(configuration)
    return {
        /**
         * 
         * @summary creates a docusign envelope and returns the url
         * @param {AgreementSigningCreateRequestContract} agreementSigningCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agreementSigning(agreementSigningCreateRequestContract: AgreementSigningCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<DocusignSigning> {
            return localVarFp.agreementSigning(agreementSigningCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a result from the callback
         * @param {number} agreementDocumentId 
         * @param {number} userId 
         * @param {string} envelopeId 
         * @param {string} agreementDocumentSigner 
         * @param {string} [event] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agreementSigningComplete(agreementDocumentId: number, userId: number, envelopeId: string, agreementDocumentSigner: string, event?: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.agreementSigningComplete(agreementDocumentId, userId, envelopeId, agreementDocumentSigner, event, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for agreementSigning operation in AgreementSigningApi.
 * @export
 * @interface AgreementSigningApiAgreementSigningRequest
 */
export interface AgreementSigningApiAgreementSigningRequest {
    /**
     * 
     * @type {AgreementSigningCreateRequestContract}
     * @memberof AgreementSigningApiAgreementSigning
     */
    readonly agreementSigningCreateRequestContract: AgreementSigningCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof AgreementSigningApiAgreementSigning
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof AgreementSigningApiAgreementSigning
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof AgreementSigningApiAgreementSigning
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof AgreementSigningApiAgreementSigning
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof AgreementSigningApiAgreementSigning
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof AgreementSigningApiAgreementSigning
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for agreementSigningComplete operation in AgreementSigningApi.
 * @export
 * @interface AgreementSigningApiAgreementSigningCompleteRequest
 */
export interface AgreementSigningApiAgreementSigningCompleteRequest {
    /**
     * 
     * @type {number}
     * @memberof AgreementSigningApiAgreementSigningComplete
     */
    readonly agreementDocumentId: number

    /**
     * 
     * @type {number}
     * @memberof AgreementSigningApiAgreementSigningComplete
     */
    readonly userId: number

    /**
     * 
     * @type {string}
     * @memberof AgreementSigningApiAgreementSigningComplete
     */
    readonly envelopeId: string

    /**
     * 
     * @type {string}
     * @memberof AgreementSigningApiAgreementSigningComplete
     */
    readonly agreementDocumentSigner: string

    /**
     * 
     * @type {string}
     * @memberof AgreementSigningApiAgreementSigningComplete
     */
    readonly event?: string

    /**
     * 
     * @type {string}
     * @memberof AgreementSigningApiAgreementSigningComplete
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof AgreementSigningApiAgreementSigningComplete
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof AgreementSigningApiAgreementSigningComplete
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof AgreementSigningApiAgreementSigningComplete
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof AgreementSigningApiAgreementSigningComplete
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof AgreementSigningApiAgreementSigningComplete
     */
    readonly xUserRoleId?: string
}

/**
 * AgreementSigningApi - object-oriented interface
 * @export
 * @class AgreementSigningApi
 * @extends {BaseAPI}
 */
export class AgreementSigningApi extends BaseAPI {
    /**
     * 
     * @summary creates a docusign envelope and returns the url
     * @param {AgreementSigningApiAgreementSigningRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementSigningApi
     */
    public agreementSigning(requestParameters: AgreementSigningApiAgreementSigningRequest, options?: AxiosRequestConfig) {
        return AgreementSigningApiFp(this.configuration).agreementSigning(requestParameters.agreementSigningCreateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a result from the callback
     * @param {AgreementSigningApiAgreementSigningCompleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementSigningApi
     */
    public agreementSigningComplete(requestParameters: AgreementSigningApiAgreementSigningCompleteRequest, options?: AxiosRequestConfig) {
        return AgreementSigningApiFp(this.configuration).agreementSigningComplete(requestParameters.agreementDocumentId, requestParameters.userId, requestParameters.envelopeId, requestParameters.agreementDocumentSigner, requestParameters.event, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type AgreementSigningErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 422;
        data: ErrorValidation;
    }

    | {
        status: 442;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AgreementSigningErrorCodes = [
    401,
    422,
    442,
];

export type AgreementSigningCompleteErrorTypes =
    | {
        status: 302;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AgreementSigningCompleteErrorCodes = [
    302,
];


