import React from 'react';

import { Text } from '../Typography';

import { getCurrentStepCompletion } from './ProgressBar.utils';
import { ProgressStep } from './ProgressStep';

import * as S from './ProgressBar.styles';

type Props = {
  title?: React.ReactNode;
  currentStep: number;
  totalSteps: number;
  stepBackground?: string;
};

export const ProgressBar = ({
  title,
  currentStep,
  totalSteps,
  stepBackground,
}: Props) => {
  return (
    <S.Progress>
      <S.TextContainer>
        {title && (
          <Text size="sm" marginBottom="xs">
            {title}
          </Text>
        )}
        <S.Counter>
          <Text size="xs" colorVariant="primary">
            {Math.floor(currentStep)} / {totalSteps}
          </Text>
        </S.Counter>
      </S.TextContainer>
      <S.StepsContainer>
        {Array.from({ length: totalSteps }, (_, i) => (
          <ProgressStep
            key={i}
            percent={getCurrentStepCompletion(currentStep, i)}
            stepBackground={stepBackground}
          />
        ))}
      </S.StepsContainer>
    </S.Progress>
  );
};
