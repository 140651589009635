/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AnnualPercentageYield } from '../model';
/**
 * AnnualPercentageYieldApi - axios parameter creator
 * @export
 */
export const AnnualPercentageYieldApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get apy from a cash account partner
         * @param {string} partnerName 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualPercentageYieldShow: async (partnerName: string, xWorkstation?: string, xNPMUser?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerName' is not null or undefined
            assertParamExists('annualPercentageYieldShow', 'partnerName', partnerName)
            const localVarPath = changeUrl(`/api/investor/annual_percentage_yields/{partner_name}`)
                .replace(`{${"partner_name"}}`, encodeURIComponent(String(partnerName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xNPMUser !== undefined && xNPMUser !== null) {
                localVarHeaderParameter['X-NPM-User'] = String(xNPMUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnualPercentageYieldApi - functional programming interface
 * @export
 */
export const AnnualPercentageYieldApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnnualPercentageYieldApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get apy from a cash account partner
         * @param {string} partnerName 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annualPercentageYieldShow(partnerName: string, xWorkstation?: string, xNPMUser?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnualPercentageYield>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annualPercentageYieldShow(partnerName, xWorkstation, xNPMUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnnualPercentageYieldApi - factory interface
 * @export
 */
export const AnnualPercentageYieldApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnnualPercentageYieldApiFp(configuration)
    return {
        /**
         * 
         * @summary Get apy from a cash account partner
         * @param {string} partnerName 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualPercentageYieldShow(partnerName: string, xWorkstation?: string, xNPMUser?: string, options?: any): AxiosPromise<AnnualPercentageYield> {
            return localVarFp.annualPercentageYieldShow(partnerName, xWorkstation, xNPMUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for annualPercentageYieldShow operation in AnnualPercentageYieldApi.
 * @export
 * @interface AnnualPercentageYieldApiAnnualPercentageYieldShowRequest
 */
export interface AnnualPercentageYieldApiAnnualPercentageYieldShowRequest {
    /**
     * 
     * @type {string}
     * @memberof AnnualPercentageYieldApiAnnualPercentageYieldShow
     */
    readonly partnerName: string

    /**
     * 
     * @type {string}
     * @memberof AnnualPercentageYieldApiAnnualPercentageYieldShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof AnnualPercentageYieldApiAnnualPercentageYieldShow
     */
    readonly xNPMUser?: string
}

/**
 * AnnualPercentageYieldApi - object-oriented interface
 * @export
 * @class AnnualPercentageYieldApi
 * @extends {BaseAPI}
 */
export class AnnualPercentageYieldApi extends BaseAPI {
    /**
     * 
     * @summary Get apy from a cash account partner
     * @param {AnnualPercentageYieldApiAnnualPercentageYieldShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnualPercentageYieldApi
     */
    public annualPercentageYieldShow(requestParameters: AnnualPercentageYieldApiAnnualPercentageYieldShowRequest, options?: AxiosRequestConfig) {
        return AnnualPercentageYieldApiFp(this.configuration).annualPercentageYieldShow(requestParameters.partnerName, requestParameters.xWorkstation, requestParameters.xNPMUser, options).then((request) => request(this.axios, this.basePath));
    }
}

export type AnnualPercentageYieldShowErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AnnualPercentageYieldShowErrorCodes = [
];


