import React from 'react';
import { useTheme } from 'styled-components';

import {
  type investorWorkstationRouteKeys,
  type secondMarketRouteKeys,
} from '../../../routes';
import { truncate } from '../../../utils/formatters';
import { getFullImageUrl } from '../../../utils/path/Path.utils';
import { Icon } from '../../atoms/Icon';
import { Image } from '../../atoms/Image';
import { Text } from '../../atoms/Typography';
import { Link, useLocation } from '../Link';

import * as S from './CompanyLogo.styles';

type LogoSize = 'sm' | 'md' | 'lg' | 'xl';

const sizeMap: Record<LogoSize, number> = {
  sm: 20,
  md: 24,
  lg: 28,
  xl: 32,
};

type Props = {
  url?: string | null;
  title?: string | null;
  name?: string | null;
  nameMaxLength?: number;
  descriptionMaxLength?: number;
  description?: string;
  size?: LogoSize | number;
  className?: string;
  isCompanyCard?: boolean;
  watchlist?: boolean;
  linkProps?: {
    companyId: number;
    variant?: 'logo' | 'icon';
    prefix?: secondMarketRouteKeys | investorWorkstationRouteKeys;
    afterPrefixPath?: string;
    iconVariant?: 'external-link' | 'chevron-right';
  };
  isDisabled?: boolean;
};

export const CompanyLogo = React.memo(function CompanyLogo({
  url,
  title,
  size = 'xl',
  name,
  nameMaxLength,
  descriptionMaxLength,
  watchlist,
  description,
  className,
  linkProps,
  isCompanyCard,
  isDisabled,
}: Props) {
  const {
    companyId,
    variant = 'icon',
    prefix,
    afterPrefixPath = '/company-data',
    iconVariant = 'external-link',
  } = linkProps || {};
  const fullUrl = getFullImageUrl(url);
  const theme = useTheme();
  const location = useLocation();

  const secondMarketUrlPrefix =
    prefix ??
    (location.pathname.indexOf('/second-market/sponsored-investor') >= 0
      ? '/second-market/sponsored-investor'
      : '/second-market');

  const link = companyId ? (
    <Link
      to={`${secondMarketUrlPrefix}${afterPrefixPath}${
        afterPrefixPath.includes('?') ? '&' : '?'
      }companyId=${companyId}`}
      onClick={e => e.stopPropagation()}
    >
      <Icon name={iconVariant} size="xs" />
    </Link>
  ) : null;

  const image = (
    <div style={{ position: 'relative', opacity: isDisabled ? 0.5 : 1 }}>
      <Image
        title={title || name || undefined}
        src={fullUrl ?? undefined}
        preview={false}
        width={typeof size === 'number' ? size : sizeMap[size]}
        height={typeof size === 'number' ? size : sizeMap[size]}
        borderRadius={theme.borderRadius.md}
      />
      {watchlist && <S.WatchlistIcon name={'star-filled'} size="xs" />}
    </div>
  );

  const logo =
    companyId && variant === 'logo' ? (
      <S.LogoContainer>
        {image}
        {link}
      </S.LogoContainer>
    ) : (
      image
    );

  return (
    <S.Container className={className}>
      {logo}
      {name ? (
        <div>
          <S.NameContainer
            $isDisabled={!!isDisabled}
            data-dd-action-name="Company Link"
            data-dd-privacy="mask"
          >
            <Text size="sm" colorVariant="primary">
              {nameMaxLength ? truncate(name, nameMaxLength) : name}
            </Text>
            {variant === 'icon' ? link : null}
          </S.NameContainer>
          {description ? (
            <Text
              size="xxs"
              colorVariant="secondary"
              lineClamp={isCompanyCard ? 1 : undefined}
            >
              {descriptionMaxLength
                ? truncate(description, descriptionMaxLength)
                : description}
            </Text>
          ) : null}
        </div>
      ) : null}
    </S.Container>
  );
});
