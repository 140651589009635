/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  S2SIssuerHoldingsApi,
  S2SGetIssuerSecurityErrorCodes,
  S2SGetIssuerSecurityErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new S2SIssuerHoldingsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type S2SGetIssuerSecurityMethod = S2SIssuerHoldingsApi['s2SGetIssuerSecurity'];
export type S2SGetIssuerSecurityResponseType = MethodResult<S2SGetIssuerSecurityMethod>;

export const useS2SGetIssuerSecurity = (
  variables: Parameters<S2SGetIssuerSecurityMethod>[0],
  config?: UseQueryConfig<
    S2SGetIssuerSecurityMethod,
    S2SGetIssuerSecurityErrorTypes
  >
) => {
  return useQuery<
    S2SGetIssuerSecurityMethod,
    S2SGetIssuerSecurityErrorTypes
  >(
    (...args) => api.s2SGetIssuerSecurity(...args),
    S2SGetIssuerSecurityErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['S2SGetIssuerSecurity'],
        ...apiQueryConfig.S2SGetIssuerSecurity,
        ...config?.queryConfig
      },
    },
    'S2SGetIssuerSecurity',
  );
};

export const useS2SGetIssuerSecurityInfinite = (
  variables: Parameters<S2SGetIssuerSecurityMethod>[0],
  config?: UseInfiniteQueryConfig<
    S2SGetIssuerSecurityMethod,
    S2SGetIssuerSecurityErrorTypes
  >
) => {
  return useInfiniteQuery<
    S2SGetIssuerSecurityMethod,
    S2SGetIssuerSecurityErrorTypes
  >(
    (...args) => api.s2SGetIssuerSecurity(...args),
    S2SGetIssuerSecurityErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['S2SGetIssuerSecurity', 'S2SGetIssuerSecurityInfinite'],
        ...apiQueryConfig.S2SGetIssuerSecurity,
        ...config?.queryConfig,
      },
    },
    'S2SGetIssuerSecurity',
  );
};

export const useS2SGetIssuerSecurityLazy = (baseOptions?: {
  variables?: Parameters<S2SGetIssuerSecurityMethod>[0],
  config?: UseQueryConfig<
    S2SGetIssuerSecurityMethod,
    S2SGetIssuerSecurityErrorTypes
  >
}) => {
  return useLazyQuery<
    S2SGetIssuerSecurityMethod,
    S2SGetIssuerSecurityErrorTypes
  >(
    (...args) => api.s2SGetIssuerSecurity(...args),
    S2SGetIssuerSecurityErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['S2SGetIssuerSecurity'],
        ...apiQueryConfig.S2SGetIssuerSecurity,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'S2SGetIssuerSecurity',
  );
};

