import { useEffect } from 'react';

// eslint-disable-next-line no-restricted-imports
import { DatadogService } from '../datadog';

const UTM_DATA_SESSION_STORAGE_KEY = 'UTM_DATA';
const ALLOWED_UTM_KEYS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
];

const transformQueryStringToObj = (queryString: string | undefined) => {
  if (!queryString?.length) {
    return {};
  }

  const searchParams = new URLSearchParams(queryString);

  let paramsObj = {};
  for (const [key, value] of searchParams.entries()) {
    paramsObj = { ...paramsObj, [key]: value };
  }

  return paramsObj;
};

const filterKnownUtmKeys = (data: { [key: string]: unknown }) => {
  return Object.entries(data).reduce((acc, [key, value]) => {
    if (ALLOWED_UTM_KEYS.includes(key?.toLowerCase()) && !!key && !!value) {
      acc[key] = value;
    }
    return acc;
  }, {});
};

export const useTrackUtm = () => {
  useEffect(() => {
    const qs = filterKnownUtmKeys(
      transformQueryStringToObj(window.location.search)
    );

    if (Object.entries(qs).length > 0) {
      window.sessionStorage.setItem(
        UTM_DATA_SESSION_STORAGE_KEY,
        JSON.stringify(qs)
      );

      DatadogService.setUtmData(qs);
    } else {
      let utmData = {};
      try {
        const utmDataAsString = window.sessionStorage.getItem(
          UTM_DATA_SESSION_STORAGE_KEY
        );
        if (utmDataAsString?.length) {
          utmData = filterKnownUtmKeys(JSON.parse(utmDataAsString));
        }
      } catch (e) {
        console.warn(e);
      }

      DatadogService.setUtmData(utmData);
    }
  }, []);
};
