import styled from 'styled-components';

import { Card as CardBase } from '@npm/core/ui/components/molecules/Card';

export const SectionWrapper = styled(CardBase)`
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
  background-color: ${({ theme }) => theme.color.general.layout.one};
`;

export const SectionTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.md}px;
  padding-bottom: 0;
  gap: ${({ theme }) => theme.spacing.xs}px;
`;

export const SectionSubTitleWrapper = styled.div`
  padding: ${({ theme }) => `${theme.spacing.sm}px ${theme.spacing.md}px`};
  background-color: ${({ theme }) => theme.color.general.layout.zero};
`;

export const ItemsWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.md}px;
`;

export const ItemWrapper = styled.div<{ $areChildrenExpanded: boolean }>`
  ${({ theme, $areChildrenExpanded }) =>
    $areChildrenExpanded &&
    `background-color: ${theme.color.general.layout.zero};
    border-radius: ${theme.borderRadius.lg}px;
    `}
`;
