import styled from 'styled-components';

export const HeaderButtonWrap = styled.div`
  display: flex;
  align-items: center;

  svg {
    color: ${({ theme }) => theme.color.info.typography.primary};
    margin-right: ${({ theme }) => theme.spacing.sm}px;
  }
`;

export const BackButtonWrap = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.md}px;
`;
