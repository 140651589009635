import React, { useMemo } from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { type MenuItem } from '@npm/core/ui/components/organisms/Layout';

import { useUserContextStore } from '../../../auth/user/context';
import { useFeatureFlags } from '../featureFlags.context';

export const useFeatureFlagMenuItem = () => {
  const { featureFlags, isMenuEnabled } = useFeatureFlags();
  const user = useUserContextStore(s => s.user);
  const featureFlagMenuItem = useMemo(() => {
    if (!isMenuEnabled) {
      return null;
    }

    return {
      label: (
        <Tooltip
          title={
            <Flex gap="sm" direction="column">
              {featureFlags?.map(flag => (
                <div key={flag.name}>
                  <Text size="sm">{flag.name}</Text>
                  <Text size="xs">Enabled: {flag.enabled ? 'Yes' : 'No'}</Text>
                  {flag.technical_owner && (
                    <Text size="xs">
                      Technical contact: {flag.technical_owner}
                    </Text>
                  )}
                </div>
              ))}
              <Text size="xxs">
                * These features are only visible by you ({user?.email}), are
                experimental before their customer release. If you notice
                problems with them, please see the technical contact listed on
                the future
              </Text>
            </Flex>
          }
        >
          Feature Flags
        </Tooltip>
      ),
      title: 'Feature Flags',
      key: 'featureFlags',
      icon: <Icon name="warning" />,
    } satisfies MenuItem;
  }, [featureFlags, isMenuEnabled]);

  return useMemo(() => {
    return {
      featureFlagMenuItem,
      handleFeatureFlagMenuItemClick: (key: string) => {
        return key === 'featureFlags';
      },
    };
  }, [featureFlagMenuItem]);
};
