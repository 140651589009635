import {
  FormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { CharLimitHelper, TextArea } from '@npm/core/ui/components/atoms/Input';
import { CollapsibleFormSection } from '@npm/core/ui/components/molecules/CollapsibleFormSection';

import {
  getNotePreview,
  NOTE_MAX_LENGTH,
  NOTE_PREVIEW_MAX_LENGTH,
} from '../../../../order';

type Props = {
  value?: string;
  key: string;
} & Partial<React.ComponentProps<typeof CollapsibleFormSection.Item>>;

export const NoteFormSection = ({ value, ...props }: Props) => {
  return (
    <CollapsibleFormSection.Item
      label="Note"
      optional
      placeholder=""
      preview={getNotePreview(value, NOTE_PREVIEW_MAX_LENGTH)}
      name={props.key}
      {...props}
    >
      <FormItem
        name="note"
        initialValue={''}
        rules={[VALIDATION_RULES.max(NOTE_MAX_LENGTH)]}
        extra={<CharLimitHelper fieldName="note" limit={NOTE_MAX_LENGTH} />}
        marginBottom="sm"
      >
        <TextArea placeholder="Note" />
      </FormItem>
    </CollapsibleFormSection.Item>
  );
};
