/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DocumentAggregate } from '../model';
/**
 * TsMatchActivityUploadStnaApi - axios parameter creator
 * @export
 */
export const TsMatchActivityUploadStnaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary upload stna for TS match activity
         * @param {number} matchActivityId 
         * @param {string} displayName 
         * @param {boolean} downloadable 
         * @param {boolean} watermark 
         * @param {File} file 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {Array<string>} [includeField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tsMatchActivityUploadStnaUpdate: async (matchActivityId: number, displayName: string, downloadable: boolean, watermark: boolean, file: File, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, includeField?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'matchActivityId' is not null or undefined
            assertParamExists('tsMatchActivityUploadStnaUpdate', 'matchActivityId', matchActivityId)
            // verify required parameter 'displayName' is not null or undefined
            assertParamExists('tsMatchActivityUploadStnaUpdate', 'displayName', displayName)
            // verify required parameter 'downloadable' is not null or undefined
            assertParamExists('tsMatchActivityUploadStnaUpdate', 'downloadable', downloadable)
            // verify required parameter 'watermark' is not null or undefined
            assertParamExists('tsMatchActivityUploadStnaUpdate', 'watermark', watermark)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('tsMatchActivityUploadStnaUpdate', 'file', file)
            const localVarPath = changeUrl(`/api/ts-match-activity-upload-stnas/{match_activity_id}`)
                .replace(`{${"match_activity_id"}}`, encodeURIComponent(String(matchActivityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }

            if (includeField) {
                localVarFormParams.append('include_field', JSON.stringify(includeField));
            }

    
            if (displayName !== undefined) { 
                localVarFormParams.append('display_name', displayName as any);
            }
    
            if (downloadable !== undefined) { 
                localVarFormParams.append('downloadable', downloadable as any);
            }
    
            if (watermark !== undefined) { 
                localVarFormParams.append('watermark', watermark as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TsMatchActivityUploadStnaApi - functional programming interface
 * @export
 */
export const TsMatchActivityUploadStnaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TsMatchActivityUploadStnaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary upload stna for TS match activity
         * @param {number} matchActivityId 
         * @param {string} displayName 
         * @param {boolean} downloadable 
         * @param {boolean} watermark 
         * @param {File} file 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {Array<string>} [includeField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tsMatchActivityUploadStnaUpdate(matchActivityId: number, displayName: string, downloadable: boolean, watermark: boolean, file: File, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, includeField?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tsMatchActivityUploadStnaUpdate(matchActivityId, displayName, downloadable, watermark, file, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, includeField, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TsMatchActivityUploadStnaApi - factory interface
 * @export
 */
export const TsMatchActivityUploadStnaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TsMatchActivityUploadStnaApiFp(configuration)
    return {
        /**
         * 
         * @summary upload stna for TS match activity
         * @param {number} matchActivityId 
         * @param {string} displayName 
         * @param {boolean} downloadable 
         * @param {boolean} watermark 
         * @param {File} file 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {Array<string>} [includeField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tsMatchActivityUploadStnaUpdate(matchActivityId: number, displayName: string, downloadable: boolean, watermark: boolean, file: File, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, includeField?: Array<string>, options?: any): AxiosPromise<DocumentAggregate> {
            return localVarFp.tsMatchActivityUploadStnaUpdate(matchActivityId, displayName, downloadable, watermark, file, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, includeField, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for tsMatchActivityUploadStnaUpdate operation in TsMatchActivityUploadStnaApi.
 * @export
 * @interface TsMatchActivityUploadStnaApiTsMatchActivityUploadStnaUpdateRequest
 */
export interface TsMatchActivityUploadStnaApiTsMatchActivityUploadStnaUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof TsMatchActivityUploadStnaApiTsMatchActivityUploadStnaUpdate
     */
    readonly matchActivityId: number

    /**
     * 
     * @type {string}
     * @memberof TsMatchActivityUploadStnaApiTsMatchActivityUploadStnaUpdate
     */
    readonly displayName: string

    /**
     * 
     * @type {boolean}
     * @memberof TsMatchActivityUploadStnaApiTsMatchActivityUploadStnaUpdate
     */
    readonly downloadable: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TsMatchActivityUploadStnaApiTsMatchActivityUploadStnaUpdate
     */
    readonly watermark: boolean

    /**
     * 
     * @type {File}
     * @memberof TsMatchActivityUploadStnaApiTsMatchActivityUploadStnaUpdate
     */
    readonly file: File

    /**
     * 
     * @type {string}
     * @memberof TsMatchActivityUploadStnaApiTsMatchActivityUploadStnaUpdate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof TsMatchActivityUploadStnaApiTsMatchActivityUploadStnaUpdate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof TsMatchActivityUploadStnaApiTsMatchActivityUploadStnaUpdate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof TsMatchActivityUploadStnaApiTsMatchActivityUploadStnaUpdate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof TsMatchActivityUploadStnaApiTsMatchActivityUploadStnaUpdate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof TsMatchActivityUploadStnaApiTsMatchActivityUploadStnaUpdate
     */
    readonly xUserRoleId?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof TsMatchActivityUploadStnaApiTsMatchActivityUploadStnaUpdate
     */
    readonly includeField?: Array<string>
}

/**
 * TsMatchActivityUploadStnaApi - object-oriented interface
 * @export
 * @class TsMatchActivityUploadStnaApi
 * @extends {BaseAPI}
 */
export class TsMatchActivityUploadStnaApi extends BaseAPI {
    /**
     * 
     * @summary upload stna for TS match activity
     * @param {TsMatchActivityUploadStnaApiTsMatchActivityUploadStnaUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TsMatchActivityUploadStnaApi
     */
    public tsMatchActivityUploadStnaUpdate(requestParameters: TsMatchActivityUploadStnaApiTsMatchActivityUploadStnaUpdateRequest, options?: AxiosRequestConfig) {
        return TsMatchActivityUploadStnaApiFp(this.configuration).tsMatchActivityUploadStnaUpdate(requestParameters.matchActivityId, requestParameters.displayName, requestParameters.downloadable, requestParameters.watermark, requestParameters.file, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, requestParameters.includeField, options).then((request) => request(this.axios, this.basePath));
    }
}

export type TsMatchActivityUploadStnaUpdateErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const TsMatchActivityUploadStnaUpdateErrorCodes = [
];


