import styled, { css } from 'styled-components';

import { useBreakpoint } from '../../../utils/style';

const BUTTON_MIN_WIDTH = 160;
export const BOTTOM_NAVIGATION_BAR_MIN_HEIGHT = 72;
export const BOTTOM_NAVIGATION_BAR_MIN_HEIGHT_MOBILE = 64;

export const Wrapper = styled.div<{ $hide: boolean }>`
  z-index: ${({ theme }) => theme.zIndex.fixed};
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  transition: transform 0.2s;

  ${({ $hide }) =>
    $hide &&
    css`
      transform: translateY(100%);
    `}

  ${({ theme }) =>
    useBreakpoint(theme).tablet`${css`
      background-color: ${theme.color.general.layout.one};

      &&& {
        position: fixed;
        bottom: 0;
        left: 0;
        margin-bottom: 0;
      }
    `}`}
`;

export const InnerWrapper = styled.div<{
  maxWidth: string;
  fullWidth?: boolean;
  showSeparator: boolean;
}>`
  max-width: ${({ maxWidth, fullWidth }) => (fullWidth ? '100%' : maxWidth)}px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.spacing.md}px
    ${({ theme }) => theme.spacing.md * 2}px;
  min-height: ${BOTTOM_NAVIGATION_BAR_MIN_HEIGHT}px;
  background-color: ${({ theme }) => theme.color.general.layout.one};
  border-top: ${({ showSeparator, theme }) =>
    showSeparator
      ? `1px solid ${theme.color.general.borders.primary}`
      : 'none'};

  ${({ theme }) => useBreakpoint(theme).tablet`
    box-shadow: ${theme.shadow.level3};
  `}

  ${({ theme }) => useBreakpoint(theme).mobile`
    min-height: ${BOTTOM_NAVIGATION_BAR_MIN_HEIGHT_MOBILE}px;
  `}

  gap: ${({ theme }) => theme.spacing.md}px;

  && > .ant-btn {
    min-width: ${BUTTON_MIN_WIDTH}px;
  }

  ${({ theme }) =>
    useBreakpoint(theme).tablet`${css`
      padding: ${theme.spacing.lg / 2}px;
      justify-content: space-between;
      background-color: ${theme.color.general.layout.one};
      gap: ${theme.spacing.sm}px;

      && > .ant-btn {
        min-width: auto;
        flex: 1;
      }
    `}`}
`;
