import styled from 'styled-components';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import { preserveHorizontalFocusBorder } from '@npm/core/ui/utils/style';

export const Wrapper = styled.div`
  margin-top: -${({ theme }) => theme.spacing.md}px;
`;

export const Button = styled(Text)`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs}px;
  margin-bottom: ${({ theme }) => theme.spacing.xs}px;
`;

export const Footer = styled.div<{ $isCollapsed: boolean }>`
  ${preserveHorizontalFocusBorder}
  max-height: ${({ $isCollapsed }) => ($isCollapsed ? 0 : 1000)}px;
  transition: max-height 0.3s;
`;
