/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DestroySuccess } from '../model';
// @ts-ignore
import { Watchlist } from '../model';
// @ts-ignore
import { WatchlistCreateRequestContract } from '../model';
// @ts-ignore
import { WatchlistIndex } from '../model';
// @ts-ignore
import { WatchlistUpdateRequestContract } from '../model';
/**
 * WatchlistApi - axios parameter creator
 * @export
 */
export const WatchlistApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary creates a watchlist
         * @param {WatchlistCreateRequestContract} watchlistCreateRequestContract 
         * @param {Array<'account' | 'issuer_entity' | 'issuer_entity_sectors' | 'note' | 'user_can_edit_watchlist' | 'user_can_delete_company'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        watchlistCreate: async (watchlistCreateRequestContract: WatchlistCreateRequestContract, includeField?: Array<'account' | 'issuer_entity' | 'issuer_entity_sectors' | 'note' | 'user_can_edit_watchlist' | 'user_can_delete_company'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'watchlistCreateRequestContract' is not null or undefined
            assertParamExists('watchlistCreate', 'watchlistCreateRequestContract', watchlistCreateRequestContract)
            const localVarPath = changeUrl(`/api/watchlists`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (includeField) {
                localVarQueryParameter['include_field'] = includeField.join(COLLECTION_FORMATS.csv);
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(watchlistCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Destroy the watchlist specified in the path
         * @param {number} issuerEntityId 
         * @param {number} [watchlistAccountId] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        watchlistDestroy: async (issuerEntityId: number, watchlistAccountId?: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerEntityId' is not null or undefined
            assertParamExists('watchlistDestroy', 'issuerEntityId', issuerEntityId)
            const localVarPath = changeUrl(`/api/watchlists/{issuer_entity_id}`)
                .replace(`{${"issuer_entity_id"}}`, encodeURIComponent(String(issuerEntityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (watchlistAccountId !== undefined) {
                localVarQueryParameter['watchlist_account_id'] = watchlistAccountId;
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list watchlists accessible to user
         * @param {number} [accountId] 
         * @param {boolean} [assignedAccounts] 
         * @param {number} [issuerEntityId] 
         * @param {string} [createdAfter] 
         * @param {string} [orderBuySell] limits results to watchlists that cover orders with given side
         * @param {string} [orderItemVisibility] limits results to watchlists that cover orders with given visibility
         * @param {Array<'account' | 'issuer_entity' | 'issuer_entity_sectors' | 'note' | 'user_can_edit_watchlist' | 'user_can_delete_company' | 'minimum_size_range' | 'distinct_issuer_entity_count'>} [includeField] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        watchlistIndex: async (accountId?: number, assignedAccounts?: boolean, issuerEntityId?: number, createdAfter?: string, orderBuySell?: string, orderItemVisibility?: string, includeField?: Array<'account' | 'issuer_entity' | 'issuer_entity_sectors' | 'note' | 'user_can_edit_watchlist' | 'user_can_delete_company' | 'minimum_size_range' | 'distinct_issuer_entity_count'>, page?: number, size?: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/watchlists`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId !== undefined) {
                localVarQueryParameter['account_id'] = accountId;
            }

            if (assignedAccounts !== undefined) {
                localVarQueryParameter['assigned_accounts'] = assignedAccounts;
            }

            if (issuerEntityId !== undefined) {
                localVarQueryParameter['issuer_entity_id'] = issuerEntityId;
            }

            if (createdAfter !== undefined) {
                localVarQueryParameter['created_after'] = createdAfter;
            }

            if (orderBuySell !== undefined) {
                localVarQueryParameter['order_buy_sell'] = orderBuySell;
            }

            if (orderItemVisibility !== undefined) {
                localVarQueryParameter['order_item_visibility'] = orderItemVisibility;
            }

            if (includeField) {
                localVarQueryParameter['include_field'] = includeField.join(COLLECTION_FORMATS.csv);
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the watchlist specified in the path
         * @param {number} issuerEntityId 
         * @param {number} [watchlistAccountId] 
         * @param {Array<'account' | 'issuer_entity' | 'issuer_entity_sectors' | 'note' | 'user_can_edit_watchlist' | 'user_can_delete_company'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        watchlistShow: async (issuerEntityId: number, watchlistAccountId?: number, includeField?: Array<'account' | 'issuer_entity' | 'issuer_entity_sectors' | 'note' | 'user_can_edit_watchlist' | 'user_can_delete_company'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerEntityId' is not null or undefined
            assertParamExists('watchlistShow', 'issuerEntityId', issuerEntityId)
            const localVarPath = changeUrl(`/api/watchlists/{issuer_entity_id}`)
                .replace(`{${"issuer_entity_id"}}`, encodeURIComponent(String(issuerEntityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (watchlistAccountId !== undefined) {
                localVarQueryParameter['watchlist_account_id'] = watchlistAccountId;
            }

            if (includeField) {
                localVarQueryParameter['include_field'] = includeField.join(COLLECTION_FORMATS.csv);
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates a watchlist
         * @param {number} issuerEntityId 
         * @param {WatchlistUpdateRequestContract} watchlistUpdateRequestContract 
         * @param {Array<'account' | 'issuer_entity' | 'issuer_entity_sectors' | 'note' | 'user_can_edit_watchlist' | 'user_can_delete_company'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        watchlistUpdate: async (issuerEntityId: number, watchlistUpdateRequestContract: WatchlistUpdateRequestContract, includeField?: Array<'account' | 'issuer_entity' | 'issuer_entity_sectors' | 'note' | 'user_can_edit_watchlist' | 'user_can_delete_company'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerEntityId' is not null or undefined
            assertParamExists('watchlistUpdate', 'issuerEntityId', issuerEntityId)
            // verify required parameter 'watchlistUpdateRequestContract' is not null or undefined
            assertParamExists('watchlistUpdate', 'watchlistUpdateRequestContract', watchlistUpdateRequestContract)
            const localVarPath = changeUrl(`/api/watchlists/{issuer_entity_id}`)
                .replace(`{${"issuer_entity_id"}}`, encodeURIComponent(String(issuerEntityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (includeField) {
                localVarQueryParameter['include_field'] = includeField.join(COLLECTION_FORMATS.csv);
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(watchlistUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WatchlistApi - functional programming interface
 * @export
 */
export const WatchlistApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WatchlistApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary creates a watchlist
         * @param {WatchlistCreateRequestContract} watchlistCreateRequestContract 
         * @param {Array<'account' | 'issuer_entity' | 'issuer_entity_sectors' | 'note' | 'user_can_edit_watchlist' | 'user_can_delete_company'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async watchlistCreate(watchlistCreateRequestContract: WatchlistCreateRequestContract, includeField?: Array<'account' | 'issuer_entity' | 'issuer_entity_sectors' | 'note' | 'user_can_edit_watchlist' | 'user_can_delete_company'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WatchlistIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.watchlistCreate(watchlistCreateRequestContract, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Destroy the watchlist specified in the path
         * @param {number} issuerEntityId 
         * @param {number} [watchlistAccountId] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async watchlistDestroy(issuerEntityId: number, watchlistAccountId?: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DestroySuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.watchlistDestroy(issuerEntityId, watchlistAccountId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list watchlists accessible to user
         * @param {number} [accountId] 
         * @param {boolean} [assignedAccounts] 
         * @param {number} [issuerEntityId] 
         * @param {string} [createdAfter] 
         * @param {string} [orderBuySell] limits results to watchlists that cover orders with given side
         * @param {string} [orderItemVisibility] limits results to watchlists that cover orders with given visibility
         * @param {Array<'account' | 'issuer_entity' | 'issuer_entity_sectors' | 'note' | 'user_can_edit_watchlist' | 'user_can_delete_company' | 'minimum_size_range' | 'distinct_issuer_entity_count'>} [includeField] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async watchlistIndex(accountId?: number, assignedAccounts?: boolean, issuerEntityId?: number, createdAfter?: string, orderBuySell?: string, orderItemVisibility?: string, includeField?: Array<'account' | 'issuer_entity' | 'issuer_entity_sectors' | 'note' | 'user_can_edit_watchlist' | 'user_can_delete_company' | 'minimum_size_range' | 'distinct_issuer_entity_count'>, page?: number, size?: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WatchlistIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.watchlistIndex(accountId, assignedAccounts, issuerEntityId, createdAfter, orderBuySell, orderItemVisibility, includeField, page, size, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the watchlist specified in the path
         * @param {number} issuerEntityId 
         * @param {number} [watchlistAccountId] 
         * @param {Array<'account' | 'issuer_entity' | 'issuer_entity_sectors' | 'note' | 'user_can_edit_watchlist' | 'user_can_delete_company'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async watchlistShow(issuerEntityId: number, watchlistAccountId?: number, includeField?: Array<'account' | 'issuer_entity' | 'issuer_entity_sectors' | 'note' | 'user_can_edit_watchlist' | 'user_can_delete_company'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Watchlist>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.watchlistShow(issuerEntityId, watchlistAccountId, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updates a watchlist
         * @param {number} issuerEntityId 
         * @param {WatchlistUpdateRequestContract} watchlistUpdateRequestContract 
         * @param {Array<'account' | 'issuer_entity' | 'issuer_entity_sectors' | 'note' | 'user_can_edit_watchlist' | 'user_can_delete_company'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async watchlistUpdate(issuerEntityId: number, watchlistUpdateRequestContract: WatchlistUpdateRequestContract, includeField?: Array<'account' | 'issuer_entity' | 'issuer_entity_sectors' | 'note' | 'user_can_edit_watchlist' | 'user_can_delete_company'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Watchlist>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.watchlistUpdate(issuerEntityId, watchlistUpdateRequestContract, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WatchlistApi - factory interface
 * @export
 */
export const WatchlistApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WatchlistApiFp(configuration)
    return {
        /**
         * 
         * @summary creates a watchlist
         * @param {WatchlistCreateRequestContract} watchlistCreateRequestContract 
         * @param {Array<'account' | 'issuer_entity' | 'issuer_entity_sectors' | 'note' | 'user_can_edit_watchlist' | 'user_can_delete_company'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        watchlistCreate(watchlistCreateRequestContract: WatchlistCreateRequestContract, includeField?: Array<'account' | 'issuer_entity' | 'issuer_entity_sectors' | 'note' | 'user_can_edit_watchlist' | 'user_can_delete_company'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<WatchlistIndex> {
            return localVarFp.watchlistCreate(watchlistCreateRequestContract, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Destroy the watchlist specified in the path
         * @param {number} issuerEntityId 
         * @param {number} [watchlistAccountId] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        watchlistDestroy(issuerEntityId: number, watchlistAccountId?: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<DestroySuccess> {
            return localVarFp.watchlistDestroy(issuerEntityId, watchlistAccountId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list watchlists accessible to user
         * @param {number} [accountId] 
         * @param {boolean} [assignedAccounts] 
         * @param {number} [issuerEntityId] 
         * @param {string} [createdAfter] 
         * @param {string} [orderBuySell] limits results to watchlists that cover orders with given side
         * @param {string} [orderItemVisibility] limits results to watchlists that cover orders with given visibility
         * @param {Array<'account' | 'issuer_entity' | 'issuer_entity_sectors' | 'note' | 'user_can_edit_watchlist' | 'user_can_delete_company' | 'minimum_size_range' | 'distinct_issuer_entity_count'>} [includeField] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        watchlistIndex(accountId?: number, assignedAccounts?: boolean, issuerEntityId?: number, createdAfter?: string, orderBuySell?: string, orderItemVisibility?: string, includeField?: Array<'account' | 'issuer_entity' | 'issuer_entity_sectors' | 'note' | 'user_can_edit_watchlist' | 'user_can_delete_company' | 'minimum_size_range' | 'distinct_issuer_entity_count'>, page?: number, size?: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<WatchlistIndex> {
            return localVarFp.watchlistIndex(accountId, assignedAccounts, issuerEntityId, createdAfter, orderBuySell, orderItemVisibility, includeField, page, size, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the watchlist specified in the path
         * @param {number} issuerEntityId 
         * @param {number} [watchlistAccountId] 
         * @param {Array<'account' | 'issuer_entity' | 'issuer_entity_sectors' | 'note' | 'user_can_edit_watchlist' | 'user_can_delete_company'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        watchlistShow(issuerEntityId: number, watchlistAccountId?: number, includeField?: Array<'account' | 'issuer_entity' | 'issuer_entity_sectors' | 'note' | 'user_can_edit_watchlist' | 'user_can_delete_company'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<Watchlist> {
            return localVarFp.watchlistShow(issuerEntityId, watchlistAccountId, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates a watchlist
         * @param {number} issuerEntityId 
         * @param {WatchlistUpdateRequestContract} watchlistUpdateRequestContract 
         * @param {Array<'account' | 'issuer_entity' | 'issuer_entity_sectors' | 'note' | 'user_can_edit_watchlist' | 'user_can_delete_company'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        watchlistUpdate(issuerEntityId: number, watchlistUpdateRequestContract: WatchlistUpdateRequestContract, includeField?: Array<'account' | 'issuer_entity' | 'issuer_entity_sectors' | 'note' | 'user_can_edit_watchlist' | 'user_can_delete_company'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<Watchlist> {
            return localVarFp.watchlistUpdate(issuerEntityId, watchlistUpdateRequestContract, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for watchlistCreate operation in WatchlistApi.
 * @export
 * @interface WatchlistApiWatchlistCreateRequest
 */
export interface WatchlistApiWatchlistCreateRequest {
    /**
     * 
     * @type {WatchlistCreateRequestContract}
     * @memberof WatchlistApiWatchlistCreate
     */
    readonly watchlistCreateRequestContract: WatchlistCreateRequestContract

    /**
     * 
     * @type {Array<'account' | 'issuer_entity' | 'issuer_entity_sectors' | 'note' | 'user_can_edit_watchlist' | 'user_can_delete_company'>}
     * @memberof WatchlistApiWatchlistCreate
     */
    readonly includeField?: Array<'account' | 'issuer_entity' | 'issuer_entity_sectors' | 'note' | 'user_can_edit_watchlist' | 'user_can_delete_company'>

    /**
     * 
     * @type {string}
     * @memberof WatchlistApiWatchlistCreate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof WatchlistApiWatchlistCreate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof WatchlistApiWatchlistCreate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof WatchlistApiWatchlistCreate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof WatchlistApiWatchlistCreate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof WatchlistApiWatchlistCreate
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for watchlistDestroy operation in WatchlistApi.
 * @export
 * @interface WatchlistApiWatchlistDestroyRequest
 */
export interface WatchlistApiWatchlistDestroyRequest {
    /**
     * 
     * @type {number}
     * @memberof WatchlistApiWatchlistDestroy
     */
    readonly issuerEntityId: number

    /**
     * 
     * @type {number}
     * @memberof WatchlistApiWatchlistDestroy
     */
    readonly watchlistAccountId?: number

    /**
     * 
     * @type {string}
     * @memberof WatchlistApiWatchlistDestroy
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof WatchlistApiWatchlistDestroy
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof WatchlistApiWatchlistDestroy
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof WatchlistApiWatchlistDestroy
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof WatchlistApiWatchlistDestroy
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof WatchlistApiWatchlistDestroy
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for watchlistIndex operation in WatchlistApi.
 * @export
 * @interface WatchlistApiWatchlistIndexRequest
 */
export interface WatchlistApiWatchlistIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof WatchlistApiWatchlistIndex
     */
    readonly accountId?: number

    /**
     * 
     * @type {boolean}
     * @memberof WatchlistApiWatchlistIndex
     */
    readonly assignedAccounts?: boolean

    /**
     * 
     * @type {number}
     * @memberof WatchlistApiWatchlistIndex
     */
    readonly issuerEntityId?: number

    /**
     * 
     * @type {string}
     * @memberof WatchlistApiWatchlistIndex
     */
    readonly createdAfter?: string

    /**
     * limits results to watchlists that cover orders with given side
     * @type {string}
     * @memberof WatchlistApiWatchlistIndex
     */
    readonly orderBuySell?: string

    /**
     * limits results to watchlists that cover orders with given visibility
     * @type {string}
     * @memberof WatchlistApiWatchlistIndex
     */
    readonly orderItemVisibility?: string

    /**
     * 
     * @type {Array<'account' | 'issuer_entity' | 'issuer_entity_sectors' | 'note' | 'user_can_edit_watchlist' | 'user_can_delete_company' | 'minimum_size_range' | 'distinct_issuer_entity_count'>}
     * @memberof WatchlistApiWatchlistIndex
     */
    readonly includeField?: Array<'account' | 'issuer_entity' | 'issuer_entity_sectors' | 'note' | 'user_can_edit_watchlist' | 'user_can_delete_company' | 'minimum_size_range' | 'distinct_issuer_entity_count'>

    /**
     * 
     * @type {number}
     * @memberof WatchlistApiWatchlistIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof WatchlistApiWatchlistIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof WatchlistApiWatchlistIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof WatchlistApiWatchlistIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof WatchlistApiWatchlistIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof WatchlistApiWatchlistIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof WatchlistApiWatchlistIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof WatchlistApiWatchlistIndex
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for watchlistShow operation in WatchlistApi.
 * @export
 * @interface WatchlistApiWatchlistShowRequest
 */
export interface WatchlistApiWatchlistShowRequest {
    /**
     * 
     * @type {number}
     * @memberof WatchlistApiWatchlistShow
     */
    readonly issuerEntityId: number

    /**
     * 
     * @type {number}
     * @memberof WatchlistApiWatchlistShow
     */
    readonly watchlistAccountId?: number

    /**
     * 
     * @type {Array<'account' | 'issuer_entity' | 'issuer_entity_sectors' | 'note' | 'user_can_edit_watchlist' | 'user_can_delete_company'>}
     * @memberof WatchlistApiWatchlistShow
     */
    readonly includeField?: Array<'account' | 'issuer_entity' | 'issuer_entity_sectors' | 'note' | 'user_can_edit_watchlist' | 'user_can_delete_company'>

    /**
     * 
     * @type {string}
     * @memberof WatchlistApiWatchlistShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof WatchlistApiWatchlistShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof WatchlistApiWatchlistShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof WatchlistApiWatchlistShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof WatchlistApiWatchlistShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof WatchlistApiWatchlistShow
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for watchlistUpdate operation in WatchlistApi.
 * @export
 * @interface WatchlistApiWatchlistUpdateRequest
 */
export interface WatchlistApiWatchlistUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof WatchlistApiWatchlistUpdate
     */
    readonly issuerEntityId: number

    /**
     * 
     * @type {WatchlistUpdateRequestContract}
     * @memberof WatchlistApiWatchlistUpdate
     */
    readonly watchlistUpdateRequestContract: WatchlistUpdateRequestContract

    /**
     * 
     * @type {Array<'account' | 'issuer_entity' | 'issuer_entity_sectors' | 'note' | 'user_can_edit_watchlist' | 'user_can_delete_company'>}
     * @memberof WatchlistApiWatchlistUpdate
     */
    readonly includeField?: Array<'account' | 'issuer_entity' | 'issuer_entity_sectors' | 'note' | 'user_can_edit_watchlist' | 'user_can_delete_company'>

    /**
     * 
     * @type {string}
     * @memberof WatchlistApiWatchlistUpdate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof WatchlistApiWatchlistUpdate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof WatchlistApiWatchlistUpdate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof WatchlistApiWatchlistUpdate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof WatchlistApiWatchlistUpdate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof WatchlistApiWatchlistUpdate
     */
    readonly xUserRoleId?: string
}

/**
 * WatchlistApi - object-oriented interface
 * @export
 * @class WatchlistApi
 * @extends {BaseAPI}
 */
export class WatchlistApi extends BaseAPI {
    /**
     * 
     * @summary creates a watchlist
     * @param {WatchlistApiWatchlistCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WatchlistApi
     */
    public watchlistCreate(requestParameters: WatchlistApiWatchlistCreateRequest, options?: AxiosRequestConfig) {
        return WatchlistApiFp(this.configuration).watchlistCreate(requestParameters.watchlistCreateRequestContract, requestParameters.includeField, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Destroy the watchlist specified in the path
     * @param {WatchlistApiWatchlistDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WatchlistApi
     */
    public watchlistDestroy(requestParameters: WatchlistApiWatchlistDestroyRequest, options?: AxiosRequestConfig) {
        return WatchlistApiFp(this.configuration).watchlistDestroy(requestParameters.issuerEntityId, requestParameters.watchlistAccountId, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list watchlists accessible to user
     * @param {WatchlistApiWatchlistIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WatchlistApi
     */
    public watchlistIndex(requestParameters: WatchlistApiWatchlistIndexRequest = {}, options?: AxiosRequestConfig) {
        return WatchlistApiFp(this.configuration).watchlistIndex(requestParameters.accountId, requestParameters.assignedAccounts, requestParameters.issuerEntityId, requestParameters.createdAfter, requestParameters.orderBuySell, requestParameters.orderItemVisibility, requestParameters.includeField, requestParameters.page, requestParameters.size, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the watchlist specified in the path
     * @param {WatchlistApiWatchlistShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WatchlistApi
     */
    public watchlistShow(requestParameters: WatchlistApiWatchlistShowRequest, options?: AxiosRequestConfig) {
        return WatchlistApiFp(this.configuration).watchlistShow(requestParameters.issuerEntityId, requestParameters.watchlistAccountId, requestParameters.includeField, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates a watchlist
     * @param {WatchlistApiWatchlistUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WatchlistApi
     */
    public watchlistUpdate(requestParameters: WatchlistApiWatchlistUpdateRequest, options?: AxiosRequestConfig) {
        return WatchlistApiFp(this.configuration).watchlistUpdate(requestParameters.issuerEntityId, requestParameters.watchlistUpdateRequestContract, requestParameters.includeField, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type WatchlistCreateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const WatchlistCreateErrorCodes = [
    401,
];

export type WatchlistDestroyErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const WatchlistDestroyErrorCodes = [
    401,
];

export type WatchlistIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const WatchlistIndexErrorCodes = [
    401,
];

export type WatchlistShowErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const WatchlistShowErrorCodes = [
    401,
];

export type WatchlistUpdateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const WatchlistUpdateErrorCodes = [
    401,
];


