/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Account } from '../model';
// @ts-ignore
import { AccountCreateRequestContract } from '../model';
// @ts-ignore
import { AccountIndex } from '../model';
// @ts-ignore
import { ActionStatusResult } from '../model';
// @ts-ignore
import { InvestorAccountSecurities } from '../model';
/**
 * AccountsApi - axios parameter creator
 * @export
 */
export const AccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary lists all accounts that exist
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {'PersonAccount' | 'OrganizationAccount' | 'AnonymousAccount'} [accountType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountAdminIndex: async (page?: number, size?: number, search?: string, accountType?: 'PersonAccount' | 'OrganizationAccount' | 'AnonymousAccount', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/admin/accounts`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (accountType !== undefined) {
                localVarQueryParameter['account_type'] = accountType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get the account
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountAdminShow: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountAdminShow', 'id', id)
            const localVarPath = changeUrl(`/api/admin/accounts/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a account under the given investor
         * @param {AccountCreateRequestContract} accountCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountCreate: async (accountCreateRequestContract: AccountCreateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountCreateRequestContract' is not null or undefined
            assertParamExists('accountCreate', 'accountCreateRequestContract', accountCreateRequestContract)
            const localVarPath = changeUrl(`/api/investor/accounts`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all accounts
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountIndex: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/investor/accounts`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary lists all securities under an investor account
         * @param {string} accountId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountSecurityAdminIndex: async (accountId: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountSecurityAdminIndex', 'accountId', accountId)
            const localVarPath = changeUrl(`/api/admin/accounts/{account_id}/securities`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get account
         * @param {string} id accounts &#x60;npm_guid&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountShow: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountShow', 'id', id)
            const localVarPath = changeUrl(`/api/investor/accounts/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all accounts with holdings
         * @param {string} issuerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerAccountIndex: async (issuerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('issuerAccountIndex', 'issuerId', issuerId)
            const localVarPath = changeUrl(`/api/admin/issuers/{issuer_id}/accounts`)
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountsApi - functional programming interface
 * @export
 */
export const AccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary lists all accounts that exist
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {'PersonAccount' | 'OrganizationAccount' | 'AnonymousAccount'} [accountType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountAdminIndex(page?: number, size?: number, search?: string, accountType?: 'PersonAccount' | 'OrganizationAccount' | 'AnonymousAccount', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountAdminIndex(page, size, search, accountType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get the account
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountAdminShow(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountAdminShow(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a account under the given investor
         * @param {AccountCreateRequestContract} accountCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountCreate(accountCreateRequestContract: AccountCreateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionStatusResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountCreate(accountCreateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all accounts
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountIndex(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountIndex(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary lists all securities under an investor account
         * @param {string} accountId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountSecurityAdminIndex(accountId: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestorAccountSecurities>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountSecurityAdminIndex(accountId, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get account
         * @param {string} id accounts &#x60;npm_guid&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountShow(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountShow(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all accounts with holdings
         * @param {string} issuerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuerAccountIndex(issuerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuerAccountIndex(issuerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountsApi - factory interface
 * @export
 */
export const AccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountsApiFp(configuration)
    return {
        /**
         * 
         * @summary lists all accounts that exist
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {'PersonAccount' | 'OrganizationAccount' | 'AnonymousAccount'} [accountType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountAdminIndex(page?: number, size?: number, search?: string, accountType?: 'PersonAccount' | 'OrganizationAccount' | 'AnonymousAccount', options?: any): AxiosPromise<AccountIndex> {
            return localVarFp.accountAdminIndex(page, size, search, accountType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get the account
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountAdminShow(id: string, options?: any): AxiosPromise<Account> {
            return localVarFp.accountAdminShow(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a account under the given investor
         * @param {AccountCreateRequestContract} accountCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountCreate(accountCreateRequestContract: AccountCreateRequestContract, options?: any): AxiosPromise<ActionStatusResult> {
            return localVarFp.accountCreate(accountCreateRequestContract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all accounts
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountIndex(page?: number, size?: number, options?: any): AxiosPromise<AccountIndex> {
            return localVarFp.accountIndex(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary lists all securities under an investor account
         * @param {string} accountId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountSecurityAdminIndex(accountId: string, page?: number, size?: number, options?: any): AxiosPromise<InvestorAccountSecurities> {
            return localVarFp.accountSecurityAdminIndex(accountId, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get account
         * @param {string} id accounts &#x60;npm_guid&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountShow(id: string, options?: any): AxiosPromise<Account> {
            return localVarFp.accountShow(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all accounts with holdings
         * @param {string} issuerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerAccountIndex(issuerId: string, options?: any): AxiosPromise<AccountIndex> {
            return localVarFp.issuerAccountIndex(issuerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for accountAdminIndex operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountAdminIndexRequest
 */
export interface AccountsApiAccountAdminIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof AccountsApiAccountAdminIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AccountsApiAccountAdminIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof AccountsApiAccountAdminIndex
     */
    readonly search?: string

    /**
     * 
     * @type {'PersonAccount' | 'OrganizationAccount' | 'AnonymousAccount'}
     * @memberof AccountsApiAccountAdminIndex
     */
    readonly accountType?: 'PersonAccount' | 'OrganizationAccount' | 'AnonymousAccount'
}

/**
 * Request parameters for accountAdminShow operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountAdminShowRequest
 */
export interface AccountsApiAccountAdminShowRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiAccountAdminShow
     */
    readonly id: string
}

/**
 * Request parameters for accountCreate operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountCreateRequest
 */
export interface AccountsApiAccountCreateRequest {
    /**
     * 
     * @type {AccountCreateRequestContract}
     * @memberof AccountsApiAccountCreate
     */
    readonly accountCreateRequestContract: AccountCreateRequestContract
}

/**
 * Request parameters for accountIndex operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountIndexRequest
 */
export interface AccountsApiAccountIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof AccountsApiAccountIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AccountsApiAccountIndex
     */
    readonly size?: number
}

/**
 * Request parameters for accountSecurityAdminIndex operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountSecurityAdminIndexRequest
 */
export interface AccountsApiAccountSecurityAdminIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiAccountSecurityAdminIndex
     */
    readonly accountId: string

    /**
     * 
     * @type {number}
     * @memberof AccountsApiAccountSecurityAdminIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AccountsApiAccountSecurityAdminIndex
     */
    readonly size?: number
}

/**
 * Request parameters for accountShow operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountShowRequest
 */
export interface AccountsApiAccountShowRequest {
    /**
     * accounts &#x60;npm_guid&#x60;
     * @type {string}
     * @memberof AccountsApiAccountShow
     */
    readonly id: string
}

/**
 * Request parameters for issuerAccountIndex operation in AccountsApi.
 * @export
 * @interface AccountsApiIssuerAccountIndexRequest
 */
export interface AccountsApiIssuerAccountIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiIssuerAccountIndex
     */
    readonly issuerId: string
}

/**
 * AccountsApi - object-oriented interface
 * @export
 * @class AccountsApi
 * @extends {BaseAPI}
 */
export class AccountsApi extends BaseAPI {
    /**
     * 
     * @summary lists all accounts that exist
     * @param {AccountsApiAccountAdminIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountAdminIndex(requestParameters: AccountsApiAccountAdminIndexRequest = {}, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountAdminIndex(requestParameters.page, requestParameters.size, requestParameters.search, requestParameters.accountType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get the account
     * @param {AccountsApiAccountAdminShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountAdminShow(requestParameters: AccountsApiAccountAdminShowRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountAdminShow(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a account under the given investor
     * @param {AccountsApiAccountCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountCreate(requestParameters: AccountsApiAccountCreateRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountCreate(requestParameters.accountCreateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all accounts
     * @param {AccountsApiAccountIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountIndex(requestParameters: AccountsApiAccountIndexRequest = {}, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountIndex(requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary lists all securities under an investor account
     * @param {AccountsApiAccountSecurityAdminIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountSecurityAdminIndex(requestParameters: AccountsApiAccountSecurityAdminIndexRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountSecurityAdminIndex(requestParameters.accountId, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get account
     * @param {AccountsApiAccountShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountShow(requestParameters: AccountsApiAccountShowRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountShow(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all accounts with holdings
     * @param {AccountsApiIssuerAccountIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public issuerAccountIndex(requestParameters: AccountsApiIssuerAccountIndexRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).issuerAccountIndex(requestParameters.issuerId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type AccountAdminIndexErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AccountAdminIndexErrorCodes = [
];

export type AccountAdminShowErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AccountAdminShowErrorCodes = [
];

export type AccountCreateErrorTypes =

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AccountCreateErrorCodes = [
    422,
];

export type AccountIndexErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AccountIndexErrorCodes = [
];

export type AccountSecurityAdminIndexErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AccountSecurityAdminIndexErrorCodes = [
];

export type AccountShowErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AccountShowErrorCodes = [
];

export type IssuerAccountIndexErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const IssuerAccountIndexErrorCodes = [
];


