/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  ConfigErrorApi,
  ConfigErrorIndexErrorCodes,
  ConfigErrorIndexErrorTypes,
  ConfigErrorUpdateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new ConfigErrorApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type ConfigErrorIndexMethod = ConfigErrorApi['configErrorIndex'];
export type ConfigErrorIndexResponseType = MethodResult<ConfigErrorIndexMethod>;

export const useConfigErrorIndex = (
  variables: Parameters<ConfigErrorIndexMethod>[0],
  config?: UseQueryConfig<
    ConfigErrorIndexMethod,
    ConfigErrorIndexErrorTypes
  >
) => {
  return useQuery<
    ConfigErrorIndexMethod,
    ConfigErrorIndexErrorTypes
  >(
    (...args) => api.configErrorIndex(...args),
    ConfigErrorIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['ConfigErrorIndex'],
        ...apiQueryConfig.ConfigErrorIndex,
        ...config?.queryConfig
      },
    },
    'ConfigErrorIndex',
  );
};

export const useConfigErrorIndexInfinite = (
  variables: Parameters<ConfigErrorIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    ConfigErrorIndexMethod,
    ConfigErrorIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    ConfigErrorIndexMethod,
    ConfigErrorIndexErrorTypes
  >(
    (...args) => api.configErrorIndex(...args),
    ConfigErrorIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['ConfigErrorIndex', 'ConfigErrorIndexInfinite'],
        ...apiQueryConfig.ConfigErrorIndex,
        ...config?.queryConfig,
      },
    },
    'ConfigErrorIndex',
  );
};

export const useConfigErrorIndexLazy = (baseOptions?: {
  variables?: Parameters<ConfigErrorIndexMethod>[0],
  config?: UseQueryConfig<
    ConfigErrorIndexMethod,
    ConfigErrorIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    ConfigErrorIndexMethod,
    ConfigErrorIndexErrorTypes
  >(
    (...args) => api.configErrorIndex(...args),
    ConfigErrorIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['ConfigErrorIndex'],
        ...apiQueryConfig.ConfigErrorIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'ConfigErrorIndex',
  );
};


export type ConfigErrorUpdateMethod = ConfigErrorApi['configErrorUpdate'];

export const useConfigErrorUpdate = () => {
  return useMutation<ConfigErrorUpdateMethod>(
    (...args) => api.configErrorUpdate(...args),
    ConfigErrorUpdateErrorCodes,
    'ConfigErrorUpdate',
    '/api/config-errors/{id}',
    'put'
  );
};
