import { type CbLabelComponent, type LabelVariant } from '@npm/utils';

import { type CodebookItem, Codebooks } from './codebooks.types';

const Items = {
  frozen: 'frozen',
  funded: 'funded',
  pending: 'pending',
  pending_rofr: 'pending_rofr',
  pending_issuer_review: 'pending_issuer_review',
  ready_to_settle: 'ready_to_settle',
  settled: 'settled',
  signature_in_progress: 'signature_in_progress',
  wired: 'wired',
  rofr_assigned: 'rofr_assigned',
  rofr_waive: 'rofr_waive',
  rofr_blocked: 'rofr_blocked',
  verify_holding: 'verify_holding',
};

type Keys = keyof typeof Items;

const LabelMap: Record<Keys, LabelVariant> = {
  frozen: 'error',
  funded: 'success',
  pending: 'warning',
  pending_rofr: 'warning',
  pending_issuer_review: 'warning',
  ready_to_settle: 'info',
  settled: 'success',
  signature_in_progress: 'warning',
  wired: 'success',
  rofr_assigned: 'info',
  rofr_waive: 'success',
  rofr_blocked: 'error',
  verify_holding: 'warning',
};

const TooltipMap: Record<Keys, string | undefined> = {
  frozen: undefined,
  funded: 'NPM has received the buyer’s funds for settlement.',
  pending:
    'This transfer requires signatures on the Stock Transfer Notice (STN) by the buyer and seller',
  verify_holding:
    'This transfer is pending full holding details or verification. Sellers should provide complete holding details before verification by the company.',
  pending_rofr:
    'This transfer is pending a decision from the company. Click Manage Transfer to indicate a transfer decision.',
  pending_issuer_review:
    'This transfer is under review by the company. You can reference the ROFR Period to estimate a transfer decision date.',
  ready_to_settle:
    'NPM will contact the buyer directly with funding instructions.',
  settled: 'This transfer is pending settlement.',
  signature_in_progress:
    'Agreement signing for this transfer is in progress. All documents requiring your signature are available under the Agreements tab.',
  wired:
    'This transaction has closed. Reference the Agreements tab for applicable transfer documents.',
  rofr_assigned:
    'The company has assigned a new buyer to part or all of this transfer.',
  rofr_waive: 'This transfer has been approved by the company.',
  rofr_blocked: 'This transfer has been blocked by the company.',
};

type AttributeName =
  | 'bro_inv_filter_visibility'
  | 'iss_filter_visibility'
  | 'adm_filter_visibility';

type GetLabelOptions = {
  dashed?: boolean;
  useWarningIcon?: boolean;
};

export const CbMatchState = {
  code: Codebooks.MATCH_STATE,
  items: Items,
  labels: LabelMap,
  getLabel: (
    Component: CbLabelComponent,
    codebookItem: CodebookItem | undefined,
    options?: GetLabelOptions
  ) => {
    const { dashed, useWarningIcon } = options ?? {};

    return Component({
      variant: LabelMap[codebookItem?.code as Keys],
      label: codebookItem?.name,
      dashed,
      iconProps: useWarningIcon ? { name: 'clock' } : undefined,
    });
  },
  getTooltip: (codebookItem: CodebookItem) =>
    TooltipMap[codebookItem?.code as Keys],
  getAttributeValueByName: (item: CodebookItem, name: AttributeName) =>
    item.attributes?.find(q => q.name === name)?.value,
};
