import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Radio } from 'antd';

import { type UserUITheme, CbUserUITheme } from '@npm/data-access';
import { useUserShowCurrent, useUserUpdate } from '@npm/data-access';

import { useDarkModeContext } from '../../../context/DarkModeContext';
import { RadioSwitch } from '../../atoms/RadioSwitch/RadioSwitch';

import * as S from './DarkModeSwitch.styles';

type Props = {
  useUserApi?: boolean;
};

export const DarkModeSwitch = ({ useUserApi = true }: Props) => {
  const { isDarkMode, setTheme, getTheme } = useDarkModeContext();
  const { execute } = useUserUpdate();
  const { authState } = useOktaAuth();
  const localStorageTheme = getTheme();
  const { data } = useUserShowCurrent(
    {},
    {
      queryConfig: {
        enabled: authState?.isAuthenticated && useUserApi,
      },
    }
  );

  const handleThemeChange = async (theme: UserUITheme) => {
    if (localStorageTheme === theme) {
      return;
    }

    setTheme(theme);
    if (authState?.isAuthenticated && useUserApi) {
      try {
        await execute({
          id: Number(data?.id),
          userUpdateRequestContract: {
            user_ui_theme: theme,
          },
        });
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <S.DarkModeSwitcherWrapper>
      <RadioSwitch
        defaultValue={
          isDarkMode ? CbUserUITheme.items.dark : CbUserUITheme.items.light
        }
      >
        <Radio.Button
          onClick={() => {
            handleThemeChange(CbUserUITheme.items.dark);
          }}
          checked={isDarkMode}
          value="dark"
        >
          Dark
        </Radio.Button>
        <Radio.Button
          onClick={() => {
            handleThemeChange(CbUserUITheme.items.light);
          }}
          checked={!isDarkMode}
          value="light"
        >
          Light
        </Radio.Button>
      </RadioSwitch>
    </S.DarkModeSwitcherWrapper>
  );
};
