import { createContext, useContext, useEffect, useState } from 'react';
import pluralize from 'pluralize';

import { Notification } from '@npm/core/ui/components/atoms/Notification';
import {
  type NotificationApiNotificationIndexRequest,
  CbNotificationState,
  queryClient,
  useNotificationIndex,
} from '@npm/data-access';

import { useUserContextStore } from '../../auth/user/context';
import { useAreNotificationsEnabled } from '../hooks';

export const NotificationContext = createContext<number | null>(null);

const NotificationQueryBaseParams = {
  notificationState: CbNotificationState.items.unread,
  page: 1,
  size: 0,
};

const NOTIFICATION_POLLING_INTERVAL = 10000; // in miliseconds

type Props = {
  children?: React.ReactElement;
};

export const NotificationProvider = ({ children }: Props) => {
  const currentUser = useUserContextStore(state => state.user);

  const areNotificationsEnabled = useAreNotificationsEnabled();

  const [lastLoadDate, setLastLoadDate] = useState<Date>(new Date());

  const [variables, setVariables] =
    useState<NotificationApiNotificationIndexRequest>(
      NotificationQueryBaseParams
    );

  // Handle notification count load
  const { data: allNotificationData } = useNotificationIndex(
    NotificationQueryBaseParams,
    {
      queryConfig: {
        enabled: !!currentUser && areNotificationsEnabled,
      },
      onError: error => {
        // ignoring error, as we don't need to handle it
      },
    }
  );

  const updateCreatedAfter = () => {
    setLastLoadDate(new Date());
    setVariables({
      ...variables,
      createdAfter: lastLoadDate ? lastLoadDate.toISOString() : undefined,
    });
  };

  // Handle new notifications
  useNotificationIndex(variables, {
    queryConfig: {
      enabled: !!currentUser && areNotificationsEnabled,
    },
    onError: error => {
      // ignoring error, as we don't need to handle it
    },
    onComplete: data => {
      const notificationCount = data?.pagination?.total_records;
      if (notificationCount) {
        Notification.open({
          type: 'info',
          message: `You have ${notificationCount} new ${pluralize(
            'notification',
            notificationCount,
            false
          )}`,
        });

        // Reload notification badge in the menu. Updated has to be done changed, as we don't want to cause infinite loading
        updateCreatedAfter();
        queryClient.resetQueries(['NotificationIndex']);
      }
    },
  });

  useEffect(() => {
    if (!areNotificationsEnabled) {
      return;
    }

    const intervalId = setInterval(() => {
      updateCreatedAfter();
      // every minute
    }, NOTIFICATION_POLLING_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, [lastLoadDate, variables, areNotificationsEnabled]);

  return (
    <NotificationContext.Provider
      value={allNotificationData?.pagination?.total_records || 0}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const withNotificationContext = <T,>(Component: React.ComponentType) => {
  return (props: T) => (
    <NotificationProvider>
      <Component {...props} />
    </NotificationProvider>
  );
};

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error(
      'useNotificationContext must be used within a NotificationProvider'
    );
  }
  return context;
};
