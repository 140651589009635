import React, { useEffect, useState } from 'react';
import { useWizard } from 'react-use-wizard';

import { Margin } from '@npm/core/ui/components/atoms/common';
import { Form } from '@npm/core/ui/components/atoms/Form';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { Wizard } from '@npm/core/ui/components/organisms/WizardNew';
import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import {
  type StepState,
  type WizardStore,
  WizardStoreActionTypes,
} from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';
import { CypressDataIds } from '@npm/core/ui/constants';
import {
  CbBeneficialOwnershipAssessmentStatus,
  useAccountBeneficialOwnershipAssessmentShow,
  useAccountBeneficialOwnershipAssessmentUpdate,
  useAccountBeneficialOwnersIndex,
} from '@npm/data-access';

import {
  type BeneficialOwnersExemptions,
  Alerts,
  BeneficialOwnershipEdit,
} from '../../../onboardingAndSubmissionSteps/BeneficialOwnership';
import { BeneficialOwnershipReadOnly } from '../../../onboardingAndSubmissionSteps/BeneficialOwnership/ReadOnly';
import { type PostOnboardingContextType } from '../../PostOnboarding.types';
import { StepContainer } from '../../StepContainer';

type Props = {
  stepProps: Wizard.StepProps;
};

export const BeneficialOwnershipPostOnboarding = ({ stepProps }: Props) => {
  const { nextStep } = useWizard();
  const { withShowApiErrorMiddleware } = useAlerts();
  const [form] = Form.useForm();
  const { account, stepState, dispatch } = useWizardStore(
    (s: WizardStore<PostOnboardingContextType>) => ({
      account: s.data.account,
      stepState: s.activeStepState,
      dispatch: s.dispatch,
    })
  );
  const [exemptions, setExemptions] =
    useState<BeneficialOwnersExemptions | null>(null);
  const { data: assessment, isLoading: isAssessmentLoading } =
    useAccountBeneficialOwnershipAssessmentShow({
      id: account.id,
    });
  const { data: owners, isLoading: isOwnersLoading } =
    useAccountBeneficialOwnersIndex({
      accountId: account.id,
    });
  const { execute, isLoading: isSavingAssessment } =
    useAccountBeneficialOwnershipAssessmentUpdate();

  const handleFinish = async (
    values: BeneficialOwnersExemptions,
    finish?: boolean
  ) => {
    try {
      await withShowApiErrorMiddleware(execute)({
        id: account.id,
        accountsBeneficialOwnershipAssessmentUpdateRequestContract: {
          account_id: account.id,
          finalized: finish,
          ...values,
        },
      });

      if (finish) {
        stepProps.handleStepComplete();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields();
      form.submit();
    } catch (e) {
      console.error(e);
    }

    // this part is to handle the case of the "middle step" that this page requires
    // (so if the form object is empty, it should jump to the next step)
    const obj = form.getFieldsValue();
    if (Object.keys(obj).length === 0) {
      nextStep();
    }
  };

  useEffect(() => {
    if (assessment?.entity_exemption) {
      setExemptions({
        entity_exemption: assessment?.entity_exemption?.code,
        owner_exemption: assessment?.owner_exemption?.code,
      });
    }

    if (stepState > 1) return;

    const updateStepState = (nextState: StepState) => {
      dispatch({
        type: WizardStoreActionTypes.UPDATE_ACTIVE_STEP_STATE,
        payload: nextState,
      });
    };

    switch (assessment?.status.code) {
      case CbBeneficialOwnershipAssessmentStatus.items.not_started:
        if (!owners?.pagination.total_records) {
          updateStepState(Wizard.StepState.EDIT);
        }
        break;
      case CbBeneficialOwnershipAssessmentStatus.items.started:
        updateStepState(Wizard.StepState.EDIT);
        break;
      case CbBeneficialOwnershipAssessmentStatus.items.completed:
      case CbBeneficialOwnershipAssessmentStatus.items.finalized:
        updateStepState(Wizard.StepState.FILLED);
        break;
    }
  }, [assessment, owners]);

  return (
    <StepContainer
      handleSubmit={
        stepState === Wizard.StepState.EDIT && exemptions === null
          ? handleSubmit
          : null
      }
      stepProps={stepProps}
      isLoading={isSavingAssessment || isAssessmentLoading}
      isUpdating={isAssessmentLoading || isOwnersLoading}
      data-cy={CypressDataIds.PostOnboarding.BeneficialOwnershipAssessment.Step}
    >
      {stepState === Wizard.StepState.EDIT && (
        <>
          <Margin bottom="xl">
            <Alerts />
          </Margin>
          <BeneficialOwnershipEdit
            completeStep={stepProps.handleStepComplete}
            exemptions={exemptions}
            onFinish={handleFinish}
            form={form}
            accountId={account.id}
            accountName={account.name}
            handleValuesChange={() => stepProps.setFormHasUnsavedChanges(true)}
          />
        </>
      )}
      {stepState === Wizard.StepState.FILLED && (
        <BeneficialOwnershipReadOnly
          accountName={account.name}
          accountId={account.id}
          exemptions={exemptions}
        />
      )}
    </StepContainer>
  );
};
