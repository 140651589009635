import { FormItem } from '@npm/core/ui/components/atoms/FormItem';
import { CollapsibleFormSection } from '@npm/core/ui/components/molecules/CollapsibleFormSection';
import {
  type AffiliateDisclosureType,
  CbAffiliateDisclosure,
  useCodebook,
} from '@npm/data-access';

import { CodebooksSelect } from '../../../../filters';
import { affiliateDisclosureToolTipText } from '../../../utils/tooltips';

type Props = {
  value?: string;
  key: string;
  onItemChange: (value: AffiliateDisclosureType | undefined) => void;
} & Partial<React.ComponentProps<typeof CollapsibleFormSection.Item>>;

export const AffiliateDisclosureFormSection = ({ value, ...props }: Props) => {
  const { data: affiliateDisclosureData } = useCodebook({
    type: CbAffiliateDisclosure.code,
  });

  return (
    <CollapsibleFormSection.Item
      label="Affiliate Disclosure"
      tooltipText={affiliateDisclosureToolTipText}
      preview={getAffiliateDisclosurePreview(
        affiliateDisclosureData?.codebooks ?? [],
        value
      )}
      name={props.key}
      {...props}
    >
      <FormItem name="affiliateDisclosure" marginBottom="sm">
        <CodebooksSelect
          codebook={CbAffiliateDisclosure.code}
          allowClear={false}
        />
      </FormItem>
    </CollapsibleFormSection.Item>
  );
};

export const getAffiliateDisclosurePreview = (
  options: { code?: string; name?: string }[],
  val?: string
) => options.find(i => i.code === val)?.name;
