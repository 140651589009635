export const DrawerEnum = {
  ROLE_SWITCHER: 'ROLE_SWITCHER',
  OBO_SELECTION: 'OBO_SELECTION',
  ACCOUNT_UPDATE: 'ACCOUNT_UPDATE',
  CONTACT_UPDATE: 'CONTACT_UPDATE',
} as const;

export type DrawerKey = typeof DrawerEnum[keyof typeof DrawerEnum];

export interface ActiveDrawerStore {
  activeDrawerKey: DrawerKey | undefined;
  openDrawer: (drawerKey: DrawerKey) => void;
  closeDrawer: () => void;
  toggleDrawer: (drawerKey: DrawerKey) => void;
  isOpen: (drawerKey: DrawerKey) => boolean;
}
