import React, { useEffect } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { Card } from '@npm/core/ui/components/molecules/Card';
import {
  type NegotiationIndex,
  type OrderBuySellCode,
  type SecondmarketInvestorOrderItemShow,
} from '@npm/data-access';

import { getOpportunityLabels } from '../../../../utils/getOpportunityLabels';
import { shouldShowBuySellMoreCta } from '../../../../utils/shouldShowBuySellMoreCta';

import { OpportunityItem } from './components/MyOpportunityItem/OpportunityItem';
import { useMyOpportunitiesLayout } from './hooks/useMyOpportunitiesLayout';

import * as S from './MyOpportunities.styles';

export const OPPORTUNITY_ID_QUERY_KEY = 'opportunityId';

type Props = {
  negotiations: NegotiationIndex['negotiations'];
  handleOpportunitySelect: (id: number) => void;
  activeOpportunityId: number;
  orderSide: OrderBuySellCode;
  buySellMoreOrderItem: SecondmarketInvestorOrderItemShow;
  isMyOrder?: boolean;
  setIsBidDrawerOpen?: (isOpen: boolean) => void;
};

export const MyOpportunities = (props: Props) => {
  const {
    negotiations,
    handleOpportunitySelect,
    orderSide,
    isMyOrder,
    setIsBidDrawerOpen,
    buySellMoreOrderItem,
    activeOpportunityId,
  } = props;

  const [queryParams, setQueryParams] = useQueryParams({
    [OPPORTUNITY_ID_QUERY_KEY]: StringParam,
  });

  const { useMobileLayout } = useMyOpportunitiesLayout();
  const labels = getOpportunityLabels(orderSide, isMyOrder);

  const sellSharesButton = (
    <Button
      icon={<Icon name="arrows-opposite" />}
      color="error"
      onClick={() => {
        typeof setIsBidDrawerOpen === 'function' && setIsBidDrawerOpen(true);
      }}
    >
      Sell Shares
    </Button>
  );

  const buySharesButton = (
    <Button
      icon={<Icon name="arrows-opposite" />}
      color="success"
      onClick={() =>
        typeof setIsBidDrawerOpen === 'function' && setIsBidDrawerOpen(true)
      }
    >
      Buy Shares
    </Button>
  );

  const handleSelectOpportunity = (id: number) => {
    handleOpportunitySelect(id);
    setQueryParams({ [OPPORTUNITY_ID_QUERY_KEY]: id.toString() });
  };

  const canShowNegotiationCta = shouldShowBuySellMoreCta({
    negotiations,
    orderItem: buySellMoreOrderItem,
    isMyOrder,
  });

  useEffect(() => {
    if (queryParams?.opportunityId) {
      handleSelectOpportunity(Number(queryParams.opportunityId));
    }
  }, [queryParams?.opportunityId]);

  if (useMobileLayout) {
    return (
      <S.MobileCarousel>
        {negotiations?.map(negotiation => (
          <OpportunityItem
            negotiation={negotiation}
            key={negotiation?.id}
            handleOpportunitySelect={handleSelectOpportunity}
            activeOpportunityId={activeOpportunityId}
            isMyOrder={isMyOrder}
            orderSide={orderSide}
            fullWidth={negotiations?.length === 1}
          />
        ))}
      </S.MobileCarousel>
    );
  }

  return (
    <Card
      header={{
        title: (
          <Text size="default" weight="bold">
            {labels?.sidebar?.opportunitesLabel}
          </Text>
        ),
      }}
      expandable={true}
    >
      <Flex direction="column" gap="md">
        {negotiations?.map(negotiation => (
          <OpportunityItem
            negotiation={negotiation}
            key={negotiation?.id}
            handleOpportunitySelect={handleSelectOpportunity}
            activeOpportunityId={activeOpportunityId}
            isMyOrder={isMyOrder}
            orderSide={orderSide}
          />
        ))}

        {canShowNegotiationCta &&
          (isMyOrder
            ? orderSide === 'sell'
              ? sellSharesButton
              : buySharesButton
            : orderSide === 'sell'
            ? buySharesButton
            : sellSharesButton)}
      </Flex>
    </Card>
  );
};
