import { useCallback, useEffect, useRef } from 'react';
import {
  type UrlUpdateType,
  NumberParam,
  useQueryParams,
} from 'use-query-params';

import { Wizard } from '@npm/core/ui/components/organisms/Wizard';
import { usePreviousDistinct } from '@npm/utils';

import { EDITABLE_ONBOARDING_STEPS } from '../PostOnboarding.constants';
import { type PostOnboardingContext } from '../PostOnboarding.types';
import { getCurrentStepState } from '../PostOnboarding.utils';

const { PageState } = Wizard;

export const usePageState = (
  stepProps?: Wizard.StepProps<PostOnboardingContext>
) => {
  const { context, step } = stepProps || {};
  const [{ pageState, personId }, setQueryParams] = useQueryParams({
    pageState: NumberParam,
    personId: NumberParam,
  });
  const previousPageState = usePreviousDistinct<Wizard.PageState>(pageState);
  const isStateSetOnInitialLoad = useRef(false);

  const updatePageState = useCallback(
    (state: Wizard.PageState, urlUpdateType?: UrlUpdateType) => {
      if (!stepProps) return;
      const newState = state === PageState.SUCCESS ? PageState.FILLED : state;
      setQueryParams({ pageState: newState }, urlUpdateType);
    },
    [stepProps]
  );

  // determine page state based on GET /api/accounts/{id}/onboarding-status
  // and step in query params
  const currentStepState = getCurrentStepState(
    step?.key,
    context?.onboardingStatus
  );

  useEffect(() => {
    if (!pageState && currentStepState && !isStateSetOnInitialLoad.current) {
      updatePageState(currentStepState, 'replaceIn');
      isStateSetOnInitialLoad.current = true;
    }
  }, [pageState, currentStepState]);

  // prevent reaching prohibited states
  useEffect(() => {
    if (!currentStepState) return;

    // trying to reach "Get Started" state, but step is already filled
    // -> go to "Read Only" state
    if (
      currentStepState !== PageState.INITIAL &&
      pageState === PageState.INITIAL
    ) {
      updatePageState(PageState.FILLED, 'replaceIn');
    }

    // trying to reach "Edit" state, but step is already filled and NOT editable
    // -> go to "Read Only" state (with exception for representatives section - personId present as query param)
    if (
      pageState === PageState.EDIT &&
      currentStepState === PageState.FILLED &&
      EDITABLE_ONBOARDING_STEPS.indexOf(step?.key) === -1 &&
      !personId
    ) {
      updatePageState(PageState.FILLED, 'replaceIn');
    }

    // trying to reach "Read Only" state, but step is incomplete
    // -> go to "Get Started" state
    if (
      currentStepState !== PageState.FILLED &&
      pageState === PageState.FILLED
    ) {
      updatePageState(PageState.INITIAL, 'replaceIn');
    }

    if (pageState === PageState.SUCCESS) {
      updatePageState(currentStepState, 'replaceIn');
    }
  }, [pageState, currentStepState, updatePageState]);

  // handler to close form drawer on mobile
  const closeMobileEditState = (toggleSidebarOpen: () => void) => {
    if (!previousPageState) {
      toggleSidebarOpen();
    } else {
      updatePageState(previousPageState);
    }
  };

  return [
    pageState || PageState.NONE,
    updatePageState,
    closeMobileEditState,
  ] as const;
};
