import { type TooltipProps as AntdTooltipProps } from 'antd';

export type TooltipSize = 'sm' | 'md' | 'lg';

export type TooltipProps = AntdTooltipProps & {
  size?: TooltipSize;
  disabled?: boolean;
};

export enum TooltipClassName {
  SM = 'tooltip-sm',
  MD = 'tooltip-md',
  LG = 'tooltip-lg',
}
