import React, { type ComponentProps } from 'react';

import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { Wizard } from '@npm/core/ui/components/organisms/WizardNew';
import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import { type WizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';

import {
  GovernmentIDForm,
  GovernmentIdReadOnly,
  useGovernmentIdForm,
} from '../../../onboardingAndSubmissionSteps/GovernmentIssuedId';
import { useGetPostOnboardingPersonId } from '../../hooks';
import { type PostOnboardingContextType } from '../../PostOnboarding.types';
import { StepContainer } from '../../StepContainer';
import { type WarningStateAlert } from '../../WarningStateAlert';

const { StepState } = Wizard;

type Props = {
  stepProps: Wizard.StepProps;
} & Pick<ComponentProps<typeof WarningStateAlert>, 'zendeskTicketGroupId'>;

export const GovernmentIDPostOnboarding = ({
  stepProps,
  zendeskTicketGroupId,
}: Props) => {
  const getPersonId = useGetPostOnboardingPersonId();
  const { account, stepState } = useWizardStore(
    (s: WizardStore<PostOnboardingContextType>) => ({
      account: s.data.account,
      stepState: s.activeStepState,
    })
  );

  const { form, data, isLoading, isCreating, handleSubmit, subregions } =
    useGovernmentIdForm(getPersonId(account), {
      onCreate: () => {
        Notification.success({
          message: 'You have updated your Representative Government Issued ID',
        });
        stepProps.handleStepComplete();
      },
    });

  return (
    <StepContainer
      stepProps={stepProps}
      isLoading={isLoading}
      isUpdating={isCreating}
      handleSubmit={handleSubmit}
      zendeskTicketGroupId={zendeskTicketGroupId}
    >
      {stepState === StepState.EDIT && (
        <GovernmentIDForm
          form={form}
          subregions={subregions?.codebooks}
          handleValuesChange={() => stepProps.setFormHasUnsavedChanges(true)}
        />
      )}
      {stepState === StepState.FILLED && <GovernmentIdReadOnly data={data} />}
    </StepContainer>
  );
};
