/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  S2SInvestorHoldingsApi,
  S2SGetSecurityErrorCodes,
  S2SGetSecurityErrorTypes,
  S2SUpdateSecurityFromHoldingErrorCodes,
  S2SDestroySecurityErrorCodes,
  S2SCreateSecurityFromSMHoldingErrorCodes,
  S2SUpdateSecurityFromSmHoldingErrorCodes,
  S2SInvestorHoldingsIndexErrorCodes,
  S2SInvestorHoldingsIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new S2SInvestorHoldingsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type S2SGetSecurityMethod = S2SInvestorHoldingsApi['s2SGetSecurity'];
export type S2SGetSecurityResponseType = MethodResult<S2SGetSecurityMethod>;

export const useS2SGetSecurity = (
  variables: Parameters<S2SGetSecurityMethod>[0],
  config?: UseQueryConfig<
    S2SGetSecurityMethod,
    S2SGetSecurityErrorTypes
  >
) => {
  return useQuery<
    S2SGetSecurityMethod,
    S2SGetSecurityErrorTypes
  >(
    (...args) => api.s2SGetSecurity(...args),
    S2SGetSecurityErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['S2SGetSecurity'],
        ...apiQueryConfig.S2SGetSecurity,
        ...config?.queryConfig
      },
    },
    'S2SGetSecurity',
  );
};

export const useS2SGetSecurityInfinite = (
  variables: Parameters<S2SGetSecurityMethod>[0],
  config?: UseInfiniteQueryConfig<
    S2SGetSecurityMethod,
    S2SGetSecurityErrorTypes
  >
) => {
  return useInfiniteQuery<
    S2SGetSecurityMethod,
    S2SGetSecurityErrorTypes
  >(
    (...args) => api.s2SGetSecurity(...args),
    S2SGetSecurityErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['S2SGetSecurity', 'S2SGetSecurityInfinite'],
        ...apiQueryConfig.S2SGetSecurity,
        ...config?.queryConfig,
      },
    },
    'S2SGetSecurity',
  );
};

export const useS2SGetSecurityLazy = (baseOptions?: {
  variables?: Parameters<S2SGetSecurityMethod>[0],
  config?: UseQueryConfig<
    S2SGetSecurityMethod,
    S2SGetSecurityErrorTypes
  >
}) => {
  return useLazyQuery<
    S2SGetSecurityMethod,
    S2SGetSecurityErrorTypes
  >(
    (...args) => api.s2SGetSecurity(...args),
    S2SGetSecurityErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['S2SGetSecurity'],
        ...apiQueryConfig.S2SGetSecurity,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'S2SGetSecurity',
  );
};


export type S2SUpdateSecurityFromHoldingMethod = S2SInvestorHoldingsApi['s2SUpdateSecurityFromHolding'];

export const useS2SUpdateSecurityFromHolding = () => {
  return useMutation<S2SUpdateSecurityFromHoldingMethod>(
    (...args) => api.s2SUpdateSecurityFromHolding(...args),
    S2SUpdateSecurityFromHoldingErrorCodes,
    'S2SUpdateSecurityFromHolding',
    '/api/s2s/securities/{npm_guid}',
    'patch'
  );
};

export type S2SDestroySecurityMethod = S2SInvestorHoldingsApi['s2SDestroySecurity'];

export const useS2SDestroySecurity = () => {
  return useMutation<S2SDestroySecurityMethod>(
    (...args) => api.s2SDestroySecurity(...args),
    S2SDestroySecurityErrorCodes,
    'S2SDestroySecurity',
    '/api/s2s/securities/{npm_guid}',
    'delete'
  );
};

export type S2SCreateSecurityFromSMHoldingMethod = S2SInvestorHoldingsApi['s2SCreateSecurityFromSMHolding'];

export const useS2SCreateSecurityFromSMHolding = () => {
  return useMutation<S2SCreateSecurityFromSMHoldingMethod>(
    (...args) => api.s2SCreateSecurityFromSMHolding(...args),
    S2SCreateSecurityFromSMHoldingErrorCodes,
    'S2SCreateSecurityFromSMHolding',
    '/api/s2s/create_security_from_sm_holding',
    'post'
  );
};

export type S2SUpdateSecurityFromSmHoldingMethod = S2SInvestorHoldingsApi['s2SUpdateSecurityFromSmHolding'];

export const useS2SUpdateSecurityFromSmHolding = () => {
  return useMutation<S2SUpdateSecurityFromSmHoldingMethod>(
    (...args) => api.s2SUpdateSecurityFromSmHolding(...args),
    S2SUpdateSecurityFromSmHoldingErrorCodes,
    'S2SUpdateSecurityFromSmHolding',
    '/api/s2s/update_security_from_sm_holding',
    'patch'
  );
};

export type S2SInvestorHoldingsIndexMethod = S2SInvestorHoldingsApi['s2SInvestorHoldingsIndex'];
export type S2SInvestorHoldingsIndexResponseType = MethodResult<S2SInvestorHoldingsIndexMethod>;

export const useS2SInvestorHoldingsIndex = (
  variables: Parameters<S2SInvestorHoldingsIndexMethod>[0],
  config?: UseQueryConfig<
    S2SInvestorHoldingsIndexMethod,
    S2SInvestorHoldingsIndexErrorTypes
  >
) => {
  return useQuery<
    S2SInvestorHoldingsIndexMethod,
    S2SInvestorHoldingsIndexErrorTypes
  >(
    (...args) => api.s2SInvestorHoldingsIndex(...args),
    S2SInvestorHoldingsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['S2SInvestorHoldingsIndex'],
        ...apiQueryConfig.S2SInvestorHoldingsIndex,
        ...config?.queryConfig
      },
    },
    'S2SInvestorHoldingsIndex',
  );
};

export const useS2SInvestorHoldingsIndexInfinite = (
  variables: Parameters<S2SInvestorHoldingsIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    S2SInvestorHoldingsIndexMethod,
    S2SInvestorHoldingsIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    S2SInvestorHoldingsIndexMethod,
    S2SInvestorHoldingsIndexErrorTypes
  >(
    (...args) => api.s2SInvestorHoldingsIndex(...args),
    S2SInvestorHoldingsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['S2SInvestorHoldingsIndex', 'S2SInvestorHoldingsIndexInfinite'],
        ...apiQueryConfig.S2SInvestorHoldingsIndex,
        ...config?.queryConfig,
      },
    },
    'S2SInvestorHoldingsIndex',
  );
};

export const useS2SInvestorHoldingsIndexLazy = (baseOptions?: {
  variables?: Parameters<S2SInvestorHoldingsIndexMethod>[0],
  config?: UseQueryConfig<
    S2SInvestorHoldingsIndexMethod,
    S2SInvestorHoldingsIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    S2SInvestorHoldingsIndexMethod,
    S2SInvestorHoldingsIndexErrorTypes
  >(
    (...args) => api.s2SInvestorHoldingsIndex(...args),
    S2SInvestorHoldingsIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['S2SInvestorHoldingsIndex'],
        ...apiQueryConfig.S2SInvestorHoldingsIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'S2SInvestorHoldingsIndex',
  );
};

