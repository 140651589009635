import { useCallback, useEffect, useMemo } from 'react';
import { isMatch } from 'lodash';

import { Form } from '@npm/core/ui/components/atoms/Form';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { handleValidationError } from '@npm/core/ui/utils/form';
import { type Person, usePersonShow, usePersonUpdate } from '@npm/data-access';

import { useUserContextStore } from '../../../auth/user/context';
import { getContactInformationInitialValues } from '../ContactInfoForm/ContactInformationForm.utils';

import { type IndividualInfoFormType } from './IndividualInformation.types';

export const useIndividualInformationForm = (
  id: number | undefined,
  {
    onUpdate,
  }: {
    onUpdate?: (person: Person) => void;
  }
) => {
  const [form] = Form.useForm<IndividualInfoFormType>();
  const firstName = Form.useWatch('first', form);
  const lastName = Form.useWatch('last', form);

  const { data, isLoading } = usePersonShow(
    { id },
    {
      queryConfig: {
        enabled: !!id,
      },
    }
  );

  const { withShowApiErrorMiddleware } = useAlerts();
  const { execute: updatePerson, isLoading: isUpdating } = usePersonUpdate();

  const user = useUserContextStore(store => store.user);

  const initialValues = useMemo(() => {
    if (!data) {
      return {};
    }
    return {
      ...getContactInformationInitialValues(data?.contact_information),
      first: data?.first ?? user.person.first,
      middle: data?.middle ?? user.person.middle,
      last: data?.last ?? user.person.last,
    };
  }, [data, user.person.first, user.person.last, user.person.middle]);

  const handleSubmit = useCallback(async () => {
    let values: IndividualInfoFormType;

    try {
      values = await form.validateFields();

      if (isMatch(initialValues, values)) {
        onUpdate?.(data);
        return;
      }
    } catch (errorInfo) {
      handleValidationError(form, errorInfo);
      return;
    }

    try {
      const person = await withShowApiErrorMiddleware(updatePerson)(
        {
          id: id!,
          personUpdateRequestContract: {
            ...values,
            contact_information: {
              id: data?.contact_information?.id,
              address_line_1: values.address_line_1,
              address_line_2: values.address_line_2,
              city: values.city,
              state: values.state,
              zip: values.zip,
              country: values.country,
              phone: values?.phone,
            },
          },
        },
        {
          nullValuesHandling: 'KEEP_EMPTY_STRINGS',
        }
      );

      onUpdate?.(person);
    } catch (e) {
      console.error(e);
    }
  }, [
    data?.contact_information?.id,
    form,
    id,
    user,
    updatePerson,
    onUpdate,
    initialValues,
  ]);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  return {
    form,
    data,
    isLoading,
    isUpdating,
    noFirstAndLastName: firstName === undefined && lastName === undefined,
    handleSubmit,
  };
};
