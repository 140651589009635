import { type QueryObserverOptions } from '@tanstack/react-query';

import { type MutationKey, type QueryKey } from '../generated';

import { handleAddPortfolioHoldingAction } from './datadog';

export const apiQueryConfig: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key in QueryKey]?: QueryObserverOptions<any, any>;
} = {
  Codebook: { cacheTime: Infinity, staleTime: Infinity },
};

/* Configuration for mutations
   Properties:
    - invalidateQueries: list of QueryKeys that has to be invalidated after successful mutation execution
*/
export const apiMutationConfig: {
  [key in MutationKey]?: {
    invalidateQueries: QueryKey[];
    handleDatadogAction?: (payload: unknown, res: unknown) => void;
  };
} = {
  AssetMarkCreate: {
    invalidateQueries: ['AssetMarkIndex', 'PortfolioStats'],
  },
  AssetMarkDelete: {
    invalidateQueries: ['AssetMarkIndex', 'PortfolioStats'],
  },
  AssetMarkUpdate: {
    invalidateQueries: ['AssetMarkIndex', 'PortfolioStats'],
  },
  StockClassesCreate: {
    invalidateQueries: ['StockClassesIndex'],
  },
  InvestorMarkCreate: {
    invalidateQueries: ['PortfolioCompanyPlot', 'PortfolioStats'],
  },
  InvestorMarkDelete: {
    invalidateQueries: ['PortfolioCompanyPlot', 'PortfolioStats'],
  },
  InvestorMarkUpdate: {
    invalidateQueries: ['PortfolioCompanyPlot', 'PortfolioStats'],
  },
  SecurityCreate: {
    invalidateQueries: [
      'PortfolioCompanyPlot',
      'PortfolioStats',
      'AssetMarkIndex',
    ],
    handleDatadogAction: handleAddPortfolioHoldingAction,
  },
  SecurityDestroy: {
    invalidateQueries: ['PortfolioCompanyPlot'],
  },
  AccountOpen: {
    invalidateQueries: ['AccountList'],
  },
};
