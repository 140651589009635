import React from 'react';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { Link } from '@npm/core/ui/components/molecules/Link';
import { type AccountShowAggregate } from '@npm/data-access';

import { useCurrentWorkstation } from '../../../auth/user/role';
import { useCurrentRole } from '../../../auth/user/role/hooks/useCurrentRole';

type Props = {
  account: AccountShowAggregate;
};

export const SpousalInformationUpdateAlert = ({ account }: Props) => {
  const workstation = useCurrentWorkstation();
  const role = useCurrentRole();
  const isInvestorWS = workstation?.type?.code === 'investor';

  const getLinkPath = () => {
    const liveProgamsPath = '/investor-workstation/programs/live-programs';

    if (isInvestorWS) {
      return liveProgamsPath;
    }

    return `${liveProgamsPath}?allAccounts=0&roleId=${role.id}&oboAccountId=${account.id}&oboPersonId=${account.person_id}&oboUserId=${account.user?.id}`;
  };

  return (
    <Alert
      message={
        <Text>
          {isInvestorWS ? 'Your' : 'This'} account is invited to a live
          issuer-sponsored program, to update the spousal information please do
          so through the submission workflow.{' '}
          <Link to={getLinkPath()}>
            Click here to see {isInvestorWS ? 'your' : "this account's"} live
            programs.
          </Link>
        </Text>
      }
    />
  );
};
