/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



  /**
 * 
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'master_account_id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Account
     */
    'sub_account_ids'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'account_type'?: AccountAccountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'sm_created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'sm_external_id'?: string;
}

export const AccountAccountTypeEnum = {
    PersonAccount: 'PersonAccount',
    OrganizationAccount: 'OrganizationAccount',
    AnonymousAccount: 'AnonymousAccount'
} as const;

export type AccountAccountTypeEnum = typeof AccountAccountTypeEnum[keyof typeof AccountAccountTypeEnum];


