import React from 'react';

import { Icon } from '@npm/core/ui/components/atoms/Icon';
import {
  type MenuItem,
  type MenuItemGroup,
} from '@npm/core/ui/components/organisms/Layout';
import { type PermissionContext, RBAC } from '@npm/core/ui/models/permissions';
import { type ActionCounter } from '@npm/data-access';
import {
  type UserContextStore,
  filterMenuItems,
} from '@npm/features/auth/user/context';
import type { UserRoleContextType } from '@npm/features/auth/user/role/userRole.types';

export const workstationPrefix = 'investor-workstation';
export const postOnboardingPrefix = 'post-onboarding';

const {
  isInvMenuAgreements,
  isInvMenuOnboarding,
  isInvMenuOther,
  isInvAobo,
  isUserNotAnonymous,
  isInvNotAobo,
  isInvMenuNda,
  isEntityNpmsInvestor,
  isIndividualNpmsInvestor,
  canOptIn,
} = RBAC.investor;

export const getInvestorSidebarItems: (
  userContext: UserContextStore,
  userRole: UserRoleContextType | null,
  obo: PermissionContext['obo'] | null,
  counterData: ActionCounter,
  notificationMenuItem?: MenuItemGroup
) => (MenuItem | MenuItemGroup)[] = (
  userContext,
  userRole,
  obo,
  counterData,
  notificationMenuItem
) => {
  return filterMenuItems(
    [
      {
        label: 'SecondMarket',
        key: `/${workstationPrefix}/entity-dashboard`,
        icon: <Icon name="arrows-opposite" />,
        permissionsChecks: [isInvMenuOther, isInvNotAobo, isEntityNpmsInvestor],
      },
      ...(notificationMenuItem ? [notificationMenuItem] : []),
      {
        label: 'SecondMarket',
        key: '/second-market',
        icon: <Icon name="arrows-opposite" />,
        permissionsChecks: [isInvAobo],
      },
      {
        label: 'SecondMarket',
        key: '/second-market/investor',
        icon: <Icon name="arrows-opposite" />,
        permissionsChecks: [
          isInvMenuOther,
          isInvNotAobo,
          isIndividualNpmsInvestor,
        ],
      },
      {
        label: 'SecondMarket',
        key: '/second-market/investor/opt-in',
        icon: <Icon name="arrows-opposite" />,
        permissionsChecks: [isInvMenuOther, isInvNotAobo, canOptIn],
      },
      {
        label: 'My Watchlist',
        key: `/${workstationPrefix}/my-watchlist`,
        icon: <Icon name="star" />,
        permissionsChecks: [isInvMenuOther, isEntityNpmsInvestor],
      },
      {
        label: 'Portfolio',
        key: `/${workstationPrefix}/portfolio`,
        icon: <Icon name="chart-pie" />,
        permissionsChecks: [isInvMenuOther, isInvNotAobo, isEntityNpmsInvestor],
      },
      {
        // Holdings for NPMS individuals
        label: 'Holdings',
        key: `/${workstationPrefix}/account/holdings`,
        icon: <Icon name="chart-pie" />,
        permissionsChecks: [isInvMenuOther, isIndividualNpmsInvestor],
      },
      {
        label: 'Invited Programs',
        key: 'programs',
        icon: <Icon name="table" />,
        children: [
          {
            label: 'Live Programs',
            key: `/${workstationPrefix}/programs/live-programs`,
            permissionsChecks: [isInvMenuOther],
            actionItemsCount: counterData?.active_programs,
          },
          {
            label: 'Data Rooms',
            key: `/${workstationPrefix}/programs/data-rooms`,
            permissionsChecks: [isInvMenuOther],
          },
          {
            label: 'NDAs',
            key: `/${workstationPrefix}/programs/ndas`,
            permissionsChecks: [isInvMenuNda],
            actionItemsCount: counterData?.unsigned_ndas,
          },
          {
            label: 'Submissions',
            key: `/${workstationPrefix}/programs/submissions`,
            permissionsChecks: [isInvMenuOther],
          },
        ],
      },
      {
        label: 'General',
        key: 'account',
        icon: <Icon name="building-bank" size="xs" />,
        children: [
          {
            label: 'Matches',
            key: `/${workstationPrefix}/matches`,
            permissionsChecks: [isInvMenuOther],
          },
          {
            label: 'Agreements',
            key: `/${workstationPrefix}/account/agreements`,
            permissionsChecks: [isInvMenuAgreements],
            actionItemsCount: counterData?.unsigned_agreements,
          },
          {
            label: 'Account Information',
            key: `/${workstationPrefix}/account/detail`,
            permissionsChecks: [isInvAobo, isInvMenuOther],
          },
          {
            label: 'Onboarding',
            key: `/${postOnboardingPrefix}`,
            permissionsChecks: [
              isInvAobo,
              isInvMenuOnboarding,
              isUserNotAnonymous,
            ],
          },
        ],
      },
    ],
    userContext,
    userRole,
    obo
  );
};
