import { DatadogService } from '@npm/utils';

import { type CapTableApi } from '../..';

export const handleAddPortfolioHoldingAction = (
  _payload: CapTableApi.SecuritiesApiSecurityCreateRequest,
  res: CapTableApi.SecuritySimple
) => {
  DatadogService.addAction('Add Portfolio Holding', {
    portfolio_asset_type: res.security_type?.name,
    holding_npm_guid: res.npm_guid,
  });
};
