import React from 'react';
import { type ModalProps } from 'antd/lib/modal';

import { Margin } from '@npm/core/ui/components/atoms/common';
import { Form } from '@npm/core/ui/components/atoms/Form';
import { Heading } from '@npm/core/ui/components/atoms/Typography';
import {
  useConfirmOnLeave,
  useLocation,
} from '@npm/core/ui/components/molecules/Link';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { DiscardModal } from '@npm/core/ui/components/organisms/DiscardModal';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { useAccountRepresentativeIndexLazy } from '@npm/data-access';

import {
  type HandleCreateProps,
  useUserRoleActions,
} from '../../../../../../users/CreateNewUser';
import { UserRolesFormItem } from '../../../../../../users/CreateNewUser/Form/UserRolesFormItem';
import { PersonInfoForm } from '../../../../../../users/CreateNewUser/PersonInfoForm';
import { setObo } from '../../../../role/context/userRole.helpers';
import { type OboModalState } from '../../OboModal.types';

import { FooterButtons } from './FooterButtons';

import * as S from '../CreateNewAccount/CreateNewAccountModal.styles';

type Props = {
  account: OboModalState['account'];
  onSuccess?: () => void;
} & ModalProps;

export const CreateRepresentativeModal = ({
  account,
  open,
  onCancel,
  onSuccess,
}: Props) => {
  const { isMobile } = useBreakpoints();
  const { pathname } = useLocation();
  const [form] = Form.useForm<HandleCreateProps>();

  const [fetchRepresentative, { isLoading: isRepresentativeLoading }] =
    useAccountRepresentativeIndexLazy();
  const { handleCreate, isLoading } = useUserRoleActions({
    accountId: account?.id,
  });

  const { setHasUnsavedChanges, onCloseAttempt, discardModalProps } =
    useConfirmOnLeave();

  const handleCloseConfirm = () => {
    onCancel(null);
    form.resetFields();
    setHasUnsavedChanges(false);
  };

  const handleComplete = async () => {
    let values: HandleCreateProps;

    try {
      values = await form.validateFields();
    } catch (err) {
      console.error(err);
    }

    try {
      await handleCreate(values);

      setHasUnsavedChanges(false); // Unblock navigation

      const res = await fetchRepresentative({
        variables: {
          accountId: account.id,
        },
      });

      setObo(
        {
          account,
          representative: res.representatives?.[0],
        },
        {
          redirectTo: !pathname.includes('second-market')
            ? '/investor-workstation/account/detail'
            : null,
        }
      );

      onSuccess?.();
      handleCloseConfirm();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onOk={handleComplete}
        okText="Save & Continue"
        confirmLoading={isLoading || isRepresentativeLoading}
        onCancel={() => onCloseAttempt(handleCloseConfirm)}
        cancelText="Cancel"
        width={600}
        isFullScreen={isMobile}
        footer={
          <FooterButtons
            onOk={handleComplete}
            onCancel={() => onCloseAttempt(handleCloseConfirm)}
            isLoading={isLoading}
          />
        }
        fullScreenProps={{
          footerHeight: 110,
        }}
      >
        <S.ContentWrapper>
          <Heading variant="h3" marginBottom="md">
            Create Representative for {account?.name}
          </Heading>
          <Form form={form} onValuesChange={() => setHasUnsavedChanges(true)}>
            <Margin bottom="lg">
              <PersonInfoForm form={form} includeEmailField />
            </Margin>
            <UserRolesFormItem
              label="Representative Role"
              includeLimitedAccessRole={false}
              includeLimitedAccessSignerRole={false}
            />
          </Form>
        </S.ContentWrapper>
      </Modal>
      <DiscardModal {...discardModalProps} />
    </>
  );
};
