import React, { type ComponentProps } from 'react';

import { Wizard } from '@npm/core/ui/components/organisms/WizardNew';
import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import {
  type WizardStore,
  WizardStoreActionTypes,
} from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';

import { useCurrentWorkstation, useObo } from '../../../auth/user/role';
import {
  type OnboardingAgreementGetStarted,
  OnboardingAgreementReadOnly,
  OnboardingAgreementSignCta,
  OnboardingAgreementUploadForm,
  useOnboardingAgreementUploadForm,
} from '../../../onboardingAndSubmissionSteps/OnboardingAgreements';
import { usePayingAgentAgreement } from '../../../onboardingAndSubmissionSteps/OnboardingAgreements/PayingAgentServicesAgreement';
import { useReturnUrl } from '../../hooks';
import { type PostOnboardingContextType } from '../../PostOnboarding.types';
import { StepContainer } from '../../StepContainer';

const { StepState } = Wizard;

type Props = {
  stepProps: Wizard.StepProps;
} & Pick<
  ComponentProps<typeof OnboardingAgreementGetStarted>,
  'zendeskTicketGroupId'
>;

export const PayingAgentAgreementPostOnboarding = ({
  stepProps,
  zendeskTicketGroupId,
}: Props) => {
  const { stepState, dispatch, accountId, account, onboardingStatus } =
    useWizardStore((s: WizardStore<PostOnboardingContextType>) => ({
      stepState: s.activeStepState,
      dispatch: s.dispatch,
      accountId: s.data.account?.id,
      account: s.data.account,
      onboardingStatus: s.data.onboardingStatus,
    }));

  const stepOnboardingStatus =
    onboardingStatus?.paying_agent_agreement_state?.code;

  const returnUrl = useReturnUrl();

  const { data, isLoading, isOpenSignLoading, handleOpenSign } =
    usePayingAgentAgreement(
      accountId,
      stepOnboardingStatus === 'completed',
      returnUrl
    );

  const { isObo } = useObo();
  const workstation = useCurrentWorkstation();
  const isActingAsBroker = isObo || workstation?.type?.code === 'brokerage';

  const {
    form,
    handleSubmit,
    isLoading: isUpdating,
  } = useOnboardingAgreementUploadForm(
    'paying_agent_service_agreement',
    accountId,
    {
      onUpdate: () => {
        stepProps.setFormHasUnsavedChanges(false);
        dispatch({
          type: WizardStoreActionTypes.UPDATE_ACTIVE_STEP_STATE,
          payload: StepState.FILLED,
        });
      },
    }
  );

  return (
    <StepContainer
      stepProps={stepProps}
      isLoading={isLoading}
      isUpdating={isOpenSignLoading || isUpdating}
      handleSubmit={isActingAsBroker ? handleSubmit : null}
    >
      {stepState === StepState.EDIT &&
        (isActingAsBroker ? (
          <OnboardingAgreementUploadForm
            type="paying_agent_service_agreement"
            title={stepProps.step.title}
            form={form}
            variant={
              data?.paying_agent_service_agreement?.id ? 'replace' : 'upload'
            }
            handleCancelReplace={() => {
              stepProps.setFormHasUnsavedChanges(false);
              dispatch({
                type: WizardStoreActionTypes.UPDATE_ACTIVE_STEP_STATE,
                payload: StepState.FILLED,
              });
            }}
            handleValuesChange={() => stepProps.setFormHasUnsavedChanges(true)}
          />
        ) : (
          <OnboardingAgreementSignCta
            type="paying_agent_service_agreement"
            onClick={handleOpenSign}
            zendeskTicketGroupId={zendeskTicketGroupId}
            isRedirectingToDocusign={isOpenSignLoading}
            account={account}
          />
        ))}
      {stepState === StepState.FILLED && (
        <OnboardingAgreementReadOnly
          type="paying_agent_service_agreement"
          data={data}
          handleReplaceButtonClick={
            isActingAsBroker
              ? () =>
                  dispatch({
                    type: WizardStoreActionTypes.UPDATE_ACTIVE_STEP_STATE,
                    payload: StepState.EDIT,
                  })
              : null
          }
        />
      )}
    </StepContainer>
  );
};
