import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import { type Wizard } from '@npm/core/ui/components/organisms/WizardNew';

import { PersonaVerificationButton } from '../../Persona/PersonaVerificationButton';

import * as S from './PersonaVerification.styles';

type Props = {
  stepProps: Wizard.StepProps;
  variant?: 'in-progress' | 'not-started';
};

export const PersonaVerification = ({ variant = 'not-started' }: Props) => {
  return (
    <S.Card>
      <Flex direction="column" justify="center" align="center" gap="lg">
        <S.PersonShieldIconWrapper>
          <Icon name="person-shield" />
        </S.PersonShieldIconWrapper>
        <Flex direction="column" align="center" gap="sm">
          <Heading textAlign="center" variant="h1" colorVariant="secondary">
            We need to get to know you better
          </Heading>
          <Text align="center" colorVariant="tertiary">
            This information is collected to populate agreements and confirm
            your identity to keep you and NPM safe.
          </Text>
        </Flex>
        <PersonaVerificationButton
          buttonVariant={variant === 'not-started' ? 'outline' : 'default'}
          text={
            variant === 'not-started'
              ? 'Start Verification'
              : 'Complete Your Verification'
          }
        />
      </Flex>
    </S.Card>
  );
};
