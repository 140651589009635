import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Chart } from 'chart.js';
import styled from 'styled-components';

import {
  CrosshairPlugin,
  useChartOptions,
} from '@npm/core/ui/components/atoms/Charts';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { useDarkModeContext } from '@npm/core/ui/context/DarkModeContext';

import { useCompanyOverviewChartFilter } from '../../../CompanyOverview.hooks';
import type { CompanyOverviewChartSeries } from '../../../CompanyOverview.types';
import {
  getCompanyOverviewXScaleConfig,
  getCompanyOverviewYScaleConfig,
  sortArrayByDateField,
} from '../../../CompanyOverview.utils';
import { CompanyOverviewChartContainer } from '../../../components/CompanyOverviewChartContainer';
import { CompanyOverviewChartFilters } from '../../../components/CompanyOverviewChartFilters/CompanyOverviewChartFilters';

import { MovingAverageTooltip } from './MovingAverageTooltip/MovingAverageTooltip';
import { MtmTooltip } from './MtmTooltip/MtmTooltip';
import {
  useMutualFundChartDataSets,
  useMutualFundChartLimits,
} from './MutualFundMarksChart.hooks';
import {
  type MovingAverageValue,
  type MtmValue,
  type MutualFundActiveDataPoint,
  type MutualFundChartPoint,
} from './MutualFundMarksChart.types';
import {
  getMutualFundChartTooltip,
  MUTUAL_FUND_CHART_HTML_ID,
  showMutualFundChartTooltip,
  sortMtmData,
  splitMtmDataByAssetType,
} from './MutualFundMarksChart.utils';
import { MutualFundMarksLegend } from './MutualFundMarksLegend';

type Props = {
  mtmData: MtmValue[];
  movingAverageData: MovingAverageValue[];
  filter: React.ReactNode;
};

export const MutualFundMarksChart = ({
  mtmData,
  movingAverageData,
  filter,
}: Props) => {
  // Dark-mode context used to fix issues with switching between dark and light mode
  const { isDarkMode } = useDarkModeContext();

  const [activeDataPoint, setActiveDataPoint] =
    useState<MutualFundActiveDataPoint>(undefined);

  const sortedData = useMemo(() => {
    const sortedMtm = sortMtmData(mtmData, 'desc');

    const { commonMtm, preferredMtm } = splitMtmDataByAssetType(sortedMtm);

    return {
      commonMtm,
      preferredMtm,
      movingAverage: sortArrayByDateField(movingAverageData),
    };
  }, [mtmData, movingAverageData]);

  const [series, setSeries] = useState<CompanyOverviewChartSeries>('PPS');

  const [minDate, maxDate] = useMutualFundChartLimits(sortedData);

  const {
    setCurrentPage,
    currentMinDate,
    currentMaxDate,
    currentPage,
    pageCount,
    range,
    setRange,
  } = useCompanyOverviewChartFilter(minDate, maxDate);

  const datasets = useMutualFundChartDataSets(sortedData, series);

  const options = useChartOptions();

  const canvasRef = useRef<HTMLCanvasElement>();

  const chartRef = useRef<Chart<'line', MutualFundChartPoint[]>>();

  const destroyChart = () => {
    if (chartRef.current) {
      chartRef.current.destroy();
      chartRef.current = null;
    }
  };

  const resetTooltip = () => {
    if (!chartRef.current) return;

    getMutualFundChartTooltip(chartRef.current?.canvas).style.opacity = '0';
    chartRef.current.update('none');
  };

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');

    chartRef.current = new Chart<'line', MutualFundChartPoint[]>(ctx, {
      type: 'line',
      data: {
        datasets,
      },
      options: options({
        interaction: {
          mode: 'nearest',
        },
        scales: {
          x: getCompanyOverviewXScaleConfig(currentMinDate, currentMaxDate),
          y: getCompanyOverviewYScaleConfig(),
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
            xAlign: 'left',
            yAlign: 'bottom',
            external: context => {
              showMutualFundChartTooltip(context, setActiveDataPoint);
            },
          },
        },
      }),
      plugins: [CrosshairPlugin],
    });

    chartRef.current.update('none');

    return () => destroyChart();
  }, [datasets, isDarkMode, options, currentMinDate, currentMaxDate]);

  const hasValuation = useMemo(() => {
    return (
      mtmData?.some(trade => !!trade.implied_valuation) ||
      movingAverageData?.some(trade => !!trade.implied_valuation)
    );
  }, [movingAverageData, mtmData]);

  return (
    <Flex direction="column" gap="xs">
      <CompanyOverviewChartFilters
        series={series}
        onChangeSeries={setSeries}
        canChangeSeries={hasValuation}
        range={range}
        onChangeRange={range => {
          setRange(range);
          resetTooltip();
        }}
        pagination={{
          page: currentPage,
          totalPages: pageCount,
          onPageChange: val => {
            setCurrentPage(val);
            resetTooltip();
          },
        }}
        customFilter={filter}
      />

      <Container>
        <canvas ref={canvasRef} />
        <div
          id={MUTUAL_FUND_CHART_HTML_ID}
          className="html-chart-tooltip"
          style={{ opacity: activeDataPoint ? 1 : 0 }}
        >
          {activeDataPoint?.type === 'mtm' && (
            <MtmTooltip values={activeDataPoint.data} />
          )}

          {activeDataPoint?.type === 'movingAverage' && (
            <MovingAverageTooltip value={activeDataPoint.data} />
          )}
        </div>
      </Container>
      <MutualFundMarksLegend />
    </Flex>
  );
};

const Container = styled(CompanyOverviewChartContainer)`
  .html-chart-tooltip {
    border: none;
    box-shadow: ${({ theme }) => theme.shadow.level3};
  }
`;
