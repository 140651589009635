import { Form } from '@npm/core/ui/components/atoms/Form';
import { Heading } from '@npm/core/ui/components/atoms/Typography';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { VenusApi } from '@npm/data-access';

import { CollapsibleFilterPanel } from '../../../filters';
import { TotalRecordsLabel } from '../../../filters/filters/FilterPanel/components/TotalRecordsLabel';

import { getCompaniesDataFilters } from './CompaniesDataFilter.config';
import { type CompaniesDataFilterFormValues } from './CompaniesDataFilter.types';

const { useCompaniesOverviewsSectors } = VenusApi;

type Props = {
  handleSubmit: (values: CompaniesDataFilterFormValues) => void;
  variables?: VenusApi.CompaniesOverviewIndexRequestContract;
  initialFilterValues?: Partial<VenusApi.CompaniesOverviewIndexRequestContract>;
  totalActiveFilters: number;
  totalRecords: number | undefined;
};

export const CompaniesDataFilter = ({
  totalActiveFilters,
  handleSubmit,
  initialFilterValues,
  totalRecords,
}: Props) => {
  const [form] = Form.useForm<CompaniesDataFilterFormValues>();

  const sectorQuery = useCompaniesOverviewsSectors({});
  const { isMobile } = useBreakpoints();

  return (
    <CollapsibleFilterPanel
      responsive={false}
      form={form}
      initialFilterValues={initialFilterValues}
      filters={getCompaniesDataFilters({ sectorQuery })}
      handleSubmit={handleSubmit}
      filterDropdownProps={{ totalActiveFilters }}
      totalActiveFilters={totalActiveFilters}
      totalRecords={totalRecords}
      filterTabs={
        !isMobile && (
          <>
            <Heading
              variant="h3"
              style={{
                marginLeft: 12,
                marginRight: 12,
              }}
            >
              All Companies
            </Heading>
            <TotalRecordsLabel totalRecords={totalRecords} />
          </>
        )
      }
      showDesktopBorder={false}
    />
  );
};
