import { CbATSPool } from '@npm/data-access';

export enum IndividualInvestorPage {
  companyOverview = 'company-overview',
  holdings = 'holdings',
  orders = 'orders',
  opportunities = 'opportunities',
  negotiations = 'negotiations',
}

export const INDIVIDUAL_ATS_POOL = [
  CbATSPool.items.ats_i,
  CbATSPool.items.ats_sm,
];
