import { omit } from 'lodash';

import { type MenuItemGroup } from '@npm/core/ui/components/organisms/Layout';
import {
  type PermissionContext,
  performChecks,
} from '@npm/core/ui/models/permissions';
import { type routeKeys } from '@npm/core/ui/routes/router.constants';
import { getUrlParamValue } from '@npm/core/ui/utils/url';
import {
  type CbAccountSponsorshipTypeCode,
  type Codebook,
  type Subject,
  type UserRoleAggregate,
  type WorkstationType,
  CbAccountSponsorshipType,
  CbAccountType,
  CbRoleType,
  CbWorkstationType,
} from '@npm/data-access';
import { DatadogService } from '@npm/utils';

import { extractContextVariables } from '../../role/context/userRole.helpers';
import {
  type UserRoleContextType,
  type UserRoleDefinition,
} from '../../role/userRole.types';

import type { UserContextStore } from './user-context.store';

const routesWithoutWorkstationPrefixMapper: Partial<
  Record<routeKeys, WorkstationType>
> = {
  '/onboarding': CbWorkstationType.items.investor,
  '/portfolio-import': CbWorkstationType.items.investor,
  '/post-onboarding': CbWorkstationType.items.investor,
  '/second-market/investor': CbWorkstationType.items.investor,
  '/second-market/sponsored-investor': CbWorkstationType.items.investor,
  '/second-market': CbWorkstationType.items.brokerage,
};

export const getWorkstationFromPathname: (
  route: string
) => WorkstationType | undefined = route => {
  const workstationFromPathname = route.split('/')[1].split('-')[0];
  const workstationCode = Object.values(CbWorkstationType.items).find(
    ws => workstationFromPathname === ws
  );

  if (!workstationCode) {
    const routeWithoutWorkstationPrefix = Object.keys(
      routesWithoutWorkstationPrefixMapper
    ).find(r => route.includes(r));

    return routesWithoutWorkstationPrefixMapper[routeWithoutWorkstationPrefix];
  } else {
    return workstationCode;
  }
};

// investor, issuer workstation
export const getDefaultRole = (
  availableRoles: UserRoleAggregate[],
  activeRoleId: number
) => {
  const roleFromUrl = availableRoles.find(
    role => role.id === Number(getUrlParamValue('roleId'))
  );
  const activeRole = availableRoles.find(role => role.id === activeRoleId);
  // if current active role isn't from this workstation,
  // the first available role of current workstation should be returned
  return roleFromUrl || activeRole || availableRoles[0];
};

export const parseRedirectUrl = (redirectUrl?: string) => {
  const [redirectPath, redirectQuery] = (redirectUrl ?? '').split('?');
  return [redirectPath, redirectQuery ? `&${redirectQuery}` : ''];
};

export const filterMenuItems = (
  items: MenuItemGroup[],
  userContext: UserContextStore,
  userRole: UserRoleContextType | null,
  obo: PermissionContext['obo'] | null
) => {
  if (!userContext.isPermissionsCheckOn) {
    return items;
  }

  const { oboUserId, oboAccountId } = extractContextVariables(userRole);
  const role = userRole?.subRole as unknown as UserRoleDefinition;
  const checkContext = {
    ...userContext,
    workstation: userRole?.workstation,
    obo: oboAccountId && oboUserId ? obo : undefined,
    role,
    getIsAccountCentric: () =>
      userRole?.workstationType === 'investor' &&
      userRole?.subRole?.type === 'account-centric',
  } satisfies PermissionContext;

  return items.reduce((acc, curr) => {
    if (
      curr.permissionsChecks &&
      !performChecks(curr.permissionsChecks, checkContext)
    ) {
      return acc;
    }

    if (!curr.children) {
      return [...acc, omit(curr, 'permissionsChecks')];
    }

    const children = curr.children.reduce(
      (acc, { permissionsChecks, ...rest }) => {
        if (
          permissionsChecks &&
          !performChecks(permissionsChecks, checkContext)
        )
          return acc;

        return [...acc, { ...rest }];
      },
      []
    );

    return [...acc, ...(children.length ? [{ ...curr, children }] : [])];
  }, []);
};

export const findHighestSponsorshipType = (
  userRoles: UserRoleAggregate[] | null | undefined
) => {
  if (userRoles === undefined) return undefined; // not loaded yet
  if (userRoles === null) return null; // does not apply in the current context

  const highestSponsorshipType = userRoles.reduce(
    (highestSoFar: Codebook | undefined, userRole: UserRoleAggregate) => {
      if (!isNPMS(userRole?.subject)) return highestSoFar;
      if (!highestSoFar) return userRole?.subject?.sponsorship_type;
      const previousWeight = CbAccountSponsorshipType.codeToNumber(
        highestSoFar.code as CbAccountSponsorshipTypeCode
      );
      const currentWeight = CbAccountSponsorshipType.codeToNumber(
        userRole?.subject?.sponsorship_type
          ?.code as CbAccountSponsorshipTypeCode
      );
      return currentWeight > previousWeight
        ? userRole?.subject?.sponsorship_type
        : highestSoFar;
    },
    undefined
  );

  if (
    !highestSponsorshipType ||
    highestSponsorshipType.code === CbAccountSponsorshipType.items.none
  ) {
    return null;
  }

  return highestSponsorshipType;
};

export const NPMS_NAME = 'NPMS';
export const NPM_SETTLEMENT_NAME = 'NPM Settlement';

export const isNPMS = (
  brokerageFirm: Pick<Subject, 'brokerage_firm_name'> | undefined
) => brokerageFirm?.brokerage_firm_name === NPMS_NAME;

export const isNpmSettlement = (
  brokerageFirm: Pick<Subject, 'brokerage_firm_name'> | undefined
) => brokerageFirm?.brokerage_firm_name === NPM_SETTLEMENT_NAME;

export const isIndividualAccount = (role: UserRoleAggregate) =>
  role?.subject?.type?.code === CbAccountType.items.PersonAccount;

export const isEntityAccount = (role: UserRoleAggregate) =>
  role?.subject?.type?.code === CbAccountType.items.OrganizationAccount;

export const findIndividualNpmsAccount = (
  userRoles: UserRoleAggregate[] | null | undefined
) => {
  return userRoles?.find(
    role => isIndividualAccount(role) && isNPMS(role?.subject)
  );
};

export const hasIndividualNpmsAccount = (
  userRoles: UserRoleAggregate[] | null | undefined
) => {
  if (userRoles === undefined) return undefined; // not loaded yet
  if (userRoles === null) return false; // does not apply in the current context

  return !!findIndividualNpmsAccount(userRoles);
};

export const hasEntityNpmsAccount = (
  userRoles: UserRoleAggregate[] | null | undefined
) => {
  if (userRoles === undefined) return undefined; // not loaded yet
  if (userRoles === null) return false; // does not apply in the current context

  return userRoles.some(role => isEntityAccount(role) && isNPMS(role?.subject));
};

export const hasNpmSettlementAccount = (
  userRoles: UserRoleAggregate[] | null | undefined
) => {
  if (userRoles === undefined) return undefined; // not loaded yet
  if (userRoles === null) return false; // does not apply in the current context

  return userRoles.some(role => isNpmSettlement(role?.subject));
};

// user has only spouse or limited access role(s) in the INV workstation
export const hasSpouseOrGuestRolesOnly = (
  userRoles: UserRoleAggregate[] | null | undefined
) => {
  if (userRoles === undefined) return undefined; // not loaded yet
  if (userRoles === null) return false; // does not apply in the current context

  return userRoles.every(
    role =>
      role?.role_name?.code === CbRoleType.items.SPOUSE ||
      role?.role_name?.code === CbRoleType.items.GUEST_AND_SIGNER ||
      role?.role_name?.code === CbRoleType.items.ACCOUNT_GUEST
  );
};

// user has only spouse role(s) in the INV workstation
export const hasSpouseRolesOnly = (
  userRoles: UserRoleAggregate[] | null | undefined
) => {
  if (userRoles === undefined) return undefined; // not loaded yet
  if (userRoles === null) return false; // does not apply in the current context

  return userRoles.every(
    role => role?.role_name?.code === CbRoleType.items.SPOUSE
  );
};

export const canNegotiateAsInvestor = (
  investorAccounts: UserRoleAggregate[]
) => {
  return investorAccounts.some(
    userRole =>
      CbAccountSponsorshipType.isSponsoredAccountLevel2(
        userRole?.subject?.sponsorship_type
      ) &&
      userRole?.subject?.can_negotiate &&
      userRole?.subject?.brokerage_firm_name === NPMS_NAME
  );
};

export const setInvestorContextVariablesToDatadog = (
  investorAccounts: UserRoleAggregate[] | null
) => {
  if (!investorAccounts) return;

  const isEntityInvestor = !!investorAccounts.find(isEntityAccount);
  const sponsoredLevel = findHighestSponsorshipType(investorAccounts);

  DatadogService.addContextVariable(
    'investor_type',
    isEntityInvestor ? 'entity' : 'individual'
  );

  if (sponsoredLevel) {
    DatadogService.addContextVariable(
      'sponsored_level',
      CbAccountSponsorshipType.codeToNumber(
        sponsoredLevel.code as CbAccountSponsorshipTypeCode
      )
    );
  }
};
