import { type CbLabelComponent, type LabelVariant } from '@npm/utils';

import { type CodebookItem, Codebooks } from './codebooks.types';

const items = {
  new_lead: 'new_lead',
  sales_contacted: 'sales_contacted',
  lead_rejected: 'lead_rejected',
  engaged: 'engaged',
  open_live: 'open_live',
  closed_live: 'closed_live',
  closed_won: 'closed_won',
  closed_lost: 'closed_lost',
  closed_expired: 'closed_expired',
};

export type AccountStatusCode = keyof typeof items;

const LabelMap: Record<AccountStatusCode, LabelVariant> = {
  new_lead: 'warning',
  sales_contacted: 'info',
  lead_rejected: 'general',
  engaged: 'warning',
  open_live: 'info',
  closed_live: 'general',
  closed_won: 'general',
  closed_lost: 'general',
  closed_expired: 'general',
};

export const CbAccountStatus = {
  code: Codebooks.ACCOUNT_STATUS,
  labels: LabelMap,
  getLabel: (
    Component: CbLabelComponent,
    codebookItem: CodebookItem | undefined
  ) =>
    Component({
      variant: codebookItem?.code
        ? LabelMap[codebookItem.code as string]
        : 'general',
      label: codebookItem?.name,
    }),
};
