import { DatadogService } from '@npm/utils';

import {
  type AccountApiAccountCreateRequest,
  type AccountCreateMethod,
  type EventSubmissionCreateMethod,
  type HoldingApiIssuerEntityHoldingCreateRequest,
  type IssuerEntityHoldingCreateMethod,
  type NegotiationApiNegotiationCreateRequest,
  type NegotiationApiNegotiationUpdateRequest,
  type NegotiationCreateMethod,
  type NegotiationUpdateMethod,
  type SecondmarketOrderItemApiSecondmarketOrderItemUpdateRequest,
  type SecondmarketOrderItemUpdateMethod,
  type SecondmarketSubmissionCreateMethod,
  type SubmissionApiEventSubmissionCreateRequest,
  type SubmissionApiSecondmarketSubmissionCreateRequest,
} from '../generated';
import { type MethodResult } from '../hooks/apiBaseHooks';

export const handleAddSmHoldingAction = (
  _payload: HoldingApiIssuerEntityHoldingCreateRequest,
  res: MethodResult<IssuerEntityHoldingCreateMethod>
) => {
  DatadogService.addAction('Add SM Holding', {
    asset_type: res.asset?.type?.name,
    holding_npm_guid: res.npm_guid,
  });
};

export const handleAddEnterOrderAction = (
  payload: SubmissionApiSecondmarketSubmissionCreateRequest,
  res: MethodResult<SecondmarketSubmissionCreateMethod>
) => {
  const {
    structure,
    affiliate_disclosure,
    ats_pool,
    order_buy_sell,
    order_entry_type,
    holding_npm_guid,
  } = payload.secondmarketSubmissionCreateRequestContract;

  DatadogService.addAction('Enter Order', {
    order_id: res.id,
    asset_type: res.asset_type?.name,
    structure,
    affiliate: affiliate_disclosure,
    ats_pool,
    order_buy_sell,
    order_entry_type,
    holding_npm_guid,
  });
};

export const handleAddUpdateOrderAction = (
  payload: SecondmarketOrderItemApiSecondmarketOrderItemUpdateRequest,
  res: MethodResult<SecondmarketOrderItemUpdateMethod>
) => {
  const { structure, ats_pool } =
    payload.secondmarketOrderItemUpdateRequestContract;

  DatadogService.addAction('Update Order', {
    order_id: res.id,
    structure,
    ats_pool,
  });
};

export const handleAddCreateNegotiationAction = (
  payload: NegotiationApiNegotiationCreateRequest,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _res: MethodResult<NegotiationCreateMethod>
) => {
  const { negotiation_action, holding_npm_guid, order_item_id } =
    payload.negotiationCreateRequestContract;

  DatadogService.addAction('Create Negotiation', {
    action_type: negotiation_action,
    holding_npm_guid,
    order_id: order_item_id,
  });
};

export const handleAddActOnNegotiationAction = (
  payload: NegotiationApiNegotiationUpdateRequest,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _res: MethodResult<NegotiationUpdateMethod>
) => {
  const { negotiation_action } = payload.negotiationUpdateRequestContract;

  DatadogService.addAction('Act on Negotiation', {
    action_type: negotiation_action,
    order_id: _res.order_item_id,
  });
};

export const handleAddCreateSubmissionAction = (
  _payload: SubmissionApiEventSubmissionCreateRequest,
  res: MethodResult<EventSubmissionCreateMethod>
) => {
  DatadogService.addAction('Create Submission', {
    event_type: res.type?.name,
    event_id: _payload.eventId,
    account_id: _payload.eventsSubmissionCreateRequestContract?.account_id,
  });
};

export const handleAddCreateAccountAction = (
  _payload: AccountApiAccountCreateRequest,
  res: MethodResult<AccountCreateMethod>
) => {
  DatadogService.addAction('Create Account', {
    account_type: res.type?.name,
    account_id: res.id,
  });
};
