import React, { useState } from 'react';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import {
  type NegotiationShowAggregate,
  type NegotiationShowHolding,
} from '@npm/data-access';

import { HoldingDetailsDrawer } from '../../../../../drawers/HoldingDetailsDrawer/HoldingDetailsDrawer';

type Props = {
  holding: NegotiationShowHolding;
};

export const CertificateDetails = ({ holding }: Props) => {
  const [holdingDetailsDrawerOpen, setHoldingDetailsDrawerOpen] =
    useState(false);

  const handleOpenHoldingDetailsDrawer = e => {
    e.stopPropagation();
    if (holding) {
      setHoldingDetailsDrawerOpen(true);
    }
  };

  return (
    <>
      <Text color="info" size="sm" onClick={handleOpenHoldingDetailsDrawer}>
        {holding?.certificate_number}
      </Text>

      <HoldingDetailsDrawer
        holding={holding}
        open={holdingDetailsDrawerOpen}
        onClose={() => setHoldingDetailsDrawerOpen(false)}
      />
    </>
  );
};
