import styled from 'styled-components';

import { useBreakpoint } from '@npm/core/ui/utils/style';

// add negative bottom margin to avoid large gap between the alert and get started text on mobile and tablet
export const Wrapper = styled.div`
  ul {
    padding-left: ${({ theme }) => theme.spacing.lg}px;
  }

  ${({ theme }) => useBreakpoint(theme).tablet`
    margin-bottom: -5vh;
    margin-bottom: calc(var(--vh, 1vh) * -5);
  `}
`;
