import {
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useWizard } from 'react-use-wizard';

import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import { type WizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';
import { CbBackgroundCheckState, CbOnboardingStatus } from '@npm/data-access';

import { usePostOnboardingContext } from '../../hooks';
import { personaRequiredSteps } from '../../IndividualOnboarding.config';
import { type PostOnboardingContextType } from '../../PostOnboarding.types';

type PersonaContextValue = {
  isModalOpen: boolean;
  isPolling: boolean;
  isCompleted: boolean;
  openModal: () => void;
  closeModal: () => void;
  onComplete: () => void;
};

const PersonaContext = createContext<PersonaContextValue | null>(null);

const POLLING_INTERVAL = 1000;
const NUMBER_OF_PERSONA_ONBOARDING_STEPS = 4;

const isBackgroundCheckCompleted = (
  status: keyof typeof CbBackgroundCheckState.items
) => {
  return (
    status === CbBackgroundCheckState.items.passed ||
    status === CbBackgroundCheckState.items.failed ||
    status === CbBackgroundCheckState.items.marked_for_review
  );
};

export const PersonaContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const { goToStep } = useWizard();
  const { steps } = useWizardStore(
    (s: WizardStore<PostOnboardingContextType>) => ({
      steps: s.steps,
    })
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const [pollingCounter, setIsPollingCounter] = useState(0);
  const backgroundCheckStatusRef = useRef<
    keyof typeof CbBackgroundCheckState.items | null
  >(null);

  const { refetchOnboardingStatus, refetchAccountStatus } =
    usePostOnboardingContext();

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    // It takes time for onboarding status to be updated by Persona webhook on backend
    // Let's try to wait 1 second - this is not a mission critical update but nice-to-have
    setTimeout(async () => {
      await refetchOnboardingStatus();
    }, POLLING_INTERVAL);
  }, [refetchOnboardingStatus]);

  const onComplete = useCallback(() => {
    setIsModalOpen(false);
    setIsPollingCounter(10);
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (pollingCounter > 0) {
      // After completing Persona flow it takes time for Persona to reach out to backend
      // via webhook. Therefore we start polling backend every second until we get the result.
      timeout = setTimeout(async () => {
        setIsPollingCounter(prev => prev - 1);

        if (!isBackgroundCheckCompleted(backgroundCheckStatusRef.current)) {
          const response = await refetchAccountStatus();

          const backgroundCheckStateCode = response.data?.background_check_state
            ?.code as keyof typeof CbBackgroundCheckState.items;
          if (isBackgroundCheckCompleted(backgroundCheckStateCode)) {
            backgroundCheckStatusRef.current = backgroundCheckStateCode;
          }
        }

        await refetchOnboardingStatus();

        if (isBackgroundCheckCompleted(backgroundCheckStatusRef.current)) {
          setIsModalOpen(false);
          setIsPollingCounter(0);
          setIsCompleted(true);
        }
      }, POLLING_INTERVAL);
    }

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [pollingCounter]);

  useEffect(() => {
    if (isCompleted) {
      const marketplaceAgreementsStepIndex = steps.findIndex(
        step =>
          step.key === CbOnboardingStatus.steps.marketplace_agreement_state
      );

      if (marketplaceAgreementsStepIndex > NUMBER_OF_PERSONA_ONBOARDING_STEPS) {
        goToStep(marketplaceAgreementsStepIndex);
      }
    }
  }, [isCompleted, steps, goToStep]);

  const isPolling = pollingCounter > 0;

  const value = useMemo(
    () => ({
      isModalOpen,
      isPolling,
      openModal,
      closeModal,
      onComplete,
      isCompleted,
    }),
    [isModalOpen, openModal, closeModal, onComplete, isPolling, isCompleted]
  );

  return (
    <PersonaContext.Provider value={value}>{children}</PersonaContext.Provider>
  );
};

export const usePersonaContext = () => {
  const context = useContext(PersonaContext);
  if (!context) {
    throw new Error('usePersona must be used within a PersonaContextProvider');
  }
  return context;
};
