import styled from 'styled-components';

export const PdfWrapper = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    color: ${({ theme }) => theme.color.info.typography.primary};
    min-width: 13px;
    margin-right: ${({ theme }) => theme.spacing.xs}px;
  }
`;
