import React from 'react';
import { useTheme } from 'styled-components';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Divider } from '@npm/core/ui/components/atoms/Divider';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import {
  type Accreditation,
  type Answer,
  CbAccreditationLevel,
  CbQualifiedClientType,
} from '@npm/data-access';

import {
  type ZendeskTicketGroupID,
  ZendeskContactButton,
} from '../../../app/zendesk';
import { AccreditedInvestorStatus } from '../../components/AccreditedInvestorStatus';
import { type WhitelistedAnswerCodes } from '../../EntityAccreditation/EntityAccreditation.types';

import { AccreditationDataItem } from './AccreditationDataItem';
import { type AccreditationDataItemHeading } from './AccreditationDataItemHeading';
import { UploadedDocumentView } from './UploadedDocumentView';

type Props = {
  data: Accreditation | undefined;
  answers: Partial<Record<WhitelistedAnswerCodes, Answer>>;
  brokerageFirmName: string;
  accreditationDataItemHeadingVariant?: React.ComponentProps<
    typeof AccreditationDataItemHeading
  >['variant'];
  zendeskTicketGroupId?: ZendeskTicketGroupID;
};

export const EntityAccreditationReadOnly = ({
  data,
  answers,
  brokerageFirmName,
  accreditationDataItemHeadingVariant,
  zendeskTicketGroupId,
}: Props) => {
  const theme = useTheme();
  const isFailed =
    data?.level?.code === CbAccreditationLevel.items.not_available;
  const isQualifiedClientFailed =
    answers.qualified_client?.answer?.code ===
    CbQualifiedClientType.items.not_valid;
  const showDocument = !!data?.documents?.length;

  return (
    <Flex direction="column" gap="lg">
      {!showDocument && !!zendeskTicketGroupId && (
        <Alert
          type={isFailed ? 'error' : 'info'}
          message={
            <div>
              {isFailed ? (
                <>
                  Based on the information you provided,{' '}
                  <Text
                    size="sm"
                    as="span"
                    weight="bold"
                    colorVariant="primary"
                  >
                    you do not qualify as an accredited investor
                  </Text>
                  . If you have entered the wrong information, please{' '}
                  <ZendeskContactButton
                    zendeskTicketGroupId={zendeskTicketGroupId}
                  >
                    contact us
                  </ZendeskContactButton>
                </>
              ) : (
                <>
                  If you have entered the wrong information, please{' '}
                  <ZendeskContactButton
                    zendeskTicketGroupId={zendeskTicketGroupId}
                  >
                    contact us
                  </ZendeskContactButton>
                </>
              )}
            </div>
          }
          closable
        />
      )}
      <div>
        {data?.level && !showDocument && (
          <AccreditationDataItem
            answer={
              isFailed
                ? { field: data?.level, answer: answers.org_type.answer }
                : answers.org_type
            }
            accreditationStatusTitle="Investor Status"
            status={isFailed ? 'error' : 'success'}
            signature={answers.signature}
            entityRepTitle={answers.entity_rep_title}
            additionalAnswers={[answers.entity_total_assets]}
            headingVariant={accreditationDataItemHeadingVariant}
          />
        )}
        {showDocument && (
          <Margin bottom="lg">
            <Flex direction="column" gap="md">
              {!!zendeskTicketGroupId && (
                <UploadedDocumentView.Alert
                  zendeskTicketGroupId={zendeskTicketGroupId}
                />
              )}
              <AccreditedInvestorStatus
                level={data.level}
                isFailed={isFailed}
                headingVariant={accreditationDataItemHeadingVariant}
              />
              <UploadedDocumentView
                data={data}
                brokerageFirmName={brokerageFirmName}
              />
            </Flex>
          </Margin>
        )}
        {answers.qualified_purchaser && (
          <>
            <Divider type="horizontal" marginBottom={theme.spacing.xl} />
            <AccreditationDataItem
              answer={answers.qualified_purchaser}
              accreditationStatusTitle="Qualified Purchaser Information"
              signature={answers.qp_signature}
              entityRepTitle={answers.qp_entity_rep_title}
              headingVariant={accreditationDataItemHeadingVariant}
            />
          </>
        )}
        {answers.qualified_institutional_buyer && (
          <>
            <Divider type="horizontal" marginBottom={theme.spacing.xl} />
            <AccreditationDataItem
              answer={answers.qualified_institutional_buyer}
              accreditationStatusTitle="Qualified Institutional Buyer Information"
              signature={answers.qib_signature}
              entityRepTitle={answers.qib_entity_rep_title}
              additionalAnswers={[answers.qualified_institutional_buyer_assets]}
              headingVariant={accreditationDataItemHeadingVariant}
            />
          </>
        )}
        {answers.qualified_client && (
          <>
            <Divider type="horizontal" marginBottom={theme.spacing.xl} />
            <AccreditationDataItem
              answer={
                isQualifiedClientFailed
                  ? {
                      field: answers.qualified_client.answer,
                      answer: answers.qualified_client.answer,
                    }
                  : answers.qualified_client
              }
              accreditationStatusTitle="Qualified Client Information"
              status={isQualifiedClientFailed ? 'error' : 'success'}
              signature={answers.qc_signature}
              entityRepTitle={answers.qc_entity_rep_title}
              headingVariant={accreditationDataItemHeadingVariant}
            />
          </>
        )}
      </div>
    </Flex>
  );
};
