export const EDITABLE_ONBOARDING_STEPS = [
  'personal_information_state',
  'entity_information_state',
  'authorized_signer_state',
  'bank_account_state',
  'buyer_survey_state',
  'spouse_information_state',
  'marketplace_agreement_state',
  'paying_agent_agreement_state',
];
