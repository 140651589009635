import React, { type Dispatch } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { ErrorSkeleton } from '../../atoms/ErrorSkeleton';
import { ProgressBar } from '../../atoms/ProgressBar';
import { useLayoutFlex, useNoBottomPaddingLayout } from '../Layout';

import { WizardLayout } from './Layout/WizardLayout';
import {
  useGoToStepInWizard,
  useUpdateStepInWizardBasedOnQueryParams,
} from './hooks';
import {
  type ContextAction,
  type OnNextPayload,
  type StepDefinition,
  type StepProps,
  type WizardContext,
} from './Wizard.types';

import * as S from './Layout/WizardLayout.styles';

type Props<T> = {
  steps: StepDefinition<T>[];
  context: WizardContext<T>;
  updateContext: Dispatch<ContextAction<T>>;
  wizardLayoutProps?: Partial<React.ComponentProps<typeof WizardLayout>>;
  onNext?: (payload?: OnNextPayload<T>) => Promise<void> | void;
  // when completing whole wizard
  onComplete?: () => void;
  // when completing one step of wizard
  onCompleteStep?: (
    attributes?: Record<string, unknown>
  ) => Promise<void> | void;
  showProgressBar?: boolean;
  expandablePanel?: React.ReactNode;
  enabled?: boolean;
};

export const Wizard = <T,>({
  context,
  updateContext,
  wizardLayoutProps,
  onNext,
  onComplete,
  onCompleteStep,
  showProgressBar = true,
  expandablePanel,
  enabled = true,
}: Props<T>) => {
  useNoBottomPaddingLayout();
  useLayoutFlex();
  const isBackVisible =
    context.stepIndex > 0 ||
    (context.substepIndex != null && context.substepIndex > 0);
  const currentStepConfig = context.steps[context.stepIndex];
  const currentStepSubstepsCount = currentStepConfig?.substeps?.length;
  const isCurrentStepLast =
    context.steps.length === context.stepIndex + 1 &&
    (!currentStepSubstepsCount ||
      currentStepSubstepsCount - 1 === context.substepIndex);
  const showProgress =
    showProgressBar && currentStepConfig?.showProgressBar !== false;

  useUpdateStepInWizardBasedOnQueryParams({ context, updateContext, enabled });

  const { next, nextStep, nextSubstep, back, isNavigating } =
    useGoToStepInWizard({
      context,
      updateContext,
      onComplete,
      onNext,
    });

  const commonComponentProps: Omit<StepProps<T>, 'step'> = {
    progress: context.progress,
    context: context.data,
    updateContext,
    nextStep: nextStep?.definition,
    nextSubstep: nextSubstep?.definition,
    onNext: next,
    onBack: isBackVisible ? back : undefined,
    isNavigating,
    isCurrentStepLast,
    onCompleteStep,
    toggleSidebarOpen: wizardLayoutProps?.toggleSidebarOpen,
  };

  // render step component
  let component: React.ReactNode = undefined;
  if (currentStepSubstepsCount) {
    const currentSubstepConfig =
      currentStepConfig?.substeps?.[context.substepIndex ?? -1];
    if (currentSubstepConfig) {
      component = currentSubstepConfig.component?.({
        step: currentSubstepConfig,
        ...commonComponentProps,
      });
    }
  } else {
    component = currentStepConfig?.component?.({
      step: currentStepConfig,
      ...commonComponentProps,
    });
  }

  // render progress bar
  const progress = (
    <S.ProgressWrapper>
      <ProgressBar
        currentStep={context.stepIndex + 1}
        title={currentStepConfig?.title}
        totalSteps={context.steps.length}
      />
    </S.ProgressWrapper>
  );

  if (currentStepConfig?.layout === false) {
    return (
      <ErrorBoundary FallbackComponent={ErrorSkeleton}>
        {showProgress && progress}
        {component}
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorSkeleton}>
      {showProgress && progress}
      <WizardLayout
        body={component}
        progressBarShown={showProgress}
        expandablePanel={expandablePanel}
        {...{ ...wizardLayoutProps }}
      />
    </ErrorBoundary>
  );
};
