import { RBAC } from '@npm/core/ui/models/permissions';
import {
  type secondMarketRouteKeys,
  componentLoader,
} from '@npm/core/ui/routes';
import { type RouteDefinition } from '@npm/core/ui/routes/router.types';
import { ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE } from '@npm/features/auth/user/role/roleOverrides';
import { IndividualInvestorPage } from '@npm/features/second-market/individual-investor-landing/IndividualInvestor.types';

type RouteDef = RouteDefinition & {
  path: secondMarketRouteKeys;
};

export const workstationPrefix = 'second-market';

export const registerRoutes = (desc: RouteDefinition[]) => {
  routes.forEach(r => {
    desc.push(r);
  });
};

export const routes: RouteDef[] = [
  {
    title: 'Second Market',
    path: `/${workstationPrefix}`,
    component: componentLoader(() => import('../pages/index')),
  },
  {
    title: 'Market Feed',
    path: `/${workstationPrefix}/market-feed`,
    component: componentLoader(() => import(`../pages/market-feed`)),
    pageHistory: {
      isRoot: true,
    },
  },
  {
    title: 'Client Inventory',
    path: `/${workstationPrefix}/client-orders`,
    component: componentLoader(() => import(`../pages/client-orders`)),
    pageHistory: {
      isRoot: true,
    },
    permissionsChecks: [RBAC.brokerage.isBroNotReadOnly],
    subRoutes: [
      {
        title: 'Order Detail',
        path: `/${workstationPrefix}/client-orders/:orderItemId`,
        component: componentLoader(
          () => import(`../pages/client-orders/detail`)
        ),
        permissionsChecks: [RBAC.brokerage.isBroNotReadOnly],
      },
    ],
  },
  {
    title: 'Negotiations',
    path: `/${workstationPrefix}/negotiations`,
    component: componentLoader(() => import(`../pages/negotiations`)),
    permissionsChecks: [RBAC.brokerage.isBroNotReadOnly],
    pageHistory: {
      isRoot: true,
    },

    subRoutes: [
      {
        path: `/${workstationPrefix}/negotiations/:negotiationId`,
        component: componentLoader(
          () => import(`../pages/negotiations/detail`)
        ),
        permissionsChecks: [RBAC.brokerage.isBroNotReadOnly],
      },
    ],
  },
  {
    title: 'Company Data',
    path: `/${workstationPrefix}/company-data`,
    component: componentLoader(() => import(`../pages/company-data`)),
    pageHistory: {
      isRoot: true,
    },
  },
  {
    title: 'Second Market',
    path: `/${workstationPrefix}/sponsored-investor`,
    component: componentLoader(() => import(`../pages/sponsored-investor`)),
    pageHistory: {
      isRoot: true,
    },
    permissionsChecks: [RBAC.investor.isSponsoredInvestor],
    roles: {
      overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
    },
  },
  {
    title: 'Market Feed',
    path: `/${workstationPrefix}/sponsored-investor/market-feed`,
    component: componentLoader(
      () => import(`../pages/sponsored-investor/market-feed`)
    ),
    pageHistory: {
      isRoot: true,
    },
    permissionsChecks: [
      RBAC.investor.isSponsoredInvestor,
      RBAC.investor.isEntityNpmsInvestor,
    ],
    roles: {
      overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
    },
  },
  {
    title: 'My Orders',
    path: `/${workstationPrefix}/sponsored-investor/orders`,
    component: componentLoader(
      () => import(`../pages/sponsored-investor/orders`)
    ),
    pageHistory: {
      isRoot: true,
    },
    permissionsChecks: [
      RBAC.investor.isSponsoredInvestor,
      RBAC.investor.isEntityNpmsInvestor,
    ],
    roles: {
      overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
    },
    subRoutes: [
      {
        title: 'Order Detail',
        path: `/${workstationPrefix}/sponsored-investor/orders/:orderItemId`,
        component: componentLoader(
          () => import(`../pages/client-orders/detail`)
        ),
        roles: {
          overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
        },
      },
    ],
  },
  {
    title: 'My Opportunities',
    path: `/${workstationPrefix}/sponsored-investor/negotiations`,
    component: componentLoader(
      () => import(`../pages/sponsored-investor/negotiations`)
    ),
    pageHistory: {
      isRoot: true,
    },
    roles: {
      overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
    },
    permissionsChecks: [
      RBAC.investor.isSponsoredInvestorLevel2,
      RBAC.investor.isEntityNpmsInvestor,
    ],
    subRoutes: [
      {
        path: `/${workstationPrefix}/sponsored-investor/negotiations/:negotiationId`,
        component: componentLoader(
          () => import(`../pages/negotiations/detail`)
        ),
        roles: {
          overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
        },
      },
    ],
  },
  {
    title: 'Company Data',
    path: `/${workstationPrefix}/sponsored-investor/company-data`,
    component: componentLoader(() => import(`../pages/company-data`)),
    pageHistory: {
      isRoot: true,
    },
  },
  {
    path: `/${workstationPrefix}/investor`,
    component: componentLoader(() => import(`../pages/investor`)),
    title: 'Second Market - Investor',
    roles: {
      overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
    },
    // This is a hack to ensure the page doesn't have SM Layout
    subRoutes: [
      {
        title: 'Add Watchlist',
        path: `/${workstationPrefix}/investor/add-watchlist`,
        component: componentLoader(
          () => import('../pages/investor/add-watchlist')
        ),
        permissionsChecks: [
          RBAC.investor.isIndividualNpmsInvestor,
          RBAC.investor.isInvMenuOther,
        ],
        roles: {
          overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
        },
      },
      {
        title: 'Individual Dashboard',
        path: `/${workstationPrefix}/investor/dashboard`,
        component: componentLoader(
          () => import('../pages/investor/individual')
        ),
        permissionsChecks: [
          RBAC.investor.isIndividualNpmsInvestor,
          RBAC.investor.isInvMenuOther,
        ],
        roles: {
          overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
        },
      },
      {
        title: 'Individual Dashboard',
        path: `/${workstationPrefix}/investor/${IndividualInvestorPage.companyOverview}`,
        component: componentLoader(
          () => import('../pages/investor/individual/company-overview')
        ),
        permissionsChecks: [
          RBAC.investor.isIndividualNpmsInvestor,
          RBAC.investor.isInvMenuOther,
        ],
        roles: {
          overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
        },
      },
      {
        title: 'Individual Dashboard - My Holdings',
        path: `/${workstationPrefix}/investor/${IndividualInvestorPage.holdings}`,
        component: componentLoader(
          () => import('../pages/investor/individual/my-holdings')
        ),
        permissionsChecks: [
          RBAC.investor.isIndividualNpmsInvestor,
          RBAC.investor.isInvMenuOther,
        ],
        roles: {
          overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
        },
      },
      {
        title: 'Individual Dashboard - My Orders',
        path: `/${workstationPrefix}/investor/${IndividualInvestorPage.orders}`,
        component: componentLoader(
          () => import('../pages/investor/individual/my-orders')
        ),
        permissionsChecks: [
          RBAC.investor.isIndividualNpmsInvestor,
          RBAC.investor.isInvMenuOther,
        ],
        roles: {
          overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
        },
      },
      {
        title: 'Individual Dashboard - My Opportunities',
        path: `/${workstationPrefix}/investor/${IndividualInvestorPage.opportunities}`,
        component: componentLoader(
          () => import('../pages/investor/individual/my-opportunities')
        ),
        permissionsChecks: [
          RBAC.investor.isIndividualNpmsInvestor,
          RBAC.investor.isInvMenuOther,
        ],
        roles: {
          overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
        },
      },
      {
        path: `/${workstationPrefix}/investor/sell-indication`,
        component: componentLoader(
          () => import('../pages/investor/sell-indication')
        ),
        permissionsChecks: [
          RBAC.investor.isIndividualNpmsInvestor,
          RBAC.investor.isInvMenuOther,
        ],
        roles: {
          overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
        },
        layout: {
          noHamburgerMenu: true,
        },
      },
      {
        path: `/${workstationPrefix}/sponsored-investor/opportunity/:orderItemId`,
        component: componentLoader(() => import('../pages/opportunities')),
        title: 'Opportunity',
        permissionsChecks: [
          RBAC.investor.isEntityNpmsInvestor,
          RBAC.investor.isSponsoredInvestor,
          RBAC.investor.isInvMenuOther,
        ],
      },
      {
        path: `/${workstationPrefix}/investor/opportunity/:orderItemId`,
        component: componentLoader(() => import('../pages/opportunities')),
        title: 'Opportunity',
        permissionsChecks: [
          RBAC.investor.isIndividualNpmsInvestor,
          RBAC.investor.isInvMenuOther,
        ],
      },
    ],
    pageHistory: {
      isRoot: true,
    },
  },
];
