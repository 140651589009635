import React from 'react';

import { Modal } from '@npm/core/ui/components/molecules/Modal';

import { OptInStep } from '../../OptIn.enums';

export type CancelOptInModalProps = {
  onConfirm: () => void;
  onClose: () => void;
  setOptInStep: (step: OptInStep) => void;
  open?: boolean;
};

export const CancelOptInModal = ({
  open,
  onConfirm,
  onClose,
  setOptInStep,
}: CancelOptInModalProps) => {
  return (
    <Modal
      open={open}
      onCancel={onClose}
      onOk={() => {
        setOptInStep(OptInStep.ACCOUNT_TYPE_SELECTION);
        onConfirm();
      }}
      okButtonProps={{
        color: 'error',
      }}
      okText="Leave Now"
      cancelText="Close"
      title="Leave account creation"
      size="md"
    >
      Leaving now will result in losing your progress. However, you can return
      to access the SecondMarket and trade in the marketplace whenever it suits
      you.
    </Modal>
  );
};
