import styled, { css } from 'styled-components';

import { CardBase } from '@npm/core/ui/components/atoms/CardBase';

import { OptInEntityColorVariant } from '../../OptIn.enums';

export const Card = styled(CardBase)<{
  $backgroundVariant: OptInEntityColorVariant;
  $isDarkMode: boolean;
}>`
  ${({ $backgroundVariant, $isDarkMode }) => {
    switch ($backgroundVariant) {
      case OptInEntityColorVariant.INSTITUTION:
        return css`
          background-image: url(/public/assets/optin/${$isDarkMode
            ? 'dark'
            : 'light'}/bg-card-institution.png);
        `;
      case OptInEntityColorVariant.INDIVIDUAL:
        return css`
          background-image: url(/public/assets/optin/${$isDarkMode
            ? 'dark'
            : 'light'}/bg-card-individual.png);
          background-size: cover;
        `;
    }
  }}
  background-position: bottom right;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: ${({ theme }) => theme.spacing.xxl}px;
`;
