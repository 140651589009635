import { useState } from 'react';

export const useDrawerConfirmDialog = () => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  return {
    isConfirmDialogOpen: showConfirmDialog,
    openConfirmDialog: () => setShowConfirmDialog(true),
    closeConfirmDialog: () => setShowConfirmDialog(false),
  };
};
