import { CbLabel } from '@npm/core/ui/components/atoms/Label';
import {
  type ColumnType,
  TABLE_BREAKPOINTS,
} from '@npm/core/ui/components/molecules/Table';
import { formatDate, getFullName } from '@npm/core/ui/utils/formatters';
import { type UnpackArrayType } from '@npm/core/ui/utils/unpack.type';
import { type AccountShowResponseType } from '@npm/data-access';
import { CbAccreditationState, CbBackgroundCheckState } from '@npm/data-access';

export const columns: ColumnType<
  UnpackArrayType<AccountShowResponseType, 'users'>
>[] = [
  {
    title: 'Name',
    key: 'person.first',
    render: (_, { person }) =>
      getFullName(person?.first, person?.last, person?.middle),
    responsive: TABLE_BREAKPOINTS.MOBILE,
  },
  {
    title: 'Roles',
    key: 'roles',
    render: (_, { roles }) => roles?.map(({ name }) => name).join(', '),
    responsive: TABLE_BREAKPOINTS.MOBILE,
  },
  {
    title: 'AML/KYC',
    key: 'aml_kyc_state',
    render: (_, { background_check_state }) =>
      CbBackgroundCheckState.getLabel(CbLabel, background_check_state),
    responsive: TABLE_BREAKPOINTS.DESKTOP,
  },
  {
    title: 'Accreditation',
    key: 'accreditation',
    render: (_, { accreditation_state }) =>
      CbAccreditationState.getLabel(CbLabel, accreditation_state),
    responsive: TABLE_BREAKPOINTS.DESKTOP,
  },
  {
    title: 'Last Login',
    key: 'last_login',
    render: (_, { last_login_at }) => formatDate(last_login_at) ?? '--',
    responsive: TABLE_BREAKPOINTS.DESKTOP,
  },
];
