import styled from 'styled-components';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { useBreakpoint } from '@npm/core/ui/utils/style';

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  gap: ${({ theme }) => theme.spacing.sm}px;
  max-width: 200px;

  & > a,
  button {
    flex: 1;

    svg {
      flex-shrink: 0;
    }
  }

  ${({ theme }) => useBreakpoint(theme).mobile`
    margin-left: unset;
    max-width: unset;
  `}
`;

export const DropdownButton = styled(Button)`
  && {
    flex: 0 0 40px;
  }
`;

export const Column = styled.div<{ $align: React.CSSProperties['alignItems'] }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ $align }) => $align};

  & > *:not(:first-child) {
    margin-top: ${({ theme }) => theme.spacing.xs}px;
  }
`;
