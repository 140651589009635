/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ActionRedirectUrl } from '../model';
// @ts-ignore
import { CashAccountCreateRequestContract } from '../model';
// @ts-ignore
import { CashAccountMapIndex } from '../model';
/**
 * CashAccountApi - axios parameter creator
 * @export
 */
export const CashAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List cash accounts
         * @param {string} accountId accounts &#x60;npm_guid&#x60;
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountList: async (accountId: string, xWorkstation?: string, xNPMUser?: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountList', 'accountId', accountId)
            const localVarPath = changeUrl(`/api/investor/accounts/{account_id}/cash_accounts`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xNPMUser !== undefined && xNPMUser !== null) {
                localVarHeaderParameter['X-NPM-User'] = String(xNPMUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Open a cash account
         * @param {string} accountId accounts &#x60;npm_guid&#x60;
         * @param {CashAccountCreateRequestContract} cashAccountCreateRequestContract 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountOpen: async (accountId: string, cashAccountCreateRequestContract: CashAccountCreateRequestContract, xWorkstation?: string, xNPMUser?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountOpen', 'accountId', accountId)
            // verify required parameter 'cashAccountCreateRequestContract' is not null or undefined
            assertParamExists('accountOpen', 'cashAccountCreateRequestContract', cashAccountCreateRequestContract)
            const localVarPath = changeUrl(`/api/investor/accounts/{account_id}/cash_accounts`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xNPMUser !== undefined && xNPMUser !== null) {
                localVarHeaderParameter['X-NPM-User'] = String(xNPMUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cashAccountCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CashAccountApi - functional programming interface
 * @export
 */
export const CashAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CashAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List cash accounts
         * @param {string} accountId accounts &#x60;npm_guid&#x60;
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountList(accountId: string, xWorkstation?: string, xNPMUser?: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CashAccountMapIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountList(accountId, xWorkstation, xNPMUser, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Open a cash account
         * @param {string} accountId accounts &#x60;npm_guid&#x60;
         * @param {CashAccountCreateRequestContract} cashAccountCreateRequestContract 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountOpen(accountId: string, cashAccountCreateRequestContract: CashAccountCreateRequestContract, xWorkstation?: string, xNPMUser?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionRedirectUrl>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountOpen(accountId, cashAccountCreateRequestContract, xWorkstation, xNPMUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CashAccountApi - factory interface
 * @export
 */
export const CashAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CashAccountApiFp(configuration)
    return {
        /**
         * 
         * @summary List cash accounts
         * @param {string} accountId accounts &#x60;npm_guid&#x60;
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountList(accountId: string, xWorkstation?: string, xNPMUser?: string, page?: number, size?: number, options?: any): AxiosPromise<CashAccountMapIndex> {
            return localVarFp.accountList(accountId, xWorkstation, xNPMUser, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Open a cash account
         * @param {string} accountId accounts &#x60;npm_guid&#x60;
         * @param {CashAccountCreateRequestContract} cashAccountCreateRequestContract 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountOpen(accountId: string, cashAccountCreateRequestContract: CashAccountCreateRequestContract, xWorkstation?: string, xNPMUser?: string, options?: any): AxiosPromise<ActionRedirectUrl> {
            return localVarFp.accountOpen(accountId, cashAccountCreateRequestContract, xWorkstation, xNPMUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for accountList operation in CashAccountApi.
 * @export
 * @interface CashAccountApiAccountListRequest
 */
export interface CashAccountApiAccountListRequest {
    /**
     * accounts &#x60;npm_guid&#x60;
     * @type {string}
     * @memberof CashAccountApiAccountList
     */
    readonly accountId: string

    /**
     * 
     * @type {string}
     * @memberof CashAccountApiAccountList
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof CashAccountApiAccountList
     */
    readonly xNPMUser?: string

    /**
     * 
     * @type {number}
     * @memberof CashAccountApiAccountList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof CashAccountApiAccountList
     */
    readonly size?: number
}

/**
 * Request parameters for accountOpen operation in CashAccountApi.
 * @export
 * @interface CashAccountApiAccountOpenRequest
 */
export interface CashAccountApiAccountOpenRequest {
    /**
     * accounts &#x60;npm_guid&#x60;
     * @type {string}
     * @memberof CashAccountApiAccountOpen
     */
    readonly accountId: string

    /**
     * 
     * @type {CashAccountCreateRequestContract}
     * @memberof CashAccountApiAccountOpen
     */
    readonly cashAccountCreateRequestContract: CashAccountCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof CashAccountApiAccountOpen
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof CashAccountApiAccountOpen
     */
    readonly xNPMUser?: string
}

/**
 * CashAccountApi - object-oriented interface
 * @export
 * @class CashAccountApi
 * @extends {BaseAPI}
 */
export class CashAccountApi extends BaseAPI {
    /**
     * 
     * @summary List cash accounts
     * @param {CashAccountApiAccountListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashAccountApi
     */
    public accountList(requestParameters: CashAccountApiAccountListRequest, options?: AxiosRequestConfig) {
        return CashAccountApiFp(this.configuration).accountList(requestParameters.accountId, requestParameters.xWorkstation, requestParameters.xNPMUser, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Open a cash account
     * @param {CashAccountApiAccountOpenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashAccountApi
     */
    public accountOpen(requestParameters: CashAccountApiAccountOpenRequest, options?: AxiosRequestConfig) {
        return CashAccountApiFp(this.configuration).accountOpen(requestParameters.accountId, requestParameters.cashAccountCreateRequestContract, requestParameters.xWorkstation, requestParameters.xNPMUser, options).then((request) => request(this.axios, this.basePath));
    }
}

export type AccountListErrorTypes =

    | {
        status: 401;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AccountListErrorCodes = [
    401,
];

export type AccountOpenErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
    }

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AccountOpenErrorCodes = [
    401,
    404,
    422,
];


