import { memo } from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { formatStringDate } from '@npm/core/ui/utils/formatters';
import { type VenusApi } from '@npm/data-access';

import * as S from './FinancingHistoryChart.styles';

type Props = {
  activity: VenusApi.FinancialActivity;
};

export const FinancingHistoryChartTooltip = memo(
  function FinancingHistoryChartTooltip({ activity }: Props) {
    if (!activity) return null;

    return (
      <S.TooltipCard>
        <S.TooltipHeader>
          <S.TooltipLabel size="sm" colorVariant="primary">
            {activity.name}
          </S.TooltipLabel>
          <Text size="sm" as="span">
            {formatStringDate(activity.date)}
          </Text>
        </S.TooltipHeader>
        <S.TooltipValueDisplay>
          <Flex align="center" gap="sm" style={{ width: '100%' }}>
            <S.TooltipCol>
              <Text size="xs">Price Per Share</Text>
              <Text.Price
                value={activity.price_per_share}
                colorVariant="primary"
              />
            </S.TooltipCol>
            <S.TooltipCol>
              <Text size="xs">Estimated Val.</Text>
              <Text.Price
                value={activity.valuation}
                colorVariant="primary"
                compact
              />
            </S.TooltipCol>
          </Flex>
        </S.TooltipValueDisplay>
        <Flex align="center" gap="md" style={{ width: '100%' }}>
          <Text size="xs">Investment Amount</Text>
          <Text.Price
            value={activity.investment_amount}
            colorVariant="primary"
            compact
          />
        </Flex>
      </S.TooltipCard>
    );
  }
);
