/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  InvestorSubmissionsExportApi,
  InvestorSubmissionsExportShowErrorCodes,
  InvestorSubmissionsExportShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new InvestorSubmissionsExportApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type InvestorSubmissionsExportShowMethod = InvestorSubmissionsExportApi['investorSubmissionsExportShow'];
export type InvestorSubmissionsExportShowResponseType = MethodResult<InvestorSubmissionsExportShowMethod>;

export const useInvestorSubmissionsExportShow = (
  variables: Parameters<InvestorSubmissionsExportShowMethod>[0],
  config?: UseQueryConfig<
    InvestorSubmissionsExportShowMethod,
    InvestorSubmissionsExportShowErrorTypes
  >
) => {
  return useQuery<
    InvestorSubmissionsExportShowMethod,
    InvestorSubmissionsExportShowErrorTypes
  >(
    (...args) => api.investorSubmissionsExportShow(...args),
    InvestorSubmissionsExportShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InvestorSubmissionsExportShow'],
        ...apiQueryConfig.InvestorSubmissionsExportShow,
        ...config?.queryConfig
      },
    },
    'InvestorSubmissionsExportShow',
  );
};

export const useInvestorSubmissionsExportShowInfinite = (
  variables: Parameters<InvestorSubmissionsExportShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    InvestorSubmissionsExportShowMethod,
    InvestorSubmissionsExportShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    InvestorSubmissionsExportShowMethod,
    InvestorSubmissionsExportShowErrorTypes
  >(
    (...args) => api.investorSubmissionsExportShow(...args),
    InvestorSubmissionsExportShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InvestorSubmissionsExportShow', 'InvestorSubmissionsExportShowInfinite'],
        ...apiQueryConfig.InvestorSubmissionsExportShow,
        ...config?.queryConfig,
      },
    },
    'InvestorSubmissionsExportShow',
  );
};

export const useInvestorSubmissionsExportShowLazy = (baseOptions?: {
  variables?: Parameters<InvestorSubmissionsExportShowMethod>[0],
  config?: UseQueryConfig<
    InvestorSubmissionsExportShowMethod,
    InvestorSubmissionsExportShowErrorTypes
  >
}) => {
  return useLazyQuery<
    InvestorSubmissionsExportShowMethod,
    InvestorSubmissionsExportShowErrorTypes
  >(
    (...args) => api.investorSubmissionsExportShow(...args),
    InvestorSubmissionsExportShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InvestorSubmissionsExportShow'],
        ...apiQueryConfig.InvestorSubmissionsExportShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InvestorSubmissionsExportShow',
  );
};

