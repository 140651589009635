import Big from 'big.js';

import {
  type PriceOrQuanitySingleValue,
  type PriceOrQuantityValue,
} from '@npm/core/ui/components/atoms/Typography';

const applyFee = (
  value: number | PriceOrQuanitySingleValue,
  action: 'buy' | 'sell'
): number => {
  const bigValue = new Big(value);
  const result =
    action === 'buy'
      ? bigValue.times(1 + EXECUTION_FEE)
      : bigValue.times(1 - EXECUTION_FEE);
  return result.toNumber();
};

const normalizeValues = (
  value: PriceOrQuantityValue,
  action: 'buy' | 'sell'
): PriceOrQuantityValue => {
  if (Array.isArray(value)) {
    const output: number[] = [];
    value.map(v => {
      if (v) {
        output.push(applyFee(v, action));
      }
    });

    return output as PriceOrQuantityValue;
  }
  return applyFee(value, action);
};

export const calculateEstimatedPrice = (
  price: PriceOrQuantityValue,
  action: 'buy' | 'sell'
): PriceOrQuantityValue => {
  if (!!price && !!action) {
    return normalizeValues(price, action);
  }
  return '--';
};

export const EXECUTION_FEE = 0.01;
