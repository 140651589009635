/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  TsMatchActivityUploadStnaApi,
  TsMatchActivityUploadStnaUpdateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new TsMatchActivityUploadStnaApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type TsMatchActivityUploadStnaUpdateMethod = TsMatchActivityUploadStnaApi['tsMatchActivityUploadStnaUpdate'];

export const useTsMatchActivityUploadStnaUpdate = () => {
  return useMutation<TsMatchActivityUploadStnaUpdateMethod>(
    (...args) => api.tsMatchActivityUploadStnaUpdate(...args),
    TsMatchActivityUploadStnaUpdateErrorCodes,
    'TsMatchActivityUploadStnaUpdate',
    '/api/ts-match-activity-upload-stnas/{match_activity_id}',
    'put'
  );
};
