import styled, { css } from 'styled-components';

import { Skeleton } from '../../atoms/Skeleton';
import { Card as CardBase } from '../../molecules/Card';

import { type StatsVariant } from './StatisticCard';

const ICON_WRAPPER_SIZE = 48;

export const Container = styled.div`
  display: flex;
  ${({ theme }) => `padding: ${theme.spacing.md - 2}px`};

  & > *:first-child {
    margin-right: ${({ theme }) => theme.spacing.md}px;
  }
`;

export const IconWrapper = styled.div<{
  $variant?: StatsVariant;
}>`
  height: ${ICON_WRAPPER_SIZE}px;
  min-width: ${ICON_WRAPPER_SIZE}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.md}px;
  background-color: ${({ theme }) =>
    theme.color.info.backgrounds.secondaryDefault};
  color: ${({ theme }) => theme.color.info.typography.primary};

  ${({ $variant, theme }) =>
    $variant &&
    ($variant === 'general'
      ? css`
          border: 1px solid ${theme.color.general.typography.secondary};
          color: ${theme.color.general.typography.secondary};
          background-color: ${theme.color.general.layout.two};
        `
      : css`
          border: 1px solid ${theme.color[$variant].borders.primary};
          color: ${theme.color[$variant].typography.primary};
          background-color: ${theme.color[$variant].backgrounds
            .secondaryDefault};
        `)};
`;

export const TitleContainer = styled.div<{ $withTextWrap: boolean }>`
  display: flex;
  align-items: center;
  ${({ $withTextWrap }) => ($withTextWrap ? null : 'white-space: nowrap')};

  > span {
    line-height: ${({ theme }) => theme.icon.size.xs}px;
  }

  svg {
    color: ${({ theme }) => theme.color.info.typography.primary};
    margin-left: ${({ theme }) => theme.spacing.sm}px;
  }
`;

export const Badge = styled.div`
  margin-left: ${({ theme }) => theme.spacing.sm}px;
  padding-left: ${({ theme }) => theme.spacing.sm}px;
`;

export const SkeletonBase = styled(Skeleton.Base).attrs({})`
  .ant-skeleton-content .ant-skeleton-paragraph > li {
    height: ${({ theme }) => theme.typography.size.sm}px;
    border-radius: 0;

    & + li {
      margin-top: 12px;
    }
  }
`;

export const SkeletonAvatar = styled(Skeleton.Avatar)`
  .ant-skeleton-avatar {
    height: ${ICON_WRAPPER_SIZE}px;
    width: ${ICON_WRAPPER_SIZE}px;
  }
`;

export const Card = styled(CardBase)`
  .card-content-container {
    margin: 0;
  }
`;
