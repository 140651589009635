import { format } from 'date-fns';

import { sanitizeFilename } from './sanitizeFilename';

export const downloadBlob = (blob: Blob, fileName: string) => {
  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(blob);
  a.download = fileName;
  a.click();
};

export const downloadText = (response, prefix = 'LOG_', extension = '.csv') => {
  const time = format(new Date(), 'MMM_dd_yyyy_hh_mm_aaa');
  const fileName =
    sanitizeFilename(prefix).replace(/\./g, '') + time + extension;
  const blob = new Blob([response]);
  downloadBlob(blob, fileName);
};
