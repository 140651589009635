import React from 'react';

import { BottomNavigation } from '@npm/core/ui/components/atoms/BottomNavigation';
import { Button } from '@npm/core/ui/components/atoms/Button';
import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';
import { Wizard } from '@npm/core/ui/components/organisms/Wizard';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';

import { BasicPageLayout } from '../../layout';

import * as S from './FullPageLayout.styles';

type Props = {
  children: React.ReactNode;
  goToNextIncompleteStep: () => void;
  isLoading?: boolean;
  isMobileChecklistOpen?: boolean;
};

export const FullPageLayout = ({
  children,
  goToNextIncompleteStep,
  isLoading,
  isMobileChecklistOpen,
}: Props) => {
  const { isMobile, isTablet } = useBreakpoints();

  return (
    <BasicPageLayout>
      <S.Wrap>
        {isLoading ? <Skeleton.Base /> : <>{children}</>}
        <Wizard.ActionBarPlaceholder />
        {/* mobile action bar (for desktop, it is part of StepContainer): */}
        {(isMobile || isTablet) && isMobileChecklistOpen && (
          <BottomNavigation
            backButton={
              <Button onClick={goToNextIncompleteStep}>
                Continue Onboarding
              </Button>
            }
          />
        )}
      </S.Wrap>
    </BasicPageLayout>
  );
};
