import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';

import {
  type UserUITheme,
  CbUserUITheme,
  DEFAULT_THEME,
} from '@npm/data-access';

/* eslint-disable react/no-multi-comp */

type DarkModeContextType = {
  isDarkMode: boolean;
  setTheme: (theme: UserUITheme) => void;
  getTheme: () => UserUITheme;
};

export const DarkModeContext = createContext<DarkModeContextType>({
  isDarkMode: true,
  setTheme: () => {
    // ignore
    console.warn('[DarkModeContext] This should not happen!');
  },
  getTheme: () => {
    // ignore
    console.warn('[DarkModeContext] This should not happen!');
    return DEFAULT_THEME;
  },
});

const LOCAL_STORAGE_THEME_KEY = 'theme';

/**
 * Hook which automatically reads theme from localstorage.
 * If not set, it reads system theme and listens to changes.
 */
const useDarkMode = () => {
  const [mode, setMode] = useLocalStorage(
    LOCAL_STORAGE_THEME_KEY,
    DEFAULT_THEME,
    {
      serializer: (value: UserUITheme) => value,
      deserializer: (value: unknown) => {
        // If something weird in localstorage, fallback to default.
        const isValid = Object.values(CbUserUITheme.items).includes(
          value as UserUITheme
        );
        return isValid ? (value as UserUITheme) : DEFAULT_THEME;
      },
    }
  );

  const isDarkMode = mode === CbUserUITheme.items.dark;

  // Sets the theme in localStorage
  const setTheme = (theme: UserUITheme) => {
    setMode(theme);
  };

  // Get's the current theme from localStorage
  const getTheme = () => {
    return mode as UserUITheme;
  };

  return { isDarkMode, setTheme, getTheme };
};

export const DarkModeProvider: React.FC = ({ children }) => {
  const { isDarkMode, setTheme, getTheme } = useDarkMode();

  return (
    <DarkModeContext.Provider value={{ isDarkMode, setTheme, getTheme }}>
      {children}
    </DarkModeContext.Provider>
  );
};

export const StorybookDarkModeProvider: React.FC<{ defaultValue: boolean }> = ({
  children,
  defaultValue,
}) => {
  const [isDarkMode, setIsDarkMode] = useState(defaultValue);

  const setTheme = (theme: UserUITheme) => {
    setIsDarkMode(theme === CbUserUITheme.items.dark);
  };

  const getTheme = () => {
    return isDarkMode ? CbUserUITheme.items.dark : CbUserUITheme.items.light;
  };

  useEffect(() => {
    setIsDarkMode(defaultValue);
  }, [defaultValue]);

  return (
    <DarkModeContext.Provider value={{ isDarkMode, setTheme, getTheme }}>
      {children}
    </DarkModeContext.Provider>
  );
};

export const useDarkModeContext = () => useContext(DarkModeContext);
