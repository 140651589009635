import React from 'react';

import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { Wizard } from '@npm/core/ui/components/organisms/WizardNew';
import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import { type WizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';
import { CypressDataIds } from '@npm/core/ui/constants';

import {
  EntityInformationForm,
  ReadOnlyContactInformation,
  useEntityInformationForm,
} from '../../../onboardingAndSubmissionSteps/ContactInformation';
import { type PostOnboardingContextType } from '../../PostOnboarding.types';
import { StepContainer } from '../../StepContainer';

type Props = {
  stepProps: Wizard.StepProps;
};

const { StepState } = Wizard;

export const EntityInformationPostOnboarding = ({ stepProps }: Props) => {
  const { account, stepState } = useWizardStore(
    (s: WizardStore<PostOnboardingContextType>) => ({
      account: s.data.account,
      stepState: s.activeStepState,
    })
  );

  const { form, data, isLoading, handleSubmit, isUpdating } =
    useEntityInformationForm(account.organization_id, {
      onUpdate: () => {
        Notification.success({
          message: 'You have updated your Account Entity information',
        });
        stepProps.handleStepComplete();
      },
    });

  return (
    <StepContainer
      stepProps={stepProps}
      isLoading={isLoading}
      isUpdating={isUpdating}
      handleSubmit={handleSubmit}
      data-cy={CypressDataIds.PostOnboarding.EntityInformation.Step}
    >
      {stepState === StepState.EDIT && (
        <EntityInformationForm
          form={form}
          initialCountryCode={data?.contact_information?.country.code}
          isLoading={isLoading}
          isFilled={!!data?.contact_information}
          handleValuesChange={() => stepProps.setFormHasUnsavedChanges(true)}
        />
      )}
      {stepState === StepState.FILLED && (
        <ReadOnlyContactInformation
          data={data}
          updateButtonText="Update Contact Information"
        />
      )}
    </StepContainer>
  );
};
