import { useEffect } from 'react';
import { type FormInstance, Form } from 'antd';
import { NumberParam, useQueryParam } from 'use-query-params';

import {
  type AffiliateDisclosureType,
  type Holding,
  type OrderSourceCode,
  type SecondmarketSubmissionCreateRequestContract,
  CbOrderEntryType,
  CbOrderItemUnit,
  CbOrderSource,
  CbSubmissionStructure,
  CbVisibility,
  useAccountShow,
  useIssuerEntityShow,
} from '@npm/data-access';

import { type OboDefinition } from '../../../../auth/user/role/userRole.types';
import {
  type OrderSizeType,
  sizeInShares,
  toShares,
  toUSD,
} from '../../../../order';
import { MINIMUM_NOTIONAL_VALUE } from '../../utils/minNotionalValue';
import { type OrderPlacementFormValues } from '..';

export const useDrawerStorage = () => {
  const getFormData = () => {
    const savedData = window.localStorage.getItem('ORDER_ENTRY_FORM_DATA');
    return savedData
      ? (JSON.parse(savedData) as OrderPlacementFormValues)
      : null;
  };

  const setFormData = (data: Record<string, unknown>) =>
    window.localStorage.setItem('ORDER_ENTRY_FORM_DATA', JSON.stringify(data));

  const setFormSizeType = (val: OrderSizeType) =>
    window.localStorage.setItem('ORDER_ENTRY_SIZE_TYPE', val);

  const getFormSizeType = () => {
    const savedData = window.localStorage.getItem('ORDER_ENTRY_SIZE_TYPE');
    return savedData ? (savedData as OrderSizeType) : null;
  };

  const clearSavedData = () => {
    window.localStorage.removeItem('ORDER_ENTRY_FORM_DATA');
    window.localStorage.removeItem('ORDER_ENTRY_SIZE_TYPE');
  };

  return {
    getFormData,
    setFormData,
    getFormSizeType,
    setFormSizeType,
    clearSavedData,
  };
};

export const useIssuerEntity = ({
  form,
  previewValues,
  isOpen,
}: {
  form: FormInstance<OrderPlacementFormValues>;
  previewValues: OrderPlacementFormValues;
  isOpen: boolean;
}) => {
  const [preselectedIssuerEntityId] = useQueryParam('companyId', NumberParam);
  const formIssuerEntityId = Form.useWatch('issuerEntityId', form);

  useEffect(() => {
    if (
      !form.getFieldValue('issuerEntityId') &&
      preselectedIssuerEntityId &&
      isOpen
    ) {
      form.setFieldValue('issuerEntityId', preselectedIssuerEntityId);
    }
  }, [form, preselectedIssuerEntityId, isOpen]);

  const issuerEntityId = formIssuerEntityId ?? previewValues?.issuerEntityId;

  const { data, isLoading } = useIssuerEntityShow(
    { id: issuerEntityId?.toString() },
    { queryConfig: { enabled: !!issuerEntityId } }
  );

  return {
    preselectedIssuerEntityId,
    issuerEntityId,
    issuerEntity: data,
    isLoading,
  };
};

export const useAccount = ({
  form,
  previewValues,
  obo,
}: {
  form: FormInstance<OrderPlacementFormValues>;
  previewValues: OrderPlacementFormValues;
  obo: OboDefinition | null;
}) => {
  const formAccountId = Form.useWatch('accountId', form);

  const accountId =
    obo?.account?.id ?? formAccountId ?? previewValues?.accountId;

  useEffect(() => {
    if (obo?.account?.id != null && formAccountId !== obo.account.id) {
      form.setFieldValue('accountId', obo?.account?.id);
    }
  }, [obo, formAccountId]);

  const { data, isLoading } = useAccountShow(
    { id: accountId?.toString() },
    { queryConfig: { enabled: !!accountId } }
  );

  return {
    accountId,
    account: data,
    isLoading,
  };
};

export const getSubmitPayload = (
  values: OrderPlacementFormValues,
  isObo: boolean,
  sizeType: OrderSizeType,
  source: OrderSourceCode | undefined,
  signature?: string,
  holding?: Holding,
  affiliateDisclosure?: AffiliateDisclosureType
): SecondmarketSubmissionCreateRequestContract => {
  const submissionCreateRequestContract: SecondmarketSubmissionCreateRequestContract =
    {
      include_field: ['items', 'note'],
      issuer_entity_id: values.issuerEntityId,
      account_id: isObo ? undefined : values.accountId,
      // OBO can select any order type, entities cannot select soft IOI ("Invite Only") and default to (exclusive) IOI
      order_entry_type: values.orderType || CbOrderEntryType.items.ioi,
      ats_pool: values.atsPool,
      note: values.note,
      affiliate_disclosure: affiliateDisclosure,

      ...(signature ? { signature } : {}),

      ...(values.pricePerShare || sizeType === 'Shares'
        ? {
            // If PPS is defined, we always send rounded size in shares
            order_item_unit: CbOrderItemUnit.items.SHARES,
            quantity: sizeInShares(
              sizeType,
              values.quantity,
              values.pricePerShare
            ),
            minimum_quantity: sizeInShares(
              sizeType,
              values.minimumQuantity,
              values.pricePerShare
            ),
            price: values.pricePerShare,
          }
        : {
            order_item_unit: CbOrderItemUnit.items.USD,
            quantity: values.quantity,
            minimum_quantity: values.minimumQuantity,
          }),

      gtc: values.goodTillCancelled,
      expiration: values.expiration?.toISOString(),

      order_buy_sell: values.buySell,
      ...(values.buySell === 'buy'
        ? {
            asset_type: values.assetType,
          }
        : values.buySell === 'sell'
        ? {
            holding_npm_guid: holding.npm_guid,
            holding_id: values.holdingId,
            data_room: values.dataRoom,
            rofr: values.rofr,
          }
        : {}),

      structure: values.structure,
      ...(values.structure === CbSubmissionStructure.items.spv &&
        values.hasSpvStructureFee && {
          has_spv_structure_fee: true,
          spv_management_fee: values.spvManagementFee,
          spv_carried_interest_fee: values.spvCarriedInterestFee,
        }),
      ...(values.structure !== CbSubmissionStructure.items.spv &&
        values.hasNonSpvStructureFee && {
          has_non_spv_structure_fee: true,
          structure_percentage_fee: values.structurePercentageFee,
        }),

      order_received_date: values.orderReceivedDate?.toISOString(),
      ...(source === CbOrderSource.items.historical && {
        source,
        state: values.state,
        order_published_date:
          values.visibility === CbVisibility.items.external
            ? (values.orderPublishedDate ?? new Date())?.toISOString()
            : undefined,
        placed_at_date: values.placedAtDate?.toISOString(),
        gtc: values.goodTillCancelled,
        expiration:
          values.visibility === CbVisibility.items.internal
            ? values.expiration?.toISOString()
            : (values.expiration ?? new Date()).toISOString(),
      }),
    };
  return submissionCreateRequestContract;
};

export const validateMinimumNotionalValue = ({
  pricePerShare,
  minimumSize,
  size,
  sizeType,
}: {
  pricePerShare: number;
  minimumSize: number;
  size: number;
  sizeType: OrderSizeType;
}): boolean => {
  if (!pricePerShare) {
    // If no PPS and the size is specified in USD, validate the input values
    if (sizeType === 'USD') {
      return (minimumSize ?? size) >= MINIMUM_NOTIONAL_VALUE;
    }
    return true; // Cannot validate, so allow
  }

  // Otherwise, apply rounding and recalculate size in USD
  const sizeInShares =
    sizeType === 'USD'
      ? toShares(minimumSize ?? size, pricePerShare)
      : minimumSize ?? size;

  const sizeInUSD = toUSD(sizeInShares, pricePerShare);

  return sizeInUSD >= MINIMUM_NOTIONAL_VALUE;
};
