/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  HoldingsDistributionCalculationApi,
  HoldingsDistributionCalculationIndexErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new HoldingsDistributionCalculationApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type HoldingsDistributionCalculationIndexMethod = HoldingsDistributionCalculationApi['holdingsDistributionCalculationIndex'];

export const useHoldingsDistributionCalculationIndex = () => {
  return useMutation<HoldingsDistributionCalculationIndexMethod>(
    (...args) => api.holdingsDistributionCalculationIndex(...args),
    HoldingsDistributionCalculationIndexErrorCodes,
    'HoldingsDistributionCalculationIndex',
    '/api/holding-distribution-calculation',
    'post'
  );
};
