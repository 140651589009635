import {
  type PaddingProps,
  Padding,
} from '@npm/core/ui/components/atoms/common';

import * as S from './CompanyOverviewChartContainer.styles';

type Props = {
  children: React.ReactNode;
  paddingProps?: PaddingProps;
  className?: string;
};

export const CompanyOverviewChartContainer = ({
  children,
  className,
  paddingProps,
}: Props) => {
  const usedPaddingProps = paddingProps ?? { all: 'md', top: 'xs' };

  return (
    <Padding {...usedPaddingProps}>
      <S.ChartContainer className={className}>{children}</S.ChartContainer>
    </Padding>
  );
};
