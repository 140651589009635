import React, { useMemo } from 'react';
import { useWizard } from 'react-use-wizard';
import { AnimatePresence, motion } from 'framer-motion';

import {
  type StepperItemVariant,
  StepperItem,
} from '@npm/core/ui/components/atoms/StepperItem';
import { useHandleSidebarItemClick } from '@npm/core/ui/components/organisms/WizardNew/Layout/Sidebar/Sidebar.hooks';
import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import { type WizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';
import { expandCollapse } from '@npm/core/ui/utils/animations';
import { CbOnboardingStatus } from '@npm/data-access';

import {
  type OnboardingStep,
  type PostOnboardingContextType,
} from '../../PostOnboarding.types';
import { isOnboardingStepIncomplete } from '../../PostOnboarding.utils';
import { getParentItemProps } from '../Sidebar.utils';

import * as S from '../SidebarSection/SidebarSection.styles';

const stateToStepperItemVariantMap: Record<
  keyof typeof CbOnboardingStatus['items'],
  StepperItemVariant
> = {
  [CbOnboardingStatus.items.incomplete]: 'default',
  [CbOnboardingStatus.items.warning]: 'error',
  [CbOnboardingStatus.items.completed]: 'done',
  [CbOnboardingStatus.items.not_applicable]: 'default',
};

type Props = {
  step: OnboardingStep;
  handleCloseFormAttempt: (callback?: () => void) => void;
  testId?: string;
};

export const SidebarItem = ({
  step,
  handleCloseFormAttempt,
  testId,
}: Props) => {
  const { activeStep } = useWizard();
  const { steps, onboardingStatus } = useWizardStore(
    (s: WizardStore<PostOnboardingContextType>) => ({
      steps: s.steps,
      onboardingStatus: s.data.onboardingStatus,
    })
  );
  const handleItemClick = useHandleSidebarItemClick(handleCloseFormAttempt);

  const hasChildren = !!step.substeps?.length;
  const { state, totalSubsteps, totalSubstepsCompleted } = getParentItemProps(
    onboardingStatus,
    step
  );
  const variant = stateToStepperItemVariantMap[state];
  const activeStepKey = steps[activeStep]?.key;
  const isExpanded = useMemo(
    () => !!step.substeps?.find(s => s.key === activeStepKey),
    [step.substeps, activeStepKey]
  );

  const handleClick = (stepKey: string, isParentClicked?: boolean) => {
    let nextStepKey = stepKey;
    // if an item with children is clicked, open the first incomplete step,
    // if all are completed, open the first step
    if (isParentClicked && hasChildren) {
      const incompleteSubstep = step.substeps.find(substep =>
        isOnboardingStepIncomplete(onboardingStatus[substep.key]?.code)
      );
      nextStepKey = incompleteSubstep
        ? incompleteSubstep.key
        : step.substeps[0].key;
    }

    const stepIndex = steps.findIndex(s => s.key === nextStepKey);
    handleItemClick(stepIndex);
  };

  return (
    <S.ItemWrapper
      $isActive={activeStepKey === step.key}
      $areChildrenExpanded={hasChildren && isExpanded}
    >
      <StepperItem
        variant={variant}
        size="sm"
        isExpanded={isExpanded}
        isActive={activeStepKey === step.key}
        onClick={() => handleClick(step.key, true)}
        title={step.title}
        extra={
          isExpanded ? `${totalSubstepsCompleted} / ${totalSubsteps}` : null
        }
        tooltip={step.tooltip}
        testId={testId}
      />
      <div style={{ overflow: 'hidden' }}>
        <AnimatePresence initial={false}>
          {hasChildren && isExpanded && (
            <motion.div {...expandCollapse}>
              {step.substeps.map(s => {
                const isSubstepActive = s.key === activeStepKey;
                const substepState = onboardingStatus[s.key]?.code;
                const substepVariant =
                  stateToStepperItemVariantMap[substepState];

                return (
                  <StepperItem
                    key={s.key}
                    size="sm"
                    title={s.title}
                    onClick={() => handleClick(s.key)}
                    variant={substepVariant}
                    isActive={isSubstepActive}
                    iconProps={{ hasBackground: false }}
                    testId={testId}
                  />
                );
              })}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </S.ItemWrapper>
  );
};
