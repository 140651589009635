import React from 'react';

import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import { type WizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';

import { type OnboardingSectionConfig } from '../../postOnboardingNew/PostOnboarding.types';
import { type PostOnboardingContextType } from '../PostOnboarding.types';

import { SidebarSection } from './SidebarSection';

export type SidebarConfig = OnboardingSectionConfig[];

type Props = {
  sidebarConfig: SidebarConfig;
  handleCloseFormAttempt?: (callback?: () => void) => void;
};

export const Sidebar = ({ sidebarConfig, handleCloseFormAttempt }: Props) => {
  const { activeSection, onboardingStatus } = useWizardStore(
    (s: WizardStore<PostOnboardingContextType>) => ({
      activeSection: s.data.activeSectionProps?.activeSection,
      onboardingStatus: s.data.onboardingStatus,
    })
  );

  if (!onboardingStatus || !activeSection) return null;

  return (
    <>
      {sidebarConfig.map(section => (
        <SidebarSection
          key={section.key}
          sectionKey={section.key}
          isActive={section.key === activeSection.key}
          handleCloseFormAttempt={handleCloseFormAttempt}
          {...section}
        />
      ))}
    </>
  );
};
