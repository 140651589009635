import {
  type CbLabelComponent,
  type IconNames,
  type LabelVariant,
} from '@npm/utils';

import { type CodebookItem, Codebooks } from './codebooks.types';

const Items = {
  none: 'none',
  pending: 'pending',
  failed: 'failed',
  passed: 'passed',
  expired: 'expired',
  more_info_needed: 'more_info_needed',
  not_started: 'not_started',
  pending_watchlist: 'pending_watchlist',
  marked_for_review: 'marked_for_review',
} as const;

type Keys = keyof typeof Items;

const LabelMap: Record<Keys, LabelVariant> = {
  none: 'general',
  expired: 'general',
  pending: 'warning',
  passed: 'success',
  more_info_needed: 'info',
  failed: 'error',
  not_started: 'general',
  pending_watchlist: 'warning',
  marked_for_review: 'warning',
};

const LabelIconMap: Record<Keys, IconNames> = {
  none: 'info-circle',
  pending: 'history',
  failed: 'warning',
  passed: 'check',
  expired: 'warning',
  more_info_needed: 'info-circle',
  not_started: 'info-circle',
  pending_watchlist: 'history',
  marked_for_review: 'warning',
};

export const CbBackgroundCheckState = {
  code: Codebooks.BACKGROUND_CHECK_STATE,
  items: Items,
  getLabel: (
    Component: CbLabelComponent,
    codebookItem: CodebookItem | undefined
  ) => {
    if (!codebookItem || !codebookItem.code || !codebookItem.name)
      return Component({
        variant: LabelMap.none,
        label: 'None',
        iconProps: { name: 'info-circle' },
      });

    return Component({
      variant: LabelMap[(codebookItem.code as Keys) ?? 'none'],
      label: codebookItem.name,
      iconProps: { name: LabelIconMap[(codebookItem.code as Keys) ?? 'none'] },
    });
  },
};
