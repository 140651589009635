import React from 'react';

import { Divider } from '@npm/core/ui/components/atoms/Divider';
import { FormattableInput } from '@npm/core/ui/components/atoms/FormattableInput';
import {
  FormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import {
  CharLimitHelper,
  Input,
  TextArea,
} from '@npm/core/ui/components/atoms/Input';
import { Radio } from '@npm/core/ui/components/atoms/Radio';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import { formatABARoutingNumber } from '@npm/core/ui/utils/formatters';
import {
  abaFormItemValidationRules,
  swiftBicCodeFormItemValidationRule,
} from '@npm/core/ui/utils/validators';
import { CbIntermediaryRoutingType } from '@npm/data-access';

import { INTERNATIONAL_FORM_FIELDS_ENUM } from '../../BankAccountInformation.types';
import { useAlternateBeneficiary } from '../BankAccountInformationForm.hooks';

import * as S from '../BankAccountInformationForm.styles';

const abaRoutingCodebookString = `${CbIntermediaryRoutingType.code}.${CbIntermediaryRoutingType.items['aba-routing']}`;
const swiftCodeCodebookString = `${CbIntermediaryRoutingType.code}.${CbIntermediaryRoutingType.items['swift-code']}`;

type Props = {
  allowAlternateBeneficiary?: boolean;
  accountNameExtraDetails: React.ReactNode;
};

export const InternationalFormFields = ({
  allowAlternateBeneficiary = false,
  accountNameExtraDetails,
}: Props) => {
  const { isBeneficiaryEditEnabled, checkboxComponent } =
    useAlternateBeneficiary(allowAlternateBeneficiary);

  return (
    <S.SectionSpacer $size="md">
      <S.FormItem
        label="Do you require an Intermediary / Correspondent Bank?"
        name={INTERNATIONAL_FORM_FIELDS_ENUM.REQUIRE_INTERMEDIARY_BANK}
        initialValue={false}
        extra="Please contact your bank to determine if you require intermediary/correspondent bank information for wire transfers. This routing number is not likely to be the same number used for checks for direct deposit."
      >
        <S.RadioGroup>
          <Radio label="No" value={false} />
          <Radio label="Yes" value={true} />
        </S.RadioGroup>
      </S.FormItem>

      <S.SectionSpacer $size="lg">
        <Divider />
      </S.SectionSpacer>

      <FormItem noStyle shouldUpdate>
        {form =>
          form.getFieldValue(
            INTERNATIONAL_FORM_FIELDS_ENUM.REQUIRE_INTERMEDIARY_BANK
          ) && (
            <div>
              <Heading variant="h3" marginBottom="xl">
                Intermediary Bank Information
              </Heading>
              <S.FlexWrap>
                <S.FormItem
                  label="Bank Routing"
                  name={
                    INTERNATIONAL_FORM_FIELDS_ENUM.INTERMEDIARY_ROUTING_TYPE
                  }
                  initialValue={abaRoutingCodebookString}
                >
                  <S.RadioGroup>
                    <Radio
                      label="ABA Routing Number"
                      value={abaRoutingCodebookString}
                    />
                    <Radio label="SWIFT Code" value={swiftCodeCodebookString} />
                  </S.RadioGroup>
                </S.FormItem>

                <FormItem noStyle shouldUpdate>
                  {form =>
                    form.getFieldValue(
                      INTERNATIONAL_FORM_FIELDS_ENUM.INTERMEDIARY_ROUTING_TYPE
                    ) === swiftCodeCodebookString ? (
                      <S.FormItem
                        label="SWIFT Code"
                        name={
                          INTERNATIONAL_FORM_FIELDS_ENUM.INTERMEDIARY_ROUTING_NUMBER
                        }
                      >
                        <Input placeholder="1234567890" />
                      </S.FormItem>
                    ) : (
                      <S.FormItem
                        label="ABA Routing Number"
                        name={
                          INTERNATIONAL_FORM_FIELDS_ENUM.INTERMEDIARY_ROUTING_NUMBER
                        }
                        extra="Please note your ABA routing number for wires is sometimes different from the ABA routing number that appear on your checks."
                        rules={abaFormItemValidationRules}
                      >
                        <FormattableInput
                          placeholder="123456789"
                          formatter={formatABARoutingNumber}
                          inputMode="numeric"
                        />
                      </S.FormItem>
                    )
                  }
                </FormItem>
              </S.FlexWrap>

              <S.SectionSpacer $size="md">
                <S.FormItem
                  label="Bank Name"
                  name={INTERNATIONAL_FORM_FIELDS_ENUM.INTERMEDIARY_BANK_NAME}
                >
                  <Input placeholder="Bank Name" />
                </S.FormItem>
              </S.SectionSpacer>
            </div>
          )
        }
      </FormItem>

      <S.SectionSpacer $size="md">
        <Heading variant="h3" marginBottom="xl">
          Bank Account Information
        </Heading>
        <S.FlexWrap>
          <S.FormItem
            label="SWIFT/BIC Code"
            name={INTERNATIONAL_FORM_FIELDS_ENUM.SWIFT_CODE}
            rules={swiftBicCodeFormItemValidationRule}
          >
            <Input placeholder="1234567890" />
          </S.FormItem>
          <S.FormItem
            label="Bank Name"
            name={INTERNATIONAL_FORM_FIELDS_ENUM.BANK_NAME}
          >
            <Input placeholder="Bank Name" />
          </S.FormItem>
        </S.FlexWrap>
        <S.FlexWrap>
          <S.FormItem
            label="Name on Account"
            name={INTERNATIONAL_FORM_FIELDS_ENUM.BENEFICIARY_NAME}
            extra={
              <>
                {accountNameExtraDetails}
                {checkboxComponent}
              </>
            }
          >
            <Input
              placeholder="Name on Account"
              disabled={!isBeneficiaryEditEnabled}
            />
          </S.FormItem>
        </S.FlexWrap>
        <S.FlexWrap>
          <S.FormItem
            label="Bank Account Identifier"
            name={INTERNATIONAL_FORM_FIELDS_ENUM.IBAN}
            initialValue={false}
          >
            <S.RadioGroup>
              <Radio label="Bank Account Number" value={false} />
              <Radio label="IBAN" value={true} />
            </S.RadioGroup>
          </S.FormItem>
          <FormItem noStyle shouldUpdate>
            {form => {
              const isBankNumber = !form.getFieldValue(
                INTERNATIONAL_FORM_FIELDS_ENUM.IBAN
              );
              return (
                <S.FormItem
                  label={isBankNumber ? 'Bank Account Number' : 'IBAN'}
                  extra={
                    !isBankNumber &&
                    'Many international banks require an IBAN to process incoming wires correctly. If your bank requires an IBAN and it is not provided, the wire may be delayed or rejected, and it may result in bank service fees'
                  }
                  name={INTERNATIONAL_FORM_FIELDS_ENUM.ACCOUNT_NUMBER}
                >
                  <Input placeholder="1234567890" />
                </S.FormItem>
              );
            }}
          </FormItem>
        </S.FlexWrap>
      </S.SectionSpacer>

      <Divider />

      <S.SectionSpacer $size="md">
        <S.FormItem
          name={INTERNATIONAL_FORM_FIELDS_ENUM.ADDITIONAL_INSTRUCTIONS}
          required={false}
          rules={[VALIDATION_RULES.max(35)]}
          label="Additional Wiring Instructions"
          requiredMark="optional"
          extra={
            <CharLimitHelper
              fieldName={INTERNATIONAL_FORM_FIELDS_ENUM.ADDITIONAL_INSTRUCTIONS}
              limit={35}
            />
          }
        >
          <TextArea placeholder="Wiring Instructions" />
        </S.FormItem>
      </S.SectionSpacer>
    </S.SectionSpacer>
  );
};
