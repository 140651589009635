import { Codebooks } from './codebooks.types';

const Items = {
  not_accredited: 'not_accredited',
  not_started: 'not_started',
  accredited_investor: 'accredited_investor',
  qualified_institutional_buyer: 'qualified_institutional_buyer',
  qualified_purchaser: 'qualified_purchaser',
  qualified_client: 'qualified_client',
} as const;

export const CbAccreditationFilter = {
  code: Codebooks.ACCREDITATION_FILTER,
  items: Items,
};
